import { green, red } from "@ant-design/colors"
import { LinkOutlined, TeamOutlined } from "@ant-design/icons"
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd"
import { ColumnsType } from "antd/lib/table"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useGetUsersV2 } from "../apis/user"
import { AdminIcon } from "../assets/AdminIcon"
import { CopyContent } from "../components/Utils/CopyContent"
import { useAuthContext } from "../context/AuthContext"
import { useDebounce } from "../hooks/useDebounce"
import { UserRecordV2 } from "../types/user"

type searchType = "email" | "id" | "name" | "teamId"
type Props = {
  teamId: string
}
export const UserInTeamList = ({ teamId }: Props) => {
  const { token, env } = useAuthContext()
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [searchProp, setSearchProp] = useState<searchType>("teamId")
  const debouncedSearch = useDebounce(searchTerm, 400)
  const navigate = useNavigate()
  const users = useGetUsersV2(
    {
      env,
      token: token[env],
    },
    debouncedSearch
  )

  const isUserDeleted = (
    record: UserRecordV2,
    value?: string | JSX.Element
  ) => {
    return record.email.includes("deleted__") ? (
      <p style={{ color: red.primary }}>{value}</p>
    ) : (
      value
    )
  }
  const columns: ColumnsType<UserRecordV2> = [
    {
      title: "Name",
      render: (_, e) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {isUserDeleted(e, e.name)}
          {e.roleNames.includes("sysadmin") && (
            <Tooltip title="System Admin">
              <AdminIcon />
            </Tooltip>
          )}
        </div>
      ),
      sorter: (a, b) => {
        if (!a.name) return 1
        if (!b.name) return -1
        return a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
      },
      defaultSortOrder: "ascend",
      key: "name",
      onCell: (record, rowIndex) => {
        return { onClick: () => navigate(`/users/${record.id}`) }
      },
    },
    {
      title: "Team",
      render: (_, e) => (
        <Row gutter={4} wrap={false}>
          <Col>{isUserDeleted(e, e.team.name)}</Col>
          <Col>
            <CopyContent
              //@ts-ignore
              icon={<TeamOutlined />}
              text={e.team.id}
              hideText
              tooltip={`Copy team id`}
            />
          </Col>
          <Col>
            <Tooltip title="Go to team page">
              <Button
                size="small"
                onClick={() => navigate(`/teams/${e.team.id}`)}
                //@ts-ignore
                icon={<LinkOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
      sorter: (a, b) => {
        if (!a.team.name) return 1
        if (!b.team.name) return -1
        return a.team.name.toLocaleLowerCase() < b.team.name.toLocaleLowerCase()
          ? -1
          : 1
      },
      defaultSortOrder: "ascend",
      key: "name",
    },
    {
      title: "Email",
      render: (_, e) => isUserDeleted(e, <CopyContent text={e.email} />),
      sorter: (a, b) => {
        if (!a.email) return 1
        if (!b.email) return -1
        return a.email.toLocaleLowerCase() < b.email.toLocaleLowerCase()
          ? -1
          : 1
      },
      defaultSortOrder: "ascend",
      key: "email",
    },
    {
      title: "Id",

      render: (_, e) => isUserDeleted(e, <CopyContent text={e.id} />),
      sorter: (a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1),
      key: "id",
    },
    {
      title: "Active",

      render: (_, e) => (
        <Row justify="center">
          <Tag
            style={{
              background: e.activeAccount ? green.primary : red.primary,
              color: "white",
            }}
          >
            {e.activeAccount ? "Yes" : "No"}
          </Tag>
        </Row>
      ),
      sorter: (a, b) => (a.activeAccount < b.activeAccount ? -1 : 1),
      key: "isActive",
    },
  ]

  return (
    <Card title={"Users"}>
      <Row
        gutter={0}
        style={{ marginBottom: 10, display: "flex", gap: "10px" }}
        align={"middle"}
      >
        <Col xs={3}>
          <Button
            style={{ width: "100%" }}
            onClick={() => {
              setSearchProp("teamId")
              setSearchTerm(teamId)
            }}
            type="primary"
          >
            Load All Users
          </Button>
        </Col>
        <Col xs={3}>
          <Select
            value={searchProp}
            onChange={(e: searchType) => setSearchProp(e)}
            style={{ width: "100%" }}
          >
            <Select.Option value="email">Email</Select.Option>
            <Select.Option value="id">Id</Select.Option>
            <Select.Option value="name">Name</Select.Option>
            <Select.Option value="teamId">Team Id</Select.Option>
          </Select>
        </Col>
        <Col flex="auto">
          <Input
            placeholder="Type to search..."
            onChange={(e) => {
              setSearchTerm(e.target.value)
            }}
            style={{ width: "100%" }}
          />
        </Col>
        <Col>
          <Typography.Text
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            Displaying:
            <br />{" "}
            <strong style={{ fontSize: 16 }}>
              {users.data
                ? users.data.totalRecords + "/" + users.data.totalRecords
                : "0/0"}
            </strong>
          </Typography.Text>
        </Col>
      </Row>
      <Row>
        <Table
          style={{ minWidth: "100%" }}
          columns={columns}
          rowKey={(record) => `${record.id}-${record.team.id}`}
          dataSource={
            users.data
              ? users.data.records.filter((user) => user.team.id === teamId)
              : []
          }
          loading={users.isLoading && users.fetchStatus !== "idle"}
          scroll={{ y: "calc(100vh - 365px)" }}
          pagination={false}
          rowClassName={(record, index) =>
            record.email.includes("deleted__") ? "table-row-deleted" : ""
          }
        />
      </Row>
    </Card>
  )
}
