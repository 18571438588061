import { useEffect, useMemo, useState } from "react"
import { ColumnsType } from "antd/lib/table"
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Tag,
  Tooltip,
  Table,
  Spin,
  Card,
} from "antd"
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons"
import { SubscribablesList } from "../widgets/SubscribablesList"
import { useAuthContext } from "../../../context/AuthContext"
import { SubscriptionV2 } from "../../../types/Subscription"
import { useSearchTags } from "../../../utils/useSearchTags"
import {
  useBuckets,
  useDeleteSubscriptionV2,
  useSubscriptionsV2,
  useUserBucketPreferences,
} from "../../../apis/subscriptions"
import { filterBySearchValueAndTag } from "../../Utils/search"
import PreferenceUserIcon from "../../../VizznIcons/PreferenceUserIcon"
import { entityTypeMapping } from "../../../utils/utils"
import UpdateActivityFeedNotification from "./UpdateActivityFeedNotification"
import { PreferenceModal } from "./PreferenceModal"

type Props = {
  userId?: string
  isUserDetail: boolean
}

export const SubscriptionListV2 = ({ userId }: Props) => {
  const { env, token, user: userState } = useAuthContext()

  const id = userId || userState[env]!.uid
  /*   const user = useGetUserData({ userId: id, env, token: token[env] })
   */ const subscriptions = useSubscriptionsV2({
    env,
    token: token[env],
    id,
  })
  const systemBuckets = useBuckets({ env, token: token[env], id: id })
  const preference = useUserBucketPreferences({
    env,
    token: token[env],
    id: id,
  })
  const { mutateAsync: deleteUserSubscriptionList } =
    useDeleteSubscriptionV2(id)

  const { tags, addTag, removeTag } = useSearchTags("subscriptions")
  const [searchValue, setSearchValue] = useState("")
  const [filteredData, setFilteredData] = useState<{
    [key: string]: SubscriptionV2[]
  }>({})
  const [isSubscriptionsModalOpen, setIsSubscriptionsModalOpen] =
    useState(false)
  const subscriptionsIds = subscriptions.data?.map(({ id }) => id)
  const columns: ColumnsType<SubscriptionV2> = useMemo(
    () => [
      {
        title: "Name",
        align: "left",
        showHeader: false,
        render: (_, e) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div>{e.entity.name}</div>
              {!e.isDefault ? (
                <Tooltip title="Customized Preference">
                  <div>
                    <PreferenceUserIcon
                      color="#19b1b4"
                      width={20}
                      height={15}
                    />
                  </div>
                </Tooltip>
              ) : null}
            </div>
            <Row
              justify="center"
              className="icon-buttons"
              gutter={4}
              wrap={false}
              style={{ display: "flex", gap: "5px" }}
            >
              {/*  <Col>
                <Tooltip
                  title={
                    e.isPrimary
                      ? "Remove primary subscription"
                      : "Make " + e.entity.name + " a primary subscription"
                  }
                >
                  <Button
                    type={"primary"}
                    onClick={async () =>
                      await setPrimarySubscription({
                        isPrimary:
                          user.data?.primarySubscription !== e.entity.id,
                        userId: userId ? userId : user.data?.id || "",
                        id: e.id,
                        token: token[env],
                        env,
                      })
                    }
                  >
                    {e.isPrimary ? <StarFilled /> : <StarOutlined />}
                  </Button>
                </Tooltip>
              </Col> */}
              <Col>
                <Tooltip title={"feed"}>
                  <UpdateActivityFeedNotification
                    id={e.id}
                    isDefault={e.isDefault}
                    userId={userId ?? ""}
                    allEntityBuckets={
                      systemBuckets.data?.filter(
                        (bucket) => bucket.entityType === e.entityType
                      ) || []
                    }
                    userFeedBuckets={e.feedBuckets}
                    userNotificationBuckets={e.notiBuckets}
                    entityName={e.entity.name}
                    token={token[env]}
                    env={env}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title={"Unsubscribe from " + e.entity.name}>
                  <Button
                    type={"primary"}
                    onClick={() => {
                      subscriptionsIds?.includes(e.id)
                      deleteUserSubscriptionList({
                        subscriptionId: e.id,
                        id: e.id,
                        token: token[env],
                        env,
                      })
                    }}
                  >
                    <CloseCircleOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </div>
        ),
        key: "subscription-list",
      },
    ],
    [
      userId,
      systemBuckets.data,
      token,
      env,
      subscriptionsIds,
      deleteUserSubscriptionList,
    ]
  )
  const groupByEntityType = (data: SubscriptionV2[]) => {
    return data.reduce((acc, subscription) => {
      const { entityType } = subscription
      if (!acc[entityType]) {
        acc[entityType] = []
      }
      acc[entityType].push(subscription)
      acc[entityType].sort((a, b) => (a.entity.name > b.entity.name ? 1 : -1))
      return acc
    }, {} as { [key: string]: SubscriptionV2[] })
  }
  useEffect(() => {
    if (!subscriptions.data || !subscriptions.dataUpdatedAt) return
    const searchFields = ["name"]

    let filteredSubscriptions = [...subscriptions.data]
    if (tags.length || searchValue)
      filteredSubscriptions = filterBySearchValueAndTag<SubscriptionV2>({
        tags,
        searchValue,
        searchFields,
        dataArray: filteredSubscriptions,
      })

    setFilteredData(groupByEntityType(filteredSubscriptions))
  }, [searchValue, subscriptions.data, tags, subscriptions.dataUpdatedAt])

  if (subscriptions.isLoading || subscriptions.error || !subscriptions.data)
    if (systemBuckets.isLoading || systemBuckets.error || !systemBuckets.data)
      if (preference.isLoading || preference.error || !preference.data)
        return <Spin />

  return (
    <Card title="Subscriptions V2">
      <div className="SubscriptionList">
        <div
          className="list-page"
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <Row gutter={16} wrap={false}>
            <Col flex="auto">
              <Input
                className="list-search-input"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Type to search..."
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    addTag(searchValue)
                    setSearchValue("")
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && !searchValue && tags.length) {
                    removeTag(tags[tags.length - 1])
                  }
                }}
                addonBefore={
                  tags
                    ? tags.map((name) => (
                        <Tag
                          key={`sorting-tag-${name}`}
                          color="grey"
                          closable
                          onClose={() => removeTag(name)}
                        >
                          {name}
                        </Tag>
                      ))
                    : null
                }
                addonAfter={<SearchOutlined />}
              />
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => setIsSubscriptionsModalOpen(true)}
              >
                New Subscription
              </Button>
            </Col>
          </Row>
          {Object.keys(filteredData)
            .sort((a, b) =>
              (entityTypeMapping[a] || a).localeCompare(
                entityTypeMapping[b] || b
              )
            )
            .map((entityType) => (
              <div key={entityType}>
                <h3
                  style={{ display: "flex", alignItems: "center", margin: 0 }}
                >
                  {entityTypeMapping[entityType] || entityType}

                  <PreferenceModal
                    systemBuckets={
                      systemBuckets.data?.filter(
                        (bucket) => bucket.entityType === entityType
                      ) || []
                    }
                    userBucketPreferences={
                      preference.data?.filter(
                        (bucket) => bucket.entityType === entityType
                      ) || []
                    }
                    refetchSystemBuckets={systemBuckets.refetch}
                    refetchUserBucketPreferences={preference.refetch}
                    isRefetching={
                      systemBuckets.isFetching || preference.isFetching
                    }
                    userId={id}
                  />
                </h3>
                <Table
                  showHeader={false}
                  columns={columns}
                  dataSource={filteredData[entityType]}
                  pagination={false}
                  rowKey="id"
                  size="small"
                />
              </div>
            ))}
        </div>
        {isSubscriptionsModalOpen && (
          <Modal
            title="Add Subscriptions"
            open={isSubscriptionsModalOpen}
            onCancel={() => setIsSubscriptionsModalOpen(false)}
            onOk={() => setIsSubscriptionsModalOpen(false)}
            footer={null}
            width="80vw"
          >
            <SubscribablesList userId={userId} />
          </Modal>
        )}
      </div>
    </Card>
  )
}
