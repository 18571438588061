import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {
  AccountApplicationDetailView,
  AccountApplicationFindResponseDto,
  AccountApplicationUpdateArgs,
  AccountApplicationUpdateResponseDto,
  VizznResponse,
} from "../types/AccountApplication";
import { environmentType } from "../types/environtment";
import { getEnvUrl } from "../utils/utils";
import { message } from "antd";
const MESSAGE_API_MARGIN_TOP = 80;

const getAccountApplications = async (
  env: environmentType,
  token: string | null
) => {
  const { data }: { data: VizznResponse<AccountApplicationFindResponseDto> } =
    await axios.get(`${getEnvUrl(env)}/v1/account-applications`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  return data.data;
};

export const useGetAccountApplications = (
  env: environmentType,
  token: string | null
) => {
  return useQuery<AccountApplicationFindResponseDto>(["applications"], () =>
    getAccountApplications(env, token)
  );
};

const getAccountApplication = async (
  env: environmentType,
  token: string | null,
  id: string | undefined
) => {
  const { data }: { data: VizznResponse<AccountApplicationDetailView> } =
    await axios.get(`${getEnvUrl(env)}/v1/account-applications/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  return data.data;
};

export const useGetAccountApplication = (
  env: environmentType,
  token: string | null,
  id: string | undefined
) => {
  return useQuery(["applications", id], () =>
    getAccountApplication(env, token, id)
  );
};

const updateAccountApplication = async (
  body: AccountApplicationUpdateArgs & {
    config?: { env: environmentType; token: string | null };
  }
) => {
  const env = body.config?.env;
  const token = body.config?.token;

  if (!env || !token) throw new Error("env or token is undefined");

  delete body.config;

  const { data }: { data: VizznResponse<AccountApplicationUpdateResponseDto> } =
    await axios.put(
      `${getEnvUrl(env)}/v1/account-applications/${body.id}`,
      {
        id: body.id,
        organization: body.organization,
        owner: body.owner,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

  return data.data;
};

export const useUpdateAccountApplication = () => {
  const queryClient = useQueryClient();

  return useMutation(updateAccountApplication, {
    onSuccess: (data) => {
      if (data.errors.length > 0) {
        message.error({
          content:
            "An error occured while updating account application. Please check error list.",
          style: {
            marginTop: MESSAGE_API_MARGIN_TOP,
          },
        });
        return;
      }

      message.success({
        content: "Account application updated",
        style: {
          marginTop: MESSAGE_API_MARGIN_TOP,
        },
      });

      queryClient.setQueryData(
        ["applications", data.record.id],
        () => data.record
      );
      return;
    },
    onError: (error) => {
      message.error({
        content: "An error occured while updating account application",
        style: {
          marginTop: MESSAGE_API_MARGIN_TOP,
        },
      });
    },
  });
};

const accountApplicationAction = async (body: {
  id: string;
  action: "approve" | "reject";
  rejectionNote?: string;
  config: { env: environmentType; token: string | null };
}) => {
  const { data } = await axios.post(
    `${getEnvUrl(body.config?.env)}/v1/account-applications/${body.id}/${
      body.action
    }`,
    {
      id: body.id,
      action: body.action,
      rejectionNote: body.rejectionNote,
    },
    {
      headers: {
        Authorization: `Bearer ${body.config.token}`,
      },
    }
  );

  return data;
};

export const useAccountApplicationAction = () => {
  const queryClient = useQueryClient();

  return useMutation(accountApplicationAction, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["applications"]);

      message.success({
        content: "Account application updated",
        style: {
          marginTop: MESSAGE_API_MARGIN_TOP,
        },
      });
    },
    onError: (error) => {
      message.error({
        content: "An error occured while updating account application",
        style: {
          marginTop: MESSAGE_API_MARGIN_TOP,
        },
      });
    },
  });
};
