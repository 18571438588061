import { CheckOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Form, Input, Tag } from "antd"
import { useState } from "react"
import { useUpdateUserEmail } from "../../../../apis/user"
import { useAuthContext } from "../../../../context/AuthContext"
import { RowContent } from "../../../Utils/RowContent"

type Props = {
  id: string
  email: string
  verified: boolean
}

type FormValues = {
  email: string
}

export default function UserEmailWidget({ email, id, verified }: Props) {
  const { token, env } = useAuthContext()

  const { mutateAsync: updateUserEmail } = useUpdateUserEmail(
    id,
    env,
    token[env]
  )

  const [isEditing, setIsEditing] = useState(false)
  const [form] = Form.useForm<FormValues>()

  const onSubmit = async (values: FormValues) => {
    try {
      if (values.email && values.email !== email)
        await updateUserEmail({ id: id, email: values.email })
    } finally {
      setIsEditing(false)
    }
  }

  return (
    <RowContent
      name="Email"
      content={
        isEditing ? (
          <Form
            form={form}
            onFinish={onSubmit}
            style={{ display: "flex", gap: 10, alignItems: "center" }}
          >
            <Form.Item<FormValues>
              name="email"
              initialValue={email}
              style={{ margin: 0, width: "200px" }}
            >
              <Input style={{ width: "200px" }} />
            </Form.Item>
            <Button size="small" type="primary" htmlType="submit">
              <CheckOutlined />
            </Button>
          </Form>
        ) : (
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <b>{email}</b>
            <Tag
              style={{
                color: "white",
                margin: 0,
                background: verified ? "green" : "red",
              }}
            >
              {verified ? "Verified" : "Unverified"}
            </Tag>
            <Button size="small" onClick={() => setIsEditing(true)}>
              <EditOutlined />
            </Button>
          </div>
        )
      }
    />
  )
}
