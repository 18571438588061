import Link from "antd/lib/typography/Link"

type Props = {
  href: string
  label: string
}

export default function TargetBlankLink({ href, label }: Props) {
  return (
    <Link href={href} target="_blank" rel="noopener noreferrer">
      {label}
    </Link>
  )
}
