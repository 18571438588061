import { Card, Checkbox } from "antd"
import { UsageScore } from "../../../types/UsageScores"
import { convertNumberToThousands } from "../../../utils/hooks/convertNumberToThousands"
import "./CategoryUsageScoreCards.scss"

type CategoryUsageScoreCardsProps = {
  teamUsageScoreData: UsageScore[]
  selectedCategories: { [key: string]: boolean }
  onCategoryToggle: (category: string) => void
  strokeColors: { [category: string]: string }
}

export default function CategoryUsageScoreCards({
  teamUsageScoreData,
  selectedCategories,
  onCategoryToggle,
  strokeColors,
}: CategoryUsageScoreCardsProps) {
  const totalScoreByCategory = teamUsageScoreData?.reduce(
    (acc, cur: UsageScore) => {
      if (acc[cur.category.name]) {
        acc[cur.category.name] += cur.score
      } else {
        acc[cur.category.name] = cur.score
      }
      return acc
    },
    {} as { [key: string]: number }
  )

  return (
    <div className="category-score-container">
      {totalScoreByCategory &&
        Object.entries(totalScoreByCategory).map(([key, value], index) => {
          return (
            <Card
              key={key}
              title={`${key}`}
              className="usage-card"
              style={{
                border: `2px solid ${strokeColors[key]}80`,
                flex: 1,
              }}
              styles={{
                header: { padding: 0 },
                body: { padding: 0, fontSize: "40px", textAlign: "center" },
              }}
              extra={
                <Checkbox
                  checked={!!selectedCategories[key]}
                  onChange={() => onCategoryToggle(key)}
                  style={{ marginLeft: "10px" }}
                />
              }
            >
              {convertNumberToThousands(value.toString())}
            </Card>
          )
        })}
    </div>
  )
}
