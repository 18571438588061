import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Tag,
  Table,
  Form,
  Modal,
  Popconfirm,
  message,
  Upload,
  Checkbox,
} from "antd"
import React, { useState, useEffect } from "react"

import { ColumnsType } from "antd/lib/table"
import {
  DeleteOutlined,
  EditOutlined,
  DownloadOutlined,
  FileAddTwoTone,
  CheckOutlined,
  TransactionOutlined,
  CloseOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons"

import { useAuthContext } from "../../context/AuthContext"
import { useDebounce } from "../../hooks/useDebounce"
import {
  TranslationKeyObject,
  TranslationLanguageList,
} from "../../types/Translations"
import {
  getTextTranslation,
  useCreateTranslation,
  useDeleteTranslationKey,
  useGetTranslations,
  useApproveTranslation,
} from "../../apis/Translation"
import { GetError } from "../../widgets/GetError"
import { downloadFile } from "../../utils/downloadFile"
import { languagesList } from "../../constants/languages"
import { RcFile } from "antd/es/upload"

type TranslationDataRow = TranslationKeyObject & {
  key: string
}
type FormData = {
  [lang in TranslationLanguageList | "key"]: string | string[]
}
type Tags = {
  [lang in TranslationLanguageList]?: string[]
}
type Search = {
  [lang in TranslationLanguageList]?: string
}

let bulkTranslateProcessing = false
let bulkUploadProcessing = false

export const TranslationLists = () => {
  const { token, env } = useAuthContext()
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [uploadCount, setUploadCount] = useState<string>("")
  const [tab, setTab] = useState<"total" | "unapproved" | "missing">("total")
  const [searchValue, setSearchValue] = useState<Search>({})
  const [tags, setTags] = useState<Tags>({})
  const [translationsData, settranslationsData] = useState<{
    total: TranslationDataRow[]
    unapproved: TranslationDataRow[]
    missing: TranslationDataRow[]
  }>({ total: [], unapproved: [], missing: [] })
  const [language, setLanguage] = useState<TranslationLanguageList>("en")
  const debouncedSearch = useDebounce(searchTerm, 400)
  const [editTranslation, setEditTranslation] =
    useState<TranslationDataRow | null>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [currentBoard, setCurrentBoard] = useState<string>("web-v2")
  const [loading, setLoading] = useState<boolean>(false)
  const { mutateAsync: createTranslation } = useCreateTranslation()
  const { mutateAsync: approveTranslation } = useApproveTranslation()
  const { mutateAsync: deleteTranslation } = useDeleteTranslationKey()
  const [form] = Form.useForm<FormData>()
  const setFields = form.setFieldsValue

  const translations = useGetTranslations({
    env,
    token: token[env],
    currentBoard,
  })

  const getTranslation = async (
    text: string | string[],
    lang: TranslationLanguageList
  ) => {
    if (typeof text === "string") {
      const translation = await getTextTranslation({
        env: "dev",
        token: token["dev"],
        payload: {
          source: "en",
          target: lang,
          text,
        },
      })
      if (translation) {
        return translation.translation
      } else {
        message.error("Translation error!")
      }
    } else if (typeof text === "object") {
      const value: string[] = []
      for (let str of text) {
        const translation = await getTextTranslation({
          env: "dev",
          token: token["dev"],
          payload: {
            source: "en",
            target: lang,
            text: str,
          },
        })
        if (translation) {
          value.push(translation?.translation)
        }
      }
      if (value.length === text.length) {
        return value
      } else {
        message.error("Translation error!")
      }
    }
    return ""
  }
  const handleCreateTranslation = async (
    key: string,
    value: string | string[]
  ) => {
    if (!/^[a-z0-9.-_]+$/i.test(key)) {
      message.error(`Invalid key ${key}`)
      return
    }
    await Promise.allSettled([
      createTranslation({
        env: "dev",
        token: token["dev"],
        currentBoard,
        payload: { key, value, lang: language },
      }),
      createTranslation({
        env: "prod",
        token: token["prod"],
        currentBoard,
        payload: { key, value, lang: language },
      }),
    ])
  }
  const handleTranslate = async (key: string) => {
    if (!isLogin()) return
    const text = translations.data?.json[key]?.en?.value
    if (!text) {
      message.error("English text doesn't exists")
      return
    }
    const value = await getTranslation(text, language)
    if (!value) {
      message.error("Couldn't get translation")
      return
    }
    await handleCreateTranslation(key, value)
  }

  const handleTranslateMissingKeys = async () => {
    bulkTranslateProcessing = true
    for (const data of translationsData.missing) {
      if (bulkTranslateProcessing) {
        await handleTranslate(data.key)
      }
    }
    bulkTranslateProcessing = false
  }

  const handleApproveTranslation = async (key: string) => {
    if (!isLogin()) return
    await Promise.allSettled([
      approveTranslation({
        env: "dev",
        token: token["dev"],
        currentBoard,
        key,
        lang: language,
      }),
      approveTranslation({
        env: "prod",
        token: token["prod"],
        currentBoard,
        key,
        lang: language,
      }),
    ])
    message.success("Translation marked as approved")
  }

  const handleSubmitForm = async (values: FormData) => {
    if (!isLogin()) return
    setLoading(true)
    let enText: string | string[] = ""
    Object.keys(languagesList).forEach(async (lang) => {
      let selectedValue
      if (
        tags[lang as TranslationLanguageList] &&
        tags[lang as TranslationLanguageList]?.length
      ) {
        selectedValue = tags[lang as TranslationLanguageList]
      } else if (
        searchValue[lang as TranslationLanguageList] &&
        searchValue[lang as TranslationLanguageList]?.length
      ) {
        selectedValue = searchValue[lang as TranslationLanguageList]
      } else {
        selectedValue = values[lang as TranslationLanguageList]
      }
      if (lang === "en") {
        enText = selectedValue || ""
      } else if (!selectedValue && enText) {
        selectedValue = await getTranslation(
          enText,
          lang as TranslationLanguageList
        )
      }
      if (!selectedValue) {
        message.error("No value to insert")
        return
      }
      const payload = {
        key: values.key as string,
        value: selectedValue as string | string[],
        lang,
      }
      if (
        !translations.data?.json[payload.key] ||
        JSON.stringify(
          translations.data?.json[payload.key][lang as TranslationLanguageList]
            ?.value
        ) !== JSON.stringify(payload.value)
      ) {
        handleCreateTranslation(payload.key, selectedValue)
      }
    })
    setModalOpen(false)
    message.success("Translation added successfully")
    setLoading(false)
    setEditTranslation(null)
    setSearchValue({})
    setTags({})
    form.resetFields()
  }
  const handleTranslationFileDownload = async () => {
    const file: { [key: string]: string | string[] } = {}
    translationsData[tab].forEach((row) => {
      file[row.key] = row[language]?.value || ""
    })
    downloadFile(
      JSON.stringify(file, null, 3),
      "json",
      `translations.${currentBoard}.${language}.json`
    )
  }

  const handleUploadTranlationFile = (file: RcFile, filename: string) => {
    if (file) {
      var reader = new FileReader()
      reader.readAsText(file, "UTF-8")
      reader.onload = async (evt) => {
        const uploadedJsonFile = JSON.parse(
          (evt.target?.result as string) || "{}"
        )
        if (!isLogin()) return
        let bulkUploadApproved = false
        Modal.confirm({
          width: 900,
          title: "Confirm",
          icon: <ExclamationCircleOutlined />,
          content: (
            <>
              File: <b>{filename}</b>
              <br />
              Project: <b>{currentBoard}</b>
              <br />
              Language: <b>{languagesList[language]}</b>
              <br />
              Keys: <b>{Object.keys(uploadedJsonFile).length}</b>
              <br />
              Are ALL keys Approved?{" "}
              <Checkbox
                onChange={(e) => {
                  bulkUploadApproved = e.target.checked
                }}
              />
              <pre
                style={{
                  display: "block",
                  height: 300,
                  overflow: "auto",
                  border: "2px solid #ccc",
                  marginTop: 10,
                }}
              >
                {JSON.stringify(uploadedJsonFile, undefined, 3)}
              </pre>
            </>
          ),
          okText: "Upload",
          cancelText: "Cancel",
          onOk: async (close) => {
            close()
            bulkUploadProcessing = true
            const totalKeys = Object.keys(uploadedJsonFile).length
            let processedKeys = 0
            for (let key in uploadedJsonFile) {
              if (
                bulkUploadProcessing &&
                (!translations.data?.json[key] ||
                  JSON.stringify(uploadedJsonFile[key]) !==
                    JSON.stringify(
                      translations.data?.json[key][language]?.value
                    ))
              ) {
                await handleCreateTranslation(key, uploadedJsonFile[key])
              }
              if (
                bulkUploadProcessing &&
                bulkUploadApproved &&
                new Date(
                  translations.data?.json[key][language].approvedAt || 0
                ) <=
                  new Date(
                    translations.data?.json[key][language].updatedAt || 0
                  )
              ) {
                await handleApproveTranslation(key)
              }
              processedKeys++
              setUploadCount(`${processedKeys} / ${totalKeys}`)
            }
            bulkUploadProcessing = false
            translations.refetch()
          },
        })
      }
      reader.onerror = (evt) => {
        message.error("Error reading file")
      }
    }
  }
  const isLogin = () => {
    if (!token.dev) {
      message.error("Please log in on Dev")
      return false
    }
    if (!token.prod) {
      message.error("Please log in on Prod")
      return false
    }
    return true
  }

  useEffect(() => {
    if (translations.data) {
      const translationsData: TranslationDataRow[] = []
      Object.keys(translations.data.json)
        .sort()
        .forEach((key) => {
          translationsData.push({ key, ...translations.data.json[key] })
        })
      const filteredData = translationsData.filter((item) => {
        return (
          item.key.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
          item[language]?.value
            .toString()
            .toLowerCase()
            .includes(debouncedSearch.toLowerCase())
        )
      })
      const unapproved = filteredData.filter(
        (row) =>
          row[language]?.value &&
          new Date(row[language]?.approvedAt || 0) <=
            new Date(row[language]?.updatedAt || 0)
      )
      const missing = filteredData.filter((row) => !row[language]?.value)
      settranslationsData({
        total: [...filteredData],
        unapproved: [...unapproved],
        missing: [...missing],
      })
    } else {
      settranslationsData({ total: [], unapproved: [], missing: [] })
    }
  }, [translations.data, translations.dataUpdatedAt, debouncedSearch, language])

  useEffect(() => {
    if (editTranslation !== null) {
      const tags: { [lang in TranslationLanguageList]?: string[] } = {}
      const fields: { [lang in TranslationLanguageList]?: string } = {}
      Object.keys(languagesList).forEach((lang) => {
        const lnValue = editTranslation[lang as TranslationLanguageList]?.value
        fields[lang as TranslationLanguageList] =
          typeof lnValue === "string" ? lnValue : ""
        if (typeof lnValue === "object") {
          tags[lang as TranslationLanguageList] = lnValue
        }
      })
      setFields({ ...fields, key: editTranslation.key })
      setTags(tags)
      setSearchValue(fields)
    }
  }, [language, editTranslation, setFields])

  const columns: ColumnsType<TranslationDataRow> = [
    {
      title: "Keys",
      dataIndex: ["key"],
      render: (_, e) => e.key,
      sorter: (a, b) => {
        return a.key.toLocaleLowerCase() < b.key.toLocaleLowerCase() ? -1 : 1
      },
      defaultSortOrder: "ascend",
      key: "keys",
    },
    {
      title: languagesList[language],
      dataIndex: [language],
      render: (_, e) => {
        const languageData = e[language]
        if (languageData) {
          if (Array.isArray(languageData.value)) {
            return (
              <>
                {languageData.value.map((esArray, index) => (
                  <Tag key={index}>{esArray}</Tag>
                ))}
              </>
            )
          } else {
            return languageData.value
          }
        } else {
          return ""
        }
      },
      key: "secondLanguage",
    },
    {
      title: "Action",
      width: 130,
      render: (_, e) => (
        <>
          <Button
            style={{ border: "none" }}
            icon={<EditOutlined />}
            onClick={() => {
              setEditTranslation(e)
            }}
          ></Button>
          {e[language]?.value && (
            <>
              {new Date(e[language].approvedAt || 0) <
              new Date(e[language].updatedAt) ? (
                <Button
                  title="Approve translation"
                  style={{
                    border: "none",
                    color: "#389e0d",
                  }}
                  icon={<CheckOutlined />}
                  onClick={() => {
                    handleApproveTranslation(e.key)
                  }}
                ></Button>
              ) : (
                <Button
                  title="Unapprove translation"
                  style={{
                    border: "none",
                    color: "#cf1322",
                  }}
                  icon={<CloseOutlined />}
                  onClick={() => {
                    if (!isLogin()) return
                    handleCreateTranslation(e.key, e[language].value)
                  }}
                ></Button>
              )}
            </>
          )}
          {!e[language]?.value && (
            <Button
              title="Auto translate"
              style={{
                border: "none",
                color: "#d48806",
              }}
              icon={<TransactionOutlined />}
              onClick={() => {
                handleTranslate(e.key)
              }}
            ></Button>
          )}
          <Popconfirm
            title={"This will remove all the translation. Are you sure?"}
            onConfirm={async (v) => {
              v?.stopPropagation()
              await Promise.allSettled([
                deleteTranslation({
                  env: "dev",
                  token: token["dev"],
                  currentBoard,
                  key: e.key,
                }),
                deleteTranslation({
                  env: "prod",
                  token: token["prod"],
                  currentBoard,
                  key: e.key,
                }),
              ])
              message.success("Translation deleted successfully")
            }}
            onCancel={(e) => e?.stopPropagation()}
            okText={"Yes"}
            cancelText={"No"}
          >
            <Button
              style={{ border: "none", color: "#aaa" }}
              icon={<DeleteOutlined />}
            ></Button>
          </Popconfirm>
        </>
      ),

      key: "action",
    },
    {
      title: "Status",
      width: 140,
      key: "status",
      render: (_, e) => {
        if (!e[language]?.value) return <Tag color="red">missing</Tag>
        const isApproved =
          new Date(e[language].approvedAt || 0) >
          new Date(e[language].updatedAt)
        return (
          <>
            {isApproved ? (
              <Tag color="green">approved</Tag>
            ) : (
              <Tag color="gold">not approved</Tag>
            )}
            <div style={{ color: "#aaa", fontSize: ".7rem" }}>
              {new Date(
                isApproved ? e[language].approvedAt || 0 : e[language].updatedAt
              ).toLocaleString("en-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour12: false,
                hour: "numeric",
                minute: "2-digit",
              })}
            </div>
          </>
        )
      },
    },
  ]

  return (
    <div>
      <Row gutter={0} style={{ marginBottom: 16 }}>
        <Col style={{ marginRight: "0.5vw" }}>
          <Select
            style={{ width: "200px" }}
            onChange={(e) => setCurrentBoard(e)}
            defaultValue={currentBoard}
            value={currentBoard}
          >
            <Select.Option
              key={"independent-pages"}
              value={"independent-pages"}
            >
              Independent pages
            </Select.Option>
            <Select.Option key={"mobile"} value={"mobile"}>
              Mobile
            </Select.Option>
            <Select.Option key={"web-v2"} value={"web-v2"}>
              Vizzn Web v2
            </Select.Option>
            <Select.Option key={"new"} value={"new"}>
              Test
            </Select.Option>
          </Select>
        </Col>
        <Col flex="auto">
          <Input
            placeholder="input search text"
            onChange={(e) => {
              setSearchTerm(e.target.value)
            }}
            style={{ width: "100%" }}
          />
        </Col>
        <Col>
          <Button
            type={tab === "total" ? "primary" : "default"}
            onClick={() => setTab("total")}
          >
            Total: {translationsData.total.length}
          </Button>
          <Button
            type={tab === "unapproved" ? "primary" : "default"}
            onClick={() => setTab("unapproved")}
          >
            Unapproved: {translationsData.unapproved.length}
          </Button>
          <Button
            type={tab === "missing" ? "primary" : "default"}
            onClick={() => setTab("missing")}
          >
            Missing: {translationsData.missing.length}
          </Button>
          {language !== "en" && (
            <Button
              title="Auto translate missing values"
              onClick={() => {
                if (bulkTranslateProcessing) {
                  bulkTranslateProcessing = false
                } else {
                  handleTranslateMissingKeys()
                }
              }}
            >
              <TransactionOutlined spin={bulkTranslateProcessing} />
            </Button>
          )}
          <Select
            defaultValue="en"
            style={{ width: 120 }}
            onChange={(e) => setLanguage(e as TranslationLanguageList)}
            options={Object.keys(languagesList).map((key) => ({
              value: key,
              label: languagesList[key as TranslationLanguageList],
            }))}
          />
          <Button onClick={() => translations.refetch()}>
            <ReloadOutlined spin={translations.isLoading} />
          </Button>
          <Button onClick={handleTranslationFileDownload}>
            <DownloadOutlined />
          </Button>
          <Button
            style={{ margin: "0 5px" }}
            type="primary"
            onClick={() => setModalOpen(true)}
          >
            Add Translation
          </Button>
          {!bulkUploadProcessing ? (
            <Upload
              customRequest={(options) =>
                handleUploadTranlationFile(
                  options.file as RcFile,
                  (options.file as RcFile).name || ""
                )
              }
              showUploadList={false}
            >
              <Button>
                <FileAddTwoTone />
              </Button>
            </Upload>
          ) : (
            <Button
              onClick={() => {
                bulkUploadProcessing = false
              }}
            >
              <CloseSquareOutlined /> {uploadCount}
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        {translations.isError ? (
          <GetError reFetch={translations.refetch} />
        ) : translationsData ? (
          <Table
            style={{ minWidth: "100%" }}
            columns={columns}
            rowKey="key"
            scroll={{ y: "calc(100vh - 280px)" }}
            dataSource={translationsData[tab]}
          />
        ) : translations.isLoading ? (
          <div>Loading...</div>
        ) : (
          <div>Select Project</div>
        )}
      </Row>
      {(modalOpen || editTranslation !== null) && (
        <Modal
          title={
            editTranslation !== null ? "Edit Translation" : "Add Translation"
          }
          open={modalOpen || editTranslation !== null}
          onCancel={() => {
            setModalOpen(false)
            setEditTranslation(null)
            setTags({})
            setSearchValue({})
            form.resetFields()
          }}
          footer={null}
        >
          <Form
            form={form}
            onFinish={handleSubmitForm}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault()
              }
            }}
          >
            <Form.Item label="Key" name="key">
              <Input />
            </Form.Item>
            {Object.keys(languagesList).map((lang) => (
              <Form.Item
                key={lang}
                label={languagesList[lang as TranslationLanguageList]}
                name={lang}
                initialValue={
                  editTranslation
                    ? editTranslation[lang as TranslationLanguageList]?.value ||
                      ""
                    : ""
                }
              >
                <Input
                  placeholder="If you are adding multiple values press enter after each value"
                  onChange={(e) =>
                    setSearchValue((prev) => ({
                      ...prev,
                      [lang]: e.currentTarget.value,
                    }))
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault()
                      setTags((prevTags) => ({
                        ...prevTags,
                        [lang]: [
                          ...(prevTags[lang as TranslationLanguageList] || []),
                          searchValue[lang as TranslationLanguageList],
                        ],
                      }))
                      setSearchValue((prev) => ({ ...prev, [lang]: "" }))
                    }
                  }}
                />
                {tags[lang as TranslationLanguageList]?.map((name, index) => (
                  <Tag
                    key={`tag-${lang}-${name}-${index}`}
                    className="list-tag-filter"
                    color="grey"
                    closable
                    onClose={() =>
                      setTags((prevTags) => ({
                        ...prevTags,
                        [lang]: prevTags[
                          lang as TranslationLanguageList
                        ]?.filter((tag) => tag !== name),
                      }))
                    }
                  >
                    {name}
                  </Tag>
                ))}
              </Form.Item>
            ))}
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  )
}
