import { environmentType } from "./environtment"
import { LanguageCode } from "./Language"
import { Dimension, DistanceUnits, Speed, VolumeUnits, Weight } from "./Team"

export interface VizznResponse<T> {
  detail: string
  status: number
  data: T
  title: string
}

export interface AccountApplicationFindResponseDto {
  total: number
  records: AccountApplicationMainListView[]
}

export interface AccountApplicationMainListView {
  id: string
  status: {
    id: AccountApplicationStatus
    name: string
    bgColor: string
    textColor: string
  }
  orgName: string
  requesterName: string
  requesterEmail: string
  requesterPhone?: string
  lastUpdatedAt: Date
  requestedAt: Date
}

export enum AccountApplicationStatus {
  Accepted = "accepted",
  Pending = "pending",
  Processing = "processing",
  Rejected = "rejected",
}

export interface AccountApplicationDetailView {
  acceptedTeamId?: string
  createdAt: Date
  id: string
  isArchived: boolean
  organization: RequestedOrganization
  owner: RequestedUser
  status: {
    id: AccountApplicationStatus
    name: string
    bgColor: string
    textColor: string
  }
  updatedAt: Date
  updatedBy?: UserDto
}
export interface RequestedOrganization {
  address: string
  coords: CoordinateMap
  name: string
  tag: string
  timezone: string
  type: AccountApplicationTypeTeam | string | undefined
  users: RequestedUser[]
  prefDimensionUnit: Dimension
  prefSpeedUnit: Speed
  prefWeightUnit: Weight
  prefDistanceUnit: DistanceUnits
  prefVolumeUnit: VolumeUnits
  prefLanguage: LanguageCode
}

export interface CoordinateMap {
  lat: number | undefined
  lng: number | undefined
}

export enum AccountApplicationTypeTeam {
  HeavyCivilConstruction = "Heavy Civil Construction",
  Supplier = "Supplier",
  TruckBroker = "Truck Broker",
}

export interface RequestedUser {
  firstName: string
  lastName: string
  email: string
  phone?: string
  accessRoles: UserRoles[]
  jobRoles: UserRoleTag[] | undefined | string[]
}

export enum UserRoles {
  Bidder = "bidder",
  Dispatcher = "dispatcher",
  Editor = "editor",
  Manager = "manager",
  Owner = "owner",
  Supervisor = "supervisor",
  SysAdmin = "sysadmin",
  TeamAdmin = "admin",
}

export enum UserRoleTag {
  Bidder = "bidder",
  Dispatcher = "dispatcher",
  Driver = "driver",
  DriverOnly = "driverOnly",
  FieldTeam = "fieldTeam",
  Mechanic = "mechanic",
  ResourceProvider = "resourceProvider",
}

export interface BasicInfo {
  id: string
  name: string
}

export interface BasicInfoTeam extends BasicInfo {
  team: BasicInfo
}

export interface UserDto extends BasicInfoTeam {
  isSysAdmin: boolean
}

export interface AccountApplicationUpdateArgs {
  id: string | undefined
  organization: RequestedOrganization
  owner: RequestedUser
}

export interface AccountApplicationUpdateArgsWithConfig
  extends AccountApplicationUpdateArgs {
  config: { env: environmentType; token: string | null }
}

export interface AccountApplicationUpdateResponseDto {
  record: AccountApplicationDetailView
  errors: string[]
}

export interface ApplicationFormValues {
  orgName: string
  orgType: AccountApplicationTypeTeam
  orgOwnerFirstName: string
  orgOwnerLastName: string
  orgEmail: string
  orgPhone: string
  prefDimensionUnit: string
  prefSpeedUnit: string
  prefWeightUnit: string
  organizationAddress: string
  organizationLat: number
  organizationLng: number
}

export interface OrganizationUser {
  firstName: string
  lastName: string
  email: string
  phone?: string
  roles: UserRoleTag[]
}

export interface NewUserFromProps {
  firstName: string
  lastName: string
  email: string
  role: UserRoleTag
}

export interface AccountApplicationApproveRejectRequestDto {
  id: string
  action: "approve" | "reject"
  rejectionNote?: string
}

export interface EmployeeApplicationResponseDto {
  total: number
  records: EmployeeApplication[]
}

export interface EmployeeApplication {
  createdAt: Date
  createdBy: UserDto
  id: string
  requestType: TransferRequestType
  status: {
    id: UserTransferStatus
    name: string
    bgColor: string
  }
  team: BasicInfo
  transferUser: {
    id?: string
    name: string
    email: string
  }
  updatedAt: Date
  updatedBy: UserDto
  userPhone: string
  userFocusModeOnly: boolean
  userJobTitle: string
  userRoleNames: string[]
  actions: UserTransferAction[]
}

export type UserTransferAction = "accept" | "reject" | "edit" | "cancel"

export enum UserTransferStatus {
  Accepted = "accepted",
  Cancelled = "cancelled",
  Rejected = "rejected",
  Requested = "requested",
  Transferred = "transferred",
  Transferring = "transferring",
}

export enum TransferRequestType {
  Application = "application",
  Employer = "employer",
  User = "user",
}

interface Status {
  at: string
  id: string
  name: string
  bgColor: string
  textColor: string
}

export interface CompanyApplication {
  id: string
  orgType: string
  status: Status
  orgName: string
  orgAddress: string
  orgPrefLang: string
  ownerEmail: string
  ownerName: string
  itemEmployeeCount: number
  itemPartnerCount: number
  itemTruckCount: number
  createdAt: string
}

export interface CompanyApplicationResponseDto {
  total: number
  records: CompanyApplication[]
}

export const orgTypeMap: Record<string, string> = {
  trucking: "Trucking",
  heavyCivilConstruction: "Heavy Civil Construction",
  supplier: "Supplier",
}

export interface AccountApplicationCompanyFormView {
  id: string
  itemEmployees: {
    email: string
    errors: string | undefined
    id: string
    roleNames: string[]
    status: {
      id: string
      name: string
      bgColor: string
      textColor: string
      at: string
    }
    user: null | BasicInfo
    userTransfer: null | {
      id: string
      status: string
      sourceUser?: {
        id: string
      }
      targetUser?: {
        id: string
      }
    }
  }[]
  itemPartners: {
    errors: string | undefined
    id: string
    isPreApproved: boolean
    partnerOrg: BasicInfo
    partnership: null | {
      id: string
      status: string
    }
    shareTrucks: boolean
    status: {
      id: string
      name: string
      bgColor: string
      textColor: string
      at: string
    }
  }[]
  itemTrucks: {
    completedTrucks: {
      classification: BasicInfo
      id: string
      name: string
      configuration: BasicInfo
    }[]
    errors: string | undefined
    id: string
    quantity: number
    status: {
      id: string
      name: string
      bgColor: string
      textColor: string
      at: string
    }
    type: string
  }[]
  org: {
    id: string
    name: string
  }
  orgAddress: string
  orgCoordinate: {
    lat: number
    lng: number
  }
  orgName: string
  orgPrefLang: LanguageCode
  orgTimezone: string
  orgType: keyof typeof orgTypeMap
  owner: {
    id: string
    name: string
  }
  ownerEmail: string
  ownerEmailVerified: boolean
  ownerFirstName: string
  ownerLastName: string
  ownerPhone: string
  status: {
    id: string
    name: string
    bgColor: string
    textColor: string
  }
  statusChanges: Array<{
    id: string
    name: string
    bgColor: string
    textColor: string
    at: string
  }>
  statusProcessing: number
  type: string
}
