import { VizznUserData } from "../types/user";

export const getUserNameFormat = (user: VizznUserData, isSameTeam: boolean) => {
  if (user.isSysAdmin) {
    return `${user.name} (System Admin)`;
  }
  if (isSameTeam) {
    return user.name;
  }
  return `[${user.team.name}] ${user.name}`;
};
