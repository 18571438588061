import { DeleteFilled } from "@ant-design/icons"
import { message, Popconfirm } from "antd"
import { useDeleteActivityFeedMessage } from "../../../../../apis/ActivityFeed"
import { useAuthContext } from "../../../../../context/AuthContext"

type Props = {
  id: string
}

export default function DeleteActivityFeedMessageWidget({ id }: Props) {
  const { env, token } = useAuthContext()
  const { mutateAsync: deleteActivityFeedMessage } =
    useDeleteActivityFeedMessage()

  const handleDeleteMessage = async () => {
    try {
      await deleteActivityFeedMessage({ id, env, token })
    } catch (error) {
      message.error("Failed to delete message. Please try again.")
    }
  }

  return (
    <Popconfirm
      title="Are you sure to delete this message?"
      onConfirm={handleDeleteMessage}
      okText="Yes"
      cancelText="No"
    >
      <DeleteFilled
        style={{
          color: "red",
          fontSize: 20,
          cursor: "pointer",
        }}
      />
    </Popconfirm>
  )
}
