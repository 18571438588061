import { useParams } from "react-router-dom"
import { UserOverview } from "../components/Users/UserOverview"
import { UserLocations } from "../components/Users/UserLocations"
import { UserDevices } from "../components/Users/UserDevices"
import { SubscriptionList } from "../components/Users/widgets/SubscriptionList"
import { UserNotificationWidget } from "../components/Users/UserNotificationWidget"
import { UserDataUsage } from "../components/Users/UserDataUsage"
import { UserWorkHistoryWidget } from "../components/Users/UserWorkHistoryWidget"
import UserDispatchGroupWidget from "../components/Users/widgets/UserDispatchGroupWidget/UserDispatchGroupWidget"
import { SubscriptionListV2 } from "../components/Users/widgets/SubscriptionListV2"

export const UserPage = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <div
      style={{
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        marginBottom: "20px",
      }}
    >
      <UserOverview id={id || ""} />
      <UserNotificationWidget id={id || ""} />
      <UserDispatchGroupWidget id={id || ""} />
      <UserWorkHistoryWidget id={id || ""} />
      <SubscriptionList userId={id || ""} isUserDetail />
      <SubscriptionListV2 userId={id || ""} isUserDetail />
      <UserLocations id={id || ""} />
      <UserDevices id={id || ""} />
      <UserDataUsage id={id || ""} />
    </div>
  )
}
