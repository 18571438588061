import "mapbox-gl/dist/mapbox-gl.css"
import { useRef, useState } from "react"

import ReactMapGL, { MapRef, Marker } from "react-map-gl"
import useSupercluster from "use-supercluster"
import { Team } from "../../types/Team"
import TeamMapCard from "./TeamCard/TeamCard"

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`

const SIZE = 20
const transform = `translate(${-SIZE / 2}px,${-SIZE}px)`

type Props = {
  data: Team[]
  viewport: any
  setViewport: any
}

export default function TeamMapsV2({
  data: teams,
  viewport,
  setViewport,
}: Props) {
  const [currentHoveredTeam, setCurrentHoveredTeam] = useState<any>()

  const mapRef = useRef<MapRef>(null)
  const points = teams.map((team) => {
    return {
      type: "Feature",
      properties: {
        cluster: false,
        id: team.id,
        name: team.name,
        type: team.type,
        activeUserCount: team.activeUserCount,
        createdAt: team.createdAt,
      },
      geometry: {
        type: "Point",
        coordinates: [team.coordinate?.lng || 0, team.coordinate?.lat || 0],
      },
    }
  })

  // get map bounds
  const bounds = mapRef.current
    ? (mapRef.current.getMap()?.getBounds()?.toArray()?.flat()?.slice(0, 4) as [
        number,
        number,
        number,
        number
      ])
    : undefined

  // get clusters
  const { clusters, supercluster } = useSupercluster({
    points: points!?.map((point) => ({
      type: "Feature",
      properties: {
        cluster: false,
        id: point.properties.id,
        name: point.properties.name,
        type: point.properties.type,
        activeUserCount: point.properties.activeUserCount,
        createdAt: point.properties.createdAt,
        point_count: 0,
      },
      geometry: {
        type: "Point",
        coordinates: point.geometry.coordinates,
      },
    })),
    zoom: viewport.zoom,
    bounds: bounds,
    options: {
      radius: 75,
      maxZoom: 20,
    },
  })

  return (
    <ReactMapGL
      {...viewport}
      style={{
        width: "100vw",
        height: "100vh",
      }}
      maxZoom={20}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      onMove={(e) => setViewport(e.viewState)}
      ref={mapRef}
    >
      {clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates
        const { cluster: isCluster, point_count } = cluster.properties

        if (isCluster) {
          return (
            <Marker key={cluster.id} latitude={latitude} longitude={longitude}>
              <div
                style={{
                  background: "red",
                  width: `${10 + (point_count / points.length) * 20}px`,
                  height: `${10 + (point_count / points.length) * 20}px`,
                  borderRadius: "50%",
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
                onClick={() => {
                  const clusterExpansionZoom =
                    supercluster?.getClusterExpansionZoom(Number(cluster.id))
                  const expansionZoom = Math.min(clusterExpansionZoom!, 20)

                  setViewport({
                    ...viewport,
                    latitude,
                    longitude,
                    zoom: expansionZoom || 0,
                  })
                }}
              >
                {point_count}
              </div>
            </Marker>
          )
        }

        return (
          <Marker
            key={cluster.properties.id}
            latitude={latitude}
            longitude={longitude}
          >
            <div
              style={{ display: "flex", flexDirection: "column" }}
              onMouseEnter={() => setCurrentHoveredTeam(cluster.properties)}
              onMouseLeave={() => setCurrentHoveredTeam(undefined)}
            >
              <svg
                height={SIZE}
                viewBox="0 0 24 24"
                style={{
                  cursor: "pointer",
                  fill: `red`,
                  transform: `${transform}`,
                }}
              >
                <path d={ICON} />
              </svg>
              {currentHoveredTeam?.id === cluster.properties.id && (
                <TeamMapCard
                  name={cluster.properties.name}
                  type={cluster.properties.type}
                  createdAt={cluster.properties.createdAt}
                  activeUserCount={cluster.properties.activeUserCount}
                  id={cluster.properties.id}
                />
              )}
            </div>
          </Marker>
        )
      })}
    </ReactMapGL>
  )
}
