import { Form, Select } from "antd";
import moment from "moment-timezone";
import React from "react";
type props = {
  initialValue?: string;
};

export const TimezonePicker = ({ initialValue }: props) => {
  const timezones = moment.tz.names();
  return (
    <Form.Item
      name="timezone"
      label="Timezone"
      initialValue={initialValue}
      rules={[
        {
          required: true,
          message: "Please input your timezone",
        },
      ]}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          option?.zonename.toLowerCase().includes(input.toLowerCase())
        }
        style={{ width: "300px" }}
      >
        {timezones.map((tz) => (
          <Select.Option key={tz} value={tz} zonename={tz}>
            {tz}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
