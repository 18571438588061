import { Button, DatePicker, Form, TimePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { Dispatch, SetStateAction } from "react";

type Props = {
  formDate: Date[];
  setFormDate: Dispatch<SetStateAction<Date[]>>;
  setIsUpdating: Dispatch<SetStateAction<boolean>>;
};

export const DatePickerForm = ({
  formDate,
  setFormDate,
  setIsUpdating,
}: Props) => {
  const [form] = Form.useForm();

  const handleSubmit = (values: any) => {
    const { date, time } = values;
    const [startDate, endDate] = date;
    const [startTime, endTime] = time;

    const start = dayjs(startDate)
      .set("hour", startTime.hour())
      .set("minute", startTime.minute());
    const end = dayjs(endDate)
      .endOf("day")
      .set("hour", endTime.hour())
      .set("minute", endTime.minute());

    setFormDate([start.toDate(), end.toDate()]);
    setIsUpdating(true);
  };

  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          date: [dayjs().add(-7, "d"), dayjs()],
          time: [dayjs().startOf("day"), dayjs().endOf("day")],
        }}
      >
        <Form.Item name="date">
          <DatePicker.RangePicker presets={rangePresets} />
        </Form.Item>
        <Form.Item name="time">
          <TimePicker.RangePicker />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Search
        </Button>
      </Form>
    </div>
  );
};
