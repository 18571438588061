import { Button, Col, Form, Row, Select } from "antd"
import { useState } from "react"
import { TeamType } from "../../types/Team"
import { useAuthContext } from "../../context/AuthContext"
import { useUpdateTeam } from "../../apis/teams"
import { EditOutlined, StopOutlined } from "@ant-design/icons"
import { RowContent } from "../Utils/RowContent"

type Props = {
  type: TeamType
  teamId: string
}

export const EditableTypes = ({ type, teamId }: Props) => {
  const { env, token } = useAuthContext()
  const [isEditing, setIsEditing] = useState(false)
  const { mutateAsync: updateTeam } = useUpdateTeam(teamId, env)

  const typeKey = Object.keys(TeamType).find(
    (key) => TeamType[key as keyof typeof TeamType] === type
  )

  const onSelect = async (type: TeamType) => {
    const newData = type
    await updateTeam({
      data: {
        id: teamId,
        type: newData,
      },
      env,
      token: token[env],
    })

    setIsEditing(false)
  }

  return (
    <RowContent
      name="Type"
      content={
        isEditing ? (
          <Form style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <Select
              onSelect={onSelect}
              defaultValue={type}
              style={{ minWidth: "200px" }}
            >
              {Object.entries(TeamType).map(([key, value]) => (
                <Select.Option key={key} value={value}>
                  {key}
                </Select.Option>
              ))}
            </Select>
            <Button
              size="small"
              type="default"
              onClick={() => setIsEditing(false)}
            >
              <StopOutlined />
            </Button>
          </Form>
        ) : (
          <Row gutter={8} align={"middle"}>
            <Col>
              <b>{typeKey}</b>
            </Col>
            <Col>
              <Button size="small" onClick={() => setIsEditing(true)}>
                <EditOutlined />
              </Button>
            </Col>
          </Row>
        )
      }
    ></RowContent>
  )
}
