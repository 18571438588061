import { useState } from "react"
import { RowContent } from "../../Utils/RowContent"
import { Button, Col, Form, message, Row, Select } from "antd"
import { EditOutlined, StopOutlined } from "@ant-design/icons"
import {
  LanguageCode,
  languageFlagMap,
  VizznSupportedLanguages,
  vizznSupportedLanguagesArray,
} from "../../../types/Language"
import { useAuthContext } from "../../../context/AuthContext"
import { useUpdateTeamPreferences } from "../../../apis/teams"

type Props = {
  id: string
  languagePreference: LanguageCode
}

export default function TeamLanguagePreference({
  id,
  languagePreference,
}: Props) {
  const [isEditing, setIsEditing] = useState(false)
  const { token, env } = useAuthContext()
  const { mutateAsync: updateTeamLanguagePreference, isLoading } =
    useUpdateTeamPreferences(id, env)

  const onSelect = async (value: string) => {
    try {
      await updateTeamLanguagePreference({
        data: {
          id,
          language: value as LanguageCode,
        },
        env,
        token: token[env] as string,
      })
      return
    } catch (error) {
      message.error(error as string)
      return
    } finally {
      setIsEditing(false)
      return
    }
  }

  return (
    <RowContent
      name="Language Preference"
      content={
        isEditing ? (
          <Form style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <Select
              defaultValue={languagePreference}
              options={vizznSupportedLanguagesArray}
              onSelect={onSelect}
              loading={isLoading}
              disabled={isLoading}
              style={{ minWidth: "200px" }}
            />
            <Button
              size="small"
              type="default"
              onClick={() => setIsEditing(false)}
            >
              <StopOutlined />
            </Button>
          </Form>
        ) : (
          <Row gutter={8}>
            <Col>
              <strong>
                {languageFlagMap[languagePreference]}{" "}
                {VizznSupportedLanguages[languagePreference]}
              </strong>
            </Col>
            <Col>
              <Button size="small" onClick={() => setIsEditing(true)}>
                <EditOutlined />
              </Button>
            </Col>
          </Row>
        )
      }
    />
  )
}
