import { Card, Spin } from "antd"
import { useTeamPreferences } from "../../../apis/teams"
import { useAuthContext } from "../../../context/AuthContext"
import { TeamUnitKeys, TeamUnitsMap } from "../../../types/Team"
import { GetError } from "../../../widgets/GetError"
import { RowContent } from "../../Utils/RowContent"
import { EditableDimensionWidget } from "../widgets/EditableDimensionWidget"

type Props = { id: string }

export const TeamUnitPreferences = ({ id }: Props) => {
  const { env, token } = useAuthContext()
  const preferences = useTeamPreferences({ id, env, token: token[env] })

  if (preferences.isLoading) <Spin />

  if (preferences.error || !preferences.data)
    return <GetError reFetch={preferences.refetch} />

  return (
    <Card title="Units">
      {Object.entries(TeamUnitsMap).map(([key, value]) => (
        <RowContent
          key={key}
          name={value}
          content={
            <EditableDimensionWidget
              name={key as TeamUnitKeys}
              data={preferences.data}
              id={id}
            />
          }
        />
      ))}
    </Card>
  )
}
