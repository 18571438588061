import { Input, Spin } from "antd"
import Table, { ColumnsType } from "antd/es/table"
import React, { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useGetUserAccounts } from "../../apis/SysAdmin"
import { useAuthContext } from "../../context/AuthContext"
import { SysAdminDataSmall } from "../../types/SysAdmin"
import { GetError } from "../../widgets/GetError"
import AddSysAdminModal from "./widgets/AddSysAdminModal"

export default function SysAdminPage() {
  const navigate = useNavigate()
  const { token, env, user } = useAuthContext()
  const userAccounts = useGetUserAccounts<"small">({
    env,
    token: token[env],
    dto: { format: "small", sysAdminOnly: true },
  })

  const isSysAdmin = userAccounts.data?.some((u) => u.id === user[env]?.uid)

  const [searchTerm, setSearchTerm] = React.useState<string>("")
  const [filteredUserAccounts, setFilteredUserAccounts] = React.useState<
    SysAdminDataSmall[]
  >([])

  const columns: ColumnsType<SysAdminDataSmall> = useMemo(
    () => [
      {
        title: "Name",
        key: "name",
        render: (_, e) => e.name,
      },
      {
        title: "Email",
        key: "email",
        render: (_, e) => e.email,
      },
      {
        title: "User Count",
        key: "userCount",
        render: (_, e) => e.userCount,
      },
    ],
    []
  )

  useEffect(() => {
    if (searchTerm === "") {
      return setFilteredUserAccounts(userAccounts?.data || [])
    }

    return setFilteredUserAccounts(
      userAccounts.data?.filter(
        (u) =>
          u.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          u.email.toLowerCase().includes(searchTerm.toLowerCase())
      ) || []
    )
  }, [searchTerm, userAccounts.data])

  if (userAccounts.isLoading) {
    return <Spin />
  }
  if (userAccounts.isError) {
    return <GetError reFetch={userAccounts.refetch} />
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {isSysAdmin && <AddSysAdminModal refetch={userAccounts.refetch} />}
      <Input.Search
        style={{ width: "100%" }}
        placeholder="Search by name or email"
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
      />
      <Table
        style={{ minWidth: "100%", cursor: "pointer" }}
        columns={columns}
        rowKey="id"
        dataSource={filteredUserAccounts}
        loading={userAccounts.isLoading}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => {
              return navigate(`/sysadmin/${record.id}`)
            },
          }
        }}
      />
    </div>
  )
}
