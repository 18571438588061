import { JsonViewer, NamedColorspace } from "@textea/json-viewer"
import { DraggableModal } from "ant-design-draggable-modal"
import "ant-design-draggable-modal/dist/index.css"
import { Button, theme } from "antd"
import { useCallback, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import ForceGraph2D from "react-force-graph-2d"
import { useWindowDimensions } from "../../utils/hooks/useWindowDimensions"
import { hexToRgb } from "../Utils/helpers/hexToRgb"
import SelectedNodeCard from "./SelectedNodeCard/SelectedNodeCard"

const { useToken } = theme

// TODO: Abstract into hook?
const ocean: NamedColorspace = {
  scheme: "Ocean",
  author: "Forest Park",
  base00: "#fff",
  base01: "#343d46",
  base02: "#4f5b66",
  base03: "#65737e",
  base04: "#a7adba",
  base05: "#c0c5ce",
  base06: "#dfe1e8",
  base07: "#0743bd",
  base08: "#bf616a",
  base09: "#d08770",
  base0A: "#ebcb8b",
  base0B: "#a3be8c",
  base0C: "#96b5b4",
  base0D: "#8fa1b3",
  base0E: "#b48ead",
  base0F: "#ab7967",
}

export default function ForceDirectedTree(props: any) {
  const fgRef: any = useRef()
  const [selectedNode, setSelectedNode] = useState<any>(null)
  const [nodePosition, setNodePosition] = useState<any>(null)
  const [graphData, setGraphData] = useState<any>(props.signalData)
  const [selectedNodesList, setSelectedNodesList] = useState<any>([])
  const [nodeDataList, setNodeDataList] = useState<any>([])
  const { width, height } = useWindowDimensions()
  const { token: themeToken } = useToken()
  // Calculate vertices for a regular hexagon
  const hexagonVertices = (x: number, y: number, radius: number) => {
    const angle = Math.PI / 3 // 60 degrees in radians
    return Array.from({ length: 6 }).map((_, i) => ({
      x: x + radius * Math.cos(angle * i),
      y: y + radius * Math.sin(angle * i),
    }))
  }

  // finding node position
  const setPosition = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    let position: any = {}
    position.x = e?.pageX
    position.y = e?.pageY
    setNodePosition(position)
  }, [])

  const closeModal = useCallback((targetNodeId: string) => {
    return setSelectedNodesList((prev: any) =>
      prev.filter((n: any) => n.id !== targetNodeId)
    )
  }, [])

  useEffect(() => {
    setGraphData(props.signalData)
    fgRef.current.d3Force("charge").distanceMax(100)
  }, [graphData, props.signalData])

  return (
    <>
      <div>
        {selectedNode &&
          ReactDOM.createPortal(
            <SelectedNodeCard
              selectedNode={selectedNode}
              selectedNodesList={selectedNodesList}
              setSelectedNodesList={setSelectedNodesList}
              nodePosition={nodePosition}
              nodeDataList={nodeDataList}
              setNodeDataList={setNodeDataList}
              isCurrentNodeSelected={selectedNodesList.find(
                (node: any) => node.id === selectedNode.id
              )}
            />,
            document.body
          )}
        <div onMouseMove={setPosition}>
          <ForceGraph2D
            ref={fgRef}
            graphData={graphData}
            backgroundColor={themeToken.colorBgBase}
            height={height * 0.8}
            width={width * 0.8}
            nodeId="id"
            autoPauseRedraw={true}
            onNodeHover={(node: any, prevNode: any) => {
              if (node) {
                setSelectedNode(node)
              } else {
                setSelectedNode(null)
              }
            }}
            onNodeClick={(node: any, event: any) => {
              fgRef.current.centerAt(node.x, node.y, 1000)
              fgRef.current.zoom(6, 1000)
            }}
            onNodeDrag={(node: any) => {
              node.fx = node.x
              node.fy = node.y
            }}
            onNodeDragEnd={(node: any) => {
              node.fx = node.x
              node.fy = node.y
            }}
            nodeCanvasObject={(node, ctx, globalScale) => {
              const borderColor = node.color
              const fillColor = themeToken.colorBgBase
              const borderSize = 1 // Adjust as needed

              // Size and position
              const nodeSize = 10
              const x = node.x
              const y = node.y

              const rgb = hexToRgb(themeToken.colorText)

              // Shadow properties
              ctx.shadowColor = `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.5)`
              // ctx.shadowColor = "rgba(255,255,255, 0.5)"; // Adjust the RGBA values for the desired shadow color and opacity
              ctx.shadowBlur = 8 // Adjust the value for the desired blur size
              ctx.shadowOffsetX = 3 // Adjust the value for horizontal offset
              ctx.shadowOffsetY = 3 // Adjust the value for vertical offset

              // Draw border (outer hexagon)
              ctx.beginPath()
              hexagonVertices(x, y, nodeSize).forEach((vertex, idx) => {
                if (idx === 0) {
                  ctx.moveTo(vertex.x, vertex.y)
                } else {
                  ctx.lineTo(vertex.x, vertex.y)
                }
              })
              ctx.closePath()
              ctx.fillStyle = borderColor
              ctx.fill()

              // Reset shadow properties for inner hexagon and text
              ctx.shadowColor = "transparent"
              ctx.shadowBlur = 0
              ctx.shadowOffsetX = 0
              ctx.shadowOffsetY = 0

              // Draw inner hexagon
              ctx.beginPath()
              hexagonVertices(x, y, nodeSize - borderSize).forEach(
                (vertex, idx) => {
                  if (idx === 0) {
                    ctx.moveTo(vertex.x, vertex.y)
                  } else {
                    ctx.lineTo(vertex.x, vertex.y)
                  }
                }
              )
              ctx.closePath()
              ctx.fillStyle = fillColor
              ctx.fill()

              // Draw node id
              ctx.fillStyle = "#fff"
              ctx.font = "8px Arial"
              ctx.textAlign = "center"
              ctx.textBaseline = "middle"

              const nodeLabel = node.team.name
              const fontSize = 12 / globalScale
              ctx.font = `${fontSize}px Sans-Serif`
              ctx.textAlign = "center"
              ctx.textBaseline = "middle"
              ctx.fillStyle = themeToken.colorText

              ctx.fillText(nodeLabel, x, y)
            }}
            linkLineDash={[]}
            nodeCanvasObjectMode={() => "after"}
            minZoom={5}
            maxZoom={20}
            // NOTE: What does this do??
            // onEngineStop={() => {
            //   if (!stopEngine) {
            //     fgRef.current.zoomToFit(1000, 50);
            //     setStopEngine(true);
            //   }
            // }}
            linkWidth={2}
            linkColor={
              (link: any) => {
                return themeToken.colorText
              }
              // TODO: Come back to this after talking to Rick
              // link.type === "partner" ? "#ea671b" : "#000"
            }
            // TODO: Come back to this after talking to Rick
            // linkDirectionalParticles={4}
            // linkDirectionalParticleColor={() => "#ea671b"}
            // linkDirectionalParticleWidth={(link: any) =>
            //   link.type === "partner" ? 5 : 0
            // }
            linkDirectionalParticleSpeed={() => 1 * 0.005}
            linkDirectionalArrowLength={5}
            linkDirectionalArrowRelPos={() => 0.5}
            linkDirectionalArrowColor={() => "#5936ca"}
          />
        </div>
        {selectedNodesList.map((node: any) => {
          return (
            <DraggableModal
              key={node.id + node.team.id}
              title={`Json Data - ${node.id}`}
              open={true}
              initialWidth={width * 0.5}
              initialHeight={height * 0.7}
              onCancel={() => closeModal(node.id)}
              footer={[
                <Button
                  type="primary"
                  danger
                  onClick={() => closeModal(node.id)}
                >
                  Close
                </Button>,
                <Button
                  type="primary"
                  onClick={() => {
                    setSelectedNodesList((prev: any) =>
                      prev.filter((n: any) => n.id === node.id)
                    )
                  }}
                >
                  Focus
                </Button>,
              ]}
            >
              {nodeDataList.find((data: any) => data.id === node.id) ? (
                <JsonViewer
                  value={nodeDataList.find((data: any) => data.id === node.id)}
                  defaultInspectDepth={3}
                  theme={ocean}
                />
              ) : (
                <div>Loading...</div>
              )}
            </DraggableModal>
          )
        })}
      </div>
    </>
  )
}
