export const jobRoleOptions = [
  {
    label: "Dispatcher",
    value: "dispatcher",
  },
  {
    label: "Bidder",
    value: "bidder",
  },
  {
    label: "Driver",
    value: "driver",
  },
  {
    label: "DriverOnly",
    value: "driverOnly",
  },
  {
    label: "FieldTeam",
    value: "fieldTeam",
  },
  {
    label: "Mechanic",
    value: "mechanic",
  },
  {
    label: "ResourceProvider",
    value: "resourceProvider",
  },
];
