import * as Sentry from "@sentry/react"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import Axios from "axios"
import { AuthContextProvider } from "./context/AuthContext"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { message } from "antd"
import "./index.css"
import { DraggableModalProvider } from "ant-design-draggable-modal"

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://admin.vizzn-dev.ca"],
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
    },
  },
})

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && typeof error.response.data.detail === "string") {
      message.error(error.response.data.detail)
    } else {
      message.error(error.message)
    }
    return Promise.reject(error)
  }
)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
message.success("app started")
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <DraggableModalProvider>
          <App />
        </DraggableModalProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AuthContextProvider>
  </React.StrictMode>
)
