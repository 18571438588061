import { Link } from "react-router-dom"
import {
  ActivityFeedsPropData,
  DispatchType,
} from "../../../types/DispatchExplorer"

export const DispatchActivityFeed = ({
  type,
  data,
}: {
  type?: DispatchType

  data: ActivityFeedsPropData
}) => {
  return (
    <>
      <Link
        className="markdown-link"
        to={type ? `/${type}-detail-page/${data.id}` : `/dispatches/${data.id}`}
      >
        {String(data.value)}
      </Link>
    </>
  )
}
