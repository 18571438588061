import { Line } from "@ant-design/charts";
import { Card, Spin } from "antd";
import React from "react";
import { useGetServicesErrorCount } from "../../apis/service";
import { useAuthContext } from "../../context/AuthContext";
import { GetError } from "../../widgets/GetError";
import { format } from "date-fns";
type Props = {
  serviceName?: string;
};
export const ServiceErrorCount = ({ serviceName }: Props) => {
  const { env, token } = useAuthContext();
  const errorCount = useGetServicesErrorCount({ env, token: token[env] });
  if (errorCount.isLoading) return <Spin />;
  if (errorCount.error || !errorCount.data)
    return <GetError reFetch={errorCount.refetch} />;
  const data = serviceName
    ? errorCount.data.filter((s) => s.service === serviceName)
    : errorCount.data;

  const sortedData = data
    .sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))
    .map((e) => ({
      count: e.count || 0,
      service: e.service,
      timestamp: format(new Date(e.timestamp), "HH:mm"),
    }));
  const config = {
    data: sortedData,
    yField: "count",
    xField: "timestamp",
    seriesField: "service",
    legend: {
      position: "top" as "top",
    },
  };
  return (
    <Card title={`Error count: ${serviceName ? "Last 24 hours" : "Services"}`}>
      <Line {...config} />
    </Card>
  );
};
