import { Avatar, Card, Col, Row, Spin, Tag, Typography } from "antd"
import { RowContent } from "../Utils/RowContent"
import { useGetSingleTeam, useTeamPreferences } from "../../apis/teams"
import { useAuthContext } from "../../context/AuthContext"
import { GetError } from "../../widgets/GetError"
import { formatToDateAndHour } from "../Utils/TimeUtils"
import TeamLanguagePreference from "./widgets/TeamLanguagePreference"
import { EditableTypes } from "./EditableTypes"
import TeamLocationMap from "../../pages/TeamDetailsPage/widgets/TeamLocationMap"
import { EditableEmails } from "./EditableEmails"

type Props = {
  id: string
}

export const TeamOverview = ({ id }: Props) => {
  const { env, token } = useAuthContext()
  const preferences = useTeamPreferences({ id, env, token: token[env] })
  const team = useGetSingleTeam({ id, env, token: token[env] })

  if (team.isLoading || preferences.isLoading) return <Spin />
  if (team.error || !team.data || !preferences.data || preferences.error)
    return <GetError reFetch={team.refetch} />

  return (
    <Card
      title={
        <Row gutter={16} align={"middle"}>
          <Col>
            {team.data.logo ? (
              <img
                style={{ maxHeight: 40 }}
                src={team.data.logo}
                alt="team logo"
              />
            ) : (
              <Avatar size={40}>{team.data.name[0].toUpperCase()}</Avatar>
            )}
          </Col>

          <Col>
            <Typography.Title style={{ marginTop: 10 }}>
              {team.data.name}
            </Typography.Title>
          </Col>

          <Col>
            <Tag>{team.data.status}</Tag>
          </Col>
        </Row>
      }
    >
      <Row
        gutter={16}
        style={{
          display: "flex",
          flexWrap: "nowrap",
        }}
      >
        <Col
          style={{
            flex: 1, // Make this column grow to fill remaining space
            minWidth: "200px", // Ensure this column has a reasonable minimum width
            overflow: "hidden", // Prevent content from overflowing
          }}
        >
          <EditableTypes type={team.data.type} teamId={id} />
          <EditableEmails emails={team.data.emailParts} teamId={id} />
          <RowContent name="Tag" content={team.data.tag} />
          <RowContent
            name="Start Of Day"
            content={formatToDateAndHour(team.data.startOfDay)}
          />
          <RowContent
            name="End Of Day"
            content={formatToDateAndHour(team.data.endOfDay)}
          />
          <TeamLanguagePreference
            id={id}
            languagePreference={preferences.data.language}
          />
        </Col>
        <Col
          style={{
            width: "300px",
            minHeight: "292px",
            flexShrink: 1,
          }}
        >
          <TeamLocationMap coordinate={team.data.coordinate} />
        </Col>
      </Row>
    </Card>
  )
}
