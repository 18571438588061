import { useEffect, useState } from "react";
import { Card, Tabs, Skeleton, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  useGetNotificationTaxonomy,
  updateNotificationTaxonomy,
} from "../apis/notifications";
import { TaxonomyItem } from "../components/Notification/TaxonomyItem";
import { TaxonomyModal } from "../components/Notification/TaxonomyModal";
import { useAuthContext } from "../context/AuthContext";
import { GetError } from "../widgets/GetError";
import {
  EntityNotificationTaxonomy,
  NotificationTaxonomy as NTaxonomy,
} from "../types/notifications";

const environments: ("dev" | "prod")[] = ["dev", "prod"];

export const NotificationTaxonomy = () => {
  const { token } = useAuthContext();

  const [notificationTaxonomyData, setNotificationTaxonomyData] = useState<{
    dev?: NTaxonomy;
    prod?: NTaxonomy;
  }>({});
  const notificationTaxonomyDev = useGetNotificationTaxonomy({
    env: "dev",
    token: token["dev"],
  });
  const notificationTaxonomyProd = useGetNotificationTaxonomy({
    env: "prod",
    token: token["prod"],
  });
  const [modal, setModal] = useState<{
    open: boolean;
    index?: number;
    type?: "team" | "entity";
    dev?: Partial<EntityNotificationTaxonomy>;
    prod?: Partial<EntityNotificationTaxonomy>;
  }>({ open: false });
  useEffect(() => {
    setNotificationTaxonomyData((ntd) => ({
      ...ntd,
      dev: notificationTaxonomyDev.data,
    }));
  }, [notificationTaxonomyDev.data]);
  useEffect(() => {
    setNotificationTaxonomyData((ntd) => ({
      ...ntd,
      prod: notificationTaxonomyProd.data,
    }));
  }, [notificationTaxonomyProd.data]);
  if (!token["dev"] || !token["prod"])
    return (
      <Card title="Missing Environemnt Login">
        Notification Taxonomy editor requires you to be logged into all the
        environments before you can continue.
      </Card>
    );
  if (notificationTaxonomyDev.isLoading || notificationTaxonomyProd.isLoading)
    return <Skeleton />;
  if (notificationTaxonomyDev.error || !notificationTaxonomyData.dev)
    return <GetError reFetch={notificationTaxonomyDev.refetch} />;
  if (notificationTaxonomyProd.error || !notificationTaxonomyData.prod)
    return <GetError reFetch={notificationTaxonomyProd.refetch} />;
  const handle = {
    addItem: (type: "entity" | "team") => {
      const oldData = notificationTaxonomyData;
      environments.forEach((env) => {
        const oldDataEnv = oldData[env];
        if (oldDataEnv) {
          if (type === "team")
            oldDataEnv[type].push({
              id: "",
              name: "",
              buckets: [],
              isPublished: false,
              roles: [],
            });
          if (type === "entity")
            oldDataEnv[type].push({
              id: "",
              name: "",
              buckets: [],
              isPublished: false,
              roles: [],
              quickview: false,
              subscriptionItem: [],
            });
        }
      });
      setNotificationTaxonomyData({ ...oldData });
    },
    deleteItem: (type: "entity" | "team", index: number) => {
      const oldData = notificationTaxonomyData;
      environments.forEach((env) => {
        const oldDataEnv = oldData[env];
        if (oldDataEnv) {
          oldDataEnv[type].splice(index, 1);
          setNotificationTaxonomyData({ ...oldData });
        }
      });
    },
    moveItem: (type: "entity" | "team", index: number, newIndex: number) => {
      const oldData = notificationTaxonomyData;
      environments.forEach((env) => {
        const oldDataEnv = oldData[env];
        if (oldDataEnv) {
          [oldDataEnv[type][newIndex], oldDataEnv[type][index]] = [
            oldDataEnv[type][index],
            oldDataEnv[type][newIndex],
          ];
        }
      });
      setNotificationTaxonomyData({ ...oldData });
    },
    onSave: () => {
      environments.forEach((env) => {
        const notificationTaxonomyDataEnv = notificationTaxonomyData[env];
        if (notificationTaxonomyDataEnv)
          updateNotificationTaxonomy({
            env,
            token: token[env],
            payload: notificationTaxonomyDataEnv,
          });
      });
    },
  };

  return (
    <>
      <div style={{ textAlign: "right" }}>
        <Button onClick={() => handle.onSave()}>Save</Button>
      </div>

      <Tabs>
        <Tabs.TabPane tab="Team Notification Taxonomy" key="0">
          {notificationTaxonomyData.dev.team.map((item, index) => (
            <TaxonomyItem
              key={`${index}-${item.id}`}
              item={{
                dev: item,
                prod: notificationTaxonomyData.prod?.team[index],
              }}
              sortUp={
                index > 0
                  ? () => handle.moveItem("team", index, index - 1)
                  : null
              }
              sortDown={
                index + 1 < (notificationTaxonomyData.dev?.team.length || 0)
                  ? () => handle.moveItem("team", index, index + 1)
                  : null
              }
              onDelete={() => handle.deleteItem("team", index)}
              onEdit={() =>
                setModal({
                  open: true,
                  type: "team",
                  index,
                  dev: item,
                  prod: notificationTaxonomyData.prod?.team[index],
                })
              }
            />
          ))}
          <Button onClick={() => handle.addItem("team")}>
            <PlusOutlined />
          </Button>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Entity Notification Taxonomy" key="1">
          {notificationTaxonomyData.dev.entity.map((item, index) => (
            <TaxonomyItem
              key={index}
              item={{
                dev: item,
                prod: notificationTaxonomyData.prod?.entity[index],
              }}
              sortUp={
                index > 0
                  ? () => handle.moveItem("entity", index, index - 1)
                  : null
              }
              sortDown={
                index + 1 < (notificationTaxonomyData.dev?.entity.length || 0)
                  ? () => handle.moveItem("entity", index, index + 1)
                  : null
              }
              onDelete={() => handle.deleteItem("entity", index)}
              onEdit={() =>
                setModal({
                  open: true,
                  type: "entity",
                  index,
                  dev: item,
                  prod: notificationTaxonomyData.prod?.entity[index],
                })
              }
            />
          ))}
          <Button onClick={() => handle.addItem("entity")}>
            <PlusOutlined />
          </Button>
        </Tabs.TabPane>
      </Tabs>
      {modal.open && (
        <TaxonomyModal
          modal={modal}
          setModal={setModal}
          onOk={() => {
            setModal((modal) => ({ ...modal, open: false }));
          }}
        />
      )}
    </>
  );
};
