// const version = await Axios.get<VizznResponse<App>>('/v1/apps/web')
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { AppInfo } from "../types/DeploymentStatus"
import { environmentType } from "../types/environtment"
import { getEnvUrl } from "../utils/utils"

const getAppInfo = async (body: {
  env: environmentType
  token: string | null
}) => {
  const {
    data,
  }: {
    data: {
      data: AppInfo
    }
  } = await axios.get(`${getEnvUrl(body.env)}/v1/apps/web`, {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  })

  return data.data
}

export const useGetApp = (body: {
  env: environmentType
  token: string | null
}) => {
  return useQuery<AppInfo>(["appVersion", body.env], () => getAppInfo(body))
}

const updateAppVersion = async (body: {
  env: environmentType
  token: string | null
  payload: {
    version: string
    ref: "master" | "develop"
  }
}) => {
  const {
    data,
  }: {
    data: {
      data: AppInfo
    }
  } = await axios.post(
    `${getEnvUrl(body.env)}/v1/apps/web/version`,
    {
      object_kind: "pipeline",
      object_attributes: {
        duration: 300,
        id: 1234,
        status: "success",
        ref: body.payload.ref,
      },
      commit: {
        message: body.payload.version,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useUpdateAppVersion = (env: environmentType) => {
  const queryClient = useQueryClient()
  return useMutation(updateAppVersion, {
    onSuccess: () => {
      queryClient.invalidateQueries(["appVersion", env])
    },
  })
}
