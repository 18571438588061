import { Button, message, Row } from "antd";
import { useAuthContext } from "../context/AuthContext";
import { JsonViewer } from "@textea/json-viewer";
import { useState } from "react";
import { UserData } from "../types/user";

export const AuthCheck = () => {
  const { user, env, token } = useAuthContext();
  const [userData] = useState<UserData>(user[env]);

  return (
    <>
      <Row style={{ marginBottom: 8 }}>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(token[env] || "");
            message.success("Token copied to clipboard");
          }}
          type="primary"
        >
          Copy Token To Clipboard
        </Button>
      </Row>
      <div style={{ background: "white", padding: 16, borderRadius: 4 }}>
        <h3 style={{ color: "#363853" }}>Firebase User Data</h3>
        <p style={{ color: "#363853" }}>
          Firebase <b>{user[env]?.email}</b> object value:
        </p>
        {!!userData && <JsonViewer value={userData} defaultInspectDepth={2} />}
      </div>
    </>
  );
};
