import { Button, Col, Form, message, Modal, Row, Select, Skeleton } from "antd"

import { memo } from "react"
import {
  useGetUsersInEnterprise,
  useSetEnterpriseAdmin,
} from "../../../apis/Enterprise"
import ErrorRefetch from "../../../components/ErrorRefetch/ErrorRefetch"
import { useAuthContext } from "../../../context/AuthContext"

const AddAdminModal = memo(function AddAdminModal({
  isOpen,
  onClose,
  enterpriseId,
}: {
  isOpen: boolean
  onClose: () => void
  enterpriseId: string
}) {
  const {env,token} =useAuthContext()

  const { mutateAsync: setEnterpriseAdmin, isLoading } =
    useSetEnterpriseAdmin(enterpriseId)
  const users = useGetUsersInEnterprise({
    env,
    token: token[env],
    enterpriseId,
    isOpen,
  })
  const [form] = Form.useForm()

  const onSubmit = async (values: { userId: string }) => {
    try {
      await setEnterpriseAdmin({
        env,
        token: token[env],
        userId: values.userId,
        enterpriseId,
      })

      onClose()
    } catch (error) {
      message.error("There was an error adding the admin. Please try again.")
    }
  }

  return (
    <Modal open={isOpen} onCancel={onClose} footer={null}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          paddingTop: "2rem",
          paddingBottom: "2rem",
        }}
      >
        {users.isLoading && <Skeleton />}
        {users.isError && <ErrorRefetch refetch={users.refetch} />}
        {users.data && (
          <Form form={form} onFinish={onSubmit}>
            <Form.Item name="userId" label="User" rules={[{ required: true }]}>
              <Select
                showSearch
                placeholder="Select a user"
                options={users.data.map((user) => ({
                  label: user.name,
                  value: user.authId,
                }))}
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>
            <Row justify="end" gutter={16}>
              <Col>
                <Button disabled={isLoading} onClick={onClose}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button disabled={isLoading} type="primary" htmlType="submit">
                  Add Admin
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </Modal>
  )
})

export default AddAdminModal
