import { Button, Form, Modal, message } from "antd"
import { useState } from "react"
import { useAddException, useUpdateFeatureFlag } from "../../apis/featureFlags"
import { useAuthContext } from "../../context/AuthContext"
import { environmentType } from "../../types/environtment"
import { ReducedFlag } from "../../types/featureFlags"
import { PlusCircleOutlined } from "@ant-design/icons"
import { SearchUserDropdown } from "../Users/widgets/SearchUserDropdown"
import { SearchTeamDropdown } from "../Team/widgets/SearchTeamDropdown"

type Props = {
  type: "user" | "team"
  env: environmentType
  flag: ReducedFlag
  activeVersion: number
  versions: number[]
}

export const AddTeamOrUserModal = ({
  type,
  env,
  flag,
  activeVersion,
  versions,
}: Props) => {
  const [open, setOpen] = useState(false)
  const { mutateAsync: addException, isLoading: addExceptionIsLoading } =
    useAddException()
  const { mutateAsync: updateFeatureFlag } = useUpdateFeatureFlag()
  const { token } = useAuthContext()
  const [form] = Form.useForm()

  const handleFinish = async (values: any) => {
    if (!versions.includes(activeVersion + 1)) {
      versions.push(activeVersion + 1)
      const data = await updateFeatureFlag({
        env,
        token: token[env],
        payload: {
          name: flag.name,
          versions,
          activeVersion,
          type: "app",
          description: flag.description,
        },
      })
      if (!data)
        return message.error(`Error creating version ${activeVersion + 1}`)
    }

    try {
      await addException({
        env,
        token: token[env],
        payload: {
          name: flag.name,
          ids: Array.isArray(values.users) ? values.users : [values.users],
          type,
          activeVersion: Number(activeVersion + 1),
          description: flag.description!,
        },
      })
      return message.success(`Added ${type}`)
    } catch (error) {
      return message.error(`Error adding ${values.users}`)
    } finally {
      setOpen(false)
      form.resetFields()
      return
    }
  }

  return (
    <>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        icon={<PlusCircleOutlined />}
        size="small"
      />
      {open && (
        <Modal
          open={open}
          title={`Add ${type}`}
          onCancel={() => setOpen(false)}
          onOk={() => form.submit()}
          okText="Add"
          width={650}
          confirmLoading={addExceptionIsLoading}
        >
          <Form form={form} onFinish={handleFinish}>
            {type === "user" ? (
              <SearchUserDropdown env={env} />
            ) : (
              <SearchTeamDropdown env={env} />
            )}
          </Form>
        </Modal>
      )}
    </>
  )
}
