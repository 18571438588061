import { Card, Row, Spin, Tag, Table } from "antd"
import { useEffect, useMemo, useState } from "react"
import { useGetPartnerships } from "../../apis/Partners"
import { useAuthContext } from "../../context/AuthContext"
import { GetError } from "../../widgets/GetError"
import { PartnershipObject } from "../../types/Partner"
import { ColumnsType } from "antd/lib/table"
import { formatToDateAndHour } from "../Utils/TimeUtils"
import { getUserNameFormat } from "../../utils/NameUtils"
import { CopyContent } from "../Utils/CopyContent"
import { filterBySearchValueAndTag } from "../Utils/search"
import { useSearchTags } from "../../utils/useSearchTags"
import { ListHeader } from "../Utils/ListHeader"
import { useNavigate } from "react-router-dom"
type Props = {
  id: string
}

export const TeamPartnerships = ({ id }: Props) => {
  const { env, token } = useAuthContext()
  const partnerships = useGetPartnerships({ env, token: token[env], id })
  const searchTags = useSearchTags("partnerships")
  const { tags, searchValue } = searchTags
  const [filteredData, setFilteredData] = useState<PartnershipObject[]>([])
  const navigate = useNavigate()

  const columns: ColumnsType<PartnershipObject> = useMemo(
    () => [
      {
        title: "Name",
        align: "left",
        width: 150,
        render: (_, e) => e.name,
        sorter: (a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
        key: "name",
        onCell: (record, rowIndex) => {
          return {
            onClick: () => navigate(`/teams/${record.partnerInfo.team?.id}`),
          }
        },
      },
      {
        title: "Id",
        align: "left",
        width: 150,
        render: (_, e) => <CopyContent text={e.id} />,
        sorter: (a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1),
        key: "id",
      },
      {
        title: "Status",
        render: (_, e) => (
          <Row justify="center">
            <Tag
              className="tag tag-status"
              style={{
                borderColor: `#${e.status.bgColor}`,
                backgroundColor: `#${e.status.bgColor}`,
              }}
            >
              {e.status.name}
            </Tag>
          </Row>
        ),
        sorter: (a, b) =>
          a.status.name.toLowerCase() < b.status.name.toLowerCase() ? -1 : 1,
        key: "status",
        width: 130,
      },
      {
        title: "Requester",
        align: "left",
        width: 150,
        render: (_, e) =>
          getUserNameFormat(e.requester.user, id === e.requester.user.team.id),
        sorter: (a, b) =>
          a.requester.user.name.toLowerCase() <
          b.requester.user.name.toLowerCase()
            ? -1
            : 1,
        key: "requesterName",
      },
      {
        title: "Requested At",
        width: 150,
        align: "left",
        render: (_, e) => formatToDateAndHour(e.requester.at),
        sorter: (a, b) =>
          a.requester.user.name.toLowerCase() <
          b.requester.user.name.toLowerCase()
            ? -1
            : 1,
        key: "requesterAt",
      },
      {
        title: "Responder",
        align: "left",
        width: 150,
        render: (_, e) =>
          e.responder &&
          getUserNameFormat(e.responder.user, id === e.responder.user.team.id),
        sorter: (a, b) =>
          a.responder && b.responder
            ? a.responder.user.name.toLowerCase() <
              b.responder.user.name.toLowerCase()
              ? -1
              : 1
            : 0,
        key: "responderName",
      },
      {
        title: "Responded At",
        width: 150,
        align: "left",
        render: (_, e) => e.responder && formatToDateAndHour(e.responder.at),
        sorter: (a, b) =>
          a.responder && b.responder
            ? a.responder.user.name.toLowerCase() <
              b.responder.user.name.toLowerCase()
              ? -1
              : 1
            : 0,
        key: "responderAt",
      },
    ],
    [id, navigate]
  )
  useEffect(() => {
    if (!partnerships.data || !partnerships.dataUpdatedAt) return
    const searchFields = [
      "name",
      "status.name",
      "requester.user.name",
      "responder.user.name",
      "partnerInfo.team.name",
      "partnerInfo.alias.name",
    ]

    let filteredPartnerships = [...(partnerships.data.partnerships || [])]
    if (tags.length || searchValue)
      filteredPartnerships = filterBySearchValueAndTag<PartnershipObject>({
        tags,
        searchValue,
        searchFields,
        dataArray: filteredPartnerships,
      })

    setFilteredData(filteredPartnerships)
  }, [partnerships.data, partnerships.dataUpdatedAt, searchValue, tags])

  if (partnerships.isLoading) return <Spin />
  if (partnerships.error || !partnerships.data)
    return <GetError reFetch={partnerships.refetch} />
  return (
    <Card title="Team Partnerships">
      <ListHeader
        total={partnerships.data.partnerships.length || 0}
        displayedNum={filteredData.length}
        searchTags={searchTags}
      />
      <Table
        scroll={{ y: "60vh" }}
        dataSource={filteredData}
        columns={columns}
        pagination={false}
        rowKey="id"
        style={{ marginTop: "10px" }}
      />
    </Card>
  )
}
