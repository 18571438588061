import { faRssSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Divider, Drawer, Tooltip } from "antd"
import { useState } from "react"
import { usePaginatedActivityFeedCore } from "../../../../apis/ActivityFeed"
import "./ActivityFeedMarkDown.scss"
import { ActivityFeedMessage } from "./ActivityFeedMessage"

type Props = {
  id: string
}

export default function UserActivityFeedDrawerWidget({ id }: Props) {
  const [open, setOpen] = useState(false)
  const feed = usePaginatedActivityFeedCore(id, 20)

  return (
    <>
      <Tooltip title="View User Activity Feed">
        <Button
          onClick={() => setOpen(true)}
          type="primary"
          icon={<FontAwesomeIcon icon={faRssSquare} />}
        />
      </Tooltip>
      <Drawer
        closable
        destroyOnClose
        title={<p>Activity Feed</p>}
        placement="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <div id="activityFeed">
          {feed.data?.pages.flat().map((page) => (
            <>
              <ActivityFeedMessage message={page} />
              <Divider />
            </>
          ))}
        </div>
      </Drawer>
    </>
  )
}
