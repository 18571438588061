import { Spin } from "antd"
import { useTeams } from "../../../apis/teams"
import BarChartCard from "../../../charts/BarChartCard"
import { useAuthContext } from "../../../context/AuthContext"
import { TeamLogCount } from "../../../types/UsageMetrics"
import { GetError } from "../../../widgets/GetError"
import { getLastMonthName } from "./getLastMonthName"
type Props = { logCount: TeamLogCount[] }

export const TopTeamsLogCount = ({ logCount }: Props) => {
  const { env, token } = useAuthContext()
  const teams = useTeams({ env, token: token[env] })
  if (teams.isLoading) return <Spin />
  if (teams.error || !teams.data) return <GetError reFetch={teams.refetch} />

  const data = logCount.map((l) => ({
    teamName: teams.data.find((t) => t.id === l.teamId)?.name,
    count: l.count || 0,
  }))

  return (
    <BarChartCard
      data={data}
      title={`Log count: Top teams - ${getLastMonthName()}`}
      dataKey="count"
      xAxisDataKey="teamName"
      height={450}
    />
  )
}
