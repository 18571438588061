import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import {
  useCreateService,
  useUpdateService,
  useGetOwners,
} from "../../apis/service";
import { useAuthContext } from "../../context/AuthContext";
import { Service, ServiceRequest } from "../../types/service";
import { InputWithTagsFieldString } from "../InputWithTagsFieldString";
type Props = {
  edit?: boolean;
  service?: Service;
  details?: boolean;
};

type Values = {
  name: string;
  owners: string[];
  description?: string;
  gitRepo: string;
  runTimeEngine: string;
  errorAlertThreshold: number;
};

export const NewService = ({ edit, service, details }: Props) => {
  const { env, token } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { mutateAsync: createService } = useCreateService();
  const { mutateAsync: updateService } = useUpdateService();
  const owners = useGetOwners({ env, token: token[env] });
  const [form] = Form.useForm();
  const [subscriptions, setSubscriptions] = useState<string[]>([]);
  const [topics, setTopics] = useState<string[]>([]);
  const isEdit = edit && service;
  useEffect(() => {
    if (service) {
      form.setFieldsValue({
        Name: service.name,
        Owners: service.owners ? service.owners.map((x) => x.id) : [],
        Description: service.description,
        GitRepo: service.gitRepo,
        RunTimeEngine: service.runTimeEngine,
        errorAlertThreshold: service.errorAlertThreshold,
      });
      setTopics(service.topics || []);
      setSubscriptions(service.subscriptions || []);
    }
  }, [service, form]);

  const handleFinish = async (values: Values) => {
    setLoading(true);
    const payload = {
      ...values,
      errorAlertThreshold: Number(values.errorAlertThreshold),
      owners: values.owners.map((id) => {
        const owner = owners.data?.find((owner) => owner.id === id);
        return { Id: id, Name: owner?.name };
      }),
      subscriptions,
      topics,
    } as ServiceRequest;
    edit && service
      ? await updateService({
          env,
          token: token[env],
          payload: { ...payload, id: service.id },
          details,
        })
      : await createService({ env, token: token[env], payload });
    setLoading(false);
    setOpen(false);
    form.resetFields();
  };

  const validateErrorAlertThreshold = (_: any, value: number) => {
    if (value && (value <= 5 || value >= 500)) {
      return Promise.reject(
        "Error alert threshold must be greater than 5 and less than 500"
      );
    }
    return Promise.resolve();
  };

  return (
    <>
      {isEdit ? (
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => setOpen(true)}
        />
      ) : (
        <Button onClick={() => setOpen(true)} type="primary">
          New Service
        </Button>
      )}

      {open && (
        <Modal
          open={open}
          onCancel={() => setOpen(false)}
          title={isEdit ? `Edit ${service.name}` : "New Service"}
          footer={null}
        >
          <Form form={form} onFinish={handleFinish}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please input a name" }]}
              initialValue={service ? service.name : undefined}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="owners"
              label="Owners"
              rules={[{ required: true, message: "Please select an owner!" }]}
              initialValue={
                service ? service.owners?.map((x) => x.id) : undefined
              }
            >
              <Select
                showSearch
                mode="multiple"
                filterOption={(input, option) =>
                  option?.fullname.toLowerCase().includes(input.toLowerCase())
                }
              >
                {owners.data
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((owner: { id: string; name: string }) => (
                    <Select.Option
                      key={owner.id}
                      value={owner.id}
                      fullname={owner.name}
                    >
                      {owner.name}
                    </Select.Option>
                  )) || []}
              </Select>
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                { required: true, message: "Please input a description!" },
              ]}
              initialValue={service ? service.description : undefined}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="gitRepo"
              label="Git Repo"
              rules={[{ required: true, message: "Please input a git repo!" }]}
              initialValue={service ? service.gitRepo : undefined}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="runTimeEngine"
              label="Runtime Engine"
              rules={[
                { required: true, message: "Please input a runtime engine!" },
              ]}
              initialValue={service ? service.runTimeEngine : undefined}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Error alert threshold"
              name="errorAlertThreshold"
              validateFirst
              rules={[
                {
                  message: "Please input the error alert threshold",
                },
                { validator: validateErrorAlertThreshold },
              ]}
            >
              <Input type="number" min={5} max={500} />
            </Form.Item>
            <InputWithTagsFieldString
              tags={subscriptions}
              setTags={setSubscriptions}
              form={form}
              label="Subscriptions"
            />
            <InputWithTagsFieldString
              tags={topics}
              setTags={setTopics}
              form={form}
              label="Topics"
            />
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                {edit ? "Update" : "Create Service"}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};
