import { Card, Spin } from "antd"
import { useGetEntity } from "../../../apis/Entity"
import { useAuthContext } from "../../../context/AuthContext"
import { RowContent } from "../../../components/Utils/RowContent"
import { useTeams } from "../../../apis/teams"
import { GetError } from "../../../widgets/GetError"
import { EntityLink } from "../../../components/Entities/components/EntityLink"

type Props = {
  searchId: string
}

export default function EntityCard({ searchId }: Props) {
  const { env, token } = useAuthContext()
  const entity = useGetEntity({ env, token: token[env], searchId })
  const teams = useTeams({ env, token: token[env] })

  if (searchId === "") {
    return null
  }

  if (entity.isLoading || teams.isLoading) return <Spin />

  if (teams.error || !teams.data) return <GetError reFetch={teams.refetch} />
  if (entity.error || !entity.data) return <GetError reFetch={entity.refetch} />

  if (entity.data.length < 1) {
    return <p>No results found...</p>
  }

  return (
    <div style={{ display: "flex", gap: "20px", flexDirection: "column" }}>
      {entity.data.map((item) => (
        <Card
          title="Entity Details"
          extra={<EntityLink type={item.type} id={item.id} />}
        >
          <RowContent name="Name" content={item.name} />
          <RowContent name="Type" content={item.type} />
          <RowContent
            name="Team"
            content={
              teams.data.find((t) => t.id === item.teamId)?.name || "Not found"
            }
          />
        </Card>
      ))}
    </div>
  )
}
