import React from "react"
import { Link } from "react-router-dom"
// import "components/ActivityFeed/ActivityFeedMarkDown.scss"
import {
  ActivityFeedsPropData,
  DispatchType,
} from "../../../../../types/DispatchEvent"
import { Typography } from "antd"

export const DispatchActivityFeed = ({
  type,
  data,
}: {
  type?: DispatchType
  data: ActivityFeedsPropData
}) => {
  return (
    <>
      <Link
        className="markdown-link"
        to={type ? `/${type}-detail-page/${data.id}` : `/dispatches/${data.id}`}
      >
        <Typography.Text>{data.value?.toString()}</Typography.Text>
      </Link>
    </>
  )
}
