import * as React from 'react'

export const CheckedMap = (props: any) => (
  <svg width={14} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.6 2.846c0-.297-.118-.583-.327-.794a1.113 1.113 0 0 0-.79-.329H10.25V.6H9.133v1.123H4.667V.6H3.55v1.123H1.317c-.297 0-.58.119-.79.33-.21.21-.327.496-.327.793v11.231c0 .298.118.584.327.794.21.211.493.33.79.33H3.55v-1.124H1.317V2.847H3.55V3.97h1.117V2.846h4.466V3.97h1.117V2.846h2.233v3.37H13.6v-3.37Z"
      fill="#8C9095"
    />
    <path d="M0 3.076V4.2h9.666A1.134 1.134 0 0 0 10.8 3.067V.8H9.666v2.276H0Z" fill="#8C9095" />
    <path d="M6.74 0 7.6.88 2.78 5.8.2 3.165l.86-.878 1.72 1.757L6.74 0Z" fill="#8C9095" />
  </svg>
)
