import React, { useState } from "react"
import { Button, Card, Col, Input, Row, Table } from "antd"
import {
  CustomerRequestMetric,
  FlattenedCustomerRequestMetrics,
} from "../../../types/Team"
import { SearchOutlined } from "@ant-design/icons"

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 300,
  },
  {
    title: "Lifetime Requests",
    children: [
      {
        title: "Total",
        dataIndex: "totalLifetime",
        key: "totalLifetime",
      },
      {
        title: "Accepted",
        dataIndex: "acceptedLifetime",
        key: "acceptedLifetime",
      },
      {
        title: "Declined",
        dataIndex: "declinedLifetime",
        key: "declinedLifetime",
      },
      {
        title: "Pending",
        dataIndex: "pendingLifetime",
        key: "pendingLifetime",
      },
    ],
  },
  {
    title: "Non-Customer Requests",
    children: [
      {
        title: "Total",
        dataIndex: "totalNonCustomer",
        key: "totalNonCustomer",
      },
      {
        title: "Accepted",
        dataIndex: "acceptedNonCustomer",
        key: "acceptedNonCustomer",
      },
      {
        title: "Declined",
        dataIndex: "declinedNonCustomer",
        key: "declinedNonCustomer",
      },
      {
        title: "Pending",
        dataIndex: "pendingNonCustomer",
        key: "pendingNonCustomer",
      },
    ],
  },
]

export default function TopPropertyRequests({
  requestData,
}: {
  title?: string
  requestData: CustomerRequestMetric[]
}) {
  const [searchValueProperties, setSearchValueProperties] = useState<string>("")
  const [sortedData, setSortedData] = useState<
    FlattenedCustomerRequestMetrics[]
  >([])

  const remappedData: FlattenedCustomerRequestMetrics[] = requestData.map(
    ({ id: key, name, allRequests, requestsToNonCustomers }) => ({
      key,
      name,
      totalLifetime: allRequests.total,
      acceptedLifetime: allRequests.accepted,
      declinedLifetime: allRequests.declined,
      pendingLifetime: allRequests.pending,
      totalNonCustomer: requestsToNonCustomers.total,
      acceptedNonCustomer: requestsToNonCustomers.accepted,
      declinedNonCustomer: requestsToNonCustomers.declined,
      pendingNonCustomer: requestsToNonCustomers.pending,
    })
  )

  const handleSortTopNetworkers = () => {
    const sorted = [...remappedData].sort(
      (a, b) => b.totalLifetime - a.totalLifetime
    )
    setSortedData(sorted)
  }

  const handleSortTopAmbassadors = () => {
    const sorted = [...remappedData].sort(
      (a, b) => b.totalNonCustomer - a.totalNonCustomer
    )
    setSortedData(sorted)
  }

  return (
    <Card>
      <Col style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <Row
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
          }}
        >
          <Col style={{ flex: 1 }}>
            <Input
              style={{ minWidth: "150px" }}
              value={searchValueProperties}
              onChange={(e) => setSearchValueProperties(e.target.value)}
              placeholder={"Type to search..."}
              addonAfter={<SearchOutlined />}
            />
          </Col>

          <Button type="primary" onClick={handleSortTopNetworkers}>
            Top Networkers
          </Button>
          <Button type="primary" onClick={handleSortTopAmbassadors}>
            Top Ambassadors
          </Button>
        </Row>
        <Row>
          <div style={{ overflowX: "auto", width: "100%" }}>
            <Table
              style={{ minWidth: "800px" }} // Set a minWidth to ensure it can scroll when needed
              dataSource={(sortedData.length > 0
                ? sortedData
                : remappedData
              ).filter(
                (property) =>
                  searchValueProperties.length === 0 ||
                  property.name
                    .toLowerCase()
                    .includes(searchValueProperties.toLowerCase())
              )}
              columns={columns}
              bordered
            />
          </div>
        </Row>
      </Col>
    </Card>
  )
}
