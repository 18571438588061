import { Form, Input, Tag } from "antd";
import { FormInstance } from "rc-field-form";
import React, { Dispatch, SetStateAction, useState } from "react";

type Props = {
  tags: string[];
  setTags: Dispatch<SetStateAction<string[]>>;
  form: FormInstance<any>;
  label: string;
};

export const InputWithTagsFieldString = ({
  tags,
  setTags,
  form,
  label,
}: Props) => {
  const [tagsInput, setTagsInput] = useState<string>();
  return (
    <Form.Item label={label}>
      <Input
        onChange={(e) => setTagsInput(e.target.value)}
        placeholder="Press enter to add versions"
        onKeyPress={(e) => {
          if (e.key === "Enter" && tagsInput) {
            e.preventDefault();
            if (tags.includes(tagsInput)) {
              setTagsInput("");
              return;
            }
            setTags([...tags, tagsInput]);
            setTagsInput("");
          }
        }}
        value={tagsInput}
        addonBefore={
          tags
            ? tags.map((number) => (
                <Tag
                  key={`sorting-tag-${number}`}
                  className="list-tag-filter"
                  color="grey"
                  closable
                  onClose={() => setTags(tags.filter((tag) => tag !== number))}
                >
                  {number}
                </Tag>
              ))
            : null
        }
      />
    </Form.Item>
  );
};
