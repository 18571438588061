import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { VizznResponse } from "../../types/Api"
import { SetEnterpriseAdminResponse } from "../../types/Enterprise"
import { environmentType } from "../../types/environtment"
import { getEnvUrl } from "../../utils/utils"

const setEnterpriseAdmin = async (body: {
  env: environmentType
  token: string | null
  userId: string
  enterpriseId: string
  remove?: boolean
}) => {
  if (!body.token) return

  const { data } = await axios.post<
    VizznResponse<SetEnterpriseAdminResponse>
  >(
    `${getEnvUrl(body.env)}/v1/users/${body.userId}/enterprise-admin`,
    {
      userId: body.userId,
      enterpriseId: body.enterpriseId,
      remove: body.remove,
    },
    { headers: { Authorization: `Bearer ${body.token}` } }
  )
  return data.data
}

export const useSetEnterpriseAdmin = (id: string) => {
  const queryClient = useQueryClient()

  return useMutation(setEnterpriseAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["enterprise-detail", id] })
    },
  })
}
