import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import axios from "axios";
import { environmentType } from "../types/environtment";
import {
  UniversityArticle,
  UniversityVideo,
  UpdateUniversityVideoRequest,
} from "../types/university";
import { getEnvUrl } from "../utils/utils";

const getUniversityArticles = async (body: {
  env: environmentType;
  token: string | null;
}) => {
  if (!body.token) return;
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/learning/articles`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  );
  return data.data.data;
};

export const useGetUniversityArticles = (body: {
  env: environmentType;
  token: string | null;
}) => {
  const response = useQuery<UniversityArticle[]>(
    ["university-articles", body.env],
    () => getUniversityArticles(body)
  );

  return response;
};
const getUniversityVideos = async (body: {
  env: environmentType;
  token: string | null;
}) => {
  if (!body.token) return;
  const { data } = await axios.get(`${getEnvUrl(body.env)}/v1/learning`, {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  });
  if (data) return data.data;
};

export const useGetUniversityVideos = (body: {
  env: environmentType;
  token: string | null;
}) => {
  const response = useQuery<UniversityVideo[]>(
    ["university-videos", body.env],
    () => getUniversityVideos(body)
  );

  return response;
};

const createUniversityVideo = async (body: {
  payload: UpdateUniversityVideoRequest;
  env: environmentType;
  token: string | null;
}) => {
  try {
    if (body.token) {
      const { data } = await axios.post(
        `${getEnvUrl(body.env)}/v1/learning`,
        body.payload,
        {
          headers: {
            Authorization: `Bearer ${body.token}`,
          },
        }
      );
      message.success("Video Created");
      return data.data as UniversityVideo;
    }
  } catch (err) {
    // message.error(err.message)
  }
};
export const useCreateUniversityVideo = () => {
  const queryClient = useQueryClient();
  return useMutation(createUniversityVideo, {
    onSuccess: async (data, params) => {
      if (!data) return;
      const oldData = queryClient.getQueryData<UniversityVideo[]>([
        "university-videos",
        params.env,
      ]);
      if (oldData) {
        const newData = [...oldData, data];

        queryClient.setQueriesData(
          ["university-videos", params.env],
          () => newData
        );
      }
    },
  });
};
const updateUniversityVideo = async (body: {
  payload: UpdateUniversityVideoRequest;
  env: environmentType;
  token: string | null;
}) => {
  try {
    if (body.token) {
      const { data } = await axios.put(
        `${getEnvUrl(body.env)}/v1/learning/${body.payload.id}`,
        body.payload,
        {
          headers: {
            Authorization: `Bearer ${body.token}`,
          },
        }
      );
      message.success("Video updated");
      return data.data as UniversityVideo;
    }
  } catch (err) {
    // message.error(err.message)
  }
};

export const useUpdateUniversityVideo = () => {
  const queryClient = useQueryClient();
  return useMutation(updateUniversityVideo, {
    onSuccess: async (data, params) => {
      if (!data) return;
      const oldData = queryClient.getQueryData<UniversityVideo[]>([
        "university-videos",
        params.env,
      ]);
      if (oldData) {
        const index = oldData.findIndex((item) => item.id === data.id);
        const newData = [...oldData];
        newData[index] = data;
        queryClient.setQueriesData(
          ["university-videos", params.env],
          () => newData
        );
      }
    },
  });
};

export const removeUniversityVideo = async (body: {
  id: string;
  env: environmentType;
  token: string | null;
}) => {
  try {
    if (body.token) {
      const { data } = await axios.delete(
        `${getEnvUrl(body.env)}/v1/learning/${body.id}`,

        {
          headers: {
            Authorization: `Bearer ${body.token}`,
          },
        }
      );
      message.success("Video removed");
      return data.data.id;
    }
  } catch (err) {
    // message.error(err.message)
  }
};

export const useRemoveUniversityVideo = () => {
  const queryClient = useQueryClient();
  return useMutation(removeUniversityVideo, {
    onSuccess: async (data, params) => {
      if (!data) return;
      const oldData = queryClient.getQueryData<UniversityVideo[]>([
        "university-videos",
        params.env,
      ]);
      if (oldData) {
        const newData = oldData.filter((item) => item.id !== data);
        queryClient.setQueriesData(
          ["university-videos", params.env],
          () => newData
        );
      }
    },
  });
};
