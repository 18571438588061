import { Spin } from "antd"
import { useGetUsageScores } from "../../../apis/UsageScores"
import BarChartCard from "../../../charts/BarChartCard"
import { useAuthContext } from "../../../context/AuthContext"
import { GetError } from "../../../widgets/GetError"

export default function TeamUsageScores() {
  const { token, env } = useAuthContext()
  const teamUsageScore = useGetUsageScores({
    env,
    token: token[env],
  })

  if (teamUsageScore.isLoading || !token[env]) return <Spin />
  if (teamUsageScore.error || !teamUsageScore.data)
    return <GetError reFetch={teamUsageScore.refetch} />

  const data = teamUsageScore.data?.map((d) => ({
    date: d.timestamp,
    score: d.score,
    teamname: d.team?.name ?? "Unknown Team",
  }))

  return (
    <BarChartCard
      data={data}
      title={`Usage Scores`}
      dataKey="score"
      xAxisDataKey="teamname"
      height={450}
      hideLabels={true}
    />
  )
}
