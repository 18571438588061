import { EditOutlined, StopOutlined } from "@ant-design/icons"
import { Button, Col, Form, Row, TimePicker, Tooltip } from "antd"
import { useState } from "react"
import { UserNotificationRequest } from "../types/user"
import dayjs from "dayjs"
import { environmentType } from "../types/environtment"
import { useAuthContext } from "../context/AuthContext"
type Props = {
  name: string
  value: number | undefined
  onSave: (body: {
    id: string
    env: environmentType
    token: string | null
    payload: UserNotificationRequest
  }) => Promise<{ id: string } | undefined>
  data: UserNotificationRequest
}

const EditableTimePicker = ({ name, value = 0, onSave, data }: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const timezoneOffset = new Date().getTimezoneOffset()
  const { env, token } = useAuthContext()

  const toggleIsEditing = () => {
    setIsEditing(!isEditing)
  }
  const midNight = dayjs().startOf("day")

  return isEditing ? (
    <Form style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Form.Item style={{ margin: 0 }}>
        <TimePicker
          onBlur={() => toggleIsEditing()}
          style={{ width: "200px" }}
          className="overview-field-content"
          format={"HH:mm"}
          defaultValue={dayjs()
            .startOf("day")
            .add(value - timezoneOffset, "minute")}
          onChange={(e) => {
            const newValue =
              (1440 + (e?.diff(midNight, "minutes") || 0) + timezoneOffset) %
              1440
            onSave({
              id: data.id,
              env,
              token: token[env],
              payload: { ...data, [name]: newValue },
            })
            toggleIsEditing()
          }}
        />
      </Form.Item>
      <Button size="small" type="default" onClick={() => toggleIsEditing()}>
        <StopOutlined />
      </Button>
    </Form>
  ) : (
    <Row gutter={8} justify="space-between" align="middle">
      <Col>
        <div
          className="overview-field-content bold"
          onClick={() => toggleIsEditing()}
        >
          {midNight.add(value - timezoneOffset, "minute").format("HH:mm")}
        </div>
      </Col>
      <Col>
        <Tooltip title="Edit">
          <EditOutlined
            onClick={() => toggleIsEditing()}
            className="editable-icon"
          />
        </Tooltip>
      </Col>
    </Row>
  )
}

export default EditableTimePicker
