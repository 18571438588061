import { Col, Input, Row } from "antd"
import { debounce } from "lodash"
import { useState } from "react"
import EntityCard from "../components/Entities/components/EntityCard"

export const EntitySearch = () => {
  const [searchId, setSearchId] = useState<string>("")

  const handleSearchValue = debounce((val: string) => {
    setSearchId(val)
  }, 350)

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "20px",
      }}
    >
      <Row>
        <Col flex="auto">
          <Input.Search
            onChange={(e) => handleSearchValue(e.target.value || "")}
            allowClear
            placeholder="Type to search..."
          />
        </Col>
      </Row>
      <EntityCard searchId={searchId} />
    </div>
  )
}
