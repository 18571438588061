import React, { useEffect } from "react"
import { Card } from "antd"
import { format, startOfWeek, parseISO } from "date-fns"
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { useGetUsageScoresByTeamId } from "../../apis/UsageScores"
import { useAuthContext } from "../../context/AuthContext"
import { colors } from "../../utils/constants"
import "./TeamUsageScore.scss"
import CategoryUsageScoreCards from "./partial/CategoryUsageScoreCards"

type Props = {
  id: string
}

export default function TeamUsageScore({ id }: Props) {
  const { token, env } = useAuthContext()

  const teamUsageScore = useGetUsageScoresByTeamId({
    env,
    token: token[env],
    teamId: id,
  })

  const [selectedCategories, setSelectedCategories] = React.useState<{
    [key: string]: boolean
  }>({})

  useEffect(() => {
    if (teamUsageScore.data) {
      const initialSelectedCategories = teamUsageScore.data.reduce(
        (acc: { [key: string]: boolean }, cur: any) => {
          acc[cur.category.name] = true
          return acc
        },
        {}
      )
      setSelectedCategories(initialSelectedCategories)
    }
  }, [teamUsageScore.data])

  const groupedScoresByWeek = React.useMemo(() => {
    if (!teamUsageScore.data) return []

    const groupedData = teamUsageScore.data.reduce(
      (acc: { [key: string]: { [key: string]: number } }, cur: any) => {
        const timestamp = new Date(cur.timestamp)
        const weekStart = startOfWeek(timestamp, { weekStartsOn: 0 })
        const category = cur.category.name
        const weekKey = format(weekStart, "yyyy-MM-dd")

        if (!acc[category]) acc[category] = {}
        if (!acc[category][weekKey]) acc[category][weekKey] = 0
        acc[category][weekKey] += cur.score

        return acc
      },
      {}
    )

    const uniqueWeeks = new Set<string>()
    Object.values(groupedData).forEach((category) => {
      Object.keys(category).forEach((week) => uniqueWeeks.add(week))
    })

    const sortedWeeks = Array.from(uniqueWeeks).sort(
      (a, b) => parseISO(a).getTime() - parseISO(b).getTime()
    )

    return Object.entries(groupedData).map(([category, data]) => ({
      category,
      data: sortedWeeks.map((week) => ({
        week: format(parseISO(week), "yyyy, MMM, dd"),
        score: data[week] || 0,
      })),
      stroke: colors[Math.floor(Math.random() * colors.length)],
    }))
  }, [teamUsageScore.data])

  const filteredScores = React.useMemo(() => {
    return groupedScoresByWeek.filter(
      (group) => selectedCategories[group.category]
    )
  }, [groupedScoresByWeek, selectedCategories])

  const strokeColors = React.useMemo(() => {
    return groupedScoresByWeek.reduce(
      (acc: { [key: string]: string }, group) => {
        acc[group.category] = group.stroke
        return acc
      },
      {}
    )
  }, [groupedScoresByWeek])

  const handleCategoryToggle = (category: string) => {
    setSelectedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }))
  }

  return (
    <div style={{ marginBottom: "20px" }}>
      <div className="team-usage-scores-container">
        <div>
          <Card title={`Usage Scores`}>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart width={500} height={300}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="week"
                  type="category"
                  allowDuplicatedCategory={false}
                />
                <YAxis dataKey="score" />
                <Tooltip />
                {filteredScores.map((s, index) => (
                  <Line
                    dataKey="score"
                    data={s.data || []}
                    name={s.category}
                    key={`${s.category}-${index}`}
                    stroke={s.stroke}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </div>
        <CategoryUsageScoreCards
          teamUsageScoreData={teamUsageScore.data ?? []}
          selectedCategories={selectedCategories}
          onCategoryToggle={handleCategoryToggle}
          strokeColors={strokeColors}
        />
      </div>
    </div>
  )
}
