import React from 'react'
import { Link } from 'react-router-dom'
import Markdown from 'react-markdown'
import { EntityActivityFeed } from './partial/EntityActivityFeed'
import { DefaultActivityFeed } from './partial/DefaultActivityFeed'
import { TimeActivityFeed } from './partial/TimeActivityFeed'
import { StatusActivityFeed } from './partial/StatusActivityFeed'
import { DispatchActivityFeed } from './partial/DispatchActivityFeed'
import { LoadTypesActivityFeed } from './partial/LoadTypesActivityFeed'
import { ActivityFeedData } from '../../types/DispatchExplorer'

type Props = {
  message: ActivityFeedData
}

export const MarkDownMessage = ({ message }: Props) => {
  return (
    <Markdown className="markdown" components={markdownRouter(message) as any}>
      {message.text}
    </Markdown>
  )
}

export const markdownRouter = (message: ActivityFeedData) => {
  return {
    a: ({ children, href }: { children: any; href: 'type' | 'id' | 'value' }) => {
      const child = children && children[0]
      switch (child) {
        case `v:equipment`:
          return <EntityActivityFeed type={'equipment'} data={message.props[href]} />
        case `v:site`:
          return <EntityActivityFeed type={'site'} data={message.props[href]} />
        case 'v:contact':
          return <EntityActivityFeed type={'contact'} data={message.props[href]} />
        case `v:dispatch-request`:
          return <EntityActivityFeed type={'dispatch-request'} data={message.props[href]} />
        case 'v:dispatch':
          return <DispatchActivityFeed type={message.props[href].type} data={message.props[href]} />
        case 'v:dispatch-resource':
          return <DefaultActivityFeed data={message.props[href]} />
        case 'v:time':
          return <TimeActivityFeed data={message.props[href]} />
        case 'v:trailer':
          return <EntityActivityFeed type={'trailer'} data={message.props[href]} />
        case 'v:trailer-combination':
          return <EntityActivityFeed type={'trailer-combination'} data={message.props[href]} />
        case 'v:truck':
          return <EntityActivityFeed type={'truck'} data={message.props[href]} />
        case 'v:truck-configuration':
          return <EntityActivityFeed type={'truck-configuration'} data={message.props[href]} />
        case 'v:external-company':
          return <EntityActivityFeed type={'external-company'} data={message.props[href]} />
        case 'v:status':
          return <StatusActivityFeed data={message.props[href]} />
        case 'v:loadTypes':
          return <LoadTypesActivityFeed data={message.props[href] as { value?: string[] | undefined }} />
        case 'v:entityClassId':
          return (
            <>
              {message.props && message.props[href].id ? (
                <Link className="markdown-link" to={`/sites/${message.props[href].id}`}>
                  <span>{String(message.props[href].value)}</span>
                </Link>
              ) : (
                message.props && <strong>{String(message.props[href].value)}</strong>
              )}
            </>
          )

        default:
          return <DefaultActivityFeed data={message.props[href]} />
      }
    },
  }
}
