import { EditOutlined, StopOutlined } from "@ant-design/icons"
import { Button, Col, TimePicker, Tooltip } from "antd"
import { useState } from "react"
import { Team } from "../../../types/Team"
import { useUpdateTeam } from "../../../apis/teams"
import { useAuthContext } from "../../../context/AuthContext"
import { add, format, startOfDay } from "date-fns"
import dayjs from "dayjs"

type Props = {
  name: "start" | "end"
  value: number | undefined
  data: Team
}

export const TeamEditableTimePicker = ({ name, value = 0, data }: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const { env, token } = useAuthContext()
  const { mutateAsync: updateTeam } = useUpdateTeam(data.id, env)

  const toggleIsEditing = () => {
    setIsEditing(!isEditing)
  }
  const midNight = startOfDay(new Date())

  return isEditing ? (
    <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
      <TimePicker
        style={{ width: "200px" }}
        minuteStep={30}
        className="overview-field-content"
        format="HH:mm"
        value={dayjs(add(midNight, { minutes: value }))}
        onChange={(e) => {
          const newValue = 1440 + ((e?.diff(midNight, "minutes") || 0) % 1440)
          updateTeam({
            data: { id: data.id, hours: { ...data.hours, [name]: newValue } },
            env,
            token: token[env],
          })
          toggleIsEditing()
        }}
      />
      <Button size="small" type="default" onClick={() => toggleIsEditing()}>
        <StopOutlined />
      </Button>
    </div>
  ) : (
    <>
      <Col>
        <b
          className="overview-field-content bold"
          onClick={() => toggleIsEditing()}
        >
          {format(add(midNight, { minutes: value }), "HH:mm")}
        </b>
      </Col>
      <Col>
        <Tooltip title="Edit">
          <Button size="small" onClick={() => toggleIsEditing()}>
            <EditOutlined />
          </Button>
        </Tooltip>
      </Col>
    </>
  )
}
