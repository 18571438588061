import { PlusCircleOutlined } from "@ant-design/icons"
import { Button, Form, Input, Modal, Tooltip } from "antd"
import { useEffect, useState } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { useCreateFeatureFlag } from "../../apis/featureFlags"
import { useGetUserData } from "../../apis/user"
import { useAuthContext } from "../../context/AuthContext"
import { environmentType } from "../../types/environtment"
import { FeatureFlagDTO } from "../../types/featureFlags"
import { reduceHtmlBr } from "../../utils/reduceHtmlBr"

type Props = {
  envName: environmentType
}

export const NewFeatureFlagModal = ({ envName }: Props) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name] = useState<string | undefined>()
  const [versions] = useState<number[]>([])
  const { mutateAsync: createFeatureFlag } = useCreateFeatureFlag()
  const { token, user } = useAuthContext()
  const userName = useGetUserData({
    userId: user.dev?.uid,
    env: envName,
    token: token[envName],
  })

  const [form] = Form.useForm()
  /*   const descriptionValue = Form.useWatch("description", form)
  const setFields = form.setFieldsValue */

  /* const handleNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields({
      description: replaceFirstH1(descriptionValue, e.target.value),
    })
  } */

  useEffect(() => {
    return () => form.resetFields()
  }, [form])

  const currentDate = new Date().toLocaleString("en-CA", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "America/Edmonton",
  })
  const newMessage = Form.useWatch("description", form)
  const newVersion = form.getFieldValue("activeVersion")
  const newText = `<p>${currentDate} - v${newVersion} - ${newMessage} - ${userName.data?.firstName}</p>`

  const handleFinish = async (values: FeatureFlagDTO) => {
    const { name, activeVersion, description } = values
    setLoading(true)
    const data = await createFeatureFlag({
      env: envName,
      token: token[envName],
      payload: {
        name,
        activeVersion,
        type: "app",
        versions,
        description: reduceHtmlBr(description) + newText,
      },
    })

    form.resetFields()
    setLoading(false)
    if (data) setOpen(false)
  }

  return (
    <>
      <Tooltip title={`Create new feature flag in ${envName}`}>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => setOpen(true)}
        />
      </Tooltip>
      {open && (
        <Modal
          open={open}
          title={`Create new feature flag in ${envName}`}
          onCancel={() => setOpen(false)}
          footer={null}
        >
          <Form form={form} onFinish={handleFinish}>
            <Form.Item name="name" label="Name">
              <Input value={name} placeholder={`Feature flag name`} />
            </Form.Item>
            <Form.Item
              name="activeVersion"
              label="Active Version"
              initialValue={0}
            >
              <Input type="number" disabled />
            </Form.Item>

            <Form.Item name="description" label="Change Logs/Notes">
              <ReactQuill theme="snow" />
            </Form.Item>

            <hr />
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Create
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}
