// import { LocalOnlyTestComponent } from "../components/common/LocalOnlyTestComponent"
// import { useAuthContext } from "../context/AuthContext"
import { environmentType } from "../types/environtment"
import { EnvStatus } from "./EnvStatus"

export const AppLoginStatus = () => {
  const envs: environmentType[] = ["dev", "prod"]
  // const { isFirebaseLogin, setIsFirebaseLogin, env } = useAuthContext()

  return (
    <span>
      <b style={{ marginRight: 8 }}>Login Status:</b>
      {envs.map((env) => (
        <EnvStatus env={env} key={env} />
      ))}
    </span>
  )
}
