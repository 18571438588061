import { Button, Result } from "antd";
import React from "react";

type Props = {
  reFetch: () => void;
  children?: string;
};
export const GetError = ({ reFetch, children }: Props) => (
  <Result
    subTitle={children || "An Error has occured!"}
    status="error"
    extra={<Button onClick={(e) => reFetch()}>Re-Try</Button>}
  />
);
