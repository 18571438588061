import TargetBlankLink from "./TargetBlankLink"

const GOOGLE_MAPS_URL = "https://maps.google.com/maps?q="

type Props = {
  address: string
  label: string
}

export default function GoogleMapsLink({ address, label }: Props) {
  return <TargetBlankLink href={`${GOOGLE_MAPS_URL}${address}`} label={label} />
}
