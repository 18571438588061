const mimeType: { [type: string]: string } = {
  csv: "text/csv; charset=utf-8",
  json: "application/json; charset=utf-8",
}

export const downloadFile = (
  data: string,
  contentType: string,
  fileName: string
) => {
  const hiddenElement = document.createElement("a")
  hiddenElement.href = `data:${mimeType[contentType]},${encodeURIComponent(
    data
  )}`
  hiddenElement.target = "_blank"
  hiddenElement.download = fileName
  hiddenElement.click()
}
