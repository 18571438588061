import { Card, Col, Row, Skeleton, Switch, Tag } from "antd"
import React from "react"
import { GetError } from "../../widgets/GetError"
import { useAuthContext } from "../../context/AuthContext"
import {
  useGetUserData,
  useUpdateUserNotificationPreferences,
} from "../../apis/user"
import EditableTimePicker from "../../widgets/EditableTimePicker"
import { RowContent } from "../Utils/RowContent"
type Params = {
  id: string
}

export const UserNotificationWidget = ({ id }: Params) => {
  const { env, token } = useAuthContext()
  const userData = useGetUserData({ userId: id, env, token: token[env] })
  const { mutateAsync: updateUserNotificationPreferences } =
    useUpdateUserNotificationPreferences(id, env)

  const { status, ...notificationPreferences } = {
    ...userData.data?.notificationPreference,
    id: id,
  }

  if (userData.isLoading)
    return (
      <Card>
        <Row gutter={[16, 16]} justify="space-between">
          <Col flex="auto">
            <Skeleton title paragraph={{ rows: 1 }} active />
          </Col>
          <Col>
            <Skeleton.Button shape="circle" active />
          </Col>
        </Row>
        <div className="info-row">
          <Skeleton.Input size="small" active />
        </div>
        <div className="info-row">
          <Skeleton.Input size="small" active />
        </div>
        <div className="info-row">
          <Skeleton.Input size="small" active />
        </div>
        <div className="info-row">
          <Skeleton.Input size="small" active />
        </div>
      </Card>
    )

  if (userData.error) {
    return <GetError reFetch={userData.refetch} />
  }
  if (!userData.data) {
    return <></>
  }

  return (
    <Card
      title={
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          Notifications
          <Tag
            className="tag"
            style={
              status === "Receiving"
                ? { backgroundColor: "green", color: "white" }
                : {}
            }
          >
            {status}
          </Tag>
        </div>
      }
    >
      <RowContent
        name="Receive Notifications"
        content={
          <Switch
            className="overview-field-content"
            checked={userData.data.notificationPreference.isActive}
            onChange={(value) =>
              updateUserNotificationPreferences({
                id,
                env,
                token: token[env],
                payload: {
                  ...notificationPreferences,
                  isActive: value,
                },
              })
            }
          />
        }
      />

      {status !== "Off" && (
        <>
          <RowContent
            name="Schedule"
            content={
              <Switch
                className="overview-field-content"
                checked={notificationPreferences.hasSchedule}
                onChange={(value) =>
                  updateUserNotificationPreferences({
                    id,
                    env,
                    token: token[env],
                    payload: {
                      ...notificationPreferences!,
                      hasSchedule: value,
                    },
                  })
                }
              />
            }
          />

          {!notificationPreferences.hasSchedule && (
            <RowContent name="Notifications enabled" content="Always" />
          )}
          {notificationPreferences.hasSchedule && (
            <>
              <RowContent
                name="From"
                content={
                  <EditableTimePicker
                    name="scheduleStart"
                    value={notificationPreferences.scheduleStart}
                    onSave={updateUserNotificationPreferences}
                    data={notificationPreferences}
                  />
                }
              />

              <RowContent
                name="To"
                content={
                  <EditableTimePicker
                    name="scheduleEnd"
                    value={notificationPreferences.scheduleEnd}
                    onSave={updateUserNotificationPreferences}
                    data={notificationPreferences}
                  />
                }
              />
            </>
          )}
        </>
      )}
    </Card>
  )
}
