import { useState } from "react"
import moment from "moment-timezone"
import { Button, Col, Form, Select, Tooltip } from "antd"
import { EditOutlined, StopOutlined } from "@ant-design/icons"
import { Team } from "../../../types/Team"
import { useUpdateTeam } from "../../../apis/teams"
import { useAuthContext } from "../../../context/AuthContext"

type Props = {
  value: string
  data: Team
}

export const EditableTimezoneDropdown = ({ value, data }: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const { env, token } = useAuthContext()
  const { mutateAsync: updateTeam } = useUpdateTeam(data.id, env)
  const timezones = moment.tz.names()

  const toggleIsEditing = () => {
    setIsEditing(!isEditing)
  }

  return isEditing ? (
    <Form style={{ display: "flex", gap: 10, alignItems: "center" }}>
      <Select
        value={value}
        showSearch
        filterOption={(input, option) =>
          option?.tz.toLowerCase().includes(input.toLowerCase())
        }
        style={{ width: "200px" }}
        onChange={(value) => {
          updateTeam({
            data: {
              id: data.id,
              hours: { ...data.hours, timezone: value as string },
            },
            env,
            token: token[env],
          })
          toggleIsEditing()
        }}
      >
        {timezones.map((tz) => (
          <Select.Option key={tz} tz={tz} value={tz}>
            {tz}
          </Select.Option>
        ))}
      </Select>
      <Button type="default" size="small" onClick={() => toggleIsEditing()}>
        <StopOutlined />
      </Button>
    </Form>
  ) : (
    <>
      <Col>
        <b
          className="overview-field-content bold"
          onClick={() => toggleIsEditing()}
        >
          {value}
        </b>
      </Col>
      <Col>
        <Tooltip title="Edit">
          <Button size="small" onClick={() => toggleIsEditing()}>
            <EditOutlined />
          </Button>
        </Tooltip>
      </Col>
    </>
  )
}
