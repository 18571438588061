import { Button, Col, Input, Row, Skeleton, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import { format } from "date-fns"
import { useMemo, useState } from "react"
import { useGetEmployeeApplications } from "../../apis/SelfApplications"
import { useAuthContext } from "../../context/AuthContext"
import { EmployeeApplication } from "../../types/AccountApplication"
import ErrorRefetch from "../ErrorRefetch/ErrorRefetch"
import { filterByKey } from "../../utils/filterByKey"

export default function EmployeeApplications() {
  const { token, env } = useAuthContext()
  const [searchText, setSearchText] = useState("")
  const employees = useGetEmployeeApplications(env, token[env])

  const columns: ColumnsType<EmployeeApplication> = useMemo(
    () => [
      {
        title: (
          <Row justify="center">
            <Col>Requester Name</Col>
          </Row>
        ),
        dataIndex: "requesterName",
        key: "requesterName",
        render: (_, row) => (
          <Row justify="center">
            <span>{row.transferUser.name}</span>
          </Row>
        ),
      },
      {
        title: (
          <Row justify="center">
            <Col>Requester Email</Col>
          </Row>
        ),
        dataIndex: "requesterEmail",
        key: "requesterEmail",
        render: (_, row) => (
          <Row justify="center">
            <span>{`${row.transferUser.email}`}</span>
          </Row>
        ),
      },
      {
        title: (
          <Row justify="center">
            <Col>Organization</Col>
          </Row>
        ),
        key: "organization",
        render: (_, record) => {
          return (
            <Row justify="center">
              <Col>{record.team.name ? record.team.name : "N/A"}</Col>
            </Row>
          )
        },
      },
      {
        title: <Row justify="center">Requester Phone</Row>,
        dataIndex: "requesterPhone",
        key: "requesterPhone",
        render: (_, record) => {
          return (
            <Row justify="center">
              <Col>{record.userPhone ? record.userPhone : "N/A"}</Col>
            </Row>
          )
        },
      },

      {
        title: (
          <Row justify="center">
            <Col>Requested At</Col>
          </Row>
        ),
        dataIndex: "requestedAt",
        key: "requestedAt",
        render: (_, record) => (
          <Row justify="center">
            <span>{format(new Date(record.createdAt), "MMM-dd-yyyy")}</span>
          </Row>
        ),
      },
      {
        title: (
          <Row justify="center">
            <Col>Last Updated At</Col>
          </Row>
        ),
        render: (_, row) => (
          <Row justify="center">
            <span>{format(new Date(row.updatedAt), "MMM-dd-yyyy")}</span>
          </Row>
        ),
        sorter: (a, b) =>
          a.updatedAt.toLocaleString() < b.updatedAt.toLocaleString() ? -1 : 1,
        key: "lastUpdatedAt",
      },
      {
        title: (
          <Row justify="center">
            <Col>Status</Col>
          </Row>
        ),
        dataIndex: "status",
        key: "status",
        render: (_, record) => {
          return (
            <Row justify="center">
              <Col>
                <Tag color={`#${record?.status?.bgColor}`}>
                  <span>{record.status.name}</span>
                </Tag>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  if (employees.isLoading) return <Skeleton active />
  if (!employees.data || employees.isError)
    return <ErrorRefetch refetch={employees.refetch} />

  return (
    <Col span={24}>
      <Row style={{ width: "100%", paddingBottom: 20 }} justify="space-between">
        <Col span={22}>
          <Input.Search
            placeholder="Search table data"
            onSearch={handleSearch}
            enterButton
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>
        <Col span={2}>
          <Row justify="end">
            <Button onClick={() => handleSearch("")}>Clear</Button>
          </Row>
        </Col>
      </Row>

      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={filterByKey<EmployeeApplication>(
              ["transferUser.name", "transferUser.email", "team.name"],
              employees?.data,
              searchText
            )}
            pagination={false}
          />
        </Col>
      </Row>
    </Col>
  )
}
