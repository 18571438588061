import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"
import { VizznResponse } from "../types/AccountApplication"
import { environmentType } from "../types/environtment"
import { FindTagsByTeamIdResponseDto } from "../types/MasterPriceItem"
import { TokenState } from "../types/user"
import { getEnvUrl } from "../utils/utils"

export const createLabel = async (body: {
  env: environmentType
  token: TokenState
  dto: {
    entityId: string
    entityType: "user"
    name: string
  }
}) => {
  try {
    const { data } = await axios.post<VizznResponse<any>>(
      `${getEnvUrl(body.env)}/v1/labels`,
      body.dto,
      {
        headers: {
          Authorization: `Bearer ${body.token[body.env]}`,
        },
      }
    )
    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}

//Delete Labels
export const deleteLabel = async (body: {
  env: environmentType
  token: TokenState
  dto: {
    entityId: string
    entityType: "user"
    name: string
  }
}) => {
  try {
    const { data } = await axios.delete<VizznResponse<any>>(
      `${getEnvUrl(body.env)}/v1/labels`,
      {
        headers: {
          Authorization: `Bearer ${body.token[body.env]}`,
        },
        data: body.dto,
      }
    )
    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}

export const useDeleteLabel = () => {
  return useMutation(deleteLabel)
}

export const useCreateLabelInEntity = () => {
  return useMutation(createLabel)
}

const findTagsByTeamId = async (
  env: environmentType,
  token: TokenState,
  teamId: string | null | undefined
) => {
  const { data } = await axios.get<FindTagsByTeamIdResponseDto>(
    `
    ${getEnvUrl(env)}/v1/tags/team/${teamId}`,
    {
      headers: {
        Authorization: `Bearer ${token[env]}`,
      },
    }
  )

  return data.tags
}

export const useFindTagsByTeamId = (
  env: environmentType,
  token: TokenState,
  teamId: string | null | undefined,
  isModalOpen: boolean
) => {
  return useQuery(
    ["tags", teamId],
    () => findTagsByTeamId(env, token, teamId),
    {
      enabled: isModalOpen,
    }
  )
}
