import { LanguageCode } from "./Language"
import { OperatingHours } from "./Time"

export type Team = {
  allowSignup: boolean
  endOfDay: Date
  hours: {
    end: number
    start: number
    timezone: string
  }
  id: string
  isActive: boolean
  partners: string[]
  startOfDay: Date
  status: string
  tag: string
  name: string
  logo: string
  emailParts: string
  coordinate: TeamCoordinates
  activeUserCount: number
  userCount: number
  createdAt: string
  timeDisplay: TimeDisplay
  type: TeamType
}
export enum TeamType {
  Internal = "internal",
  HeavyCivil = "heavyCivilConstruction",
  Other = "other",
  Supplier = "supplier",
  Trucking = "trucking",
}

export type TeamCoordinates = {
  lat: number
  lng: number
}

export type TeamsResponse = {
  records: Team[]
  searchTerm?: string
  totalRecords: number
}
export type TeamRequest = {
  allowSignup?: boolean
  dimension?: DimensionUnit
  emailParts?: string
  hours: Hours
  name: string
  type: TeamType
  tag?: string
  weight?: WeightUnit
  speed?: SpeedUnit
  address: string
  coordinates: {
    lat: number
    lng: number
  }
}

export type DimensionUnit = "foot" | "inch" | "meter"
export type WeightUnit = "ton" | "pound" | "kilogram"
export type SpeedUnit = "kph" | "mph"

export type Hours = {
  start: number
  end: number
  timezone: string
}

export type TeamUpdateResquest = {
  allowSignup: boolean
  emailParts: string | null // ?
  hours: OperatingHours
  id: string
  isResourceProvider: boolean
  name: string
  type: TeamType
  tag: string
  logo?: string
  timeDisplay: TimeDisplay
}

export type Dimension = "foot" | "meter" | "yards"

export const DimensionUnitsMap: Record<Dimension, string> = {
  foot: "Foot",
  meter: "Meter",
  yards: "Yard",
}

export type Weight = "pound" | "kilogram" | "ton" | "metricTon"

export const WeightUnitsMap: Record<Weight, string> = {
  kilogram: "Kilogram",
  pound: "Pound",
  ton: "Ton",
  metricTon: "Metric Ton",
}

export type Speed = "kph" | "mph"

export const SpeedUnitsMap: Record<Speed, string> = {
  kph: "KPH",
  mph: "MPH",
}

export type DistanceUnits = "kilometer" | "mile"

export const DistanceUnitsMap: Record<DistanceUnits, string> = {
  kilometer: "Kilometer",
  mile: "Mile",
}

export type VolumeUnits = "cubicMeters" | "cubicYards" | "gallons" | "litres"

export const VolumeUnitsMap: Record<VolumeUnits, string> = {
  cubicMeters: "Cubic Meters",
  cubicYards: "Cubic Yards",
  gallons: "Gallons",
  litres: "Litres",
}

type AllVizznUnits = Dimension | Weight | Speed | DistanceUnits | VolumeUnits

export type TimeDisplay = "hour12" | "hour24"

export type TeamUnitKeys =
  | "dimension"
  | "weight"
  | "speed"
  | "volume"
  | "distance"

export const TeamUnitSelectValuesMap: Record<
  TeamUnitKeys,
  Record<string, string>
> = {
  dimension: DimensionUnitsMap,
  weight: WeightUnitsMap,
  speed: SpeedUnitsMap,
  distance: DistanceUnitsMap,
  volume: VolumeUnitsMap,
}

export type Preferences = {
  dimension: Dimension
  weight: Weight
  speed: Speed
  distance: DistanceUnits
  volume: VolumeUnits
  timeDisplay: TimeDisplay
  language: LanguageCode
}

export const TimeDisplayMap: Record<TimeDisplay, string> = {
  hour12: "12 Hour",
  hour24: "24 Hour",
}

export const TeamUnitsMap: Record<TeamUnitKeys, string> = {
  dimension: "Dimension",
  weight: "Weight",
  speed: "Speed",
  distance: "Distance",
  volume: "Volume",
}

export const unitsMap: Record<AllVizznUnits, string> = {
  ...DimensionUnitsMap,
  ...WeightUnitsMap,
  ...SpeedUnitsMap,
  ...DistanceUnitsMap,
  ...VolumeUnitsMap,
}

export type byType = {
  count: number
  byType: {
    internal: number
    heavyCivil: number
    other: number
    supplire: number
    trucking: number
    unclassified: number
  }
}

export type TeamMetricsGrowth = { month: string; count: number }[]

export type TeamMetricsCount = { count: number; byType: byType }

export type TeamMetricsData = {
  nonArchived: TeamMetricsCount
  archived: TeamMetricsCount
  growth: TeamMetricsGrowth
}

export type TeamMetrics = {
  detail: string
  status: number
  data: TeamMetricsData
}

export const monthNames: { [key: string]: string } = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  "10": "October",
  "11": "November",
  "12": "December",
}

export type RequestMetric = {
  total: number
  totalSparkline: number[]
  accepted: number
  acceptedSparkline: number[]
  declined: number
  declinedSparkline: number[]
  pending: number
  pendingSparkline: number[]
}

export type SimpleRequestMetric = {
  total: number
  accepted: number
  declined: number
  pending: number
}

export type CustomerRequestMetric = {
  id: string
  name: string
  allRequests: SimpleRequestMetric
  requestsToNonCustomers: SimpleRequestMetric
}

export type FlattenedCustomerRequestMetrics = {
  key: string
  name: string
  totalLifetime: number
  acceptedLifetime: number
  declinedLifetime: number
  pendingLifetime: number
  totalNonCustomer: number
  acceptedNonCustomer: number
  declinedNonCustomer: number
  pendingNonCustomer: number
}

export type PartnershipMetricsResponse = {
  allRequests: RequestMetric
  requestsToNonCustomers: RequestMetric
  requestsPerCustomer: CustomerRequestMetric[]
  sparklineLegend: string[]
}
