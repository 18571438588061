import { SearchOutlined } from "@ant-design/icons"
import { Row, Col, Input, Tag, Typography, Switch } from "antd"
import { debounce } from "lodash"
import React, { Dispatch, SetStateAction, useRef } from "react"
import { TooltipInfo } from "./TooltipInfo"
import { SearchTagsReturnProps } from "../../utils/useSearchTags"

export const ListHeader = ({
  title,
  description,
  total,
  displayedNum,
  searchTags,
  showArchived,
  setShowArchived,
  switchLabel,
  placeholder,
  actionButton,
}: {
  title?: string
  description?: string | React.ReactNode
  total: number
  displayedNum: number
  searchTags: SearchTagsReturnProps
  showArchived?: boolean
  setShowArchived?: Dispatch<SetStateAction<boolean>>
  switchLabel?: string
  placeholder?: string
  actionButton?: string | React.ReactNode
}) => {
  const searchRef = useRef<any | null>(null)
  const { tags, removeTag, addTag, searchValue, setSearchValue } = searchTags

  const handleSearch = debounce((val: string) => {
    setSearchValue(val)
  }, 250)

  return (
    <div>
      <Row>
        {title && (
          <Typography.Title level={4}>
            <strong>{title}</strong>
          </Typography.Title>
        )}
        {description && <TooltipInfo message={description} />}
        {actionButton}
      </Row>
      <Row gutter={16}>
        <Col flex="auto" style={{ alignContent: "center" }}>
          <Input
            ref={(input) => {
              searchRef.current = input
            }}
            className="list-search-input"
            defaultValue={searchValue}
            placeholder={placeholder || "Type to search..."}
            onChange={(e) => {
              e.persist()
              handleSearch(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && searchRef?.current && searchValue) {
                addTag(searchValue)
                searchRef.current.setValue("")
                setSearchValue("")
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && !searchValue && tags.length) {
                removeTag(tags[tags.length - 1])
              }
            }}
            addonBefore={
              tags &&
              tags.map((name) => (
                <Tag
                  key={`sorting-tag-${name}`}
                  className="list-tag-filter"
                  color="grey"
                  closable
                  onClose={() => removeTag(name)}
                >
                  {name}
                </Tag>
              ))
            }
            addonAfter={<SearchOutlined />}
          />
        </Col>
        {total > 0 && (
          <Col>
            <Typography.Text
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              Displaying: <br />{" "}
              <strong style={{ fontSize: 16 }}>
                {displayedNum} / {total}
              </strong>
            </Typography.Text>
          </Col>
        )}

        {setShowArchived && (
          <Col>
            <Switch
              className="list-archived-switch"
              size="small"
              checkedChildren="Show"
              unCheckedChildren="Hide"
              checked={showArchived}
              onChange={() => setShowArchived((state) => !state)}
            />
            <Typography>{switchLabel ? switchLabel : "Archived"}</Typography>
          </Col>
        )}
      </Row>
    </div>
  )
}
