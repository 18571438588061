import { CheckOutlined, EditOutlined } from "@ant-design/icons"
import { Tooltip, Button, Input, message, Form } from "antd"
import { updateUser } from "../../apis/user"
import { RowContent } from "../Utils/RowContent"
import { VizznUser } from "../../types/user"
import { useState } from "react"
import { useAuthContext } from "../../context/AuthContext"

type Props = { user: VizznUser }

type FormValues = { accessLevel: string }

export default function UserFileAccessWidget({ user }: Props) {
  const [editAccessLevel, setEditAccessLevel] = useState(false)
  const { env, token } = useAuthContext()

  const onSubmit = async (value: FormValues) => {
    try {
      if (
        value.accessLevel &&
        parseInt(value.accessLevel) !== user.fileAccessLevel
      ) {
        await updateUser({
          id: user.id,
          fileAccessLevel: parseInt(value.accessLevel),
          env,
          token: token[env],
        })
        message.success("File Access Level Updated")
        user.fileAccessLevel = parseInt(value.accessLevel)
      }
    } catch (error) {
      message.error("Error Updating File Access Level")
      return
    } finally {
      setEditAccessLevel(false)
    }
  }

  return (
    <RowContent
      name="File Access Level"
      content={
        <>
          {!editAccessLevel ? (
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <b>{user.fileAccessLevel}</b>
              <Tooltip title="Change User's File Access Level">
                <Button size="small" onClick={() => setEditAccessLevel(true)}>
                  <EditOutlined />
                </Button>
              </Tooltip>
            </div>
          ) : (
            <Form
              onFinish={onSubmit}
              style={{ display: "flex", gap: 10, alignItems: "center" }}
            >
              <Form.Item<FormValues>
                name="accessLevel"
                initialValue={user.fileAccessLevel}
                style={{ margin: 0, width: "200px" }}
              >
                <Input
                  type="number"
                  max={5}
                  min={1}
                  style={{ width: "200px" }}
                />
              </Form.Item>
              <Button size="small" type="primary" htmlType="submit">
                <CheckOutlined />
              </Button>
            </Form>
          )}
        </>
      }
    />
  )
}
