import { Card, Col, Row, Spin, Typography } from "antd"
import UserLanguagePreference from "../../../components/Users/widgets/UserLanguagePreference/UserLanguagePreference"
import { RowContent } from "../../../components/Utils/RowContent"
import { SysAdminDataFull } from "../../../types/SysAdmin"
import SysAdminPhoneNumberWidget from "./SysAdminPhoneNumberWidget"

type Props = {
  user: SysAdminDataFull | undefined
}

export default function SysAdminOverview({ user }: Props) {
  if (!user) return <Spin />

  return (
    <Card>
      <Row align="middle" justify="space-between" gutter={8}>
        <Col>
          <Typography.Title>{user?.name}</Typography.Title>
        </Col>
      </Row>
      <RowContent name="Email" content={user.email} />

      <SysAdminPhoneNumberWidget id={user.id} phone={user.phone} />
      <UserLanguagePreference
        languagePreference={user.languagePreference}
        id={user.id}
      />
    </Card>
  )
}
