import { useQuery } from "@tanstack/react-query"
import { message } from "antd"
import { environmentType } from "../types/environtment"
import { getEnvUrl } from "../utils/utils"
import axios from "axios"
import { Context } from "vm"
import { dateRange } from "../types/WeeklyMetrics"

const updateGithubMetrics = async (body: {
  env: environmentType
  token: string | null
}) => {
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/github-cve-metrics`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  return data
}

const updateSentryMetrics = async (body: {
  env: environmentType
  token: string | null
  payload: dateRange
}) => {
  const { data } = await axios.post(
    `${getEnvUrl(body.env)}/v1/sentry-error-metrics`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  return data
}

const updateShortcutMetrics = async (body: {
  env: environmentType
  token: string | null
}) => {
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/shortcut-bug-metrics`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  return data
}

const updateGCPMetrics = async (body: {
  env: environmentType
  token: string | null
  payload: dateRange
}) => {
  const { data } = await axios.post(
    `${getEnvUrl(body.env)}/v1/gcp-log-metrics`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  return data
}

export const useUpdateGithubMetrics = ({ env, token }: Context) => {
  return useQuery({
    queryKey: ["updateGithubMetrics", env],
    queryFn: () => updateGithubMetrics({ env, token: token[env] }),
    enabled: false,
    onSuccess: () => {
      message.success("GitHub Dependabot Alert metrics updated successfully!")
    },
    onError: () => {
      message.error("Failed to update GitHub Dependabot Alert metrics")
    },
  })
}

export const useUpdateSentryMetrics = (body: {
  env: environmentType
  token: string | null
  payload: dateRange
}) => {
  return useQuery({
    queryKey: ["updateSentryMetrics", body.payload, body.env],
    queryFn: () => updateSentryMetrics(body),
    enabled: false,
    onSuccess: () => {
      message.success("Sentry Error Metrics updated successfully!")
    },
    onError: () => {
      message.error("Failed to update Sentry Error metrics")
    },
  })
}

export const useUpdateShortuctMetrics = ({ env, token }: Context) => {
  return useQuery({
    queryKey: ["updateShortcutMetrics", env],
    queryFn: () => updateShortcutMetrics({ env, token: token[env] }),
    enabled: false,
    onSuccess: () => {
      message.success("Shortcut Bug metrics updated successfully!")
    },
    onError: () => {
      message.error("Failed to update Shortcut Bug metrics")
    },
  })
}

export const useUpdateGCPMetrics = (body: {
  env: environmentType
  token: string | null
  payload: dateRange
}) => {
  return useQuery({
    queryKey: ["updateGCPMetrics", body.payload, body.env],
    queryFn: () => updateGCPMetrics(body),
    enabled: false,
    onSuccess: () => {
      message.success("GCP Log Error metrics updated successfully!")
    },
    onError: () => {
      message.error("Failed to update GCP Log Error metrics ")
    },
  })
}
