import { Card, Col, Divider, Row } from "antd"
import { TeamMetricsCount } from "../../../types/Team"

export default function TeamTypeMetrics({
  title,
  teamData,
}: {
  title?: string
  teamData: TeamMetricsCount
}) {
  return (
    <Card>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <b style={{ fontSize: "30px", flex: 1 }}>{title}</b>
        <p
          style={{
            fontSize: "80px",
            margin: 0,
            flex: 1,
            textAlign: "center",
          }}
        >
          {teamData.count}
        </p>
      </div>

      <Row gutter={16}>
        {Object.entries(teamData.byType).map(([nameData, countData], index) => (
          <Col span={24} key={index} style={{ marginBottom: "10px" }}>
            <Row justify="space-between">
              <Col>{nameData.charAt(0).toUpperCase() + nameData.slice(1)}</Col>
              <Col>{countData.toString()}</Col>
              <Divider style={{ margin: "7px 0" }}></Divider>
            </Row>
          </Col>
        ))}
      </Row>
    </Card>
  )
}
