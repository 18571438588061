export default function CustomToolTip({
  active,
  payload,
  months,
}: {
  active?: boolean
  payload?: any[]
  months: string[]
}) {
  if (active && payload && payload.length) {
    const month = months[payload[0].payload.index]
    const value = payload[0].value
    return (
      <p
        style={{
          backgroundColor: "white",
          color: "black",
          padding: "7px",
          borderRadius: "5px",
        }}
      >
        {month}: {value}
      </p>
    )
  }
  return null
}
