import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Popconfirm, Row, Skeleton, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useDeleteService, useGetServices } from "../apis/service";
import { NewService } from "../components/Services/NewService";
import { useAuthContext } from "../context/AuthContext";
import { Service } from "../types/service";
import { GetError } from "../widgets/GetError";
import { useNavigate } from "react-router-dom";
import { ServiceErrorCount } from "../components/Services/ServiceErrorCount";

export const Services = () => {
  const { env, token } = useAuthContext();
  const services = useGetServices({ env, token: token[env] });
  const { mutateAsync: deleteService } = useDeleteService();
  const navigate = useNavigate();
  if (services.isLoading) return <Skeleton />;
  if (services.error || !services.data)
    return <GetError reFetch={services.refetch} />;

  const columns: ColumnsType<Service> = [
    {
      title: "Name",
      render: (_, e) => e.name,
      sorter: (a, b) => {
        if (!a.name) return 1;
        if (!b.name) return -1;
        return a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1;
      },
      defaultSortOrder: "ascend",
      key: "Name",
      onCell: (record, rowIndex) => {
        return { onClick: () => navigate(`/services/${record.id}`) };
      },
    },
    {
      title: "Owners",
      ellipsis: true,
      render: (_, e) =>
        e.owners ? (
          <span>
            {e.owners.map((owner) => owner.name).join(", ") || "No owners"}
          </span>
        ) : undefined,
      key: "Owners",
    },
    {
      title: "Description",

      render: (_, e) => <span>{e.description}</span>,
      key: "Description",
    },
    {
      title: "Git Repo",
      render: (_, e) => (
        <a href={e.gitRepo} target="_blank" rel="noreferrer">
          {e.gitRepo}
        </a>
      ),
      key: "GitRepo",
    },
    {
      title: "Runtime Engine",
      render: (_, e) => <span>{e.runTimeEngine}</span>,
      key: "RuntimeEngine",
    },
    {
      title: "Subscriptions",
      render: (_, e) =>
        e.subscriptions ? <span>{e.subscriptions.join(", ")}</span> : undefined,
      key: "subscriptions",
    },
    {
      title: "Topics",
      render: (_, e) =>
        e.topics ? <span>{e.topics.join(", ")}</span> : undefined,
      key: "topics",
    },
    {
      title: "Error alert threshold",
      render: (_, e) =>
        e.errorAlertThreshold ? (
          <Row justify="center">
            <span>{e.errorAlertThreshold}</span>
          </Row>
        ) : undefined,
      key: "topics",
    },
    {
      title: "Actions",
      render: (_, e) => (
        <Row gutter={4}>
          <Col>
            <NewService edit service={e} />
          </Col>
          <Col>
            <Popconfirm
              title="Are you sure about deleting this service?"
              onConfirm={() =>
                deleteService({ env, token: token[env], id: e.id })
              }
            >
              <Button type="primary" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <div>
      <Row style={{ marginBottom: 16 }}>
        <NewService />
      </Row>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={services.data || []}
        pagination={false}
        loading={services.isLoading}
      />
      <Divider />
      <ServiceErrorCount />
    </div>
  );
};
