import { LoginOutlined, ReloadOutlined } from "@ant-design/icons"
import {
    Button,
    Col,
    Input,
    Popconfirm,
    Row,
    Tag,
    Table,
    Typography,
    Tooltip,
} from "antd"
import { ColumnsType } from "antd/lib/table"
import { useCallback, useEffect, useState } from "react"
import {
    debouncedTeamFetch,
    useArchiveTeam,
    useChangeUserTeam,
    useGetTeams,
} from "../apis/teams"
import { NewTeamModal } from "../components/Team/NewTeamModal"
import { CopyContent } from "../components/Utils/CopyContent"
import { useAuthContext } from "../context/AuthContext"
import { Team, TeamType, TeamsResponse } from "../types/Team"
import { useNavigate } from "react-router-dom"
import { useGetUserData } from "../apis/user"
import { format } from "date-fns"

export const TeamSearch = () => {
  const { token, env, user } = useAuthContext()
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [loading, setLoading] = useState(false)
  const [loadingAllTeams, setLoadingAllTeams] = useState(false)
  const { mutateAsync: fetchAllTeams } = useGetTeams(env)
  const { mutateAsync: archiveTeam } = useArchiveTeam()
  const { mutateAsync: changeUserTeam } = useChangeUserTeam()
  const [teams, setTeams] = useState<TeamsResponse>()

  const userTeamId = useGetUserData({
    userId: user[env]?.uid,
    env,
    token: token[env],
  })
  const navigate = useNavigate()

  const columns: ColumnsType<Team> = [
    {
      title: "Name",
      render: (_, e) => e.name,
      sorter: (a, b) => {
        if (!a.name) return 1
        if (!b.name) return -1
        return a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
      },
      defaultSortOrder: "ascend",
      key: "name",
      onCell: (record, rowIndex) => {
        return { onClick: () => navigate(`/teams/${record.id}`) }
      },
    },
    {
      title: "Id",
      render: (_, e) => <CopyContent text={e.id} />,
      sorter: (a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1),
      key: "id",
    },
    {
      title: "Status",
      ellipsis: true,
      render: (_, e) => (
        <Row justify="center">
          <Tag>{e.status}</Tag>
        </Row>
      ),
      sorter: (a, b) => (a.status < b.status ? -1 : 1),
      key: "status",
    },
    {
      title: "Active",
      ellipsis: true,
      render: (_, e) => (
        <Row justify="center">
          <Tag>{e.isActive ? "Yes" : "No"}</Tag>
        </Row>
      ),
      sorter: (a, b) => (a.isActive < b.isActive ? -1 : 1),
      key: "isActive",
    },
    {
      title: "Created At",
      ellipsis: true,
      render: (_, e) => (
        <Row justify="center">
          <span>{format(new Date(e.createdAt), "MMM do, yyyy")}</span>
        </Row>
      ),
      sorter: (a, b) => (a.createdAt < b.createdAt ? -1 : 1),
      key: "createdAt",
    },
    {
      title: "Type",
      align: "center",
      ellipsis: true,
      render: (_, e) => {
        const typeKey = Object.keys(TeamType).find(
          (key) => TeamType[key as keyof typeof TeamType] === e.type
        )
        return typeKey || e.type
      },
      sorter: (a, b) => (a.createdAt < b.createdAt ? -1 : 1),
      key: "usersCount",
    },
    {
      title: "Number of Users",
      align: "center",
      ellipsis: true,
      render: (_, e) => e.userCount,
      sorter: (a, b) => (a.userCount < b.userCount ? -1 : 1),
      key: "usersCount",
    },
    {
      title: "Action",
      ellipsis: true,
      render: (_, e) =>
        e.status !== "archived" && (
          <Row justify="center">
            <div style={{ marginRight: "5px" }}>
              <Tooltip
                title={
                  e.id !== userTeamId.data?.teamId
                    ? "Switch to team account"
                    : `Currently on ${e.name} team`
                }
              >
                <Button
                  type="primary"
                  icon={<LoginOutlined />}
                  onClick={() => {
                    changeUserTeam({
                      env,
                      token: token[env],
                      teamId: e.id,
                      userId: user[env]?.uid,
                      teamName: e.name,
                    })
                  }}
                  disabled={e.id === userTeamId.data?.teamId}
                ></Button>
              </Tooltip>
            </div>
            <Popconfirm
              title="Are you sure about archiving this team?"
              onConfirm={async () => {
                const res = await archiveTeam({
                  env,
                  token: token[env],
                  teamId: e.id,
                })

                if (res) {
                  const mappedTeams = teams?.records.map((team) => {
                    if (team.id === e.id) team.status = "archived"
                    return team
                  })
                  setTeams((prev) => {
                    return {
                      records: mappedTeams!,
                      totalRecords: prev!.totalRecords,
                      searchTerm: prev?.searchTerm,
                    }
                  })
                }
              }}
            >
              <Button type="primary" danger disabled={e.status === "archived"}>
                Archive
              </Button>
            </Popconfirm>
          </Row>
        ),
      key: "isActive",
    },
  ]
  const fetchTeams = useCallback(async () => {
    if (!searchTerm || searchTerm.length < 3) return
    setLoading(true)
    const data = await debouncedTeamFetch({
      env,
      token: token[env],
      searchTerm,
    })
    if (data) setTeams(data)
    setLoading(false)
  }, [env, searchTerm, token])

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchTeams()
    }, 400)
    return () => {
      clearTimeout(handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  const handleLoadAllTeams = useCallback(async () => {
    setLoading(true)
    const data = (await fetchAllTeams({ token: token[env], env })) as Team[]
    setLoadingAllTeams(false)
    setLoading(false)
    setTeams({
      records: data,
      totalRecords: data.length,
    })
  }, [env, fetchAllTeams, token])

  useEffect(() => setTeams(undefined), [env])

  useEffect(() => {
    if (loadingAllTeams) {
      handleLoadAllTeams()
    }
  }, [handleLoadAllTeams, loadingAllTeams])

  return (
    <div>
      <Row gutter={8} style={{ marginBottom: 16 }}>
        <Col flex="auto">
          <Input
            placeholder="input search text"
            onChange={(e) => {
              setSearchTerm(e.target.value)
            }}
            style={{ width: "100%" }}
          />
        </Col>
        <Col>
          <Button
            onClick={() => setLoadingAllTeams(true)}
            icon={<ReloadOutlined />}
            type="primary"
          >
            Load Teams
          </Button>
        </Col>
        <Col>
          <NewTeamModal />
        </Col>
      </Row>
      {teams && (
        <Typography.Title
          level={4}
        >{`${teams.totalRecords} results found`}</Typography.Title>
      )}
      <Row>
        <Table
          style={{ cursor: "pointer" }}
          columns={columns}
          rowKey="id"
          dataSource={teams ? teams.records : []}
          pagination={false}
          loading={loading}
        />
      </Row>
    </div>
  )
}
