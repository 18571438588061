import { Button, Col, Input, Row, Skeleton, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import { format } from "date-fns"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  useAccountApplicationAction,
  useGetAccountApplications,
} from "../apis/onboarding"
import ApplicationRejectModal from "../components/AccountApplication/ApplicationRejectModal"
import ErrorRefetch from "../components/ErrorRefetch/ErrorRefetch"
import { useAuthContext } from "../context/AuthContext"
import { AccountApplicationMainListView } from "../types/AccountApplication"
import convertPhoneNumberToDisplayFormat from "../utils/convertPhoneNumberToDisplayFormat"

export default function TeamApplications() {
  const navigate = useNavigate()
  const { token, env } = useAuthContext()
  const [searchText, setSearchText] = useState("")
  const applications = useGetAccountApplications(env, token[env])
  const { mutateAsync: accountApplicationAction } =
    useAccountApplicationAction()
  const [rejectModalIsOpen, setRejectModalIsOpen] = useState<boolean>(false)
  const [currentId, setCurrentId] = useState<string>("")

  useEffect(() => {
    applications.refetch()
  }, [applications, env])

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleRejectApplication = async (note: string, id: string) => {
    if (!id) return

    await accountApplicationAction({
      config: {
        env: env,
        token: token[env],
      },
      id: id,
      action: "reject",
      rejectionNote: note,
    })
    return
  }

  const handleRejectButtonClick = (id: string) => {
    setCurrentId(id)
    setRejectModalIsOpen(true)
  }

  const columns: ColumnsType<AccountApplicationMainListView> = [
    {
      title: "Organization Name",
      render: (_, row) => <Row justify="center">{row.orgName}</Row>,
      sorter: (a, b) => {
        if (!a.orgName) return 1
        if (!b.orgName) return -1
        return a.orgName.toLocaleLowerCase() < b.orgName.toLocaleLowerCase()
          ? -1
          : 1
      },
      defaultSortOrder: "ascend",
      key: "orgName",
    },
    {
      title: "Requester Name",
      render: (_, row) => (
        <Row justify="center">
          <span>{row.requesterName}</span>
        </Row>
      ),
      sorter: (a, b) => {
        if (!a.requesterName) return 1
        if (!b.requesterName) return -1
        return a.requesterName.toLocaleLowerCase() <
          b.requesterName.toLocaleLowerCase()
          ? -1
          : 1
      },
      defaultSortOrder: "ascend",
      key: "requesterName",
    },
    {
      title: "Requester Email",
      render: (_, row) => (
        <Row justify="center">
          <span>{`${row.requesterEmail}`}</span>
        </Row>
      ),
      sorter: (a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1),
      key: "email",
    },
    {
      title: "Requester Phone Number",
      render: (_, row) => (
        <Row justify="center">
          <span>{`${convertPhoneNumberToDisplayFormat(
            row.requesterPhone
          )}`}</span>
        </Row>
      ),
      sorter: (a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1),
      key: "phoneNumber",
    },
    {
      title: "Requested At",
      render: (_, row) => (
        <Row justify="center">
          <span>{format(new Date(row.requestedAt), "MMM-dd-yyyy")}</span>
        </Row>
      ),
      sorter: (a, b) =>
        a.requestedAt.toLocaleString() < b.requestedAt.toLocaleString()
          ? -1
          : 1,
      key: "requestedAt",
    },
    {
      title: "Last Updated At",
      render: (_, row) => (
        <Row justify="center">
          <span>{format(new Date(row.lastUpdatedAt), "MMM-dd-yyyy")}</span>
        </Row>
      ),
      sorter: (a, b) =>
        a.lastUpdatedAt.toLocaleString() < b.lastUpdatedAt.toLocaleString()
          ? -1
          : 1,
      key: "lastUpdatedAt",
    },
    {
      title: "Status",
      render: (_, row) => (
        <Row justify="center">
          <Tag color={`#${row.status.bgColor}`}>
            <span style={{ color: `#${row.status.textColor}` }}>
              {row.status.name}
            </span>
          </Tag>
        </Row>
      ),
      sorter: (a, b) => (a.status < b.status ? -1 : 1),
      key: "status",
    },
    {
      title: "Action",
      ellipsis: true,
      render: (_, row) => (
        <Row onClick={(e) => e.stopPropagation()} justify="center">
          <Button
            onClick={() => handleRejectButtonClick(row.id)}
            type="primary"
            danger
          >
            Reject
          </Button>
        </Row>
      ),
      key: "action",
    },
  ]

  if (applications.isLoading) return <Skeleton active />
  if (!applications.data || applications.isError)
    return <ErrorRefetch refetch={applications.refetch} />

  return (
    <Col span={24}>
      <Row style={{ width: "100%", paddingBottom: 20 }} justify="space-between">
        <Col span={22}>
          <Input.Search
            placeholder="Search table data"
            onSearch={handleSearch}
            enterButton
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>
        <Col span={2}>
          <Row justify="end">
            <Button onClick={() => handleSearch("")}>Clear</Button>
          </Row>
        </Col>
      </Row>
      <Row>
        <Table
          columns={columns}
          rowKey="id"
          dataSource={applications.data?.records.filter(
            (record) =>
              record.orgName.toLocaleLowerCase().includes(searchText) ||
              record.requesterEmail.toLocaleLowerCase().includes(searchText) ||
              record.requesterName.toLocaleLowerCase().includes(searchText)
          )}
          pagination={false}
          onRow={(record) => ({
            onClick: () => navigate(`/applications/${record.id}`),
          })}
        />
      </Row>
      <ApplicationRejectModal
        isOpen={rejectModalIsOpen}
        setIsOpen={setRejectModalIsOpen}
        id={currentId}
        handleRejectApplication={handleRejectApplication}
      />
    </Col>
  )
}
