import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import {
  TranslationCreateRequest,
  TranslationKeyObject,
  TranslationLanguageList,
  TranslationResponseDto,
} from "../types/Translations"
import { environmentType } from "../types/environtment"
import { getEnvUrl } from "../utils/utils"
import { VizznResponse } from "../types/Api"

const getTranslations = async (body: {
  env: environmentType
  token: string | null
  currentBoard: string
}) => {
  const { data } = await axios.get<VizznResponse<TranslationResponseDto>>(
    `${getEnvUrl(body.env)}/v1/translations/${body.currentBoard}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}
export const useGetTranslations = (body: {
  env: environmentType
  token: string | null
  currentBoard: string
}) => {
  const response = useQuery<TranslationResponseDto>(
    ["translations", body.env, body.currentBoard],
    () =>
      getTranslations({
        env: body.env,
        token: body.token,
        currentBoard: body.currentBoard,
      }),
    {
      refetchOnWindowFocus: false,
    }
  )
  return response
}

export const getTranslationsJsonFile = async (body: {
  env: environmentType
  currentBoard: string
  language: string
}) => {
  const { data } = await axios.get<string>(
    `${getEnvUrl(body.env)}/v1/translations/${body.currentBoard}/${
      body.language
    }`
  )
  return data
}

export const getTextTranslation = async (body: {
  env: environmentType
  token: string | null
  payload: { source: string; target: string; text: string }
}) => {
  if (body.token) {
    const { data } = await axios.post<
      VizznResponse<{
        source: string
        target: string
        text: string
        translation: string
      }>
    >(`${getEnvUrl(body.env)}/v1/translate-text`, body.payload, {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    })
    if (data) return data.data
  }
}

const createTranslation = async (body: {
  env: environmentType
  token: string | null
  currentBoard: string
  payload: TranslationCreateRequest
}) => {
  if (body.token) {
    const { data } = await axios.post<VizznResponse<any>>(
      `${getEnvUrl(body.env)}/v1/translation-key/${body.currentBoard}/${
        body.payload.key
      }`,
      body.payload,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    if (data) return data.data
  }
}
export const useCreateTranslation = () => {
  const queryClient = useQueryClient()
  return useMutation(createTranslation, {
    onSuccess: (data, variables) => {
      if (!data) return
      const correctData: TranslationKeyObject = data[variables.payload.lang]
        ?.updatedAt
        ? data
        : data[variables.payload.key]
      try {
        correctData[
          variables.payload.lang as TranslationLanguageList
        ].updatedAt = new Date()
      } catch (e) {}

      queryClient.setQueryData<TranslationResponseDto>(
        ["translations", variables.env, variables.currentBoard],
        (oldData) => {
          if (!oldData) return
          oldData.json[variables.payload.key] = {
            ...(oldData.json[variables.payload.key] || {}),
            ...correctData,
          }
          return oldData
        }
      )
    },
  })
}

const approveTranslation = async (body: {
  env: environmentType
  token: string | null
  currentBoard: string
  key: string
  lang: string
}) => {
  if (body.token) {
    const { data } = await axios.post(
      `${getEnvUrl(body.env)}/v1/translation-key/${body.currentBoard}/${
        body.key
      }/approve`,
      { lang: body.lang },
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    if (data) return data.data
  }
}
export const useApproveTranslation = () => {
  const queryClient = useQueryClient()
  return useMutation(approveTranslation, {
    onSuccess: (data, variables) => {
      if (!data) return
      data[variables.lang].approvedAt = new Date().toISOString()
      queryClient.setQueryData<TranslationResponseDto>(
        ["translations", variables.env, variables.currentBoard],
        (oldData) => {
          if (!oldData) return
          oldData.json[variables.key] = data
          return oldData
        }
      )
    },
  })
}

const deleteTranslationKey = async (body: {
  env: environmentType
  token: string | null
  currentBoard: string
  key: string
}) => {
  if (body.token) {
    const { data } = await axios.delete(
      `${getEnvUrl(body.env)}/v1/translation-key/${body.currentBoard}/${
        body.key
      }`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    if (data) return data.data
  }
}
export const useDeleteTranslationKey = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteTranslationKey, {
    onSuccess: (data, variables) => {
      const oldData = queryClient.getQueryData<TranslationResponseDto>([
        "translations",
        variables.env,
        variables.currentBoard,
      ])

      if (oldData) {
        delete oldData.json[variables.key]
        queryClient.setQueryData(
          ["translations", variables.env, variables.currentBoard],
          () => ({ ...oldData })
        )
      }
    },
  })
}
