import { CopyOutlined } from "@ant-design/icons"
import { Button, message } from "antd"

type Props = {
  text: string
  label?: string
}

export default function CopyTextButton({ text, label }: Props) {
  return (
    <Button
      style={{ marginRight: "5px" }}
      size="small"
      icon={<CopyOutlined />}
      onClick={(e) => {
        e.stopPropagation()
        navigator.clipboard.writeText(text)
        message.success("Text copied to clipboard")
      }}
    >
      {label}
    </Button>
  )
}
