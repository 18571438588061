import { Card, Col, Row, Tag, Typography } from "antd"
import { useAuthContext } from "../../../../context/AuthContext"
import { useGetDispatcherGroupUser } from "../../../../apis/Dispatcher"

type Props = {
  id: string
}

export default function UserDispatchGroupWidget({ id }: Props) {
  const { env, token } = useAuthContext()
  const user = useGetDispatcherGroupUser({
    env,
    token: token[env],
    id,
  })

  return (
    <Card title="Dispatch Groups">
      <Row gutter={8}>
        <Col>
          <Row gutter={8}>
            {!user.data?.groups.length && (
              <Typography.Text type="secondary">
                User is not in any dispatch group
              </Typography.Text>
            )}
            {user.data?.groups.map((group) => (
              <Col key={group.id}>
                <Tag>{group.name}</Tag>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
