import { Col, Form, Row, Select, Tag, Tooltip } from "antd"
import { useState } from "react"
import { useGetUsersV2 } from "../../../apis/user"
import { AdminIcon } from "../../../assets/AdminIcon"
import { useAuthContext } from "../../../context/AuthContext"
import { useDebounce } from "../../../hooks/useDebounce"
import { environmentType } from "../../../types/environtment"

type Props = {
  env: environmentType
  onChange?: (id: string) => void
}

export const SearchUserDropdown = ({ env }: Props) => {
  const { token } = useAuthContext()
  const [searchTerm, setSearchTerm] = useState<string>("")
  const debouncedSearch = useDebounce(searchTerm, 400)
  const users = useGetUsersV2(
    {
      env,
      token: token[env],
    },
    debouncedSearch
  )
  return (
    <Form.Item label="Users" name="users">
      <Select
        mode="multiple"
        placeholder="Type to search for an user name"
        loading={users.isLoading}
        onSearch={(value) => setSearchTerm(value)}
        showSearch
        allowClear
        filterOption={(input, option) =>
          option?.searchValue?.toLowerCase()?.includes(input.toLowerCase())
        }
      >
        {users.data?.records
          .sort((a, b) =>
            a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1
          )
          .map(({ id, name, team, roleNames }) => (
            <Select.Option
              key={`${id}-${team.id}`}
              value={id}
              searchValue={name}
            >
              <Row align="middle" gutter={8}>
                <Col>
                  <span>{name}</span>
                </Col>
                <Col>
                  <Tag>{team.name}</Tag>
                </Col>
                <Col>
                  <Tag>{id}</Tag>
                </Col>
                {roleNames.includes("sysadmin") && (
                  <Col>
                    <Tooltip title="System Admin">
                      <AdminIcon />
                    </Tooltip>
                  </Col>
                )}
              </Row>
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  )
}
