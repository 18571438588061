import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { VizznResponse } from "../../types/Api"
import { Enterprise } from "../../types/Enterprise"
import { environmentType } from "../../types/environtment"
import { getEnvUrl } from "../../utils/utils"

const getEnterprises = async (body: {
  env: environmentType
  token: string | null
}) => {
  if (!body.token) return
  const { data } = await axios.get<VizznResponse<Enterprise[]>>(
    `${getEnvUrl(body.env)}/v1/enterprises`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  return data.data
}

export const useGetEnterprises = (body: {
  env: environmentType
  token: string | null
}) => {
  return useQuery({
    queryKey: ["enterprises"],
    queryFn: () => getEnterprises(body),
  })
}
