import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  List,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Space,
  Tag,
  Typography,
} from "antd"
import { type DefaultOptionType } from "antd/es/select"
import { useEffect, useState } from "react"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { useParams } from "react-router-dom"
import {
  useAccountApplicationAction,
  useGetAccountApplication,
  useUpdateAccountApplication,
} from "../apis/onboarding"
import ApplicationRejectModal from "../components/AccountApplication/ApplicationRejectModal"
import ErrorRefetch from "../components/ErrorRefetch/ErrorRefetch"
import {
  DIMENSION_UNIT_OPTIONS,
  DISTANCE_UNIT_OPTIONS,
  LANGUAGE_OPTIONS,
  ORGANIZTION_TYPE_OPTIONS,
  SPEED_UNIT_OPTIONS,
  VOLUME_UNIT_OPTIONS,
  WEIGHT_UNIT_OPTIONS,
} from "../constants/AccountApplication/UnitPreferences"
import { jobRoleOptions } from "../constants/AccountApplication/UserRoleTag"
import { useAuthContext } from "../context/AuthContext"
import { AccountApplicationUpdateArgsWithConfig } from "../types/AccountApplication"
import convertPhoneNumberToDisplayFormat from "../utils/convertPhoneNumberToDisplayFormat"
import convertStringtToKebabCase from "../utils/convertStringToKebabCase"
import convertStringToTitleCase from "../utils/convertStringToTitleCase"
import GoogleMapsLink from "../components/TargetBlankLink/GoogleMapsLink"
import {
  LanguageCode,
  languageFlagMap,
  VizznSupportedLanguages,
} from "../types/Language"
import {
  Dimension,
  DistanceUnits,
  Speed,
  unitsMap,
  VolumeUnits,
  Weight,
} from "../types/Team"

interface TeamApplicationSubmitProps {
  jobRoles: string[]
  orgEmail: string
  orgName: string
  orgOwnerFirstName: string
  orgOwnerLastName: string
  orgPhone: string
  orgType: string
  organizationAddress: string
  prefDimensionUnit: Dimension
  prefSpeedUnit: Speed
  prefWeightUnit: Weight
  prefDistanceUnit: DistanceUnits
  prefVolumeUnit: VolumeUnits
  prefLanguage: LanguageCode
}

export default function TeamApplicationsDetail() {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["drawing", "places", "geometry"],
  })
  const { id } = useParams<{ id: string }>()
  const { token, env } = useAuthContext()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false)
  const [form] = Form.useForm()

  const [errorsList, setErrorsList] = useState<string[]>([])
  const [locationLatLng, setLocationLatLng] = useState<{
    lat: number
    lng: number
  } | null>(null)
  const { mutateAsync: updateAccountApplication } =
    useUpdateAccountApplication()
  const accountApplication = useGetAccountApplication(env, token[env], id)

  const { mutateAsync: accountApplicationAction } =
    useAccountApplicationAction()

  useEffect(() => {
    setIsEditing(false)
  }, [])

  const handleSave = () => {
    form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    setIsEditing(false)
  }

  const onFinish = async (values: TeamApplicationSubmitProps) => {
    if (!values) return

    const accountApplicationUpdateArgs: AccountApplicationUpdateArgsWithConfig =
      {
        config: {
          env: env,
          token: token[env],
        },
        id: id,
        organization: {
          address: values.organizationAddress || "",
          coords: {
            lat:
              locationLatLng?.lat ||
              accountApplication.data?.organization.coords.lat,
            lng:
              locationLatLng?.lng ||
              accountApplication.data?.organization.coords.lng,
          },
          name: values.orgName,
          tag: convertStringtToKebabCase(values.orgName) || "",
          timezone: accountApplication.data?.organization.timezone || "",
          type: values.orgType || accountApplication.data?.organization.type,
          users: accountApplication.data?.organization.users || [],
          prefDimensionUnit: values.prefDimensionUnit,
          prefSpeedUnit: values.prefSpeedUnit,
          prefWeightUnit: values.prefWeightUnit,
          prefDistanceUnit: values.prefDistanceUnit,
          prefVolumeUnit: values.prefVolumeUnit,
          prefLanguage: values.prefLanguage,
        },
        owner: {
          firstName: values.orgOwnerFirstName,
          lastName: values.orgOwnerLastName,
          email: values.orgEmail,
          phone: values.orgPhone,
          accessRoles: accountApplication.data?.owner.accessRoles || [],
          jobRoles: values.jobRoles || accountApplication.data?.owner.jobRoles,
        },
      }

    const response = await updateAccountApplication(
      accountApplicationUpdateArgs
    )

    if (response.errors.length > 0) {
      setErrorsList(response.errors || [])
      return
    }
    setErrorsList([])
    setIsEditing(false)
    return
  }

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleApproveApplication = async () => {
    if (!id) return

    await accountApplicationAction({
      config: {
        env: env,
        token: token[env],
      },
      id: id,
      action: "approve",
    })
    return
  }

  const handleRejectApplication = async (rejectionNote: string) => {
    if (!id) return

    await accountApplicationAction({
      config: {
        env: env,
        token: token[env],
      },
      id: id,
      action: "reject",
      rejectionNote: rejectionNote,
    })
    return
  }

  const handleRejectModalClick = () => {
    setIsRejectModalOpen(true)
  }

  if (accountApplication.isLoading || accountApplication.isRefetching)
    return <Skeleton active />

  if (accountApplication.isError)
    return <ErrorRefetch refetch={accountApplication.refetch} />

  return (
    <Form style={{ paddingBottom: 20 }} onFinish={onFinish} form={form}>
      <Row gutter={16}>
        <Col span={errorsList.length > 0 ? 18 : 24}>
          <Row gutter={16} justify="end" style={{ width: "100%" }}>
            {isEditing ? (
              <Row gutter={16}>
                <Space>
                  <Button size="large" type="primary" onClick={handleSave}>
                    Save
                  </Button>
                  <Button
                    size="large"
                    danger
                    type="primary"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Space>
              </Row>
            ) : (
              <Button size="large" type="default" onClick={handleEdit}>
                Edit
              </Button>
            )}
          </Row>
          <Row gutter={[16, 24]}>
            <Col span={24}>
              {/* Organization */}
              <Divider orientation="left">
                <Typography.Title level={1}>
                  {accountApplication.data?.organization.name}
                </Typography.Title>
              </Divider>
              <Descriptions bordered size="middle">
                <Descriptions.Item label="ID" span={3}>
                  {id}
                </Descriptions.Item>

                <Descriptions.Item label="Status" span={1}>
                  <Col>
                    <Tag color={`#${accountApplication.data?.status.bgColor}`}>
                      <span
                        style={{
                          color: `#${accountApplication.data?.status.textColor}`,
                        }}
                      >
                        {accountApplication.data?.status.name}
                      </span>
                    </Tag>
                  </Col>
                </Descriptions.Item>
                <Descriptions.Item label="Actions" span={2}>
                  <Col>
                    <Space>
                      <Popconfirm
                        title="Are you sure you want to approve this application?"
                        onConfirm={handleApproveApplication}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="primary">Approve</Button>
                      </Popconfirm>
                      <Button
                        onClick={handleRejectModalClick}
                        type="primary"
                        danger
                      >
                        Reject
                      </Button>
                    </Space>
                  </Col>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            {/* Organization */}
            <Col span={24}>
              <Divider orientation="left">
                <Typography.Title level={4}>Organization info</Typography.Title>
              </Divider>
              <Descriptions bordered size="middle" column={3}>
                <Descriptions.Item label="Name" span={1}>
                  {isEditing ? (
                    <Form.Item
                      initialValue={accountApplication.data?.organization.name}
                      name="orgName"
                    >
                      <Input />
                    </Form.Item>
                  ) : (
                    accountApplication.data?.organization.name
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Tag" span={1}>
                  {accountApplication.data?.organization.tag}
                </Descriptions.Item>
                <Descriptions.Item label="Type" span={1}>
                  {isEditing ? (
                    <Form.Item
                      initialValue={accountApplication.data?.organization.type}
                      name="orgType"
                    >
                      <Select
                        allowClear
                        placeholder="Please select an organization type"
                        options={ORGANIZTION_TYPE_OPTIONS}
                      />
                    </Form.Item>
                  ) : (
                    accountApplication.data?.organization.type
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Address" span={2}>
                  {isEditing ? (
                    <Form.Item
                      initialValue={
                        accountApplication.data?.organization.address
                      }
                      name="organizationAddress"
                    >
                      <Select
                        placeholder="Search Address..."
                        defaultActiveFirstOption
                        onSearch={(text) => {
                          getPlacePredictions({
                            input: text,
                            types: [],
                            language: "en",
                          })
                        }}
                        filterOption={(
                          text: string,
                          option: DefaultOptionType | undefined
                        ) => {
                          if (!option?.name) return false

                          return (option?.name as string)
                            .toLowerCase()
                            .includes(text.toLowerCase())
                        }}
                        showSearch
                        loading={isPlacePredictionsLoading}
                        showArrow={false}
                        onInputKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault()
                          }
                        }}
                        notFoundContent={null}
                        onSelect={async (_: any, option: any) => {
                          await placesService?.getDetails(
                            {
                              placeId: option.value,
                            },
                            async (placeDetails: any) => {
                              const lat = placeDetails?.geometry?.location.lat()
                              const lng = placeDetails?.geometry?.location.lng()

                              const placeLatLong = {
                                lat: lat,
                                lng: lng,
                              }

                              setLocationLatLng(placeLatLong)

                              return placeLatLong
                            }
                          )

                          return form.setFieldsValue({
                            organizationAddress: option.name,
                          })
                        }}
                      >
                        {placePredictions.map((location) => {
                          return (
                            <Select.Option
                              key={location.place_id}
                              value={location.place_id}
                              name={location.description}
                            >
                              {location.description}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  ) : (
                    <GoogleMapsLink
                      address={accountApplication.data?.organization.address}
                      label={accountApplication.data?.organization.address}
                    />
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Coords" span={1}>
                  <GoogleMapsLink
                    address={`${accountApplication.data?.organization.coords.lat},${accountApplication.data?.organization.coords.lng}`}
                    label={`${accountApplication.data?.organization.coords.lat}, ${accountApplication.data?.organization.coords.lng}`}
                  />
                </Descriptions.Item>

                <Descriptions.Item label="Preferred Dimensions Unit" span={1}>
                  {isEditing ? (
                    <Form.Item
                      initialValue={
                        accountApplication.data?.organization.prefDimensionUnit
                      }
                      name="prefDimensionUnit"
                    >
                      <Select
                        placeholder="Please a preferred dimension unit"
                        options={DIMENSION_UNIT_OPTIONS}
                      />
                    </Form.Item>
                  ) : (
                    <Tag>
                      {unitsMap[
                        accountApplication.data?.organization.prefDimensionUnit
                      ] ??
                        accountApplication.data?.organization.prefDimensionUnit}
                    </Tag>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="Preferred Speed Unit" span={1}>
                  {isEditing ? (
                    <Form.Item
                      initialValue={
                        accountApplication.data?.organization.prefSpeedUnit
                      }
                      name="prefSpeedUnit"
                    >
                      <Select
                        placeholder="Please a preferred speed unit"
                        options={SPEED_UNIT_OPTIONS}
                      />
                    </Form.Item>
                  ) : (
                    <Tag>
                      {unitsMap[
                        accountApplication.data?.organization.prefSpeedUnit
                      ] ?? accountApplication.data?.organization.prefSpeedUnit}
                    </Tag>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="Preferred Weight Unit" span={1}>
                  {isEditing ? (
                    <Form.Item
                      initialValue={
                        accountApplication.data?.organization.prefWeightUnit
                      }
                      name="prefWeightUnit"
                    >
                      <Select
                        placeholder="Please a preferred Weight unit"
                        options={WEIGHT_UNIT_OPTIONS}
                      />
                    </Form.Item>
                  ) : (
                    <Tag>
                      {unitsMap[
                        accountApplication.data?.organization.prefWeightUnit
                      ] ?? accountApplication.data?.organization.prefWeightUnit}
                    </Tag>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="Preferred Distance Unit" span={1}>
                  {isEditing ? (
                    <Form.Item
                      initialValue={
                        accountApplication.data?.organization.prefDistanceUnit
                      }
                      name="prefDistanceUnit"
                    >
                      <Select
                        placeholder="Please a preferred Distance unit"
                        options={DISTANCE_UNIT_OPTIONS}
                      />
                    </Form.Item>
                  ) : (
                    <Tag>
                      {unitsMap[
                        accountApplication.data?.organization.prefDistanceUnit
                      ] ??
                        accountApplication.data?.organization.prefDistanceUnit}
                    </Tag>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="Preferred Volume Unit" span={1}>
                  {isEditing ? (
                    <Form.Item
                      initialValue={
                        accountApplication.data?.organization.prefVolumeUnit
                      }
                      name="prefVolumeUnit"
                    >
                      <Select
                        placeholder="Please a preferred Weight unit"
                        options={VOLUME_UNIT_OPTIONS}
                      />
                    </Form.Item>
                  ) : (
                    <Tag>
                      {unitsMap[
                        accountApplication.data?.organization.prefVolumeUnit
                      ] ?? accountApplication.data?.organization.prefVolumeUnit}
                    </Tag>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="Preferred Language" span={1}>
                  {isEditing ? (
                    <Form.Item
                      initialValue={
                        accountApplication.data?.organization.prefLanguage
                      }
                      name="prefLanguage"
                    >
                      <Select
                        placeholder="Please a preferred Language"
                        options={LANGUAGE_OPTIONS}
                      />
                    </Form.Item>
                  ) : (
                    <Tag>
                      {
                        languageFlagMap[
                          accountApplication.data?.organization.prefLanguage
                        ]
                      }{" "}
                      {
                        VizznSupportedLanguages[
                          accountApplication.data?.organization.prefLanguage
                        ]
                      }
                    </Tag>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            {/* Organization Owner*/}
            <Col span={24}>
              <Divider orientation="left">
                <Typography.Title level={4}>Owner info</Typography.Title>
              </Divider>
              <Descriptions bordered size="middle" column={3}>
                <Descriptions.Item label="Owner First Name" span={1}>
                  {isEditing ? (
                    <Form.Item
                      initialValue={accountApplication.data?.owner.firstName}
                      name="orgOwnerFirstName"
                    >
                      <Input />
                    </Form.Item>
                  ) : (
                    accountApplication.data?.owner.firstName
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Owner Last Name" span={1}>
                  {isEditing ? (
                    <Form.Item
                      initialValue={accountApplication.data?.owner.lastName}
                      name="orgOwnerLastName"
                    >
                      <Input />
                    </Form.Item>
                  ) : (
                    accountApplication.data?.owner.lastName
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Owner Email" span={1}>
                  {isEditing ? (
                    <Form.Item
                      initialValue={accountApplication.data?.owner.email}
                      name="orgEmail"
                    >
                      <Input />
                    </Form.Item>
                  ) : (
                    accountApplication.data?.owner.email
                  )}
                </Descriptions.Item>
                {accountApplication.data.owner?.phone && (
                  <Descriptions.Item label="Owner Phone" span={1}>
                    {isEditing ? (
                      <Form.Item
                        initialValue={accountApplication.data?.owner.phone}
                        name="orgPhone"
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      convertPhoneNumberToDisplayFormat(
                        accountApplication?.data?.owner.phone
                      )
                    )}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Owner Access Roles" span={3}>
                  {accountApplication.data?.owner.accessRoles?.map((role) => (
                    <Tag key={role + Math.random()}>
                      {convertStringToTitleCase(role)}
                    </Tag>
                  ))}
                </Descriptions.Item>
                <Descriptions.Item label="Owner Job Roles" span={3}>
                  {isEditing ? (
                    <Form.Item
                      name="jobRoles"
                      initialValue={accountApplication.data?.owner.jobRoles}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select job roles"
                        options={jobRoleOptions}
                      />
                    </Form.Item>
                  ) : (
                    accountApplication.data?.owner.jobRoles?.map((role) => (
                      <Tag key={role + Math.random()}>
                        {convertStringToTitleCase(role)}
                      </Tag>
                    ))
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Col>

        {errorsList.length > 0 && (
          <Col span={6}>
            <Card>
              <Divider orientation="left">Errors to Fix</Divider>
              <List
                size="small"
                bordered
                dataSource={errorsList}
                renderItem={(item: string) => <List.Item>{item}</List.Item>}
              />
            </Card>
          </Col>
        )}
      </Row>
      {id && (
        <ApplicationRejectModal
          isOpen={isRejectModalOpen}
          setIsOpen={setIsRejectModalOpen}
          id={id}
          handleRejectApplication={handleRejectApplication}
        />
      )}
    </Form>
  )
}
