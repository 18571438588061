import {
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons"
import { EntityNotificationTaxonomy } from "../../types/notifications"
import { Bucket } from "./Bucket"

const Env = ({ env, isPublished }: { env: string; isPublished?: boolean }) => {
  return (
    <span
      style={{
        border: "1px solid #fff",
        padding: "0 5px",
        marginRight: 4,
        background: isPublished ? "#fff" : "transparent",
        color: isPublished ? "#000" : "#fff",
      }}
    >
      {env}
    </span>
  )
}

export const TaxonomyItem = ({
  item,
  sortUp,
  sortDown,
  onDelete,
  onEdit,
}: {
  item: {
    dev: Partial<EntityNotificationTaxonomy>
    prod?: Partial<EntityNotificationTaxonomy>
  }
  sortUp: null | (() => void)
  sortDown: null | (() => void)
  onDelete: () => void
  onEdit: () => void
}) => {
  return (
    <div style={{ display: "flex", borderBottom: "1px solid #ddd" }}>
      <div style={{ flexGrow: 1 }}>
        <label
          style={{
            fontWeight: "bold",
            fontSize: 16,
            color: "#19b1b4",
            display: "block",
          }}
        >
          {sortUp ? <CaretUpOutlined onClick={sortUp} /> : <></>}{" "}
          {sortDown ? <CaretDownOutlined onClick={sortDown} /> : <></>}{" "}
          <EditOutlined onClick={onEdit} /> {item.dev.name}
        </label>
        {item.dev.subscriptionItem ? (
          <div>
            Quickview: {item.dev.quickview ? "Yes" : "No"}
            <br />
            Subscription Item: {item.dev.subscriptionItem.join(", ")}
          </div>
        ) : (
          <></>
        )}
        <div style={{ margin: 6 }}>
          <Env env="dev" isPublished={item.dev.isPublished} />
          <Env env="prod" isPublished={item.prod?.isPublished} />
          &nbsp;
          <DeleteOutlined onClick={onDelete} />
        </div>
      </div>
      {item.dev.buckets?.map((bucket, bIndex) => (
        <Bucket
          key={bucket.id}
          bucket={{
            dev: bucket,
            prod: item.prod?.buckets ? item.prod.buckets[bIndex] : undefined,
          }}
        />
      ))}
    </div>
  )
}
