import { CopyOutlined } from "@ant-design/icons";
import { Button, Tooltip, message } from "antd";
import { ReactNode } from "react";

type Props = {
  text: string;
  left?: boolean;
  icon?: ReactNode;
  hideText?: boolean;
  tooltip?: string;
};
export const CopyContent = ({ text, left, icon, hideText, tooltip }: Props) => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    message.success("Text copied to clipboard");
  };

  return (
    <Tooltip title={tooltip}>
      {!left && !hideText && text}
      <Button
        style={{ marginRight: 4, marginLeft: 4 }}
        size="small"
        icon={icon ? icon : <CopyOutlined />}
        onClick={() => copyToClipboard(text)}
      />
      {left && !hideText && text}
    </Tooltip>
  );
};
