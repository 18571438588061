import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import axios from "axios";
import { environmentType } from "../types/environtment";
import { Service, ServiceRequest } from "../types/service";
import { getEnvUrl } from "../utils/utils";
import { VizznResponse } from "../types/AccountApplication";
import { TeamErrorCount } from "../types/UsageMetrics";

const getOwners = async (env: environmentType, token: string | null) => {
  if (!token) return;
  const { data } = await axios.get(`${getEnvUrl(env)}/v1/users/sysadmins`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (data) return data.data;
};

export const useGetOwners = (body: {
  env: environmentType;
  token: string | null;
}) => {
  const response = useQuery<{ id: string; name: string }[]>(
    ["owners", body.env],
    () => getOwners(body.env, body.token)
  );
  return response;
};

const getServices = async (env: environmentType, token: string | null) => {
  if (!token) return;
  const { data } = await axios.get(`${getEnvUrl(env)}/v1/service-catalog`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (data) return data.data || [];
};

export const useGetServices = (body: {
  env: environmentType;
  token: string | null;
}) => {
  const response = useQuery<Service[]>(["services", body.env], () =>
    getServices(body.env, body.token)
  );
  return response;
};

const createService = async (body: {
  env: environmentType;
  token?: string | null;
  payload: ServiceRequest;
}) => {
  if (!body.token) return;
  const { data } = await axios.post(
    `${getEnvUrl(body.env)}/v1/service-catalog`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  );
  if (data) {
    message.success(`Service "${body.payload.name}" created`);
    return data.data;
  }
};

export const useCreateService = () => {
  const queryClient = useQueryClient();
  return useMutation(createService, {
    onSuccess: async (data, params) => {
      if (!data) return;
      const oldData = queryClient.getQueryData<Service[]>([
        "services",
        params.env,
      ]);
      if (oldData) {
        const newData = [...oldData, data];

        queryClient.setQueriesData(["services", params.env], () => newData);
      }
    },
  });
};

const updateService = async (body: {
  env: environmentType;
  token?: string | null;
  payload: ServiceRequest & { id: string };
  details?: boolean;
}) => {
  if (!body.token) return;
  const { data } = await axios.put(
    `${getEnvUrl(body.env)}/v1/service-catalog`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  );
  if (data) {
    message.success(`Service "${body.payload.name}" updated`);
    return data.data;
  }
};

export const useUpdateService = () => {
  const queryClient = useQueryClient();
  return useMutation(updateService, {
    onSuccess: async (data, params) => {
      if (!data) return;
      if (params.details) {
        queryClient.setQueriesData(
          ["service", params.payload.id, params.env],
          () => data
        );
      } else {
        const oldData = queryClient.getQueryData<Service[]>([
          "services",
          params.env,
        ]);

        if (oldData) {
          const newData = [...oldData];
          const index = oldData.findIndex((item) => item.id === data.id);
          newData[index] = data;

          queryClient.setQueriesData(["services", params.env], () => newData);
        }
      }
    },
  });
};
const deleteService = async (body: {
  env: environmentType;
  token?: string | null;
  id: string;
  callback?: () => void;
}) => {
  if (!body.token) return;
  const { data } = await axios.delete(
    `${getEnvUrl(body.env)}/v1/service-catalog?Id=${body.id}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  );
  if (data) {
    if (body.callback) body.callback();
    message.success(`Service deleted`);
    return data.data;
  }
};

export const useDeleteService = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteService, {
    onSuccess: async (data, params) => {
      const oldData = queryClient.getQueryData<Service[]>([
        "services",
        params.env,
      ]);

      if (oldData) {
        queryClient.setQueriesData(["services", params.env], () =>
          oldData.filter((item) => item.id !== params.id)
        );
      }
    },
  });
};

export const getSingleService = async (body: {
  ServiceCatalogId?: string;
  env: environmentType;
  token: string | null;
}) => {
  if (!body.ServiceCatalogId || !body.token) return;
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/service-catalog/${body.ServiceCatalogId}`,
    {
      params: { ServiceCatalogId: body.ServiceCatalogId },
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  );
  if (data) return data.data;
};

export const useGetSingleService = (body: {
  ServiceCatalogId?: string;
  env: environmentType;
  token: string | null;
}) => {
  const { ServiceCatalogId, env, token } = body;
  const response = useQuery<Service>(
    ["service", body.ServiceCatalogId, body.env],
    () => getSingleService({ ServiceCatalogId, env, token })
  );

  return response;
};

const getServicesErrorCount = async (body: {
  env: environmentType;
  token: string | null;
}) => {
  const { data } = await axios.get<VizznResponse<TeamErrorCount[]>>(
    `${getEnvUrl(body.env)}/v1/error-count`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  );

  return data.data;
};

export const useGetServicesErrorCount = (body: {
  env: environmentType;
  token: string | null;
}) => {
  const response = useQuery<TeamErrorCount[]>(
    ["error-log-count", body.env],
    () => getServicesErrorCount(body)
  );

  return response;
};
