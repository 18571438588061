import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import {
  AccountApplicationCompanyFormView,
  CompanyApplication,
  CompanyApplicationResponseDto,
  EmployeeApplicationResponseDto,
  VizznResponse,
} from "../types/AccountApplication"
import { environmentType } from "../types/environtment"
import { getEnvUrl } from "../utils/utils"

const getEmployeeApplications = async (
  env: environmentType,
  token: string | null
) => {
  const { data }: { data: VizznResponse<EmployeeApplicationResponseDto> } =
    await axios.get(
      `${getEnvUrl(env)}/v1/user-transfers?requestType=application`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

  return data.data.records
}

export const useGetEmployeeApplications = (
  env: environmentType,
  token: string | null
) => {
  return useQuery<any>(["employee-applications"], () =>
    getEmployeeApplications(env, token)
  )
}
// /v1/account-employee-applications

const getCompanyApplications = async (
  env: environmentType,
  token: string | null
) => {
  const { data }: { data: VizznResponse<CompanyApplicationResponseDto> } =
    await axios.get(`${getEnvUrl(env)}/v1/account-company-applications`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

  return data.data.records
}

export const useGetCompanyApplications = (
  env: environmentType,
  token: string | null
) => {
  return useQuery<CompanyApplication[]>(["company-applications"], () =>
    getCompanyApplications(env, token)
  )
}

const getCompanyApplicationDetail = async (
  env: environmentType,
  token: string | null,
  id: string
) => {
  const { data }: { data: VizznResponse<AccountApplicationCompanyFormView> } =
    await axios.get(`${getEnvUrl(env)}/v1/account-company-applications/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

  return data.data
}

export const useGetCompanyApplicationDetail = (
  env: environmentType,
  token: string | null,
  id: string
) => {
  return useQuery<AccountApplicationCompanyFormView>(
    ["company-application-detail", id],
    () => getCompanyApplicationDetail(env, token, id),
    {
      enabled: !!id,
    }
  )
}
