import { RowContent } from "../Utils/RowContent"
import { CopyContent } from "../Utils/CopyContent"

type Props = {
  authId: string
}

export default function UserAuthIDWidget({ authId }: Props) {
  return (
    <RowContent
      name={"Auth ID"}
      content={
        <div style={{ display: "flex", gap: "5px" }}>
          <b>{authId}</b>
          <CopyContent text={authId} hideText tooltip={`Copy Auth ID`} />
        </div>
      }
    ></RowContent>
  )
}
