import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { message } from "antd"
import axios from "axios"
import { flattenCategoryData } from "../pages/UsageScores/utils/flattenCateogryData"
import { VizznResponse } from "../types/Api"
import {
  CategoryDetail,
  CreateUsageCategoryRequestDto,
  TopUsageScoresReturnDto,
  UpdateUsageCategoryRequestDto,
  UsageCase,
  UsageCategory,
  UsageScore,
} from "../types/UsageScores"
import { environmentType } from "../types/environtment"
import { getEnvUrl } from "../utils/utils"

const getUsageCases = async (body: {
  env: environmentType
  token: string | null
}) => {
  const {
    data,
  }: {
    data: {
      data: UsageCase[]
    }
  } = await axios.get(`${getEnvUrl(body.env)}/v1/usage-use-cases`, {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  })

  return data.data.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
}

export const useGetUsageCases = (body: {
  env: environmentType
  token: string | null
}) => {
  return useQuery<UsageCase[] | undefined>(["usage-cases", body.env], () =>
    getUsageCases(body)
  )
}

export const getUsageScores = async (body: {
  env: environmentType
  token: string | null
}) => {
  const { data } = await axios.get(`${getEnvUrl(body.env)}/v1/usage-scores`, {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  })

  const sortedData: UsageScore[] = data.data.sort(
    (a: UsageScore, b: UsageScore) => {
      return b.score - a.score
    }
  )

  return sortedData
}

export const useGetUsageScores = (body: {
  env: environmentType
  token: string | null
}) => {
  return useQuery<UsageScore[] | undefined>(["usage-scores", body.env], () =>
    getUsageScores(body)
  )
}

const getUsageScoresByTeamId = async (body: {
  env: environmentType
  token: string | null
  teamId: string
}) => {
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/usage-scores/${body.teamId}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

const getUsageCategoryById = async (body: {
  env: environmentType
  token: string | null
  categoryId: string
}) => {
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/usage-scores/category/${body.categoryId}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useGetUsageCategoryById = (body: {
  env: environmentType
  token: string | null
  categoryId: string
}) => {
  return useQuery<CategoryDetail[] | undefined>(
    ["usage-scores-category", body.env, body.categoryId],
    () => getUsageCategoryById(body)
  )
}

export const useGetUsageScoresByTeamId = (body: {
  env: environmentType
  token: string | null
  teamId: string
}) => {
  return useQuery<UsageScore[] | undefined>(["usage-scores", body.env], () =>
    getUsageScoresByTeamId(body)
  )
}

const createUsageCategory = async (body: {
  env: environmentType
  token: string | null
  dto: CreateUsageCategoryRequestDto
}) => {
  const { data } = await axios.post(
    `${getEnvUrl(body.env)}/v1/usage-category`,
    body.dto,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useCreateUsageCategory = () => {
  const queryClient = useQueryClient()

  return useMutation(createUsageCategory, {
    onSuccess: (_, params) => {
      message.success("Category created successfully")
      queryClient.refetchQueries(["usage-categories", params.env])
    },
  })
}

const getUsageCategories = async (body: {
  env: environmentType
  token: string | null
}) => {
  const {
    data,
  }: {
    data: {
      data: UsageCategory[]
    }
  } = await axios.get(`${getEnvUrl(body.env)}/v1/usage-categories`, {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  })

  return data.data
}

export const useGetUsageCategories = (body: {
  env: environmentType
  token: string | null
}) => {
  return useQuery<UsageCategory[] | undefined>(
    ["usage-categories", body.env],
    () => getUsageCategories(body)
  )
}

const editUsageCategory = async (body: {
  env: environmentType
  token: string | null
  id: string
  dto: UpdateUsageCategoryRequestDto
}) => {
  const { data } = await axios.put(
    `${getEnvUrl(body.env)}/v1/usage-category/${body.id}`,
    body.dto,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useEditUsageCategory = () => {
  const queryClient = useQueryClient()

  return useMutation(editUsageCategory, {
    onSuccess: (_, params) => {
      message.success("Category edited successfully")
      queryClient.invalidateQueries(["usage-categories", params.env])
    },
  })
}

const deleteUsageCategory = async (body: {
  env: environmentType
  token: string | null
  id: string
}) => {
  const { data } = await axios.delete(
    `${getEnvUrl(body.env)}/v1/usage-category/${body.id}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
      data: {
        id: body.id,
      },
    }
  )

  return data.data
}

export const useDeleteUsageCategory = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteUsageCategory, {
    onSuccess: (_, params) => {
      message.success("Category deleted successfully")
      queryClient.refetchQueries(["usage-categories", params.env])
    },
  })
}

const updateUsageScoresByCategory = async (body: {
  env: environmentType
  token: string | null
  dto?: {
    teamId?: string
    categoryId?: string
  }
}) => {
  const { data } = await axios.post(
    `${getEnvUrl(body.env)}/v1/usage-scores-by-category`,
    body.dto,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useUpdateUsageScoresByCategory = () => {
  const queryClient = useQueryClient()

  return useMutation(updateUsageScoresByCategory, {
    onSuccess: (_, params) => {
      message.success("Scores updated successfully")
      queryClient.refetchQueries(["usage-scores", params.env])
    },
  })
}

const updateUsageScores = async (body: {
  env: environmentType
  token: string | null
}) => {
  const { data } = await axios.post(
    `${getEnvUrl(body.env)}/v1/usage-scores`,
    {},
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useUpdateUsageScores = () => {
  const queryClient = useQueryClient()

  return useMutation(updateUsageScores, {
    onSuccess: (_, params) => {
      message.success("Scores updated successfully")
      queryClient.refetchQueries(["usage-scores", params.env])
    },
  })
}

const getTopUsageScoresByCategory = async (body: {
  env: environmentType
  token: string | null
}) => {
  const { data } = await axios.get<VizznResponse<TopUsageScoresReturnDto>>(
    `${getEnvUrl(body.env)}/v1/top-usage-scores`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return flattenCategoryData(data.data.TopUsageScores)
}

export const useGetTopUsageScoresByCategory = (body: {
  env: environmentType
  token: string | null
}) => {
  return useQuery<any[] | undefined>(
    ["usage-scores-top-by-category", body.env],
    () => getTopUsageScoresByCategory(body)
  )
}

// /v1/update-usage-scores-by-category

// GET '/v1/usage-scores'
// GET '/v1/usage-scores/:teamId'

// POST '/v1/usage-category'
// category creation request dto:

// type UsageCategoryWeightRequestDto struct {
//   UseCase string `json:"useCase" firestore:"useCase" db:"useCase"`
//   Weight float32 `json:"weight" firestore:"weight" db:"weight"`
// }

// type CreateUsageCategoryRequestDto struct {
//   Name string `json:"name" firestore:"name" db:"name"`
//   Weights []UsageCategoryWeightRequestDto `json:"weights" firestore:"weights" db:"weights"`
// }
