// import { Bar, BarConfig, Column, ColumnConfig } from "@ant-design/charts"
import { Spin } from "antd"
import { useGetTopUsageScoresByCategory } from "../../apis/UsageScores"
import { useAuthContext } from "../../context/AuthContext"
import { TopUsageScoresByCategoryResult } from "../../types/UsageScores"
import { GetError } from "../../widgets/GetError"

import BarChartCard from "../../charts/BarChartCard"

export default function UsageScoresTopByCategory() {
  const { env, token } = useAuthContext()
  const scores = useGetTopUsageScoresByCategory({
    env,
    token: token[env],
  })

  if (scores.isLoading) return <Spin size={"large"} />
  if (scores.error || !scores.data) return <GetError reFetch={scores.refetch} />

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      {scores?.data?.map(
        (data: TopUsageScoresByCategoryResult, index: number) => {
          return (
            <BarChartCard
              data={data.teams}
              title={data.category}
              key={index}
              id={data.id}
            />
          )
        }
      )}
    </div>
  )
}
