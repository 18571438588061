import BarChartCard from "../../../charts/BarChartCard"
import { TeamMetricsData } from "../../../types/Team"

export default function TeamsGrowthByMonth({ growth }: TeamMetricsData) {
  return (
    <BarChartCard
      data={growth}
      title="Team Growth by Month"
      dataKey="count"
      xAxisDataKey="month"
      height={450}
      showDetailButton={false}
    />
  )
}
