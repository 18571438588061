import { Typography } from "antd"
import { useAuthContext } from "../../../../../context/AuthContext"
import { ActivityFeedsPropData } from "../../../../../types/DispatchEvent"

export const UserActivityFeed = ({ data }: { data: ActivityFeedsPropData }) => {
  const { user, env } = useAuthContext()
  return !data.team || user[env]?.tenantId === data.team.id ? (
    <Typography.Text strong>{data.value?.toString()}</Typography.Text>
  ) : (
    <Typography.Text strong>
      {`${data.value} [${data.team.name}]`}{" "}
    </Typography.Text>
  )
}
