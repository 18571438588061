import { NotifiableEntityType } from "../../types/notifications"

export const systemRoles = [
  { id: "editor", name: "Editor" },
  { id: "manager", name: "Manager" },
  { id: "owner", name: "Owner" },
  { id: "supervisor", name: "Supervisor" },
  { id: "admin", name: "Team Admin" },
]
export const jobRoles = [
  { id: "dispatcher", name: "Dispatcher" },
  { id: "driver", name: "Driver" },
  { id: "driverOnly", name: "Driver Only" },
  { id: "fieldTeam", name: "Field Team Member" },
  { id: "mechanic", name: "Mechanic" },
  { id: "resourceProvider", name: "Resource Provider" },
]
//crew, dispatchEvent, dispatchRequest, equipment, equipmentAttachment, externalCompany, externalPerson, externalSite, site, trailer, trailerCombination, truck, truckConfiguration, user
export const entityType: { id: NotifiableEntityType; name: string }[] = [
  { id: "classification", name: "Entity Classes (Classifications)" },
  { id: "combinationType", name: "Combination Type" },
  { id: "costCode", name: "Cost Code" },
  { id: "crew", name: "Crew" },
  { id: "crewActivity", name: "Crew Activity" },
  { id: "dispatchEventType", name: "Dispatch Event Type" },
  { id: "dispatchGroup", name: "Dispatcher Team (Dispatch Group)" },
  { id: "dispatchRequestType", name: "Dispatch Request Type" },
  { id: "equipment", name: "Equipment" },
  { id: "equipmentAttachment", name: "Equipment Attachment" },
  { id: "externalCompany", name: "External Company" },
  { id: "externalPerson", name: "External Person" },
  { id: "externalSite", name: "External Site" },
  { id: "folder", name: "Folder" },
  { id: "form", name: "Form" },
  { id: "formSubmission", name: "Form Submission" },
  { id: "generalActivity", name: "General Activity" },
  { id: "site", name: "Site" },
  { id: "team", name: "Team" },
  { id: "trailer", name: "Trailer" },
  { id: "trailerCombination", name: "Trailer Combination" },
  { id: "truck", name: "Truck" },
  { id: "truckConfiguration", name: "Truck Configuration" },
  { id: "user", name: "User" },
]
