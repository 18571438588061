import { UsergroupAddOutlined } from "@ant-design/icons"
import * as Sentry from "@sentry/react"
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from "antd"
import { useState } from "react"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { useCreateTeam } from "../../apis/teams"
import { useAuthContext } from "../../context/AuthContext"
import useDebounceFunction from "../../hooks/useDebounceFunction"
import { PlaceDetail } from "../../types/PlaceDetails"
import {
  DimensionUnit,
  SpeedUnit,
  TeamRequest,
  TeamType,
  WeightUnit,
} from "../../types/Team"
import { TimezonePicker } from "../TimezonePicker"
import { TeamTimePicker } from "./TeamDatePicker"

type Values = {
  allowSignup?: boolean
  dimension?: DimensionUnit
  emailParts?: string
  name: string
  type: TeamType
  tag?: string
  weight?: WeightUnit
  timezone: string
  speed: SpeedUnit
  address: string
  lat: number
  lng: number
}

export const NewTeamModal = () => {
  const [open, setOpen] = useState(false)
  const [form] = Form.useForm()
  const [schedule, setSchedule] = useState<number[]>([])
  const { mutateAsync: createTeam } = useCreateTeam()
  const { env, token } = useAuthContext()
  const [creating, setCreating] = useState(false)
  const onSearch = (text: string) => {
    // Send sentry log to trace Google Place API usage
    Sentry.captureEvent({
      message: "[Admin] Places API - New Team Modal",
      level: "info",
      tags: {
        type: "places-api",
      },
    })

    getPlacePredictions({
      input: text,
      types: [],
      language: "en",
    })
  }

  const debouncedOnSearch = useDebounceFunction(onSearch, 250)

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places", "geometry"],
  })

  const handleSelectPlaceDetails = (placeDetails: PlaceDetail) => {
    //  NOTE: Might use in the future
    // const address = placeDetails?.formatted_address;
    // const city = placeDetails?.address_components?.find((add) =>
    //   add.types.some(
    //     (type) =>
    //       type.includes("locality") ||
    //       type.includes("administrative_area_level_2")
    //   )
    // )?.long_name;
    // const state = placeDetails?.address_components?.find((add) =>
    //   add.types.includes("administrative_area_level_1")
    // )?.short_name;
    // const country = placeDetails?.address_components?.find((add) =>
    //   add.types.includes("country")
    // )?.long_name;

    form.setFieldsValue({
      lat: placeDetails?.geometry?.location?.lat(),
      lng: placeDetails?.geometry?.location?.lng(),
    })

    return
  }

  const handleCreateTeam = async () => {
    const values: Values = form.getFieldsValue()
    const payload: TeamRequest = {
      name: values.name.trim().replace(/\s\s+/g, " "),
      type: values.type,
      tag: values.tag
        ? values.tag
            .toLowerCase()
            .trim()
            .replace(/\s\s+/g, " ")
            .replace(/ /g, "-")
        : undefined,
      allowSignup: values.allowSignup,
      dimension: values.dimension,
      weight: values.weight,
      speed: values.speed,
      hours: {
        timezone: values.timezone,
        start: schedule[0],
        end: schedule[1],
      },
      address: values.address.trim(),
      coordinates: {
        lat: values.lat,
        lng: values.lng,
      },
    }

    const hasError = await form.validateFields()
    if (Array.isArray(hasError)) return

    if (schedule.length !== 2)
      return message.error("Please set you team work start and end time")
    setCreating(true)
    try {
      await createTeam({ env, token: token[env], payload })
      handleCloseModal()
    } catch (error: any) {
      message.error(error.message)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    setSchedule([])
    form.resetFields()
    setCreating(false)
    setOpen(false)
  }
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        icon={<UsergroupAddOutlined />}
        type="primary"
      >
        Create New Team
      </Button>
      {open && (
        <Modal
          title="New Team Account"
          open={open}
          onCancel={handleCloseModal}
          onOk={() => handleCreateTeam()}
          okButtonProps={{ loading: creating }}
        >
          <Form form={form} layout="vertical">
            <Typography.Title level={4}>General Info</Typography.Title>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input your team name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="type"
              label="Type"
              rules={[
                {
                  required: true,
                  message: "Please input a type",
                },
              ]}
            >
              <Select>
                {Object.entries(TeamType).map(([key, value]) => (
                  <Select.Option key={key} value={value}>
                    {key}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Please input your address",
                },
              ]}
            >
              <Select
                placeholder={"Search for your address"}
                defaultActiveFirstOption
                className="search-box"
                onSearch={debouncedOnSearch}
                filterOption={(text, option) => {
                  return !option!.name
                    .toLowerCase()
                    .includes(text.toLocaleLowerCase)
                }}
                showSearch
                loading={isPlacePredictionsLoading}
                onInputKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault()
                  }
                }}
                notFoundContent={null}
                onSelect={(e, option) => {
                  const value = placePredictions.find(
                    (loc) => loc.place_id === option.key
                  )

                  if (!value) message.error("Cannot find your address")

                  if (value)
                    placesService?.getDetails(
                      {
                        placeId: value.place_id,
                      },
                      (placeDetails: PlaceDetail) =>
                        handleSelectPlaceDetails(placeDetails)
                    )
                }}
              >
                {placePredictions.map((location) => (
                  <Select.Option
                    key={location.place_id}
                    value={location.description}
                    name={location.description}
                  >
                    {location.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Lat"
              name="lat"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Lng"
              name="lng"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Row gutter={16}>
              <Col flex="auto">
                <Form.Item name="tag" label="Tag">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Typography.Title level={4}>Default Units</Typography.Title>
            <Row gutter={16}>
              <Col flex="auto">
                <Form.Item name="dimension" label="Dimension Unit">
                  <Select>
                    <Select.Option value="foot">Foot</Select.Option>
                    <Select.Option value="inch">Inch</Select.Option>
                    <Select.Option value="meter">Meter</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="auto">
                <Form.Item name="weight" label="Weight Unit">
                  <Select>
                    <Select.Option value="ton">Ton</Select.Option>
                    <Select.Option value="pound">Pound</Select.Option>
                    <Select.Option value="kilogram">Kilogram</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="auto">
                <Form.Item name="speed" label="Speed Unit">
                  <Select>
                    <Select.Option value="kph">kph</Select.Option>
                    <Select.Option value="mph">mph</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Typography.Title level={4}>Work Hours</Typography.Title>
            <TimezonePicker />
            <Row gutter={16}>
              <Col flex="auto">
                <Form.Item label="Start Time">
                  <TeamTimePicker
                    setSchedule={(value: number) =>
                      setSchedule((prev) => [value, ...prev])
                    }
                  />
                </Form.Item>
              </Col>
              <Col flex="auto">
                <Form.Item label="End Time">
                  <TeamTimePicker
                    setSchedule={(value: number) =>
                      setSchedule((prev) => [...prev, value])
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  )
}
