export function filterByKey<T>(
  keys: string[],
  data: T[],
  searchText: string
): T[] {
  const lowerCaseSearchText = searchText.toLowerCase()

  return data.filter((item) =>
    keys.some((key) => {
      const value = getNestedValue(item, key)
      return (
        typeof value === "string" &&
        value.toLowerCase().includes(lowerCaseSearchText)
      )
    })
  )
}

function getNestedValue(obj: any, key: string): any {
  return key.split(".").reduce((o, k) => (o || {})[k], obj)
}
