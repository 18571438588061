import { Form, Input, Modal } from "antd";
import React from "react";

interface ApplicationRejectModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  handleRejectApplication: (note: string, id: string) => Promise<void>;
}

interface ApplicationRejectModalForm {
  rejectionNote: string;
}

export default function ApplicationRejectModal({
  isOpen,
  setIsOpen,
  id,
  handleRejectApplication,
}: ApplicationRejectModalProps) {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = async (data: ApplicationRejectModalForm) => {
    await handleRejectApplication(data.rejectionNote, id);

    form.resetFields();
    setIsOpen(false);
  };

  return (
    <Modal
      title="Please provide a note for application rejection"
      open={isOpen}
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="rejectionNote">
          <Input placeholder="Add note here" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
