import React, { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useSearchTags } from "../utils/useSearchTags";
import { UserNotification } from "../types/user";
import { usePaginatedUserNotification } from "../apis/user";
import { flatten } from "lodash";
import { filterBySearchValueAndTag } from "../utils/search";
import { GetError } from "../widgets/GetError";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  List,
  Popover,
  Row,
  Spin,
  Tag,
} from "antd";
import { DatePickerForm } from "../components/Utils/DatePickerForm";
import {
  CalendarOutlined,
  CaretDownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { ListHeader } from "../components/Utils/ListHeader";
import { green, red } from "@ant-design/colors";
import { formatToDateAndHour } from "../components/Utils/TimeUtils";
import { CopyContent } from "../components/Utils/CopyContent";

export const NotificationPage = () => {
  const { env, token } = useAuthContext();
  const searchTags = useSearchTags("notificationsPage");
  const { tags, searchValue } = searchTags;
  const [filteredData, setFilteredData] = useState<UserNotification[]>([]);
  const [formDates, setFormDates] = useState<Date[]>([]);
  const [updating, setUpdating] = useState(false);
  const notification = usePaginatedUserNotification({
    env,
    token: token[env],
    pageSize: 20,
    start: !!formDates.length ? formDates[0] : undefined,
    end: !!formDates.length ? formDates[1] : undefined,
  });

  useEffect(() => {
    if (updating) {
      notification.refetch();
      setUpdating(false);
    }
  }, [notification, updating]);
  useEffect(() => {
    if (!notification.data || !notification.dataUpdatedAt) return;
    const searchFields = ["subject", "message", "channel", "userId", "to"];

    let filteredNotifications = [...(flatten(notification.data.pages) || [])];
    if (tags.length || searchValue)
      filteredNotifications = filterBySearchValueAndTag<UserNotification>({
        tags,
        searchValue,
        searchFields,
        dataArray: filteredNotifications,
      });

    setFilteredData(filteredNotifications);
  }, [notification.data, notification.dataUpdatedAt, searchValue, tags]);

  if (notification.isLoading) return <Spin />;

  if (!!notification.error || !notification.data)
    return <GetError reFetch={notification.refetch} />;
  return (
    <Card title="Notifications">
      <Row gutter={8}>
        <Col>
          <Popover
            placement="bottomLeft"
            trigger="click"
            title="Filter by date"
            content={
              <DatePickerForm
                formDate={formDates}
                setFormDate={setFormDates}
                setIsUpdating={setUpdating}
              />
            }
          >
            <Button icon={<CaretDownOutlined />}>Date</Button>
          </Popover>
        </Col>
        <Col flex="auto">
          <ListHeader
            total={flatten(notification.data.pages).length || 0}
            displayedNum={filteredData.length}
            searchTags={searchTags}
          />
        </Col>
      </Row>
      <List
        itemLayout="horizontal"
        dataSource={filteredData}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                item.successful ? (
                  <Avatar
                    size="small"
                    style={{ backgroundColor: green.primary }}
                    icon={<CheckOutlined />}
                  />
                ) : (
                  <Avatar
                    size="small"
                    style={{ backgroundColor: red.primary }}
                    icon={<CloseOutlined />}
                  />
                )
              }
              title={item.subject}
              description={
                <>
                  <div>{item.message}</div>
                  <div>
                    <CalendarOutlined />
                    <Divider type="vertical" />
                    {formatToDateAndHour(item.time)}
                    <Divider type="vertical" />
                    <span>
                      Channel: <b>{item.channel}</b>
                    </span>
                  </div>
                  <div>
                    User ID:{" "}
                    <b>
                      <CopyContent text={item.userId} />
                    </b>
                    <Divider type="vertical" />
                    To:{" "}
                    {item.to.map((name) => (
                      <Tag key={name}>{name}</Tag>
                    ))}
                  </div>
                </>
              }
            />
          </List.Item>
        )}
      />
      <Row>
        {notification.hasNextPage && (
          <Button
            type="primary"
            block
            onClick={() => notification.fetchNextPage()}
          >
            Load More
          </Button>
        )}
      </Row>
    </Card>
  );
};
