import { format } from "date-fns";

export const formatToDateAndHour = (date: Date | string | number) => {
  if (!date) return "No date submitted";
  return format(new Date(date), "MMM do, yyyy (HH:mm:ss)");
};
export const formatToDateHourAndSeconds = (date: Date | string | number) => {
  if (!date) return "No date submitted";
  return format(new Date(date), "MMM do, yyyy (HH:mm:ss.SSS)");
};
