import { Button, Input, Table } from "antd"
import { AnyObject } from "antd/es/_util/type"
import { TableProps } from "antd/es/table"
import { useMemo, useState } from "react"
interface GenericTableComponentProps<T> extends TableProps<T> {
  data: T[]
  searchFilter: (keyof T)[]
}
export default function GenericTableComponent<T extends AnyObject>(
  props: GenericTableComponentProps<T>
) {
  const [search, setSearch] = useState("")
  const filteredData = useMemo(() => {
    return props.data.filter((item) =>
      props.searchFilter.some((key) => {
        const value = item[key]
        return typeof value === "string"
          ? value.toLowerCase().includes(search.toLowerCase())
          : typeof value === "number"
          ? value.toString().toLowerCase().includes(search.toLowerCase())
          : false
      })
    )
  }, [props.data, search, props.searchFilter])
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <div style={{ display: "flex", gap: "10px" }}>
        <Input
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button type="primary" onClick={() => setSearch("")}>
          Clear
        </Button>
      </div>
      <Table {...props} dataSource={filteredData} />
    </div>
  )
}
