import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import axios from "axios"
import { useAuthContext } from "../context/AuthContext"
import { VizznResponse } from "../types/AccountApplication"
import { ActivityFeedData } from "../types/DispatchEvent"
import { environmentType } from "../types/environtment"
import { TokenState } from "../types/user"
import { getEnvUrl } from "../utils/utils"

export const usePaginatedActivityFeedCore = (id: string, pageSize?: number) => {
  const { env, token } = useAuthContext()
  const fetchPage = async ({ pageParam = 1 }) => {
    const { data } = await axios.get<VizznResponse<ActivityFeedData[]>>(
      `${getEnvUrl(env)}/v1/activity-feeds`,
      {
        params: {
          entityId: id,
          feedType: "core",
          page: pageParam,
          size: pageSize || 20,
        },
        headers: {
          Authorization: `Bearer ${token[env]}`,
        },
      }
    )

    return data.data
  }

  const activities = useInfiniteQuery<ActivityFeedData[]>({
    queryKey: ["activities", id, "core"],
    queryFn: fetchPage,
    getNextPageParam: (latestPage, pages) => {
      // on any page, if the latest page has less document than the increment number, there isn't more doc to fetch
      if (latestPage.length < (pageSize || 20)) return undefined
      return pages.length + 1
    },
  })

  return activities
}

const deleteActivityFeedMessage = async (body: {
  id: string
  env: environmentType
  token: TokenState
}) => {
  const { data } = await axios.delete(
    `${getEnvUrl(body.env)}/v1/activity-feeds/${body.id}`,
    {
      headers: {
        Authorization: `Bearer ${body.token[body.env]}`,
      },
    }
  )

  return data.data
}

export const useDeleteActivityFeedMessage = () => {
  const client = useQueryClient()

  return useMutation(deleteActivityFeedMessage, {
    onSuccess: () => {
      client.invalidateQueries(["activities"])
    },
  })
}
