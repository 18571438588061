import { environmentType } from "../types/environtment"
import { Role, RoleType } from "../types/Role"
import axios from "axios"
import { VizznResponse } from "../types/AccountApplication"
import { useQuery } from "@tanstack/react-query"
import { getEnvUrl } from "../utils/utils"

const getRoles = async (body: {
  env: environmentType
  token: string | null
  type: RoleType
}) => {
  if (!body.token) return

  const { data } = await axios.get<VizznResponse<Role[]>>(
    `${getEnvUrl(body.env)}/v1/${body.type}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export function useGetRoles(body: {
  env: environmentType
  token: string | null
  type: RoleType
  enabled?: boolean
}) {
  return useQuery(["roles", body.type], () => getRoles(body), {
    enabled: body.enabled,
  })
}

// export const getRoles = async (type: 'roles/system' | 'roles') => {
//   try {
//     const { data } = await Axios.get<VizznResponse<Role[]>>(`/v1/${type}`)

//     return data.data
//   } catch (error) {
//     // axios response error intercepted
//   }
// }

// export const useGetRoles = (type: 'roles/system' | 'roles') => {
//   const response = useQuery<Role[] | undefined>(['rolesList', type], () => getRoles(type))

//   return response
// }
