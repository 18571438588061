import { Card, Col, Row, Select, Table, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetUsageCategoryById } from "../../apis/UsageScores"
import { useAuthContext } from "../../context/AuthContext"
import { TeamType } from "../../types/Team"
import { CategoryDetail } from "../../types/UsageScores"
import ErrorRefetch from "../../components/ErrorRefetch/ErrorRefetch"

type TeamScores = {
  id: string
  previousScore: number
  currentScore: number
  variance: number
  team: {
    id: string
    name: string
    type: string
  }
  percentChange: number
}

function UsagesScoresDetail() {
  const { id, name } = useParams<{ id: string; name: string }>()
  const { env, token } = useAuthContext()
  const [type, setType] = useState<TeamType>()
  const [categoryData, setCategoryData] = useState<CategoryDetail[]>([])
  const category = useGetUsageCategoryById({
    env,
    token: token[env],
    categoryId: id as string,
  })

  const getResult = useCallback(() => {
    const result = new Map<string, TeamScores>()

    categoryData.forEach((item) => {
      const teamId = item.team.id
      const existing = result.get(teamId)

      if (existing) {
        const existingTimestamp = new Date(
          existing.currentScore > existing.previousScore
            ? existing.currentScore
            : existing.previousScore
        )
        const itemTimestamp = new Date(item.timestamp)

        if (itemTimestamp > existingTimestamp) {
          result.set(teamId, {
            id: teamId,
            previousScore: existing.currentScore,
            currentScore: item.score,
            variance: item.score - existing.currentScore,
            percentChange:
              ((item.score - existing.currentScore) / existing.currentScore) *
              100,
            team: item.team,
          })
        } else if (itemTimestamp < existingTimestamp) {
          result.set(teamId, {
            id: teamId,
            previousScore: item.score,
            currentScore: existing.currentScore,
            variance: existing.currentScore - item.score,
            percentChange:
              ((existing.currentScore - item.score) / item.score) * 100,
            team: item.team,
          })
        }
      } else {
        result.set(teamId, {
          id: teamId,
          previousScore: item.score,
          currentScore: item.score,
          variance: 0,
          team: item.team,
          percentChange: 0,
        })
      }
    })

    const teamScores = Array.from(result, ([teamId, value]) => ({
      id: teamId,
      team: value.team,
      previousScore: value.previousScore,
      currentScore: value.currentScore,
      variance: value.variance,
      percentChange: value.percentChange,
    })) as TeamScores[]

    const topPositiveVariance = teamScores
      .filter((score) => score.variance > 0)
      .sort((a, b) => b.variance - a.variance)
      .slice(0, 5)

    const topNegativeVariance = teamScores
      .filter((score) => score.variance < 0)
      .sort((a, b) => a.variance - b.variance)
      .slice(0, 5)

    return {
      topPositiveVariance,
      topNegativeVariance,
      teamScores,
    }
  }, [categoryData])

  useEffect(() => {
    if (category.data) {
      setCategoryData(
        category.data.filter((item) => (type ? item.team.type === type : true))
      )
    }
  }, [category.data, type])

  const columns: ColumnsType<TeamScores> = [
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      render: (_, e) => e.team.name,
    },
    {
      title: "Previous Score",
      dataIndex: "previousScore",
      key: "previousScore",
      render: (_, e) => e.previousScore,
      sorter: (a, b) => a.previousScore - b.previousScore,
    },
    {
      title: "Current Score",
      dataIndex: "currentStore",
      key: "currentStore",
      render: (_, e) => e.currentScore,
      sorter: (a, b) => a.currentScore - b.currentScore,
    },
    {
      title: "Variance",
      dataIndex: "variance",
      key: "variance",
      render: (_, e) => e.variance,
      sorter: (a, b) => a.variance - b.variance,
    },
    {
      title: "Percent Change",
      dataIndex: "percentChange",
      key: "percentChange",
      render: (_, e) => (
        <span>
          <span
            style={{ color: e.percentChange > 0 ? "green" : "red" }}
          >{`${e.percentChange.toFixed(2)}%`}</span>
        </span>
      ),
      sorter: (a, b) => a.percentChange - b.percentChange,
    },
  ]

  const { topNegativeVariance, topPositiveVariance, teamScores } = getResult()

  const [tableHeight, setTableHeight] = useState(600)

  useEffect(() => {
    const updateTableHeight = () => {
      const windowHeight = window.innerHeight
      const cardRow = document.getElementById("usage-scores-card-row")
      const cardRowBottom = cardRow ? cardRow.getBoundingClientRect().bottom : 0
      const newHeight = windowHeight - cardRowBottom - 24 // 24px for some bottom margin
      setTableHeight(Math.max(300, newHeight)) // Set a minimum height of 300px
    }

    updateTableHeight()
    window.addEventListener("resize", updateTableHeight)

    return () => window.removeEventListener("resize", updateTableHeight)
  }, [categoryData])

  if (category.isLoading || category.isLoading) return <div>Loading...</div>

  if (!category.data) return <ErrorRefetch refetch={category.refetch} />

  return (
    <div>
      <Row gutter={24} align={"top"}>
        <Col>
          <Typography.Title
            level={3}
            style={{ marginBottom: 16, marginTop: 0 }}
          >
            {name}
          </Typography.Title>
        </Col>
        <Col>
          <Select
            style={{ width: 300 }}
            onSelect={(val) => setType(val)}
            value={type}
            placeholder="Select team type"
          >
            <Select.Option value={undefined}>All</Select.Option>
            {Object.entries(TeamType).map(([key, value]) => (
              <Select.Option key={key} value={value}>
                {key}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: 16 }} id="usage-scores-card-row">
        <Col span={12}>
          <Card title="Top Gainers" bordered={false} style={{ height: "100%" }}>
            {topPositiveVariance.map((score, index) => (
              <Row key={score.id} gutter={12}>
                {index === 0 && (
                  <Col span={24}>
                    <Row>
                      <Col span={8}>
                        <strong>Team</strong>
                      </Col>
                      <Col span={8}>
                        <strong>Variance</strong>
                      </Col>
                      <Col span={8}>
                        <strong>Percent Change</strong>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col span={8}>{score.team.name}</Col>
                <Col span={8}>{score.variance}</Col>
                <Col span={8}>
                  <span
                    style={{ color: score.percentChange > 0 ? "green" : "red" }}
                  >
                    {score.percentChange.toFixed(2)}%
                  </span>
                </Col>
              </Row>
            ))}
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Top Losers" bordered={false} style={{ height: "100%" }}>
            {topNegativeVariance.map((score, index) => (
              <Row key={score.id} gutter={12}>
                {index === 0 && (
                  <Col span={24}>
                    <Row>
                      <Col span={8}>
                        <strong>Team</strong>
                      </Col>
                      <Col span={8}>
                        <strong>Variance</strong>
                      </Col>
                      <Col span={8}>
                        <strong>Percent Change</strong>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col span={8}>{score.team.name}</Col>
                <Col span={8}>{score.variance}</Col>
                <Col span={8}>
                  <span
                    style={{ color: score.percentChange > 0 ? "green" : "red" }}
                  >
                    {score.percentChange.toFixed(2)}%
                  </span>
                </Col>
              </Row>
            ))}
          </Card>
        </Col>
      </Row>
      <Table
        id="usage-scores-table"
        scroll={{ y: tableHeight - 100 }}
        style={{ minWidth: "100%" }}
        columns={columns}
        rowKey={(record) => `${record.team.id}`}
        dataSource={teamScores}
        pagination={false}
        loading={category.isLoading}
      />
    </div>
  )
}

export default UsagesScoresDetail
