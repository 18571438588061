import { Button, Form, Input, Tag } from "antd"
import { usePatchUserData } from "../../apis/user"
import { useAuthContext } from "../../context/AuthContext"
import { useState } from "react"
import { RowContent } from "../Utils/RowContent"
import { CheckOutlined, EditOutlined } from "@ant-design/icons"

type Props = { id: string; phone: string; verified: boolean }

type FormValues = {
  phone: string
}

export default function UserPhoneWidget({ id, phone, verified }: Props) {
  const { token, env } = useAuthContext()
  const { mutateAsync: updateUserPhone } = usePatchUserData(env)
  const [isEditing, setIsEditing] = useState(false)

  const [form] = Form.useForm<FormValues>()

  const onSubmit = async (values: FormValues) => {
    try {
      if (values.phone && values.phone !== phone)
        await updateUserPhone({
          payload: {
            id,
            phone: values.phone,
            phoneVerified: false,
          },
          env,
          token: token[env] as string,
        })
      return
    } catch (error) {
      console.error("Error updating user phone. Please try again.")
      return
    } finally {
      setIsEditing(false)
    }
  }

  return (
    <RowContent
      name="Phone"
      content={
        isEditing ? (
          <Form
            form={form}
            onFinish={onSubmit}
            style={{ display: "flex", gap: 10, alignItems: "center" }}
          >
            <Form.Item<FormValues>
              name="phone"
              initialValue={phone}
              style={{ margin: 0 }}
              normalize={(num: string) => {
                const match = num.replace(/\D+/g, "")

                if (!match) return ""
                const part0 =
                  match.length > 0 ? `+${match.substring(0, 1)} ` : ""
                const part1 =
                  match.length > 1 ? `(${match.substring(1, 3)}` : ""
                const part2 =
                  match.length > 3 ? `${match.substring(3, 4)}) ` : ""
                const part3 = match.length > 4 ? `${match.substring(4, 7)}` : ""
                const part4 =
                  match.length > 7 ? `-${match.substring(7, 11)}` : ""
                return `${part0}${part1}${part2}${part3}${part4}`
              }}
              rules={[
                () => ({
                  validator(_: any, value: any) {
                    if (!value) return Promise.resolve()
                    if (value.length === 0) return Promise.resolve()
                    if (
                      value.length === 17 ||
                      value.replace(/\D+/g, "").length === 11
                    ) {
                      return Promise.resolve()
                    }
                    return Promise.reject()
                  },
                }),
              ]}
            >
              <Input
                style={{ width: "200px" }}
                onKeyDown={(e) => {
                  if (e.key !== "Backspace") return
                  const value = form.getFieldValue("phone")

                  if (value && isNaN(parseInt(value[value.length - 1]))) {
                    form.setFieldsValue({ phone: value.replace(/\D+$/g, "") })
                  }
                }}
              />
            </Form.Item>
            <Button size="small" type="primary" htmlType="submit">
              <CheckOutlined />
            </Button>
          </Form>
        ) : (
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {phone !== "" ? (
              <div style={{ display: "flex", gap: "10px" }}>
                <b>{phone}</b>
                <Tag
                  style={{
                    color: "white",
                    margin: 0,
                    alignSelf: "center",
                    background: verified ? "green" : "red",
                  }}
                >
                  {verified ? "Verified" : "Unverified"}
                </Tag>
              </div>
            ) : null}

            <Button size="small" onClick={() => setIsEditing(true)}>
              <EditOutlined />
            </Button>
          </div>
        )
      }
    />
  )
}
