import { useGetDispatchById } from "../apis/DispatchExplorer"
import { useAuthContext } from "../context/AuthContext"
import { Button, Form, Input, theme } from "antd"
import { ActivityFeedModal } from "../components/DispatchExplorer/ActivityFeed"
import React from "react"
import ForceDirectedTree from "../components/DispatchExplorer/ForceDirectedTree"

export default function DispatchExplorer() {
  const { env, token } = useAuthContext()
  const [form] = Form.useForm()
  const { token: themeToken } = theme.useToken()
  const [dispatchId, setDispatchId] = React.useState<string>("")

  const dispatchData = useGetDispatchById({
    env,
    token: token[env],
    id: dispatchId,
  })

  const handleFormSubmit = ({ dispatchId }: { dispatchId: string }) => {
    setDispatchId(dispatchId)
  }

  const DispatchDataLoader = ({
    dispatchData,
    dispatchId,
  }: {
    dispatchData: any
    dispatchId: string
  }) => {
    if (!dispatchId) {
      return (
        <div style={{ color: themeToken.colorText }}>
          Please submit a dispatch id to explore...
        </div>
      )
    }

    if (dispatchData.isLoading) {
      return <div style={{ color: themeToken.colorText }}>Loading...</div>
    }

    if (dispatchData.isError) {
      return (
        <div style={{ color: themeToken.colorText }}>Error loading data</div>
      )
    }

    if (!dispatchData.data) {
      return (
        <div style={{ color: themeToken.colorText }}>
          No data found for dispatch id: <b>{dispatchId}</b>
        </div>
      )
    }

    return <ForceDirectedTree signalData={dispatchData.data} />
  }

  return (
    <div className="App">
      <div className="dispatch-search-row" style={{ display: "flex", gap: 20 }}>
        <Form
          style={{ width: "50%" }}
          form={form}
          id="dispatchForm"
          onFinish={handleFormSubmit}
        >
          <Form.Item label="Dispatch ID" name="dispatchId">
            <Input />
          </Form.Item>
        </Form>

        <Button
          disabled={dispatchData.isRefetching}
          form="dispatchForm"
          key="submit"
          htmlType="submit"
        >
          Search
        </Button>

        <ActivityFeedModal dispatchData={dispatchData.data} />
      </div>

      {DispatchDataLoader({ dispatchData, dispatchId })}
    </div>
  )
}
