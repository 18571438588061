export const colors = [
  "#FF5733", // Red
  "#33FF57", // Green
  "#3366FF", // Blue
  "#FF33E6", // Pink
  "#FF9633", // Orange
  "#33FF96", // Light Green
  "#9633FF", // Purple
  "#33B8FF", // Light Blue
  "#B833FF", // Lavender
  "#FFB833", // Amber
  "#33FFC1", // Mint
  "#C133FF", // Orchid
  "#FFC133", // Gold
  "#33FFEB", // Aqua
  "#EB33FF", // Magenta
  "#FFEB33", // Yellow
  "#336633", // Dark Green
  "#663366", // Plum
  "#336666", // Teal
  "#666633", // Olive
  "#663333", // Maroon
  "#336633", // Forest Green
  "#663300", // Brown
  "#FF3333", // Dark Red
  "#33FF33", // Lime
  "#3333FF", // Indigo
  "#FF3366", // Coral
  "#FFCC00", // Bright Yellow
  "#CC00FF", // Bright Purple
  "#00FFCC", // Bright Cyan
];
