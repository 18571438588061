import {
  BellFilled,
  BellOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons"
import { Button, message, Modal, Skeleton, Tag, Tooltip } from "antd"

import React, { useEffect, useState } from "react"
import "./SubscribablesList.scss"
import { NotificationSubscriptionBucket } from "../../../types/Subscription"
import { environmentType } from "../../../types/environtment"
import ResetNotificationsToDefault from "./ResetNotificationsToDefault"
import { PulseIcon } from "../../../VizznIcons/PulseIcon"
import { useUpdateSubscriptionV2 } from "../../../apis/subscriptions"

type FeedSubscriptionBucketWithDefault = NotificationSubscriptionBucket & {
  isDefault: boolean
}

type Props = {
  isDefault: boolean
  userId: string
  userFeedBuckets: { id: string }[]
  allEntityBuckets: NotificationSubscriptionBucket[]
  id: string
  userNotificationBuckets: { id: string }[]
  entityName: string
  isSubscriptionBell?: boolean
  isSubscribed?: boolean
  disabled?: boolean
  handleClick?: () => void
  handleRemoveSubscription?: () => void
  token: string | null
  env: environmentType
}

export default function UpdateActivityFeedNotification({
  isDefault,
  userId,
  userFeedBuckets,
  allEntityBuckets,
  id,
  userNotificationBuckets,
  entityName,
  isSubscriptionBell,
  isSubscribed,
  disabled,
  handleClick,
  handleRemoveSubscription,
  token,
  env,
}: Props) {
  const [isVisible, setIsVisible] = useState(false)
  const { mutateAsync: updateDefaultNotification } =
    useUpdateSubscriptionV2(userId)
  const [selectedFeedBuckets, setSelectedFeedBuckets] = useState<string[]>(
    userFeedBuckets.map((b) => b.id)
  )
  const [isLoading, setIsLoading] = useState(false)
  const [selectedNotificationBuckets, setSelectedNotificationBuckets] =
    useState<string[]>(userNotificationBuckets.map((b) => b.id))
  useEffect(() => {
    setSelectedFeedBuckets(userFeedBuckets.map((b) => b.id))
    setSelectedNotificationBuckets(userNotificationBuckets.map((b) => b.id))
  }, [isVisible, userFeedBuckets, userNotificationBuckets])

  const feedBuckets: FeedSubscriptionBucketWithDefault[] = allEntityBuckets.map(
    (bucket) => {
      return {
        ...bucket,
        isDefault:
          userFeedBuckets.some((b) => b.id === bucket.id) ||
          userNotificationBuckets.some((b) => b.id === bucket.id),
      }
    }
  )

  const handleUpdateNotifications = async ({
    notiBucketIds,
    feedBucketIds,
  }: {
    notiBucketIds?: string[]
    feedBucketIds?: string[]
  }) => {
    try {
      await updateDefaultNotification({
        id,
        isDefault: false,
        notiBucketIds: notiBucketIds,
        feedBucketIds: feedBucketIds,
        token: token,
        env,
      })
    } catch (error) {
      message.error("Failed to update feed notification")
    } finally {
      setIsVisible(false)
    }
  }

  const toggleSelectFeedBucket = (id: string) => {
    setSelectedFeedBuckets((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((bucketId) => bucketId !== id)
        : [...prevSelected, id]
    )
  }
  const toggleSelectNotificationBucket = (id: string) => {
    setSelectedNotificationBuckets((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((bucketId) => bucketId !== id)
        : [...prevSelected, id]
    )
  }

  return (
    <>
      <div>
        {!isSubscriptionBell ? (
          <Tooltip title={`Edit ${entityName}`}>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => setIsVisible(true)}
            ></Button>
          </Tooltip>
        ) : (
          <>
            <Tooltip title={isSubscribed ? "Unsubscribe" : "Subscribe"}>
              <Button
                onClick={async () => {
                  if (!isSubscribed) {
                    setIsLoading(true)
                    handleClick && (await handleClick())
                    setIsLoading(false)
                  }
                  setIsVisible(true)
                }}
                disabled={disabled}
                style={{ border: "none", background: "none" }}
              >
                {isSubscribed ? (
                  <BellFilled className="unsubscribed" />
                ) : (
                  <BellOutlined className="subscribed" />
                )}
              </Button>
            </Tooltip>
          </>
        )}
      </div>
      <Modal
        onCancel={() => setIsVisible(false)}
        visible={isVisible}
        width={800}
        title={
          isSubscriptionBell ? `Subscribe ${entityName}` : `Edit ${entityName}`
        }
        footer={null}
      >
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <Skeleton active />
          </div>
        ) : (
          <div id="Edit-subscription">
            <div style={{ overflowY: "auto" }}>
              {feedBuckets
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((b) => (
                  <div
                    key={b.id}
                    className="updateActivityFeedNotification"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "3px 0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color:
                          selectedNotificationBuckets.includes(b.id) ||
                          selectedFeedBuckets.includes(b.id)
                            ? "#19b1b4"
                            : "#000",
                      }}
                    >
                      {b.name}
                      {b.description && (
                        <Tooltip title={b.description}>
                          <InfoCircleOutlined
                            style={{ marginLeft: "5px", fontSize: "17px" }}
                          />
                        </Tooltip>
                      )}
                    </div>

                    <div style={{ display: "flex" }}>
                      <Tag
                        onClick={() => {
                          toggleSelectNotificationBucket(b.id)
                        }}
                        style={{
                          borderColor: selectedNotificationBuckets.includes(
                            b.id
                          )
                            ? "#19b1b4"
                            : "#afafaf",
                        }}
                        className="activity-feed-tag"
                      >
                        <strong
                          style={{
                            marginRight: "5px",
                            color: selectedNotificationBuckets.includes(b.id)
                              ? "#19b1b4"
                              : "#afafaf",
                          }}
                        >
                          User Notification
                        </strong>
                        <BellFilled
                          style={{
                            color: selectedNotificationBuckets.includes(b.id)
                              ? "#19b1b4"
                              : "#afafaf",
                          }}
                        />
                      </Tag>
                      <Tag
                        onClick={() => {
                          toggleSelectFeedBucket(b.id)
                        }}
                        style={{
                          borderColor: selectedFeedBuckets.includes(b.id)
                            ? "#19b1b4"
                            : "#afafaf",
                        }}
                        className="activity-feed-tag"
                      >
                        <strong
                          style={{
                            marginRight: "5px",
                            color: selectedFeedBuckets.includes(b.id)
                              ? "#19b1b4"
                              : "#afafaf",
                          }}
                        >
                          Activity Feed
                        </strong>
                        <PulseIcon
                          color={
                            selectedFeedBuckets.includes(b.id)
                              ? "#19b1b4"
                              : "#afafaf"
                          }
                          width="15px"
                        />
                      </Tag>
                    </div>
                  </div>
                ))}
            </div>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {isSubscriptionBell && isSubscribed && (
                <Button
                  onClick={() => {
                    handleRemoveSubscription && handleRemoveSubscription()
                    setIsVisible(false)
                  }}
                  danger
                >
                  Delete Subscription
                </Button>
              )}
              {(isSubscribed || !isSubscriptionBell) && (
                <div style={{ display: "flex" }}>
                  <ResetNotificationsToDefault
                    id={id}
                    userId={userId}
                    isDefault={isDefault}
                    entityName={entityName}
                    setIsVisible={setIsVisible}
                    token={token}
                    env={env}
                  />
                  <Button
                    type="primary"
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                      setIsVisible(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginLeft: "5px" }}
                    onClick={() =>
                      handleUpdateNotifications({
                        feedBucketIds: selectedFeedBuckets,
                        notiBucketIds: selectedNotificationBuckets,
                      })
                    }
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}
