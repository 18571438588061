import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { message } from "antd"
import axios from "axios"
import { environmentType } from "../types/environtment"
import {
  Preferences,
  Team,
  TeamMetrics,
  TeamRequest,
  TeamUpdateResquest,
  TeamsResponse,
  monthNames,
  TeamMetricsData,
  PartnershipMetricsResponse,
} from "../types/Team"
import { getEnvUrl } from "../utils/utils"
import { VizznResponse } from "../types/AccountApplication"
import {
  LogCountMetrics,
  TeamUsageMetric,
  TopTeamsAndUsersLogs,
} from "../types/UsageMetrics"

export const debouncedTeamFetch = async (body: {
  env: environmentType
  token: string | null
  searchTerm: string
}) => {
  if (!body.token) return
  const { data } = await axios.get(`${getEnvUrl(body.env)}/v1/teams/search`, {
    params: {
      searchTerm: body.searchTerm,
    },
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  })
  if (data) return data.data
}
const getTeam = async (body: {
  env: environmentType
  token: string | null
  id: string
}) => {
  if (!body.token) return
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/teams/${body.id}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) return data.data
}

export const useGetSingleTeam = (body: {
  env: environmentType
  token: string | null
  id: string
}) => {
  const response = useQuery<Team>(["team", body.id, body.env], () =>
    getTeam(body)
  )

  return response
}

const getTeams = async (body: {
  env: environmentType
  token: string | null
}) => {
  if (!body.token) return
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/teams`,

    {
      params: {
        includeArchived: true,
      },
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) return data.data
}

export const useGetTeams = (env: environmentType) => {
  const query = useQueryClient()
  return useMutation(getTeams, {
    onSuccess: (data) => {
      if (data) {
        query.setQueryData(["team-list", env], data)
        return data as Team[]
      }
    },
  })
}

export const useTeams = (
  body: { env: environmentType; token: string | null },
  teamId?: string
) => {
  const response = useQuery<Team[]>(["team-list", body.env, teamId], () =>
    getTeams(body)
  )

  return response
}

const createTeam = async (body: {
  env: environmentType
  token?: string | null
  payload: TeamRequest
}) => {
  if (!body.token) return
  const { data } = await axios.post(
    `${getEnvUrl(body.env)}/v1/teams`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) {
    message.success(`Team "${body.payload.name}" created`)
    return data.data
  }
}

export const useCreateTeam = () => {
  const queryClient = useQueryClient()
  return useMutation(createTeam, {
    onSuccess: async (data, params) => {
      if (!data) return
      const oldData = queryClient.getQueryData<TeamsResponse>([
        "team-list",
        params.env,
      ])
      if (oldData) {
        const records = [...oldData.records, data]
        const newData = { ...oldData, records }

        queryClient.setQueriesData(["team-list", params.env], () => newData)
      }
    },
  })
}

const archiveTeam = async (body: {
  env: environmentType
  token: string | null
  teamId: string
}) => {
  if (!body.token) return
  const { data } = await axios.patch(
    `${getEnvUrl(body.env)}/v1/teams/${body.teamId}/archive`,
    {},
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) return data.data
}

export const useArchiveTeam = () => {
  return useMutation(archiveTeam, {
    onSuccess: async (data, params) => {
      if (!data) return
      return data
    },
  })
}

export const updateTeam = async (body: {
  data: Partial<TeamUpdateResquest> & { id: string }
  env: environmentType
  token: string | null
}) => {
  try {
    const { data } = await axios.patch<VizznResponse<Team>>(
      `${getEnvUrl(body.env)}/v1/teams/${body.data.id}`,
      body.data,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    message.success(data.detail)
    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}

export const useUpdateTeam = (id: string, env: environmentType) => {
  const queryClient = useQueryClient()

  return useMutation(updateTeam, {
    onSuccess: async (team) => {
      if (!team) return
      queryClient.setQueryData<Team>(["team", id, env], () => team)
    },
  })
}

const getTeamPreferences = async (body: {
  id: string
  env: environmentType
  token: string | null
}) => {
  const { data } = await axios.get<VizznResponse<Preferences>>(
    `${getEnvUrl(body.env)}/v1/teams/${body.id}/preferences`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useTeamPreferences = (body: {
  id: string
  env: environmentType
  token: string | null
}) => {
  const response = useQuery<Preferences>(
    ["preferences", body.id, body.env],
    () => getTeamPreferences(body)
  )

  return response
}

const updateTeamPreferences = async (body: {
  data: Partial<Preferences> & { id: string }
  env: environmentType
  token: string | null
}) => {
  try {
    const { data } = await axios.patch<VizznResponse<Preferences>>(
      `${getEnvUrl(body.env)}/v1/teams/${body.data.id}/preferences`,
      body.data,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    message.success(data.detail)
    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}

export const useUpdateTeamPreferences = (id: string, env: environmentType) => {
  const queryClient = useQueryClient()

  return useMutation(updateTeamPreferences, {
    onSuccess: async (team) => {
      if (!team) return
      queryClient.setQueryData<Preferences>(
        ["preferences", id, env],
        () => team
      )
    },
  })
}

export const changeUserTeam = async (body: {
  env: environmentType
  token: string | null
  teamId: string
  userId?: string
  teamName?: string
}) => {
  const { data } = await axios.put(
    `${getEnvUrl(body.env)}/v1/users/${body.userId}/teams/${body.teamId}`,
    {
      teamId: body.teamId,
      userId: body.userId,
    },
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  message.success(`User team changed to ${body.teamName}(${body.teamId})`)
  if (data) return data.data
}

export const useChangeUserTeam = () => {
  return useMutation(changeUserTeam, {
    onSuccess: async (data, params) => {
      if (!data) return
      return data
    },
  })
}

const getTeamUsageMetrics = async (body: {
  id: string
  env: environmentType
  token: string | null
}) => {
  const { data } = await axios.get<VizznResponse<TeamUsageMetric[]>>(
    `${getEnvUrl(body.env)}/v1/storage-metrics/${body.id}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useGetTeamUsageMetrics = (body: {
  id: string
  env: environmentType
  token: string | null
}) => {
  const response = useQuery<TeamUsageMetric[]>(
    ["usage-metrics", body.id, body.env],
    () => getTeamUsageMetrics(body)
  )

  return response
}
const getTeamLogCount = async (body: {
  id: string
  env: environmentType
  token: string | null
}) => {
  const { data } = await axios.get<VizznResponse<LogCountMetrics>>(
    `${getEnvUrl(body.env)}/v1/log-count/${body.id}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useGetTeamLogCount = (body: {
  id: string
  env: environmentType
  token: string | null
}) => {
  const response = useQuery<LogCountMetrics>(
    ["log-count", body.id, body.env],
    () => getTeamLogCount(body)
  )

  return response
}
const getTeamsAndUsersLogCount = async (body: {
  env: environmentType
  token: string | null
}) => {
  const { data } = await axios.get<VizznResponse<TopTeamsAndUsersLogs>>(
    `${getEnvUrl(body.env)}/v1/top-log-count`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useGetTeamsAndUsersLogCount = (body: {
  env: environmentType
  token: string | null
}) => {
  const response = useQuery<TopTeamsAndUsersLogs>(
    ["top-log-count", body.env],
    () => getTeamsAndUsersLogCount(body)
  )

  return response
}

const getTeamMetrics = async (body: {
  env: environmentType
  token: string | null
}) => {
  const { data } = await axios.get<TeamMetrics>(
    `${getEnvUrl(body.env)}/v1/team-metrics`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  data.data.growth = data.data.growth.reverse().map((item) => ({
    ...item,
    month: monthNames[item.month],
  }))

  return data.data
}

export const useGetTeamMetrics = (body: {
  env: environmentType
  token: string | null
}) => {
  const response = useQuery<TeamMetricsData>(["team-metrics", body.env], () =>
    getTeamMetrics(body)
  )

  return response
}

const getPartnershipMetrics = async (body: {
  env: environmentType
  token: string | null
}) => {
  const { data } = await axios.get<VizznResponse<PartnershipMetricsResponse>>(
    `${getEnvUrl(body.env)}/v1/team-metrics/partnerships`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useGetPartnershipMetrics = (body: {
  env: environmentType
  token: string | null
}) => {
  const response = useQuery<PartnershipMetricsResponse>(
    ["property-metrics", body.env],
    () => getPartnershipMetrics(body)
  )

  return response
}
