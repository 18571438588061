import * as React from "react"

type Props = {
  color: string
  width: string
}
export const PulseIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M17.779 7.89c.16.58-.31 1.11-.913 1.11h-2.835c-.347 0-.67.178-.854.47l-1.438 2.283-2.307-6.104a1.01 1.01 0 0 0-1.798-.179L5.41 9H3.134c-.602 0-1.072-.53-.913-1.11C3.155 4.494 6.284 2 10 2s6.845 2.495 7.779 5.89m0 4.22c.16-.58-.31-1.11-.913-1.11H14.59l-2.224 3.53a1.01 1.01 0 0 1-1.798-.179L8.261 8.247 6.823 10.53a1.01 1.01 0 0 1-.854.47H3.134c-.602 0-1.072.53-.913 1.11C3.155 15.506 6.284 18 10 18s6.846-2.495 7.779-5.89"
      clipRule="evenodd"
    />
  </svg>
)
