import { get } from "lodash"
import { Contact } from "../../types/Contact"
import {
  DispatchRequestMainViewModel,
  EventRequest,
} from "../../types/DispatchRequests"
import { Equipment } from "../../types/Equipment"
import { UniversityArticle, UniversityVideo } from "../../types/university"
import { UserList } from "../../types/user"
import { DisplayData } from "../../types/shared"
import { LineItemsByExtendedByTeamId } from "../../types/MasterPriceItem"
import { UserNotification } from "../../types/user"
import { SubscriptionV2 } from "../../types/Subscription"

type SomeRecord =
  | Equipment
  | Contact
  | UniversityArticle
  | UniversityVideo
  | File
  | DisplayData
  | UserList
  | EventRequest
  | DispatchRequestMainViewModel
  | LineItemsByExtendedByTeamId
  | UserNotification
  | SubscriptionV2

export const hasValue = (
  record: SomeRecord,
  fields: string[],
  search?: string
): boolean => {
  // Why false?
  if (!search) return true

  // NOTE: these fields are hard to type, and if they are mis-typed,
  // they will not produce an error...
  // if the search is not producing a result, check your field names

  for (const field of fields) {
    // if AND blah
    // else if OR below
    let value = (get(record, field) || "").toString().toLowerCase()
    if (value.includes(search.toLowerCase())) return true
    if (
      Array.isArray(value) &&
      (value
        .map(({ name }) => name)
        .join(" ")
        .toLowerCase()
        .includes(search.toLowerCase()) ||
        value.join(" ").toLowerCase().includes(search.toLowerCase()))
    )
      return true
  }
  return false
}

export const hasTags = (tags: string[], labels: string[]): boolean => {
  if (!tags.length) return false

  // if AND blah
  // else if OR below
  for (const label of labels) {
    if (tags.includes(label)) return true
  }

  return false
}

// add type as this method is used by other entity
export const filterBySearchValueAndTag = <T extends SomeRecord>({
  tags = [],
  searchValue = "",
  dataArray,
  searchFields,
}: {
  tags?: string[]
  searchValue?: string
  dataArray: T[]
  searchFields: string[]
}): T[] => {
  const searchValues = searchValue ? [...tags, searchValue] : tags

  if (!searchValues.length) return dataArray
  return dataArray.filter(
    (record) =>
      !searchValues.length ||
      searchValues.every((searchValue) =>
        hasValue(record, searchFields, searchValue)
      )
  )
}
export const filterBySearchValue = <T extends SomeRecord>({
  searchValue = "",
  dataArray,
  searchFields,
}: {
  searchValue?: string
  dataArray: T[]
  searchFields: string[]
}): T[] => {
  if (!searchValue) return dataArray
  return dataArray.filter((record) =>
    hasValue(record, searchFields, searchValue)
  )
}

export const filterLineItemsBySearchValueAndTag = ({
  searchValue = "",
  dataArray,
  searchFields,
}: {
  searchValue?: string
  dataArray: LineItemsByExtendedByTeamId[]
  searchFields: string[]
}) => {
  if (!searchValue) return dataArray
  return dataArray.filter((record) => {
    const lineItem = record.lineItem
    const supplierQuotesForLineItem = record.supplierQuotesForLineItem
    const supplierQuotes = supplierQuotesForLineItem.map(
      ({ supplier }) => supplier
    )
    const lineItemAndSupplierQuotes = [...supplierQuotes, lineItem]
    return lineItemAndSupplierQuotes.some((record) =>
      hasValue(record, searchFields, searchValue)
    )
  })
}

export const isStringContainingSearchValues = (
  evaluatedString: string,
  tags: string[],
  searchValue?: string
) => {
  const searchValues = searchValue ? [...tags, searchValue] : tags
  return searchValues.every((searchValue) =>
    evaluatedString.toLowerCase().includes(searchValue.toLowerCase())
  )
}
