import { Col, Divider, Row } from "antd"
import { ReactNode } from "react"

type Props = {
  name: string
  content: string | ReactNode
  editButton?: ReactNode
}

export const RowContent = ({ name, content, editButton }: Props) => {
  return (
    <>
      <Row gutter={8} align={"middle"}>
        <Col>{name}:</Col>
        <Col
          style={{
            minHeight: "32px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {typeof content === "string" ? <b>{content}</b> : content}
          {editButton && editButton}
        </Col>
      </Row>
      <Divider dashed style={{ margin: "8px 0px" }} />
    </>
  )
}
