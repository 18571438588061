import React from "react"
import { useParams } from "react-router-dom"
import { useAuthContext } from "../../context/AuthContext"
import { useGetCompanyApplicationDetail } from "../../apis/SelfApplications"
import { Card, List, Typography, Divider, Space, Row, Col } from "antd"
import { formatToDateAndHour } from "../Utils/TimeUtils"

const { Title, Text } = Typography

function CompanyApplicationDetail() {
  const { id } = useParams<{ id: string }>()
  const { env, token } = useAuthContext()

  const {
    data: applicationDetail,
    isLoading,
    error,
  } = useGetCompanyApplicationDetail(env, token[env], id || "")

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error: {(error as Error).message}</div>
  if (!applicationDetail) return <div>No application details found</div>

  const renderRow = (label: string, value: React.ReactNode) => (
    <>
      <Space>
        <Text strong>{label}:</Text>
        <Text>{value}</Text>
      </Space>
      <Divider style={{ margin: "8px 0" }} />
    </>
  )

  return (
    <div>
      <Title level={1}>Company Application Detail</Title>
      <Card title="Overview" style={{ marginBottom: 16 }}>
        {renderRow("ID", applicationDetail.id)}
        {renderRow("Type", applicationDetail.type)}
        {renderRow("Status", applicationDetail.status.name)}
        {renderRow("Processing Step", applicationDetail.statusProcessing)}
        <Text strong>Status Changes</Text>
        <List
          dataSource={applicationDetail.statusChanges}
          renderItem={(change, index) => (
            <List.Item key={index}>
              {change.name} - {formatToDateAndHour(change.at)}
            </List.Item>
          )}
        />
      </Card>

      <Card title="Owner" style={{ marginBottom: 16 }}>
        <Row>
          <Col span={12}>
            {renderRow("Email", applicationDetail.ownerEmail)}
            {renderRow("First Name", applicationDetail.ownerFirstName)}
            {renderRow("Last Name", applicationDetail.ownerLastName)}
            {renderRow("Phone", applicationDetail.ownerPhone)}
            {renderRow(
              "Email Verified",
              applicationDetail.ownerEmailVerified ? "Yes" : "No"
            )}
          </Col>
          {applicationDetail.owner && (
            <Col span={12}>
              <div
                style={{ borderLeft: "1px solid #f0f0f0", paddingLeft: "16px" }}
              >
                <Text strong>Owner Details:</Text>
                <br />
                {renderRow("ID", applicationDetail.owner.id)}
                {renderRow("Name", applicationDetail.owner.name)}
              </div>
            </Col>
          )}
        </Row>
      </Card>

      <Card title="Company" style={{ marginBottom: 16 }}>
        <Row>
          <Col span={12}>
            {renderRow("Name", applicationDetail.orgName)}
            {renderRow("Address", applicationDetail.orgAddress)}
            {renderRow("Type", applicationDetail.orgType)}
            {renderRow("Preferred Language", applicationDetail.orgPrefLang)}
            {renderRow("Timezone", applicationDetail.orgTimezone)}
          </Col>
          {applicationDetail.org && (
            <Col span={12}>
              <div
                style={{ borderLeft: "1px solid #f0f0f0", paddingLeft: "16px" }}
              >
                <Text strong>Completed Company:</Text>
                <br />
                {renderRow("ID", applicationDetail.org.id)}
                {renderRow("Name", applicationDetail.org.name)}
              </div>
            </Col>
          )}
        </Row>
      </Card>

      <Card title="Employees" style={{ marginBottom: 16 }}>
        <List
          dataSource={applicationDetail.itemEmployees}
          renderItem={(employee, index) => (
            <List.Item key={index}>
              <Row style={{ width: "100%" }}>
                <Col span={12}>{renderRow("Email", employee.email)}</Col>
                <Col span={12}>
                  <Text strong>Roles: </Text>
                  <Text>{employee.roleNames.join(", ")}</Text>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </Card>

      <Card title="Partners" style={{ marginBottom: 16 }}>
        <List
          dataSource={applicationDetail.itemPartners}
          renderItem={(partner, index) => (
            <List.Item key={index}>
              <Row style={{ width: "100%" }}>
                <Col span={12}>
                  {renderRow("Partner", partner.partnerOrg.name)}
                </Col>
                <Col span={12}>
                  {renderRow(
                    "Pre-Approved",
                    partner.isPreApproved ? "Yes" : "No"
                  )}
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </Card>

      <Card title="Trucks">
        <List
          dataSource={applicationDetail.itemTrucks}
          renderItem={(truck, index) => (
            <List.Item key={index}>
              <Row style={{ width: "100%" }}>
                <Col span={12}>{renderRow("Type", truck.type)}</Col>
                <Col span={12}>{renderRow("Quantity", truck.quantity)}</Col>
              </Row>
            </List.Item>
          )}
        />
      </Card>
    </div>
  )
}

export default CompanyApplicationDetail
