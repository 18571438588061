import { Button, Card, Col, Input, Row, Spin } from "antd"
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { useAuthContext } from "../../../context/AuthContext"
import { GetError } from "../../../widgets/GetError"

import { format } from "date-fns"
import { useMemo, useState } from "react"
import { useGetTeamLogCount } from "../../../apis/teams"
import BarChartCard from "../../../charts/BarChartCard"
import { SeriesData } from "../../../types/UsageScores"
import { colors } from "../../../utils/constants"

type Props = {
  id: string
}

export const TeamLogCounts = ({ id }: Props) => {
  const { env, token } = useAuthContext()
  const logCounts = useGetTeamLogCount({ id, env, token: token[env] })
  const [simpleView, setSimpleView] = useState(true)
  const [searchTerm, setSearchTerm] = useState<string>("")

  const filteredTotal = useMemo(
    () =>
      logCounts?.data?.totalCount?.filter((t) =>
        t.useCase.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [logCounts?.data, searchTerm]
  )
  const filteredLogs = useMemo(
    () =>
      logCounts?.data?.logCount?.filter(
        (t) =>
          t.useCase.toLowerCase().includes(searchTerm.toLowerCase()) ||
          t.service.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [logCounts?.data, searchTerm]
  )

  const series = useMemo(
    () =>
      filteredLogs
        ?.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))
        .map((e) => ({
          count: e.count,
          service: `${e.service} [${e.useCase}]`,
          timestamp: format(new Date(e.timestamp), "yyyy, MMM, dd"),
        })),
    [filteredLogs]
  )

  const groupedData: SeriesData[] = useMemo(
    () =>
      series?.reduce((acc: any, cur: any) => {
        const serviceIndex = acc.findIndex(
          (s: any) => s.service === cur.service
        )
        if (serviceIndex === -1) {
          acc.push({
            service: cur.service,
            data: [{ timestamp: cur.timestamp, count: cur.count }],
          })
        } else {
          acc[serviceIndex].data.push({
            timestamp: cur.timestamp,
            count: cur.count,
          })
        }
        return acc
      }, []),
    [series]
  )

  if (logCounts.isLoading) return <Spin />
  if (logCounts.error || !logCounts.data)
    return <GetError reFetch={logCounts.refetch} />

  return (
    <Card
      title={`Log metrics: ${simpleView ? "Total" : "Detailed"}`}
      style={{ height: "100%" }}
      extra={
        <Button onClick={() => setSimpleView(!simpleView)}>
          {simpleView ? "Detailed view" : "Simple view"}
        </Button>
      }
    >
      <Row>
        <Col flex="auto">
          <Input
            placeholder="Search by use case or service"
            onChange={(e) => {
              setSearchTerm(e.target.value || "")
            }}
            style={{ width: "100%", marginBottom: 10 }}
          />
        </Col>
      </Row>
      {simpleView ? (
        <BarChartCard
          id={id}
          data={filteredTotal ?? []}
          dataKey="count"
          title="Total log counts"
          xAxisDataKey="useCase"
          hideLabels
        />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart width={500} height={300}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="timestamp"
              type="category"
              allowDuplicatedCategory={false}
            />
            <YAxis dataKey="count" />
            <Tooltip />
            {groupedData.map((s) => (
              <Line
                dataKey="count"
                data={s.data}
                name={s.service}
                key={s.service + Math.random()}
                stroke={colors[Math.floor(Math.random() * colors.length)]}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </Card>
  )
}
