import {
  Button,
  Form,
  Input,
  InputNumber,
  Switch,
  Tag,
  Tooltip,
  message,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import { useState } from "react";
import { useCreateUniversityVideo } from "../../apis/university";
import { useAuthContext } from "../../context/AuthContext";
import { v4 as uuidv4 } from "uuid";

type Values = {
  name: string;
  isFeatured: boolean;
  path: string;
  order: string;
  length: number;
  description?: string;
};

export const AddVideoModal = () => {
  const { env, token } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { mutateAsync: addVideo } = useCreateUniversityVideo();
  const [categories, setCategories] = useState<string[]>([]);
  const [newCategory, setNewCategory] = useState<string>("");
  const [featuredCategories, setFeaturedCategories] = useState<string[]>([]);
  const [newFeaturedCategory, setNewFeaturedCategory] = useState<string>("");

  const removeTag = (value: string) => {
    setCategories((prev) => prev.filter((cat) => cat !== value));
  };
  const addTag = (value: string) => {
    setCategories((prev) => [...prev, value]);

    setNewCategory("");
  };
  const removeFeatureTag = (value: string) => {
    setFeaturedCategories((prev) => prev.filter((cat) => cat !== value));
  };
  const addFeatureTag = (value: string) => {
    setFeaturedCategories((prev) => [...prev, value]);
    setNewFeaturedCategory("");
  };

  const handleFinish = async (values: Values) => {
    await addVideo({
      payload: {
        id: uuidv4(),
        name: values.name,
        isFeatured: values.isFeatured || false,
        categories,
        featuredCategories,
        path: values.path,
        order: values.order,
        length: values.length,
        description: values.description || "",
      },

      env,
      token: token[env],
    });
    message.success("Video Created");
    setVisible(false);
  };
  return (
    <>
      <Button onClick={() => setVisible(true)} type="primary">
        Add New Video
      </Button>
      <Modal
        title={"Add New Video"}
        open={visible}
        onCancel={() => setVisible(false)}
        onOk={form.submit}
      >
        <Form form={form} onFinish={handleFinish}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input the name!" }]}
          >
            <Input />
          </Form.Item>
          <Tooltip
            title="For Vimeo videos, the format is https://player.vimeo.com/video/#"
            placement="leftTop"
          >
            <Form.Item
              label="Video Url"
              name="path"
              rules={[{ required: true, message: "Please add URl path" }]}
            >
              <Input />
            </Form.Item>
          </Tooltip>
          <Form.Item label="Categories">
            <div style={{ marginBottom: 8 }}>
              {categories.map((c) => (
                <Tag key={c} closable onClose={() => removeTag(c)}>
                  {c}
                </Tag>
              ))}
            </div>
            <Input
              placeholder="Press enter to add a new tag or backspace to remove them"
              onKeyPress={(e) => {
                if (e.key === "Enter") addTag(newCategory);
              }}
              onKeyDown={(e) => {
                if (e.key === "Backspace" && !newCategory && categories.length)
                  removeTag(categories[categories.length - 1]);
              }}
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Feature Categories">
            <div style={{ marginBottom: 8 }}>
              {featuredCategories.map((c) => (
                <Tag key={c} closable onClose={() => removeTag(c)}>
                  {c}
                </Tag>
              ))}
            </div>
            <Input
              placeholder="Press enter to add a new tag or backspace to remove them"
              onKeyPress={(e) => {
                if (e.key === "Enter") addFeatureTag(newFeaturedCategory);
              }}
              onKeyDown={(e) => {
                if (
                  e.key === "Backspace" &&
                  !newFeaturedCategory &&
                  featuredCategories.length
                )
                  removeFeatureTag(
                    featuredCategories[featuredCategories.length - 1]
                  );
              }}
              value={newFeaturedCategory}
              onChange={(e) => setNewFeaturedCategory(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
          <Tooltip
            title="The order starts with 0.1 and on "
            placement="leftTop"
          >
            <Form.Item label="Order" name="order">
              <Input />
            </Form.Item>
          </Tooltip>
          <Tooltip title="Time is measured in seconds" placement="leftTop">
            <Form.Item label="Length" name="length">
              <InputNumber />
            </Form.Item>
          </Tooltip>
          <Form.Item label="Featured" name="isFeatured">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
