export function isVersionHigher(
  nextVersion: string,
  previousVersion: string
): boolean {
  // Split version numbers into components
  const nextComponents: number[] = nextVersion.split(".").map(Number)
  const previousComponents: number[] = previousVersion.split(".").map(Number)

  // Compare each component
  for (
    let i = 0;
    i < Math.max(nextComponents.length, previousComponents.length);
    i++
  ) {
    const current: number = nextComponents[i] || 0
    const previous: number = previousComponents[i] || 0

    if (current > previous) {
      return true //  Previous version is higher
    } else if (current < previous) {
      return false // Current version is higher
    }
    // If both components are equal, continue to the next component
  }

  // If all components are equal, versions are the same
  return false
}
