import { useEffect, useRef } from "react"

function useDebounceFunction<T extends (...args: any[]) => any>(
  func: T,
  delay: number
) {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const debouncedFunction = (...args: Parameters<T>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      func(...args)
    }, delay)
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return debouncedFunction as T
}

export default useDebounceFunction
