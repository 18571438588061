import { Typography } from "antd"

export const LoadTypesActivityFeed = ({
  data,
}: {
  data: { value?: string[] }
}) => {
  return (
    <Typography.Text strong>
      {!data || !data.value?.length ? "empty" : data.value.join(", ")}
    </Typography.Text>
  )
}
