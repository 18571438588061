import { Button, Modal, Card, Spin, theme } from "antd"
import { useState } from "react"
import { ActivityFeedData, DispatchEvent } from "../../types/DispatchExplorer"
import {
  PlusOutlined,
  CloseOutlined,
  CheckOutlined,
  CaretRightOutlined,
  EditFilled,
} from "@ant-design/icons"
import { useGetActivityFeedData } from "../../apis/DispatchExplorer"
import { ArchivedIcon, UnarchivedIcon } from "../VizznIcons"

import { Link } from "react-router-dom"
import Markdown from "react-markdown"
import { useAuthContext } from "../../context/AuthContext"
import { MarkDownMessage, markdownRouter } from "./MarkDownMessage"

export const ActivityFeedModal = ({
  dispatchData,
}: {
  dispatchData: DispatchEvent | undefined
}) => {
  const [open, setOpen] = useState(false)
  const { env, token } = useAuthContext()
  const { token: themeToken } = theme.useToken()

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const activityFeed = useGetActivityFeedData({
    env: env,
    token: token[env],
    dispatchData: dispatchData,
  })

  const ActivityFeedDataLoader = () => {
    if (activityFeed.isFetching) {
      return <Spin />
    }

    if (activityFeed.isError || !activityFeed.data) {
      return (
        <div style={{ color: themeToken.colorText }}>
          Error loading activity feed
        </div>
      )
    }

    if (activityFeed.data.length === 0) {
      return <div>No activity feed available</div>
    }

    return activityFeed.data
      .sort(
        (a: ActivityFeedData, b: ActivityFeedData) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .map((event: ActivityFeedData) => (
        <div>
          <Card
            title={event.node.team.name}
            extra={
              <div style={{ display: "flex", gap: "20px" }}>
                {new Date(event.createdAt).toLocaleString()}
                {getIcon(event.type)}
              </div>
            }
            style={{ width: "100%", border: `1px solid ${event.node.color}` }}
            styles={{
              body: {
                padding: `0 24px`,
              },
            }}
          >
            <div>
              {event.entityType && (
                <div>
                  [
                  {entityType[
                    event.entityType as keyof typeof entityType
                  ].title.toUpperCase()}
                  ]{" "}
                  <Link
                    className="markdown-link"
                    to={`${entityType[event.entityType as keyof typeof entityType].path}${event.entityId}`}
                  >
                    <span>{event.entityName}</span>
                  </Link>
                </div>
              )}
              <MarkDownMessage message={event} />

              {!!event.subTexts &&
                event.subTexts.length > 0 &&
                event.subTexts.map((text) => (
                  <div key={text} style={{ marginLeft: 16 }}>
                    <small>
                      <Markdown
                        className="markdown"
                        components={markdownRouter(event) as any}
                      >
                        {text}
                      </Markdown>
                    </small>
                    <hr />
                  </div>
                ))}
            </div>
          </Card>
        </div>
      ))
  }

  const getIcon = (val: boolean | string) => {
    switch (val) {
      case "archived":
        return <ArchivedIcon className="circle-icon archive-icon" />
      case "unarchived":
        return <UnarchivedIcon className="circle-icon unarchive-icon" />
      case "created":
        return <PlusOutlined className="circle-icon plus-icon" />
      case "deleted":
        return <CloseOutlined className="circle-icon minus-icon" />
      case "completed":
        return <CheckOutlined className="circle-icon completed-icon" />
      case "started":
        return <CaretRightOutlined className="circle-icon start-icon" />
      case "updated":
      default:
        return <EditFilled className="circle-icon" />
    }
  }

  const entityType = {
    crew: { title: "Crew", path: "/crews/" },
    equipment: { title: "Equipment", path: "/equipment/" },
    externalPerson: { title: "External Person", path: "/contacts/" },
    site: { title: "Site", path: "/sites/" },
    externalSite: { title: "External Site", path: "/external-sites/" },
    dispatch: { title: "Dispatch", path: "/dispatches/" },
    trailer: { title: "Trailer", path: "/trailers/" },
    truck: { title: "Truck", path: "/trucks/" },
    truckConfiguration: {
      title: "Truck Configuration",
      path: "/truck-configurations/",
    },
    trailerCombination: {
      title: "Trailer Combination",
      path: "/trailer-combinations/",
    },
    externalCompany: { title: "External Company", path: "/partners/" },
    dispatchResource: {
      title: "Dispatch Resource",
      path: "/dispatch-resources/",
    },
  }

  return (
    <>
      <Button onClick={() => handleOpenModal()} type="primary">
        Activity Feed
      </Button>
      {open && (
        <Modal
          title="Activity Feed"
          footer={null}
          style={{
            top: "20px",
            right: "20px",
            position: "absolute",
          }}
          open={open}
          onCancel={handleCloseModal}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {ActivityFeedDataLoader()}
          </div>
        </Modal>
      )}
    </>
  )
}
