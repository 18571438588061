import { ConfigProvider, Layout, theme } from "antd"
import { AnimatePresence } from "framer-motion"
import { BrowserRouter } from "react-router-dom"
import { SideNav } from "./components/SideNav"
import { useAuthContext } from "./context/AuthContext"
import LayoutHeader from "./components/common/LayoutHeader"

function App() {
  const { theme: themeName } = useAuthContext()

  const lightTheme = {
    colorPrimary: "#424bd1",
    colorGraphBg: "#f5f5f5",
    colorText: "#000",
  }

  const darkTheme = {
    colorPrimary: "#424bd1",
    colorText: "#fff",
    colorBgBase: "#151525",
    colorGraphBg: "#151525",
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <ConfigProvider
        theme={{
          token: themeName === "dark" ? darkTheme : lightTheme,
          algorithm:
            themeName === "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm,
        }}
      >
        <BrowserRouter>
          <Layout>
            <LayoutHeader />
            <SideNav />
          </Layout>
        </BrowserRouter>
      </ConfigProvider>
    </AnimatePresence>
  )
}

export default App
