import { Col, Form, Modal, Row, Select, Tag } from "antd"
import { Dispatch, SetStateAction } from "react"
import { useTeams } from "../../apis/teams"
import { useAuthContext } from "../../context/AuthContext"
import { GetError } from "../../widgets/GetError"
import { useChangeUserTeam } from "../../apis/user"
import { SearchOutlined } from "@ant-design/icons"

type Props = {
  modalVisible: true
  setModalVisible: Dispatch<SetStateAction<boolean>>
  currentTeam: string
  userId: string
}

export const ChangeTeamModal = ({
  modalVisible,
  setModalVisible,
  currentTeam,
  userId,
}: Props) => {
  const { env, token } = useAuthContext()
  const teams = useTeams({ env, token: token[env] }, currentTeam)
  const { mutateAsync: changeUserTeam } = useChangeUserTeam(userId, env)

  if (teams.error) return <GetError reFetch={teams.refetch} />

  return (
    <Modal
      title="Change User's Team"
      open={modalVisible}
      footer={null}
      onCancel={() => setModalVisible(false)}
    >
      <Form>
        <Form.Item initialValue={currentTeam}>
          <Select
            placeholder="Type at least 3 characters to search"
            filterOption={(input, option) =>
              option?.name.toLowerCase().includes(input.toLowerCase())
            }
            suffixIcon={<SearchOutlined />}
            showSearch
            loading={teams.isLoading}
            onChange={async (value) => {
              const res = await changeUserTeam({
                userId,
                teamId: value,
                env,
                token: token[env],
              })
              if (res) setModalVisible(false)
            }}
          >
            {teams.data?.map((team) => (
              <Select.Option key={team.id} value={team.id} name={team.name}>
                <Row align="middle" gutter={8}>
                  <Col>{team.name}</Col>
                  <Col>
                    <Tag>{team.status}</Tag>
                  </Col>
                </Row>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
