import { Col, Row } from "antd"
import { useGetApp } from "../../apis/DeploymentStatus"
import { useAuthContext } from "../../context/AuthContext"
import UpdateWepAppVersionModal from "./UpdateWepAppVersionModal/UpdateWepAppVersionModal"

export default function DeploymentStatus() {
  const { env, token } = useAuthContext()
  const { data, isLoading } = useGetApp({ env, token: token[env] })

  if (isLoading) return <div>Loading...</div>
  if (!data) return <div>Error</div>

  return (
    <>
      <Row gutter={[16, 16]} align="middle">
        <Col>
          <div>Web App Version:</div>
        </Col>
        <Col>
          <strong
            style={{
              fontSize: "1.2rem",
            }}
          >
            {data.version}
          </strong>
        </Col>
        <Col>
          <UpdateWepAppVersionModal currentVersion={data.version} />
        </Col>
      </Row>
    </>
  )
}
