import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { VizznResponse } from "../../types/Api"
import { environmentType } from "../../types/environtment"
import { getEnvUrl } from "../../utils/utils"

const getUsersInEnterprise = async (body: {
  env: environmentType
  token: string | null
  enterpriseId: string
}) => {
  if (!body.token) return

  const { data } = await axios.get<VizznResponse<any[]>>( // Figure out the type for this
    `${getEnvUrl(body.env)}/v1/users`,
    {
      params: {
        teamId: body.enterpriseId,
        includeSysAdmins: true,
      },
      headers: { Authorization: `Bearer ${body.token}` },
    }
  )
  return data.data
}

export const useGetUsersInEnterprise = (body: {
  env: environmentType
  token: string | null
  enterpriseId: string
  isOpen: boolean
}) => {
  return useQuery({
    queryKey: ["users-in-enterprise", body.enterpriseId],
    queryFn: () => getUsersInEnterprise(body),
    enabled: body.isOpen,
  })
}
