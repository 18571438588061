import { Typography } from "antd"
import { ReactComponent as CalendarIcon } from "../../../../../assets/sidenav-icons/calendar.svg"
import { ActivityFeedsPropData } from "../../../../../types/DispatchEvent"
export const TimeActivityFeed = ({ data }: { data: ActivityFeedsPropData }) => {
  return (
    <strong>
      <CalendarIcon className="activity-feed-icon" />{" "}
      <Typography.Text>{data.value?.toString()}</Typography.Text>
    </strong>
  )
}
