import { Typography } from "antd"
import { ActivityFeedsPropData } from "../../../../../types/DispatchEvent"

export const StatusActivityFeed = ({
  data,
}: {
  data: ActivityFeedsPropData
}) => {
  return (
    <>
      <Typography.Text>
        {data.value instanceof Date ? data.value.toLocaleString() : data.value}
      </Typography.Text>
    </>
  )
}
