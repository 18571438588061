import { UsergroupAddOutlined } from "@ant-design/icons"
import { Button, Form, Input, Select, Modal } from "antd"
import { useState } from "react"
import { useCreateBucket } from "../../../apis/notifications"
import { useAuthContext } from "../../../context/AuthContext"
import { NotifiableEntityType } from "../../../types/notifications"
import { entityType /*, jobRoles, systemRoles*/ } from "../constant"

export const NewBucketModal = () => {
  const [open, setOpen] = useState(false)
  const [form] = Form.useForm()
  const { mutateAsync: createBucket } = useCreateBucket()
  const { env, token } = useAuthContext()
  const [creating, setCreating] = useState(false)
  const { TextArea } = Input
  type Values = {
    entityType: NotifiableEntityType[]
    name: string
    description?: string
    roles: string[]
  }

  const handleCreateBucket = async () => {
    const values: Values = form.getFieldsValue()
    const hasError = await form.validateFields()
    if (Array.isArray(hasError)) return

    values.entityType.forEach(async (entityType) => {
      const payload = {
        entityType: entityType,
        name: values.name.trim(),
        roles: values.roles,
        description: values.description?.trim(),
        isPublished: false,
      }

      setCreating(true)
      if (env === "dev") {
        await createBucket({ env: "dev", token: token[env], payload })
      }
      if (env === "prod") {
        await createBucket({ env: "prod", token: token["prod"], payload })
        await createBucket({ env: "dev", token: token["dev"], payload })
      }
    })
    handleCloseModal()
  }

  const handleCloseModal = () => {
    form.resetFields(["name", "description"])
    setCreating(false)
    setOpen(false)
  }
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        icon={<UsergroupAddOutlined />}
        type="primary"
      >
        Create New Bucket
      </Button>
      {open && (
        <Modal
          title="New Bucket"
          open={open}
          onCancel={handleCloseModal}
          onOk={() => handleCreateBucket()}
          okButtonProps={{ loading: creating }}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="entityType"
              label="Entity Type"
              rules={[{ required: true, message: "Please select entity type" }]}
            >
              <Select mode="multiple">
                {entityType.map((entity) => (
                  <Select.Option key={entity.id} value={entity.id}>
                    {entity.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="name"
              label="Bucket Name"
              rules={[{ required: true, message: "Please input bucket name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <TextArea rows={6} />
            </Form.Item>
            {/* <Form.Item
              name="roles"
              label="User Roles"
              rules={[
                {
                  required: true,
                  message:
                    "Please select roles of users who can subscribe to this bucket",
                },
              ]}
            >
              <Select mode="multiple">
                {systemRoles.map((role) => (
                  <Select.Option value={role.id}>{role.name}</Select.Option>
                ))}
                {jobRoles.map((role) => (
                  <Select.Option value={role.id}>{role.name}</Select.Option>
                ))}
              </Select>
            </Form.Item> */}
          </Form>
        </Modal>
      )}
    </>
  )
}
