import { get } from "lodash";

export const hasValue = (
  record: any,
  fields: string[],
  search?: string
): boolean => {
  // Why false?
  if (!search) return true;

  // NOTE: these fields are hard to type, and if they are mis-typed,
  // they will not produce an error...
  // if the search is not producing a result, check your field names

  for (const field of fields) {
    // if AND blah
    // else if OR below
    const value = get(record, field);
    if (
      typeof value === "string" &&
      value?.toLowerCase()?.includes(search.toLowerCase())
    )
      return true;
    if (
      Array.isArray(value) &&
      (value
        .map(({ name }) => name)
        .join(" ")
        .toLowerCase()
        .includes(search.toLowerCase()) ||
        value.join(" ").toLowerCase().includes(search.toLowerCase()))
    )
      return true;
  }
  return false;
};

export const filterBySearchValueAndTag = <T extends any>({
  tags = [],
  searchValue = "",
  dataArray,
  searchFields,
}: {
  tags?: string[];
  searchValue?: string;
  dataArray: T[];
  searchFields: string[];
}): T[] => {
  const searchValues = searchValue ? [...tags, searchValue] : tags;
  if (!searchValues.length) return dataArray;
  return dataArray.filter(
    (record) =>
      !searchValues.length ||
      searchValues.every((searchValue) =>
        hasValue(record, searchFields, searchValue)
      )
  );
};
export const filterBySearchValue = <T extends any>({
  searchValue = "",
  dataArray,
  searchFields,
}: {
  searchValue?: string;
  dataArray: T[];
  searchFields: string[];
}): T[] => {
  if (!searchValue) return dataArray;
  return dataArray.filter((record) =>
    hasValue(record, searchFields, searchValue)
  );
};
