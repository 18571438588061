import BarChartCard from "../../../charts/BarChartCard"
import { TotalCount } from "../../../types/UsageMetrics"
import { getLastMonthName } from "./getLastMonthName"
type Props = { logCount: TotalCount[] }
export const TopUseCaseLogCount = ({ logCount }: Props) => {
  return (
    <BarChartCard
      data={logCount}
      title={`Log count: Use cases - ${getLastMonthName()}`}
      dataKey="count"
      xAxisDataKey="useCase"
      height={450}
    />
  )
}
