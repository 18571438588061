import { UseQueryResult } from "@tanstack/react-query";
import { Button, Card, Col, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { environmentType } from "../../types/environtment";
import { FeatureFlagResponse } from "../../types/featureFlags";
import { GetError } from "../../widgets/GetError";

type Props = {
  environment: environmentType;
  query: UseQueryResult<FeatureFlagResponse, unknown>;
};

export const LoginWidget = ({ environment, query }: Props) => {
  const { user, handleSetEnv, setRedirect } = useAuthContext();
  const navigate = useNavigate();
  const getTitleText = (
    query: UseQueryResult<FeatureFlagResponse, unknown>,
    env: environmentType
  ) => {
    if (query.isLoading && query.fetchStatus !== "idle")
      return `Loading ${env} data...`;
    if (query.error) return `Error loading ${env} data`;
    if (!user[env]) return `You must logged in ${env}`;
  };
  return (
    <Col>
      <Card title={getTitleText(query, environment)}>
        {!!query.error && <GetError reFetch={query.refetch} />}
        {query.isLoading && query.fetchStatus !== "idle" && (
          <Spin size="large" />
        )}
        {!user[environment] && (
          <Button
            type="primary"
            onClick={() => {
              handleSetEnv(environment);
              setRedirect("/feature-flags");
              navigate("/login");
            }}
          >
            Log in
          </Button>
        )}
      </Card>
    </Col>
  );
};
