import { Card, Table } from "antd"
import { useMemo } from "react"
import { useAuthContext } from "../../context/AuthContext"
import { useGetUserLocations } from "../../apis/user"
import { GetError } from "../../widgets/GetError"
import { UserLocation } from "../../types/Location"
import { ColumnsType } from "antd/lib/table"
import { formatToDateAndHour } from "../Utils/TimeUtils"
type Props = {
  id: string
}

export const UserLocations = ({ id }: Props) => {
  const { env, token } = useAuthContext()
  const locations = useGetUserLocations({ env, token: token[env], userId: id })

  const columns: ColumnsType<UserLocation> = useMemo(
    () => [
      {
        title: "Create At",

        width: 150,
        render: (_, e) => formatToDateAndHour(e.createdAt),
        sorter: (a, b) => (a.createdAt < b.createdAt ? -1 : 1),
        key: "createdAt",
      },

      {
        title: "Latitude",

        width: 150,
        render: (_, e) => e.latitude,
        sorter: (a, b) => (a.latitude < b.latitude ? -1 : 1),
        key: "latitude",
      },
      {
        title: "Longitude",

        width: 150,
        render: (_, e) => e.longitude,
        sorter: (a, b) => (a.longitude < b.longitude ? -1 : 1),
        key: "longitude",
      },
      {
        title: "Accuracy",

        width: 150,
        render: (_, e) => e.accuracy,
        sorter: (a, b) => (a.accuracy < b.accuracy ? -1 : 1),
        key: "accuracy",
      },
      {
        title: "OS",
        align: "left",
        width: 150,
        render: (_, e) => e.os,
        sorter: (a, b) =>
          a.os.toLocaleLowerCase() < b.os.toLocaleLowerCase() ? -1 : 1,
        key: "os",
      },
    ],
    []
  )

  if (locations.error || !locations.data)
    return <GetError reFetch={locations.refetch} />
  return (
    <Card title="User Locations">
      <Table
        scroll={{ y: "60vh" }}
        dataSource={locations.data.slice(0, 10)}
        columns={columns}
        pagination={false}
        rowKey="id"
        loading={locations.isLoading}
      />
    </Card>
  )
}
