import { Card, Spin } from "antd"
// import { Line } from "@ant-design/charts"
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { useGetTeamUsageMetrics } from "../../../apis/teams"
import { CustomRechartsTooltip } from "../../../charts/CustomRechartsTooltip"
import { useAuthContext } from "../../../context/AuthContext"
import { GetError } from "../../../widgets/GetError"

type Props = {
  id: string
}

export const TeamUsageMetrics = ({ id }: Props) => {
  const { env, token } = useAuthContext()
  const metrics = useGetTeamUsageMetrics({ id, env, token: token[env] })

  if (metrics.isLoading) return <Spin />
  if (metrics.error || !metrics.data)
    return <GetError reFetch={metrics.refetch} />

  function bytesToGigabytes(bytes: number) {
    return bytes / Math.pow(1024, 3)
  }

  function formatDateToMonthYearUTC(dateString: string): string {
    const date = new Date(dateString)
    const month = date.toLocaleString("en-US", {
      month: "short",
      timeZone: "UTC",
    })
    const year = date.toLocaleString("en-US", {
      year: "numeric",
      timeZone: "UTC",
    })
    return `${month} ${year}`
  }

  const data = metrics.data
    .map((r) => ({
      gigabytes: +bytesToGigabytes(r.bytes).toFixed(1),
      timestamp: formatDateToMonthYearUTC(r.timestamp as any),
    }))
    .sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    )

  return (
    <Card title="Storage Metrics" style={{ height: "100%" }}>
      <ResponsiveContainer
        width="100%"
        height={400}
        style={{ margin: "25px 0" }}
      >
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" />
          <YAxis />
          <Tooltip content={(e) => <CustomRechartsTooltip {...e} />} />
          <Legend />
          <Line
            type="monotone"
            dataKey="gigabytes"
            stroke="#19b1b4"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  )
}
