import { useAuthContext } from "../../context/AuthContext";
import {
  Button,
  Card,
  Col,
  Divider,
  Popconfirm,
  Row,
  Spin,
  Tag,
  Typography,
} from "antd";
import { GetError } from "../../widgets/GetError";
import { DeleteOutlined, LinkOutlined } from "@ant-design/icons";
import { RowContent } from "../Utils/RowContent";
import { useNavigate } from "react-router-dom";
import { useDeleteService, useGetSingleService } from "../../apis/service";
import { NewService } from "./NewService";
import { ServiceErrorCount } from "./ServiceErrorCount";

type Props = { id: string };

export const ServiceOverview = ({ id }: Props) => {
  const { env, token } = useAuthContext();
  const { mutateAsync: deleteService } = useDeleteService();
  const navigate = useNavigate();
  const service = useGetSingleService({
    ServiceCatalogId: id,
    env,
    token: token[env],
  });

  if (service.isLoading) return <Spin />;
  if (service.error || !service.data)
    return <GetError reFetch={service.refetch} />;

  const handleDelete = () => {
    navigate("/services");
  };

  const owners = service.data.owners || [];
  return (
    <>
      <Card>
        <Row align="middle" gutter={8}>
          <Col>
            <Typography.Title>{service.data.name}</Typography.Title>
          </Col>
          <Col>
            <NewService edit service={service.data} details={true} />
          </Col>
          <Col>
            <Popconfirm
              title="Are you sure about deleting this service?"
              onConfirm={() =>
                deleteService({
                  env,
                  token: token[env],
                  id: service.data.id,
                  callback: handleDelete,
                })
              }
            >
              <Button type="primary" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Col>
        </Row>
        <RowContent
          name="Owners"
          content={owners.map((owner) => (
            <Tag
              style={{ cursor: "pointer" }}
              icon={<LinkOutlined />}
              onClick={() => navigate(`/users/${owner.id}`)}
              key={owner.id}
            >
              {owner.name}
            </Tag>
          ))}
        />
        <RowContent
          name="Runtime Engine"
          content={service.data.runTimeEngine || ""}
        />

        <RowContent
          name="Git Repo"
          content={
            service.data.gitRepo ? (
              <a href={service.data.gitRepo} target="_blank" rel="noreferrer">
                <Tag icon={<LinkOutlined />}>{service.data.gitRepo}</Tag>
              </a>
            ) : undefined
          }
        />
        <RowContent
          name="Error alert threshold"
          content={service.data.errorAlertThreshold}
        />
        <RowContent
          name="Description"
          content={service.data.description || ""}
        />
        <RowContent
          name="Subscriptions"
          content={(service.data.subscriptions || []).join(", ")}
        />
        <RowContent
          name="Topics"
          content={(service.data.topics || []).join(", ")}
        />
      </Card>
      <Divider />
      <ServiceErrorCount serviceName={service.data.name} />
    </>
  );
};
