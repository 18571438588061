import {
  Card,
  Col,
  Divider,
  Input,
  List,
  Row,
  Skeleton,
  Typography,
} from "antd";
import { useState } from "react";
import { useGetUniversityArticles } from "../apis/university";
import { useAuthContext } from "../context/AuthContext";
import { filterBySearchValue } from "../utils/search";
import { GetError } from "../widgets/GetError";
import { CalendarOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { formatToDateAndHour } from "../components/Utils/TimeUtils";

export const VizznUniversityArticles = () => {
  const { env, token } = useAuthContext();
  const articles = useGetUniversityArticles({ env, token: token[env] });
  const [searchValue, setSearchValue] = useState<string>("");
  if (articles.isLoading) return <Skeleton />;
  if (articles.error || !articles.data)
    return <GetError reFetch={articles.refetch} />;
  const searchFields = ["title"];

  const filteredData = [...articles.data];
  let filteredByValue = filterBySearchValue({
    searchValue,
    searchFields,
    dataArray: filteredData,
  });
  return (
    <Card>
      <Row gutter={8} style={{ marginBottom: 16 }}>
        <Col flex="auto">
          <Input.Search
            placeholder="input to search videos"
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <List
        header={
          <div>
            <Typography.Title level={4}>Vizzn Articles</Typography.Title>
            <Typography.Text style={{ color: "gray" }}>
              <QuestionCircleFilled />
              <span style={{ marginLeft: 4 }}>
                These articles can be edited through the Intercom application
              </span>
            </Typography.Text>
          </div>
        }
        dataSource={filteredByValue}
        bordered
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                <div>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    {item.title}
                  </a>
                </div>
              }
              description={
                <>
                  <Typography.Paragraph>
                    {item.description}
                  </Typography.Paragraph>
                  <p>
                    <CalendarOutlined />
                    <span style={{ marginLeft: 4 }}>
                      Created At: {formatToDateAndHour(item.created_at)}
                    </span>
                    <Divider type="vertical" />
                    <CalendarOutlined />
                    <span style={{ marginLeft: 4 }}>
                      Updated At: {formatToDateAndHour(item.updated_at)}
                    </span>
                  </p>
                </>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};
