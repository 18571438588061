import { Button, Card } from "antd"
import { useAuthContext } from "../context/AuthContext"
import {
  useUpdateGithubMetrics,
  useUpdateSentryMetrics,
  useUpdateShortuctMetrics,
  useUpdateGCPMetrics,
} from "../apis/WeeklyMetrics"
import { DatePicker } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { useState } from "react"
import { dateRange } from "../types/WeeklyMetrics"

const { RangePicker } = DatePicker
const disabledDate = (current: Dayjs) => {
  return current > dayjs()
}

export const WeeklyMetrics = () => {
  const [sentryPayload, setSentryDates] = useState<dateRange>({
    dateRange: [dayjs().subtract(7, "day"), dayjs()],
  })

  const handleSentryChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates && dates[0] && dates[1]) {
      setSentryDates({
        dateRange: [dates[0], dates[1]],
      })
    }
  }

  const [gcpPayload, setGCPDates] = useState<dateRange>({
    dateRange: [dayjs().subtract(7, "day"), dayjs()],
  })

  const handleGCPChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates && dates[0] && dates[1]) {
      setGCPDates({
        dateRange: [dates[0], dates[1]],
      })
    }
  }

  const { env, token } = useAuthContext()

  const { refetch: githubFetch, isFetching: githubLoading } =
    useUpdateGithubMetrics({ env, token })

  const { refetch: sentryFetch, isFetching: sentryLoading } =
    useUpdateSentryMetrics({ env, token: token[env], payload: sentryPayload })

  const { refetch: shortcutFetch, isFetching: shortcutLoading } =
    useUpdateShortuctMetrics({ env, token })

  const { refetch: gcpFetch, isFetching: gcpLoading } = useUpdateGCPMetrics({
    env,
    token: token[env],
    payload: gcpPayload,
  })

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <Card
        title="Weekly Metrics"
        extra={
          <Button
            loading={
              githubLoading || sentryLoading || shortcutLoading || gcpLoading
            }
            onClick={() => {
              githubFetch()
              if (sentryPayload !== null) sentryFetch()
              shortcutFetch()
              if (gcpPayload !== null) gcpFetch()
            }}
            type="primary"
          >
            Update All
          </Button>
        }
      >
        Automatically gathers and updates data consisting of GitHub Dependabot
        Alerts, Shortcut Bugs, Sentry Errors, and GCP Error Logs into the Weekly
        Metrics spreadsheet. This tool ensures that all relevant metrics are
        up-to-date and accurate.{" "}
        <a
          href="https://docs.google.com/spreadsheets/d/1wlxD-kKSo15lHL6JUN71xQJ1srOk60Ndyw4mJMaCIZY/edit?usp=sharing"
          target="_"
          rel="noopener noreferrer"
        >
          See Sheet
        </a>
      </Card>

      <Card
        title="GCP Error Logs"
        extra={
          <div
            style={{
              display: "flex",
              gap: "30px",
            }}
          >
            <RangePicker
              allowClear={false}
              disabledDate={disabledDate}
              format={"YYYY-MM-DD"}
              defaultValue={[gcpPayload.dateRange[0], gcpPayload.dateRange[1]]}
              onChange={handleGCPChange}
            />
            <Button
              loading={gcpLoading}
              onClick={() => gcpFetch()}
              type="default"
            >
              Update
            </Button>
          </div>
        }
      >
        Click to collect and update data from GCP into the Weekly Metrics
        spreadsheet. This ensures that your GCP Error Log metrics are current
        and ready for review.
      </Card>

      <Card
        title="GitHub Dependabot Alerts"
        extra={
          <Button
            loading={githubLoading}
            onClick={() => githubFetch()}
            type="default"
          >
            Update
          </Button>
        }
      >
        Click to collect and update data from GitHub into the Weekly Metrics
        spreadsheet. This ensures that your GitHub Dependabot Alert metrics are
        current and ready for review.
      </Card>

      <Card
        title="Sentry Errors"
        extra={
          <div
            style={{
              display: "flex",
              gap: "30px",
            }}
          >
            <RangePicker
              allowClear={false}
              disabledDate={disabledDate}
              format={"YYYY-MM-DD"}
              defaultValue={[
                sentryPayload.dateRange[0],
                sentryPayload.dateRange[1],
              ]}
              onChange={handleSentryChange}
            />
            <Button
              disabled={sentryPayload === null}
              loading={sentryLoading}
              onClick={() => sentryFetch()}
              type="default"
            >
              Update
            </Button>
          </div>
        }
      >
        Click to collect and update data from Sentry into the Weekly Metrics
        spreadsheet. This ensures that your Sentry Error metrics are current and
        ready for review.
      </Card>

      <Card
        title="Shortcut Bugs"
        extra={
          <Button
            loading={shortcutLoading}
            onClick={() => shortcutFetch()}
            type="default"
          >
            Update
          </Button>
        }
      >
        Click to collect and update data from Shortcut into the Weekly Metrics
        spreadsheet. This ensures that your Shortcut Bug metrics are current and
        ready for review.
      </Card>
    </div>
  )
}
