export const firebaseConfigDev = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
export const firebaseConfigProd = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PROD,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PROD,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PROD,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_PROD,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID_PROD,
};
export const firebaseConfigStaging = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_STG,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_STG,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_STG,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_STG,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID_STG,
};
