import { Typography } from "antd"
import { ActivityFeedsPropData } from "../../../../../types/DispatchEvent"

export const DefaultActivityFeed = ({
  data,
}: {
  data: ActivityFeedsPropData
}) => {
  return (
    <Typography.Text strong>
      {data
        ? data.value instanceof Date
          ? data.value.toLocaleString()
          : data.value
        : "empty"}{" "}
    </Typography.Text>
  )
}
