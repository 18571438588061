import React, { useEffect, useMemo, useState } from "react"
import { useAuthContext } from "../../context/AuthContext"
import { getDocs, collection, query, where } from "firebase/firestore"
import { DataUsage } from "../../types/user"
import { Card, Tag } from "antd"
import Table, { ColumnsType } from "antd/es/table"
import { formatToDateAndHour } from "../Utils/TimeUtils"

type Props = {
  id: string
}

export const UserDataUsage = ({ id }: Props) => {
  const { env, getDb } = useAuthContext()
  const db = getDb(env)
  const [metrics, setMetrics] = useState<any[]>([])

  const columns: ColumnsType<DataUsage> = useMemo(
    () => [
      {
        title: "Start Time",
        align: "left",
        width: 150,
        render: (_, e) => formatToDateAndHour(e.startTime),
        sorter: (a, b) => (a.startTime < b.startTime ? -1 : 1),
      },
      {
        title: "End Time",
        align: "left",
        width: 150,
        render: (_, e) => formatToDateAndHour(e.endTime),
        sorter: (a, b) => (a.endTime < b.endTime ? -1 : 1),
      },
      {
        title: "Expired at",
        align: "left",
        width: 150,
        render: (_, e) => formatToDateAndHour(e.expiredAt.toDate()),
        sorter: (a, b) =>
          a.expiredAt.toDate() < b.expiredAt.toDate() ? -1 : 1,
      },
      {
        title: "Group Id",
        align: "center",
        width: 150,
        render: (_, e) => !!e.groupId && <Tag>{e.groupId}</Tag>,
        sorter: (a, b) => (a.groupId < b.groupId ? -1 : 1),
      },
      {
        title: "Wifi Rx bytes",
        align: "center",
        width: 150,
        render: (_, e) => e.wifiRxBytes,
        sorter: (a, b) => (a.wifiRxBytes < b.wifiRxBytes ? -1 : 1),
      },
      {
        title: "Wifi Tx bytes",
        align: "center",
        width: 150,
        render: (_, e) => e.wifiTxBytes,
        sorter: (a, b) => (a.wifiTxBytes < b.wifiTxBytes ? -1 : 1),
      },
    ],
    []
  )

  useEffect(() => {
    const q = query(
      collection(db, "diagnostics"),
      where("type", "==", "deviceNetworkUsage"),
      where("createdBy", "==", id)
    )

    const fetchData = async () => {
      try {
        const snapshot = await getDocs(q)
        const metricsData: DataUsage[] = []

        snapshot.forEach((doc) => metricsData.push(doc.data() as DataUsage))

        setMetrics(metricsData)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData()
  }, [db, env, id])

  return (
    <Card title="Data Usage">
      <Table
        scroll={{ y: "60vh" }}
        dataSource={metrics}
        columns={columns}
        pagination={false}
        rowKey="id"
      />
    </Card>
  )
}
