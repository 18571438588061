import { SortOrder, SorterResult } from "antd/lib/table/interface";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

export interface ColumnSortOrder {
  [key: string]: SortOrder | undefined;
}

export type SearchTagsReturnProps = {
  tags: string[];
  addTag: (name: string) => void;
  removeTag: (name: string) => void;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setSelectedTags: Dispatch<SetStateAction<string[]>>;
};

export const useSearchTags = (locationName: string) => {
  const [tags, setSelectedTags] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [data] = useState<string | null>(() =>
    window.localStorage.getItem(`vizzn.${locationName}.searchterms`)
  );
  const [localSortOrder] = useState<string | null>(() =>
    window.localStorage.getItem(`vizzn.${locationName}.columnSortOrder`)
  );
  const [sortOrder, setSortOrder] = useState<ColumnSortOrder>({});

  useEffect(() => {
    if (localSortOrder) {
      const parsedSortOrder = JSON.parse(localSortOrder);
      setSortOrder(parsedSortOrder);
    }
  }, [localSortOrder]);

  useEffect(() => {
    const formatedData = data ? JSON.parse(data) : undefined;
    if (formatedData) {
      if (!formatedData.tags) formatedData.tags = [];
      setSelectedTags([...formatedData.tags]);
      setSearchValue(formatedData.searchValue);
    }
  }, [data]);

  useEffect(() => {
    window.localStorage.setItem(
      `vizzn.${locationName}.searchterms`,
      JSON.stringify({ searchValue, tags })
    );
  }, [locationName, searchValue, tags]);

  const addTag = (name: string) => {
    setSelectedTags([...tags, name]);
  };

  const removeTag = (name: string) => {
    setSelectedTags(tags.filter((t) => t !== name));
  };

  const handleSortChange = <T>(sorter: SorterResult<T> | SorterResult<T>[]) => {
    if (Array.isArray(sorter) || !sorter.columnKey) {
      return {};
    }

    const newSortOrder = {
      [sorter.columnKey.toString()]: sorter.order,
    };

    window.localStorage.setItem(
      `vizzn.${locationName}.columnSortOrder`,
      JSON.stringify(newSortOrder)
    );

    return setSortOrder(newSortOrder);
  };

  const getSortOrder = useCallback(
    (
      columnKey: string,
      defaultOrder: SortOrder | null = null
    ): SortOrder | undefined => {
      const order = window.localStorage.getItem(
        `vizzn.${locationName}.columnSortOrder`
      );
      const sortOrder = order ? JSON.parse(order) : {};

      return Object.keys(sortOrder).length > 0
        ? sortOrder[columnKey]
        : defaultOrder || null;
    },
    [locationName]
  );

  return {
    tags,
    addTag,
    removeTag,
    searchValue,
    setSearchValue,
    setSelectedTags,
    handleSortChange,
    getSortOrder,
    sortOrder,
  };
};
