import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Switch,
} from "antd"
import React from "react"
import { AuthUserCreateRequestDto } from "../../../../types/user"
import { vizznSupportedLanguagesArray } from "../../../../types/Language"
import { useCreateUser } from "../../../../apis/user"
import { useAuthContext } from "../../../../context/AuthContext"
import { useGetRoles } from "../../../../apis/Roles"
import SelectTeamSelect from "../SelectTeamSelect/SelectTeamSelect"

type Props = {
  firstName: string
  lastName: string
  email: string
  refetch: () => void
  id: string
  currentTeamIds: string[]
  languagePreference: string
}

type FormValues = AuthUserCreateRequestDto

export default function CreateNewUserModal({
  refetch,
  id,
  firstName,
  lastName,
  email,
  currentTeamIds,
  languagePreference,
}: Props) {
  const [form] = Form.useForm<FormValues>()
  const { token, env } = useAuthContext()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const { mutateAsync: createUser, isLoading } = useCreateUser(env)

  const userRoles = useGetRoles({
    env,
    token: token[env],
    type: "roles/system",
    enabled: isModalOpen,
  })

  const onSubmit = async (values: FormValues) => {
    try {
      await createUser({
        env,
        token: token[env],
        dto: {
          ...values,
          userAuthId: id,
        },
      })
      message.success("User created successfully")
      refetch()
      handleClose()
    } catch (error) {
      message.error("Failed to create user. Please try again.")
    }
  }

  const handleClose = () => {
    setIsModalOpen(false)
    form.resetFields()
  }

  return (
    <>
      <Button type="primary" onClick={() => setIsModalOpen(true)}>
        Create New User
      </Button>
      {isModalOpen && (
        <Modal
          onCancel={handleClose}
          open={isModalOpen}
          width={"65%"}
          footer={null}
        >
          <Form
            onFinish={onSubmit}
            form={form}
            style={{
              padding: "1rem",
            }}
            layout="vertical"
          >
            <Row gutter={32} justify="space-evenly">
              <Col span={10}>
                <Form.Item<FormValues>
                  label="First Name"
                  name="firstName"
                  rules={[{ required: true }]}
                  initialValue={firstName}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item<FormValues>
                  label="Last Name"
                  name="lastName"
                  rules={[{ required: true }]}
                  initialValue={lastName}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32} justify="space-evenly">
              <Col span={10}>
                <Form.Item<FormValues>
                  label="Email"
                  name="email"
                  rules={[{ required: true }]}
                  initialValue={email}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item<FormValues> label="Phone Number" name="phone">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32} justify="space-evenly">
              <Col span={10}>
                <Form.Item<FormValues> label="Job Title" name="jobTitle">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item<FormValues>
                  label="Language Preference"
                  name="languagePreference"
                  initialValue={languagePreference}
                >
                  <Select options={vizznSupportedLanguagesArray} disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32} justify="space-evenly">
              <Col span={10}>
                <SelectTeamSelect currentTeamIds={currentTeamIds} />
              </Col>
              <Col span={10}>
                <Form.Item<FormValues> label="Role" name="roles">
                  <Select
                    mode="multiple"
                    options={userRoles?.data?.map((role) => ({
                      label: role.name,
                      value: role.id,
                    }))}
                    loading={userRoles.isLoading}
                    disabled={userRoles.isLoading}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32} justify="space-evenly">
              <Col span={10}>
                <Form.Item<FormValues>
                  label="Focus Mode Only"
                  name="focusModeOnly"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item<FormValues>
                  label="Activate Account"
                  name="activateAccount"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading}
              >
                Submit
              </Button>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  )
}
