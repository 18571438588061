import axios from "axios";
import { environmentType } from "../types/environtment";
import { getEnvUrl } from "../utils/utils";
import { Topic, TopicRequestDto } from "../types/Topic";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const getTopics = async (body: {
  env: environmentType;
  token: string | null;
}) => {
  if (!body.token) return;
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/service-catalog-topic`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  );
  return data.data;
};
export const useGetTopics = (body: {
  env: environmentType;
  token: string | null;
}) => {
  const response = useQuery<Topic[]>(["topics", body.env], () =>
    getTopics(body)
  );

  return response;
};

const createTopic = async (body: {
  env: environmentType;
  token: string | null;
  payload: TopicRequestDto;
}) => {
  if (!body.token) return;
  const { data } = await axios.post(
    `${getEnvUrl(body.env)}/v1/service-catalog-topic`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  );
  return data;
};

export const useCreateTopic = () => {
  const queryClient = useQueryClient();
  return useMutation(createTopic, {
    onSuccess: async (data, params) => {
      if (!data) return;
      const oldData = queryClient.getQueryData<Topic[]>(["topics", params.env]);

      if (oldData) {
        const newData = [...oldData, data.data];

        queryClient.setQueriesData(["topics", params.env], () => newData);
      }
    },
  });
};

const updateTopic = async (body: {
  env: environmentType;
  token: string | null;
  payload: TopicRequestDto;
}) => {
  if (!body.token) return;
  const { data } = await axios.put(
    `${getEnvUrl(body.env)}/v1/service-catalog-topic`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  );
  return data;
};

export const useUpdateTopic = () => {
  const queryClient = useQueryClient();
  return useMutation(updateTopic, {
    onSuccess: async (data, params) => {
      if (!data) return;
      const oldData = queryClient.getQueryData<Topic[]>(["topics", params.env]);
      if (oldData) {
        const updatedData = oldData.map((topic) => {
          if (topic.name === data.data.name) return data.data;
          return topic;
        });

        queryClient.setQueriesData(["topics", params.env], () => updatedData);
      }
    },
  });
};

const deleteTopic = async (body: {
  env: environmentType;
  token: string | null;
  id: string;
}) => {
  if (!body.token) return;
  const { data } = await axios.delete(
    `${getEnvUrl(body.env)}/v1/service-catalog-topic`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
      params: {
        id: body.id,
      },
    }
  );
  return data;
};

export const useDeleteTopic = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteTopic, {
    onSuccess: async (data, params) => {
      if (!data) return;
      const oldData = queryClient.getQueryData<Topic[]>(["topics", params.env]);
      if (oldData) {
        const updatedData = oldData.filter((topic) => topic.name !== params.id);

        queryClient.setQueriesData(["topics", params.env], () => updatedData);
      }
    },
  });
};
