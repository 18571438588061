import { Button, Col, Row, Skeleton, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { Topic } from "../types/Topic";
import { useGetTopics } from "../apis/topics";
import { useAuthContext } from "../context/AuthContext";
import { GetError } from "../widgets/GetError";
import dayjs from "dayjs";
import { NewTopic } from "../components/Topics/NewTopic";
import { DeleteTopic } from "../components/Topics/DeleteTopic";
import { useNavigate } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";

export const TopicsPage = () => {
  const { env, token } = useAuthContext();
  const topics = useGetTopics({ env, token: token[env] });
  const navigate = useNavigate();
  if (topics.isLoading) return <Skeleton />;
  if (topics.error || !topics.data)
    return <GetError reFetch={topics.refetch} />;
  const columns: ColumnsType<Topic> = [
    {
      title: "Name",
      render: (_, e) => e.name,
      sorter: (a, b) => {
        if (!a.name) return 1;
        if (!b.name) return -1;
        return a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1;
      },
      defaultSortOrder: "ascend",
      key: "Name",
    },
    {
      title: "Url",
      render: (_, e) => (
        <a href={e.url} target="_blank" rel="noreferrer">
          {e.url}
        </a>
      ),
      key: "url",
    },
    {
      title: "Schema",
      render: (_, e) => <span>{e.schema}</span>,
      key: "schema",
    },
    {
      title: "Updated At",
      render: (_, e) => (
        <span>{dayjs(e.updatedAt).format("(MMM, DD YYYY) HH:MM")}</span>
      ),
      key: "schema",
    },
    {
      title: "Updated By",
      render: (_, e) => (
        <Tooltip title="Go to user page">
          <Button
            size="small"
            onClick={() => navigate(`/users/${e.updatedBy}`)}
            icon={<LinkOutlined />}
          >
            {e.updatedBy}
          </Button>
        </Tooltip>
      ),
      key: "schema",
    },
    {
      title: "Actions",
      render: (_, e) => (
        <Row gutter={4}>
          <Col>
            <NewTopic edit topic={e} />
          </Col>
          <Col>
            <DeleteTopic topic={e} />
          </Col>
        </Row>
      ),
    },
  ];
  return (
    <div>
      <Row style={{ marginBottom: 16 }}>
        <NewTopic />
      </Row>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={topics.data || []}
        pagination={false}
        loading={topics.isLoading}
      />
    </div>
  );
};
