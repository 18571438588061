import { Button, Col, Form, Input, Row } from "antd"
import { RowContent } from "../../../components/Utils/RowContent"
import {
  CheckCircleOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons"
import { useState } from "react"
import { usePatchUserData } from "../../../apis/user"
import { useAuthContext } from "../../../context/AuthContext"

type Props = {
  phone: string | undefined
  id: string
}

type FormValues = {
  phone: string
}

export default function SysAdminPhoneNumberWidget({ phone, id }: Props) {
  const [form] = Form.useForm()
  const { token, env } = useAuthContext()

  const [isEditing, setIsEditing] = useState(false)
  const { mutateAsync: updateUserPhone } = usePatchUserData(env)

  const onSubmit = async ({ phone }: FormValues) => {
    try {
      await updateUserPhone({
        payload: {
          phone,
          id,
        },
        env,
        token: token[env],
      })
    } catch (error) {
    } finally {
      form.resetFields()
      setIsEditing(false)
    }
  }

  return (
    <RowContent
      name="Phone"
      content={
        isEditing ? (
          <Row gutter={8}>
            <Form form={form} layout="inline" onFinish={onSubmit}>
              <Col>
                <Form.Item
                  name="phone"
                  initialValue={phone}
                  normalize={(num: string) => {
                    // this functions accepts a string and normalizes it into a formatted phone number
                    const match = num.replace(/\D+/g, "")

                    if (!match) return ""
                    const part0 =
                      match.length > 0 ? `+${match.substring(0, 1)} ` : ""
                    const part1 =
                      match.length > 1 ? `(${match.substring(1, 3)}` : ""
                    const part2 =
                      match.length > 3 ? `${match.substring(3, 4)}) ` : ""
                    const part3 =
                      match.length > 4 ? `${match.substring(4, 7)}` : ""
                    const part4 =
                      match.length > 7 ? `-${match.substring(7, 11)}` : ""
                    return `${part0}${part1}${part2}${part3}${part4}`
                  }}
                  rules={[
                    () => ({
                      validator(_: any, value: any) {
                        if (!value) return Promise.resolve()
                        if (value.length === 0) return Promise.resolve()
                        if (
                          value.length === 17 ||
                          value.replace(/\D+/g, "").length === 11
                        )
                          return Promise.resolve()
                        return Promise.reject(
                          "The phone number must have 10 digits."
                        )
                      },
                    }),
                  ]}
                >
                  <Input
                    type="text"
                    value={phone}
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key !== "Backspace") return
                      const value = form.getFieldValue("phone")
                      // if the last character of the value is not a number, remove the non-numeric values at the end
                      if (value && isNaN(parseInt(value[value.length - 1]))) {
                        form.setFieldsValue({
                          phone: value.replace(/\D+$/g, ""),
                        })
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col>
                <Button
                  type="primary"
                  danger
                  onClick={() => setIsEditing(false)}
                >
                  <StopOutlined />
                </Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  <CheckCircleOutlined />
                </Button>
              </Col>
            </Form>
          </Row>
        ) : (
          <Row gutter={8}>
            <Col>{phone ? phone : "No Phone Number"}</Col>
            <Col>
              <Button size="small" onClick={() => setIsEditing(true)}>
                <EditOutlined />
              </Button>
            </Col>
          </Row>
        )
      }
    />
  )
}
