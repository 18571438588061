import { Button, Card, Col, Row } from "antd"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import convertStringToTitleCase from "../utils/convertStringToTitleCase"
import { useWindowDimensions } from "../utils/hooks/useWindowDimensions"
import { CustomRechartsTooltip } from "./CustomRechartsTooltip"
import { CustomRechartsTick } from "./CustomRechartsTick"
import { Link } from "react-router-dom"

interface BarChartCardProps<T> {
  title: string
  data: T[]
  dataKey?: string
  xAxisDataKey?: string
  height?: number
  hideLabels?: boolean
  id?: string
  showDetailButton?: boolean // Added boolean prop
}

export default function BarChartCard<T>({
  title,
  data,
  dataKey = "score",
  xAxisDataKey = "team",
  height = 300,
  hideLabels = false,
  id,
  showDetailButton = true,
}: BarChartCardProps<T>) {
  const { width } = useWindowDimensions()

  return (
    <Card
      title={
        <Row align="middle" justify="start" gutter={16}>
          <Col>{title}</Col>
          {showDetailButton && (
            <Col>
              <Link to={`/usage-category/${id}/${title}`}>
                <Button>View Detail</Button>
              </Link>
            </Col>
          )}
        </Row>
      }
    >
      <ResponsiveContainer width={"100%"} height={300}>
        <BarChart
          width={width - 350}
          height={height}
          data={data}
          margin={{
            top: 5,
            right: 10,
            left: 60,
            bottom: 70,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={xAxisDataKey}
            interval={hideLabels ? "preserveStart" : 0}
            tick={<CustomRechartsTick />}
          />
          <YAxis />
          <Tooltip content={(e) => <CustomRechartsTooltip {...e} />} />
          <Legend
            layout="horizontal"
            verticalAlign="top"
            align="center"
            formatter={(val) => convertStringToTitleCase(val)}
          />
          <Bar dataKey={dataKey} fill="#19b1b4" />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  )
}
