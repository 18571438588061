import { StarFilled } from "@ant-design/icons"
import { Button, Popover, Tag, message } from "antd"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuthContext } from "../context/AuthContext"
import { environmentType } from "../types/environtment"
import { CopyOutlined } from "@ant-design/icons"

type Props = {
  env: environmentType
}

export const EnvStatus = ({ env }: Props) => {
  const {
    user,
    token,
    env: environtment,
    handleSetEnv,
    handleLogOut,
    setRedirect,
    handleWorkosLogout,
    isFirebaseLogin,
  } = useAuthContext()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const handleLogoutCheck = () => {
    if (isFirebaseLogin[env] === "firebase") {
      handleLogOut(() => navigate("/login"))
    } else {
      handleWorkosLogout({ navigate: () => navigate("/login") })
    }
  }

  return user[env] ? (
    <Popover
      open={open}
      onOpenChange={(value) => setOpen(value)}
      placement="topRight"
      trigger="click"
      content={
        <>
          <Button
            style={{ marginRight: "5px" }}
            size="small"
            icon={<CopyOutlined />}
            onClick={() => {
              navigator.clipboard.writeText(token[env] || "")
              message.success("Text copied to clipboard")
            }}
          >
            Copy JWT
          </Button>
          <Button
            style={{ marginRight: "5px" }}
            size="small"
            icon={<CopyOutlined />}
            onClick={() => {
              navigator.clipboard.writeText(user.dev?.uid || "")
              message.success("ID copied to clipboard")
            }}
          >
            Copy My ID
          </Button>
          <Button
            size="small"
            type="primary"
            danger
            onClick={() => {
              setOpen(false)
              handleLogoutCheck()
            }}
          >
            Log Out
          </Button>
        </>
      }
    >
      <Tag
        key={env}
        style={{
          color: "white",
          background: user[env] ? "#424bd1" : "#D14242",
          cursor: "pointer",
        }}
        onClick={() => handleSetEnv(env)}
      >
        {env === environtment && <StarFilled />}
        <span>{env}</span>
      </Tag>
    </Popover>
  ) : (
    <Tag
      key={env}
      style={{
        color: "white",
        background: user[env] ? "#424bd1" : "#D14242",
        cursor: "pointer",
      }}
      onClick={() => {
        setOpen(false)
        setRedirect(location.pathname)
        handleSetEnv(env)
      }}
    >
      {env === environtment && <StarFilled />}
      <span>{env}</span>
    </Tag>
  )
}
