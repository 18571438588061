import { Card, Col, Row } from "antd"
import CopyTextButton from "../../../components/common/CopyTextButton"

interface EnterpriseOverviewProps {
  name: string
  userCount: number
  totalUserCount: number
  id: string
}

export default function EnterpriseOverview({
  name,
  userCount,
  totalUserCount,
  id,
}: EnterpriseOverviewProps) {
  return (
    <Card
      title={
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          {name} Overview - <CopyTextButton text={id} label={id} />
        </div>
      }
    >
      <Row gutter={16} justify="center">
        <Col span={6}>
          <Card title={<Row justify="center">Users</Row>}>
            <Row justify="center">
              <span style={{ fontSize: 36, fontWeight: 600 }}>{userCount}</span>
            </Row>
          </Card>
        </Col>
        <Col span={6}>
          <Card title={<Row justify="center">Total Users</Row>}>
            <Row justify="center">
              <span style={{ fontSize: 36, fontWeight: 600 }}>
                {totalUserCount}
              </span>
            </Row>
          </Card>
        </Col>
      </Row>
    </Card>
  )
}
