import { Button, Card, Form, Input, Select, Switch, message } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../context/AuthContext"
import { environmentType } from "../types/environtment"
import { motion } from "framer-motion"
// import { useGetAuthProvider } from "../apis/WorkOs"
export const Login = () => {
  const {
    handleLogin,
    user,
    env,
    handleSetEnv,
    redirect,
    handleWorkosLogin,
    setIsFirebaseLogin,
    isFirebaseLogin,
  } = useAuthContext()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  // const { refetch } = useGetAuthProvider({ env, enabled: false })

  const [form] = Form.useForm()

  const handleLoginMethod = async (values: any) => {
    setLoading(true)
    // const authProvider = await refetch()

    // try {
    //   await handleWorkosLogin({
    //     email: values.email,
    //     password: values.password,
    //     navigate: () => navigate(redirect || "/"),
    //   })
    // } catch (error: any) {
    //   message.error(error.error)
    // } finally {
    //   setLoading(false)
    // }
    // if (authProvider.data === "workos") {
    if (isFirebaseLogin[env] === "workos") {
      setIsFirebaseLogin((prev) => ({
        ...prev,
        [env]: "workos",
      }))
      handleWorkosLogin({
        email: values.email,
        password: values.password,
        navigate: () => navigate(redirect || "/"),
      })
      setLoading(false)

      return
    }

    // if (authProvider.data === "firebase") {
    if (isFirebaseLogin[env] === "firebase") {
      setIsFirebaseLogin((prev) => ({
        ...prev,
        [env]: "firebase",
      }))
      handleLogin(values.email, values.password, () =>
        navigate(redirect || "/")
      )
      setLoading(false)

      return
    }

    setLoading(false)
  }

  useEffect(() => {
    if (user[env]) navigate("/")
  }, [env, navigate, user])

  useEffect(() => {
    form.setFieldValue("env", env)
  }, [env, form])

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <Card>
          <Form form={form} onFinish={handleLoginMethod}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please input your email" }]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item label="Select Environment" name="env" initialValue={env}>
              <Select
                onChange={(value: environmentType) => handleSetEnv(value)}
              >
                <Select.Option value="dev">Development</Select.Option>
                <Select.Option value="prod">Production</Select.Option>
                <Select.Option value="staging">Staging</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Switch
                checked={isFirebaseLogin[env] === "firebase"}
                checkedChildren={`${env} - Firebase`}
                unCheckedChildren={`${env} - WorkOS`}
                onChange={(checked) => {
                  setIsFirebaseLogin((prev) => ({
                    ...prev,
                    [env]: checked ? "firebase" : "workos",
                  }))
                  message.success(
                    `Switched to ${
                      checked ? "Firebase" : "WorkOS"
                    } for ${env} login`
                  )
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                loading={loading}
                disabled={loading}
                type="primary"
                htmlType="submit"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </motion.div>
    </div>
  )
}
