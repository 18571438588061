import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { useDeleteTopic } from "../../apis/topics";
import { useAuthContext } from "../../context/AuthContext";
import { Topic } from "../../types/Topic";

type Props = {
  topic: Topic;
};

export const DeleteTopic = ({ topic }: Props) => {
  const { env, token } = useAuthContext();
  const { mutateAsync: deleteTopic } = useDeleteTopic();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    await deleteTopic({ env, token: token[env], id: topic.name });
    setLoading(false);
  };
  return (
    <Button
      loading={loading}
      type="primary"
      danger
      icon={<DeleteOutlined />}
      onClick={handleDelete}
    />
  );
};
