import { Button, Popconfirm } from "antd";
import React from "react";
import { GroupedData } from "../UsageCaseCategoryTable";
import { useDeleteUsageCategory } from "../../../apis/UsageScores";
import { useAuthContext } from "../../../context/AuthContext";

type Props = {
  category: GroupedData;
};

export default function UsageCategoryDeleteButton({ category }: Props) {
  const { env, token } = useAuthContext();
  const { mutateAsync: deleteCategory, isLoading } = useDeleteUsageCategory();

  const handleDeleteCategory = async (id: string) => {
    try {
      const payload = {
        env,
        token: token[env],
        id,
      };

      await deleteCategory(payload);
    } catch (error) {}
  };

  return (
    <Popconfirm
      title="Are you sure you want to delete this category?"
      onConfirm={() => handleDeleteCategory(category.id)}
      okText="Yes"
      cancelText="No"
    >
      <Button danger type="primary" loading={isLoading}>
        Delete
      </Button>
    </Popconfirm>
  );
}
