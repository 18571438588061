import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"
import { environmentType } from "../types/environtment"
import { SysAdminData, UseGetUserAccountsParams } from "../types/SysAdmin"
import { getEnvUrl } from "../utils/utils"

// v1/user-accounts
const getUserAccounts = async (body: {
  env: environmentType
  token: string | null
  enabled?: boolean
  dto: {
    authIds?: string[]
    sysAdminOnly?: boolean // undefined means find both
    format: "full" | "small"
    action?: "add" | "remove"
    size?: number
    page?: number
  }
}) => {
  if (!body.token) return

  const encodedAuthIds =
    body.dto.authIds?.map(encodeURIComponent).join(",") || ""

  const params = new URLSearchParams({
    ...(encodedAuthIds && { authId: encodedAuthIds }),
    ...(body.dto.action && { action: body.dto.action }),
    sysAdminOnly: body.dto.sysAdminOnly ? "true" : "false",
    format: body.dto.format,
    size: body.dto.size ? body.dto.size.toString() : "250",
    page: body.dto.page ? body.dto.page.toString() : "1",
  })

  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/user-accounts/?${params.toString()}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data)
    return data.data.records.sort((a: any, b: any) =>
      a.name.localeCompare(b.name)
    )
}

export function useGetUserAccounts<T extends "full" | "small">(
  params: UseGetUserAccountsParams<T>
) {
  const {
    env,
    enabled,
    dto: { sysAdminOnly, page },
  } = params
  const response = useQuery<SysAdminData<T>[]>(
    ["user-accounts", env, sysAdminOnly, page],
    () => getUserAccounts(params),
    {
      enabled: enabled,
    }
  )
  return response
}

const getUserAccountById = async (body: {
  env: environmentType
  token: string | null
  id: string
}) => {
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/user-accounts/${body.id}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export function useGetUserAccountById(body: {
  env: environmentType
  token: string | null
  id: string
}) {
  return useQuery(["user-account", body.id], () => getUserAccountById(body))
}

const addRemoveSysAdmin = async (body: {
  env: environmentType
  token: string | null
  authId: string
  action: "add" | "remove"
}) => {
  // /v1/user-accounts/:userAuthId/users/:targetUserId/sysadmin
  const { data } = await axios.put(
    `${getEnvUrl(body.env)}/v1/user-accounts/${body.authId}`,
    { action: body.action },
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export function useAddRemoveSysAdmin() {
  return useMutation(addRemoveSysAdmin)
}

// const updateUser = async (body: {
//   env: environmentType
//   token: string
//   id: string
//   data: {
//     email: string
//     firstName: string
//     lastName: string
//     phone: string
//     jobTitle: string
//     teamId: string
//   }
// }) => {
//   const { data } = await axios.patch(
//     `${getEnvUrl(body.env)}/v1/users/${body.id}`,
//     body.data,
//     {
//       headers: {
//         Authorization: `Bearer ${body.token}`,
//       },
//     }
//   )

//   return data.data
// }
