import { Form, Input, InputNumber, Switch, Tag, Tooltip, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useUpdateUniversityVideo } from "../../apis/university";
import { useAuthContext } from "../../context/AuthContext";
import { UniversityVideo } from "../../types/university";

type Props = {
  video: UniversityVideo;
  isVisible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
};
type Values = {
  name: string;
  isFeatured: boolean;
  path: string;
  order: string;
  length: number;
  description?: string;
};

export const UpdateVideoModal = ({ video, isVisible, setVisible }: Props) => {
  const { env, token } = useAuthContext();
  const [form] = Form.useForm();
  const { mutateAsync: updateVideo } = useUpdateUniversityVideo();
  const [categories, setCategories] = useState<string[]>(
    video.categories || []
  );
  const [featuredCategories, setFeaturedCategories] = useState<string[]>(
    video.featuredCategories || []
  );
  const [newCategory, setNewCategory] = useState<string>("");
  const [newFeaturedCategory, setNewFeaturedCategory] = useState<string>("");
  const removeTag = (value: string) => {
    setCategories((prev) => prev.filter((cat) => cat !== value));
  };
  const addTag = (value: string) => {
    setCategories((prev) => [...prev, value]);
    setNewCategory("");
  };
  const removeFeaturedTag = (value: string) => {
    setFeaturedCategories((prev) => prev.filter((cat) => cat !== value));
  };
  const addFeaturedTag = (value: string) => {
    setFeaturedCategories((prev) => [...prev, value]);
    setNewFeaturedCategory("");
  };

  const setFields = form.setFieldsValue;
  useEffect(() => {
    if (isVisible) {
      setFields({
        order: video.order,
        length: video.length,
        isFeatured: video.isFeatured,
      });
    }
  }, [isVisible, setFields, video.isFeatured, video.length, video.order]);

  const handleFinish = async (values: Values) => {
    await updateVideo({
      payload: {
        id: video.id,
        name: values.name,
        isFeatured: values.isFeatured || false,
        categories,
        featuredCategories,
        path: values.path,
        order: values.order,
        length: values.length,
        description: values.description,
      },

      env,
      token: token[env],
    });
    setVisible(false);
    message.success("Video updated successfully");
  };
  return (
    <Modal
      title={`Edit Video: ${video.name}`}
      open={isVisible}
      onCancel={() => setVisible(false)}
      onOk={() => handleFinish(form.getFieldsValue())}
    >
      <Form form={form}>
        <Form.Item label="Name" name="name" initialValue={video.name}>
          <Input />
        </Form.Item>
        <Form.Item label="Video Url" name="path" initialValue={video.path}>
          <Input />
        </Form.Item>
        <Form.Item label="Categories">
          <div style={{ marginBottom: 8 }}>
            {categories.map((c) => (
              <Tag key={c} closable onClose={() => removeTag(c)}>
                {c}
              </Tag>
            ))}
          </div>
          <Input
            placeholder="Press enter to add a new tag or backspace to remove them"
            onKeyPress={(e) => {
              if (e.key === "Enter") addTag(newCategory);
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && !newCategory && categories.length)
                removeTag(categories[categories.length - 1]);
            }}
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Feature Categories">
          <div style={{ marginBottom: 8 }}>
            {featuredCategories.map((c) => (
              <Tag key={c} closable onClose={() => removeFeaturedTag(c)}>
                {c}
              </Tag>
            ))}
          </div>
          <Input
            placeholder="Press enter to add a new tag or backspace to remove them"
            onKeyPress={(e) => {
              if (e.key === "Enter") addFeaturedTag(newFeaturedCategory);
            }}
            onKeyDown={(e) => {
              if (
                e.key === "Backspace" &&
                !newFeaturedCategory &&
                featuredCategories.length
              )
                removeFeaturedTag(
                  featuredCategories[featuredCategories.length - 1]
                );
            }}
            value={newFeaturedCategory}
            onChange={(e) => setNewFeaturedCategory(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
        <Tooltip title="The order starts with 0.1 and on " placement="leftTop">
          <Form.Item label="Order" name="order">
            <Input />
          </Form.Item>
        </Tooltip>
        <Tooltip title="Time is measured in seconds" placement="leftTop">
          <Form.Item label="Length" name="length">
            <InputNumber />
          </Form.Item>
        </Tooltip>
        <Form.Item
          label="Featured"
          name="isFeatured"
          initialValue={video.isFeatured}
        >
          <Switch defaultChecked={video.isFeatured} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
