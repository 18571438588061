import { useMemo, useState } from "react"
import { useAuthContext } from "../../context/AuthContext"
import { WorkHistoryItem } from "../../types/user"
import { Card } from "antd"
import Table, { ColumnsType } from "antd/es/table"
import { green, red, cyan } from "@ant-design/colors"
import {
  LoginOutlined,
  LogoutOutlined,
  UserAddOutlined,
} from "@ant-design/icons"
import { format } from "date-fns"
import { GetError } from "../../widgets/GetError"
import { useGetUserData, useGetWorkHistory } from "../../apis/user"

type Actions = {
  [key: string]: string
}

type Props = {
  id: string
}

export const UserWorkHistoryWidget = ({ id }: Props) => {
  const { env, token } = useAuthContext()
  const user = useGetUserData({ userId: id, env, token: token[env] })
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const history = useGetWorkHistory({
    env,
    token: token[env],
    payload: {
      userId: id,
      teamId: user.data?.teamId || "",
      page,
      size: pageSize,
    },
  })

  const columns: ColumnsType<WorkHistoryItem> = useMemo(() => {
    const actions: Actions = {
      checkIn: "Check in",
      checkOut: "Check out",
      created: "Created",
    }
    return [
      {
        width: 80,
        render: (_, e) => {
          if (e.action === "checkIn")
            return (
              <LoginOutlined
                size={24}
                style={{ color: green[5], fontSize: 16 }}
              />
            )
          if (e.action === "checkOut")
            return (
              <LogoutOutlined
                size={24}
                style={{ color: red[5], fontSize: 16 }}
              />
            )
          return (
            <UserAddOutlined
              size={24}
              style={{ color: cyan[5], fontSize: 16 }}
            />
          )
        },
        sorter: (a, b) =>
          a.action.toLowerCase() < b.action.toLowerCase() ? -1 : 1,
        key: "action",
      },
      {
        title: "Action",
        ellipsis: true,
        render: (_, e) => actions[e.action],
        sorter: (a, b) =>
          a.action.toLowerCase() < b.action.toLowerCase() ? -1 : 1,
        key: "action",
      },
      {
        title: "Created At",
        ellipsis: true,
        render: (_, e) => format(new Date(e.createdAt), "dd/MM/yyyy HH:mm"),
        sorter: (a, b) => (a.createdAt < b.createdAt ? -1 : 1),
        key: "createdAt",
      },
    ]
  }, [])

  if (history.isError) return <GetError reFetch={history.refetch} />

  return (
    <Card title="Work History">
      <Table
        dataSource={history.data?.records || []}
        columns={columns}
        pagination={{
          current: page,
          onChange: (page, size) => {
            setPage(page)
            setPageSize(size || 1)
          },
          pageSize: 10,
          total: history.data?.total,
        }}
        rowKey="id"
      />
    </Card>
  )
}
