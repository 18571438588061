export const VizznSupportedLanguages = {
  en: "English",
  es: "Spanish",
  // fr: 'French',
  // it: 'Italian',
  // de: 'German',
  // nl: 'Dutch',
  // ru: 'Russian',
  // zh: 'Chinese',
  // ja: 'Japanese',
  // ko: 'Korean',
  // ar: 'Arabic',
  // he: 'Hebrew',
  // hi: 'Hindi',
  // bn: 'Bengali',
  // pa: 'Punjabi',
  // ur: 'Urdu',
  // tr: 'Turkish',
  // fa: 'Persian',
  // th: 'Thai',
  // vi: 'Vietnamese',
  // id: 'Indonesian',
  // ms: 'Malay',
  // fil: 'Filipino',
  // sw: 'Swahili',
  // am: 'Amharic',
  // ha: 'Hausa',
  // yo: 'Yoruba',
  // ig: 'Igbo',
  // zu: 'Zulu',
  // xh: 'Xhosa',
  // so: 'Somali',
  // om: 'Oromo',
  // ti: 'Tigrinya',
  // af: 'Afrikaans',
  // ne: 'Nepali',
  // si: 'Sinhala',
  // ta: 'Tamil',
  // te: 'Telugu',
  // kn: 'Kannada',
  // ml: 'Malayalam',
  // mr: 'Marathi',
  // gu: 'Gujarati',
  // or: 'Odia',
  // as: 'Assamese',
  // mai: 'Maithili',
  // sat: 'Santali',
  // ks: 'Kashmiri',
  // kok: 'Konkani',
  // mni: 'Manipuri',
  // kha: 'Khasi',
  // lus: 'Mizo',
  // brx: 'Bodo',
  // dgo: 'Dogri',
  // sa: 'Sanskrit',
  // trp: 'Kokborok',
  // syl: 'Sylheti',
  // bhi: 'Bhili',
  // lbj: 'Ladakhi',
  // gbm: 'Garhwali',
  // kfy: 'Kumaoni',
  // na: 'Nauruan',
  // tvl: 'Tuvaluan',
  // gil: 'Kiribati',
  // mh: 'Marshallese',
  // pau: 'Palauan',
} as const

export type LanguageCode = keyof typeof VizznSupportedLanguages
// type LanguageName = (typeof VizznSupportedLanguages)[LanguageCode]

export const languageFlagMap: Record<LanguageCode, string> = {
  en: "🇺🇸", // English
  es: "🇪🇸", // Spanish
  //   fr: '🇫🇷', // French
  //   it: '🇮🇹', // Italian
  //   de: '🇩🇪', // German
  //   nl: '🇳🇱', // Dutch
  //   ru: '🇷🇺', // Russian
  //   zh: '🇨🇳', // Chinese
  //   ja: '🇯🇵', // Japanese
  //   ko: '🇰🇷', // Korean
  //   ar: '🇸🇦', // Arabic
  //   he: '🇮🇱', // Hebrew
  //   hi: '🇮🇳', // Hindi
  //   bn: '🇧🇩', // Bengali
  //   pa: '🇮🇳', // Punjabi
  //   ur: '🇵🇰', // Urdu
  //   tr: '🇹🇷', // Turkish
  //   fa: '🇮🇷', // Persian
  //   th: '🇹🇭', // Thai
  //   vi: '🇻🇳', // Vietnamese
  //   id: '🇮🇩', // Indonesian
  //   ms: '🇲🇾', // Malay
  //   fil: '🇵🇭', // Filipino
  //   sw: '🇰🇪', // Swahili
  //   am: '🇪🇹', // Amharic
  //   ha: '🇳🇬', // Hausa
  //   yo: '🇳🇬', // Yoruba
  //   ig: '🇳🇬', // Igbo
  //   zu: '🇿🇦', // Zulu
  //   xh: '🇿🇦', // Xhosa
  //   so: '🇸🇴', // Somali
  //   om: '🇪🇹', // Oromo
  //   ti: '🇪🇷', // Tigrinya
  //   af: '🇿🇦', // Afrikaans
  //   ne: '🇳🇵', // Nepali
  //   si: '🇱🇰', // Sinhala
  //   ta: '🇮🇳', // Tamil
  //   te: '🇮🇳', // Telugu
  //   kn: '🇮🇳', // Kannada
  //   ml: '🇮🇳', // Malayalam
  //   mr: '🇮🇳', // Marathi
  //   gu: '🇮🇳', // Gujarati
  //   or: '🇮🇳', // Odia
  //   as: '🇮🇳', // Assamese
  //   mai: '🇮🇳', // Maithili
  //   sat: '🇮🇳', // Santali
  //   ks: '🇮🇳', // Kashmiri
  //   kok: '🇮🇳', // Konkani
  //   mni: '🇮🇳', // Manipuri
  //   kha: '🇮🇳', // Khasi
  //   lus: '🇮🇳', // Mizo
  //   brx: '🇮🇳', // Bodo
  //   dgo: '🇮🇳', // Dogri
  //   sa: '🇮🇳', // Sanskrit
  //   trp: '🇮🇳', // Kokborok
  //   syl: '🇧🇩', // Sylheti
  //   bhi: '🇮🇳', // Bhili
  //   lbj: '🇮🇳', // Ladakhi
  //   gbm: '🇮🇳', // Garhwali
  //   kfy: '🇮🇳', // Kumaoni
  //   na: '🇳🇷', // Nauruan
  //   tvl: '🇹🇻', // Tuvaluan
  //   gil: '🇰🇮', // Kiribati
  //   mh: '🇲🇭', // Marshallese
  //   pau: '🇵🇼', // Palauan
}

export const vizznSupportedLanguagesArray: {
  label: string
  value: LanguageCode
}[] = Object.entries(VizznSupportedLanguages).map(([key, value]) => ({
  label: `${languageFlagMap[key as LanguageCode]} ${value}`,
  value: key as LanguageCode,
}))
