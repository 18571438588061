import { vizznSupportedLanguagesArray } from "../../types/Language"

export const DIMENSION_UNIT_OPTIONS = [
  {
    label: "Meter",
    value: "meter",
  },
  {
    label: "Foot",
    value: "foot",
  },
]

export const SPEED_UNIT_OPTIONS = [
  {
    label: "KPH",
    value: "kph",
  },
  {
    label: "MPH",
    value: "mph",
  },
]

export const WEIGHT_UNIT_OPTIONS = [
  {
    label: "Kilogram",
    value: "kilogram",
  },
  {
    label: "Pound",
    value: "pound",
  },
  {
    label: "Ton",
    value: "ton",
  },
]

export const DISTANCE_UNIT_OPTIONS = [
  {
    label: "Mile",
    value: "mile",
  },
  {
    label: "Kilometer",
    value: "kilometer",
  },
]

export const VOLUME_UNIT_OPTIONS = [
  {
    label: "Cubic Meters",
    value: "cubicMeters",
  },
  {
    label: "Cubic Yards",
    value: "cubicYards",
  },
  {
    label: "Gallons",
    value: "gallons",
  },
  {
    label: "Liters",
    value: "liters",
  },
]

export const LANGUAGE_OPTIONS = vizznSupportedLanguagesArray

export const ORGANIZTION_TYPE_OPTIONS = [
  {
    label: "Heavy Civil Construction",
    value: "Heavy Civil Construction",
  },
  {
    label: "Supplier",
    value: "Supplier",
  },
  {
    label: "Truck Broker",
    value: "Truck Broker",
  },
]
