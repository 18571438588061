import { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Checkbox, Collapse, Input, Skeleton, Tag } from "antd";
import { uniqBy, without } from "lodash";
import "./SubscribablesList.scss";
import {
  getFilteredData,
  getSubscribableState,
  getSubscribablesByGroup,
  getSubscribedByGroup,
  isStateChecked,
  isStateIndeterminate,
} from "../../../utils/utils";
import { useAuthContext } from "../../../context/AuthContext";
import { useSearchTags } from "../../../utils/useSearchTags";
import { GetError } from "../../../widgets/GetError";
import { SubsribablesGroup } from "../../../types/List";
import { DisplayData } from "../../../types/shared";
import {
  useCreatUserSubscription,
  useDeleteUserSubscription,
  useSubscribables,
  useSubscriptions,
} from "../../../apis/subscriptions";

type Props = {
  userId?: string;
};

export const SubscribablesList = ({ userId }: Props) => {
  const { tags, addTag, removeTag, searchValue, setSearchValue } =
    useSearchTags("subscribables");
  const [filteredData, setFilteredData] = useState<SubsribablesGroup[]>([]);
  const { mutateAsync: createUserSubscription } =
    useCreatUserSubscription(userId);
  const { mutateAsync: deleteUserSubscription } =
    useDeleteUserSubscription(userId);
  const [subscribableStates, setSubscribableStates] = useState<
    Record<string, boolean> | undefined
  >();
  const [subscribablesByGroup, setSubscribablesByGroup] = useState<
    Record<string, DisplayData[]> | undefined
  >();
  const [subscribedByGroup, setSubscribedByGroup] = useState<
    Record<string, string[]> | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const { user: userData, env, token } = useAuthContext();
  const user = userData[env]!;
  const subscribables = useSubscribables({ env, token: token[env] });
  const userSubscriptions = useSubscriptions({
    id: userId ? userId : user.uid,
    env,
    token: token[env],
  });

  // create an index of the subscribables state
  useEffect(() => {
    if (subscribableStates) return;
    const userSubscriptionsIds = userSubscriptions.data?.map(({ id }) =>
      id.slice(`${user.uid}_`.length)
    );
    setSubscribableStates(
      userSubscriptionsIds ? getSubscribableState(userSubscriptionsIds) : {}
    );
  }, [userSubscriptions.data, user.uid, subscribableStates]);

  // create an index, with group name as key, that references all its subscribables (same data different structure)
  useEffect(() => {
    // only create on if subscribablesByGroup does not exist yet and subscribables.data already does
    if (!subscribables.data || subscribablesByGroup) return;
    setSubscribablesByGroup(getSubscribablesByGroup(subscribables.data));
  }, [subscribables.data, subscribablesByGroup]);

  // create an index, with group name as key, that references an array of all the currently selected values
  useEffect(() => {
    if (!subscribables.data) return;
    setSubscribedByGroup(
      getSubscribedByGroup(subscribables.data, subscribableStates || {})
    );
  }, [subscribableStates, subscribables.data]);

  // create a filtered copy of the data to enable users to search
  useEffect(() => {
    if (!subscribables.data) return;
    setFilteredData(getFilteredData(subscribables.data, tags, searchValue));
  }, [searchValue, subscribables.data, tags]);

  if (
    subscribables.isLoading ||
    userSubscriptions.isLoading ||
    !subscribedByGroup ||
    !subscribablesByGroup
  )
    return <Skeleton title paragraph={{ rows: 10 }} active />;
  if (subscribables.error || !subscribables.data)
    return <GetError reFetch={subscribables.refetch} />;
  if (userSubscriptions.error || !userSubscriptions.data)
    return <GetError reFetch={userSubscriptions.refetch} />;

  const setMultipleSubscibalesStates = (
    subscribedIds: string[],
    newValue: boolean
  ) =>
    setSubscribableStates((state) => {
      return {
        ...state,
        ...subscribedIds.reduce((updatedSubscribed, subscribeId) => {
          updatedSubscribed[subscribeId] = newValue;
          return updatedSubscribed;
        }, {} as Record<string, boolean>),
      };
    });

  const updateSubscriptions = async (
    entityName: string,
    selectedValues: string[]
  ) => {
    setIsLoading(true);
    const currentValues = subscribedByGroup[entityName];
    const subscribedIds = without(selectedValues, ...currentValues);
    setMultipleSubscibalesStates(subscribedIds, true);
    if (subscribedIds.length) {
      const success = await createUserSubscription({
        userId: userId ? userId : user.uid,
        subscribedIds,
        env,
        token: token[env],
      });
      if (!success) {
        setMultipleSubscibalesStates(subscribedIds, false);
      }
    }

    const removedSubscriptions = without(currentValues, ...selectedValues);
    setMultipleSubscibalesStates(removedSubscriptions, false);
    if (removedSubscriptions.length) {
      const success = await deleteUserSubscription({
        userId: userId ? userId : user.uid,
        id: removedSubscriptions,
        env,
        token: token[env],
      });
      if (!success) {
        setMultipleSubscibalesStates(removedSubscriptions, true);
      }
    }
    setIsLoading(false);
  };
  return (
    <div className="SubscribablesList">
      <Input
        className="list-search-input"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Type to search..."
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            addTag(searchValue);
            setSearchValue("");
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Backspace" && !searchValue && tags.length) {
            removeTag(tags[tags.length - 1]);
          }
        }}
        addonBefore={
          tags
            ? tags.map((name) => (
                <Tag
                  key={`sorting-tag-${name}`}
                  className="list-tag-filter"
                  color="grey"
                  closable
                  onClose={() => removeTag(name)}
                >
                  {name}
                </Tag>
              ))
            : null
        }
        addonAfter={<SearchOutlined />}
      />
      <Collapse
        className="subscribables-list"
        defaultActiveKey={subscribables.data.map(([entityName]) => entityName)}
      >
        {filteredData.map(([entityName, displayedSubscribables]) => {
          const subscribablesByGroupInEntity =
            subscribablesByGroup[entityName] || [];
          return (
            <Collapse.Panel
              header={
                <Checkbox
                  disabled={isLoading}
                  indeterminate={isStateIndeterminate(
                    subscribablesByGroupInEntity,
                    subscribedByGroup[entityName]
                  )}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const selectedValues = e.target.checked
                      ? subscribablesByGroupInEntity.map(({ id }) => id) || []
                      : [];
                    updateSubscriptions(entityName, selectedValues);
                  }}
                  checked={isStateChecked(
                    subscribablesByGroupInEntity,
                    subscribedByGroup[entityName]
                  )}
                >
                  {entityName}
                </Checkbox>
              }
              key={entityName}
            >
              <Checkbox.Group
                disabled={isLoading}
                options={uniqBy(
                  displayedSubscribables.map(({ name, id }) => ({
                    label: name,
                    value: id,
                  })),
                  "value"
                )}
                value={subscribedByGroup[entityName]}
                onChange={async (selectedValues) => {
                  // selectedValues is only the values selected of the rendered boxes
                  // to define all the selected values, including the ones previously selected,
                  // we define next value which has all of the current values that are not visible by user when data is filtered
                  // plus all the selected ones from the visible ones
                  const currentValues = subscribedByGroup[entityName];
                  const displayedSubscribableIds = displayedSubscribables.map(
                    ({ id }) => id
                  );
                  const nextValues = [
                    ...currentValues.filter(
                      (id) => !displayedSubscribableIds.includes(id)
                    ),
                    ...(selectedValues as string[]),
                  ];
                  updateSubscriptions(entityName, nextValues);
                }}
              />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};
