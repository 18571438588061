import { CaretDownFilled, CaretUpFilled, EditOutlined } from "@ant-design/icons"
import { Button, Form, Input, Modal, Tooltip } from "antd"
import { useEffect, useMemo, useState } from "react"
import "react-quill/dist/quill.snow.css"
import { useUpdateFeatureFlag } from "../../apis/featureFlags"
import { useAuthContext } from "../../context/AuthContext"
import { environmentType } from "../../types/environtment"
import { ReducedFlag, UpdateFeaturFlagDTO } from "../../types/featureFlags"
import { reduceHtmlBr } from "../../utils/reduceHtmlBr"
import { useGetUserData } from "../../apis/user"
import ReactQuill from "react-quill"
import parse from "html-react-parser"

type Props = {
  flag: ReducedFlag
  env: environmentType
  mode: "increment" | "update" | "sync" | "decrement"
}

export const UpdateFeatureFlagModal = ({ flag, env, mode }: Props) => {
  const updateMode = mode === "update"
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState<boolean>(false)
  const [name, setName] = useState<string | undefined>()
  const { token, user } = useAuthContext()
  const userName = useGetUserData({
    userId: user.dev?.uid,
    env,
    token: token[env],
  })

  const { mutateAsync: updateFeatureFlag } = useUpdateFeatureFlag()
  const nextVersion =
    (mode === "increment"
      ? Number(flag.activeVersion[env] ?? 0) + 1
      : flag.activeVersion.dev ?? 1) ?? 1
  const previousVersion = Math.max(Number(flag.activeVersion[env] ?? 0) - 1, 1)

  useEffect(() => {
    return () => {
      form.resetFields()
    }
  }, [form])

  const setFields = form.setFieldsValue
  useEffect(() => {
    setFields({
      name: flag.name,
      activeVersion: mode === "decrement" ? previousVersion : nextVersion,
      type: "app",
      description: "",
    })
    // This useEffect should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  const resetFields = form.resetFields
  useEffect(() => {
    if (!open) {
      resetFields()
      setName(undefined)
    }
  }, [open, resetFields])

  const handleFinish = async (values: {
    activeVersion: string
    description: string
  }) => {
    setLoading(true)
    const payload: {
      env: environmentType
      token: string | null
      payload: UpdateFeaturFlagDTO
    } = {
      env,
      token: token[env],
      payload: {
        name: flag.name,
        activeVersion: +values.activeVersion,
        type: "app",
        description: flag.description + newText,
      },
    }

    const data = await updateFeatureFlag(payload)
    setLoading(false)
    form.resetFields()
    if (data) setOpen(false)
  }

  const titleMap = useMemo(
    () => ({
      update: "Edit",
      increment: "Increment",
      sync: "Sync",
      decrement: "Decrement",
    }),
    []
  )

  const labelMap = useMemo(
    () => ({
      update: "Updating",
      increment: "Incrementing",
      sync: "Syncing",
      decrement: "Decrementing",
    }),
    []
  )

  const buttonLabelMap = useMemo(
    () => ({
      update: "Update",
      increment: "Increment Active Version to ",
      sync: "Sync Active Version to ",
      decrement: "Decrement Active Version to ",
    }),
    []
  )
  const newDescription = Form.useWatch("description", form)
  const currentDate = new Date().toLocaleString("en-CA", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "America/Edmonton",
  })
  const newVersion = form.getFieldValue("activeVersion")
  const newText = `<p>${currentDate} - v${newVersion} - ${newDescription} - ${userName.data?.firstName}</p>`

  return open ? (
    <Modal
      title={`${titleMap[mode]} Feature Flag: ${flag.name}${
        mode !== "update" &&
        ` to v${mode === "decrement" ? previousVersion : nextVersion}`
      } - [${env.toUpperCase()}]`}
      open={open}
      footer={null}
      onCancel={() => {
        setOpen(false)
        resetFields()
      }}
      width={"80%"}
    >
      <Form form={form} onFinish={handleFinish}>
        {updateMode && (
          <>
            <Form.Item name="name" label="Name">
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={`Feature flag name`}
              />
            </Form.Item>
          </>
        )}

        {!updateMode && (
          <>
            <Form.Item
              name="currentActiveVersion"
              label="Current Active Version"
              initialValue={flag.activeVersion[env] ?? 0}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="activeVersion"
              label={`${labelMap[mode]} to Active Version`}
            >
              <Input disabled />
            </Form.Item>
          </>
        )}

        <Form.Item name="description" label="Change Logs/Notes">
          <ReactQuill theme="snow" />
        </Form.Item>

        {flag.description && (
          <>
            <hr />
            {parse(reduceHtmlBr(flag.description))}
          </>
        )}

        <hr />
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            {buttonLabelMap[mode]}
            {!updateMode && (
              <strong
                style={{
                  fontSize: 16,
                }}
              >
                {" "}
                ({mode === "decrement" ? previousVersion : nextVersion})
              </strong>
            )}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  ) : (
    <>
      {mode !== "decrement" && (
        <Tooltip
          title={
            mode === "update"
              ? env === "dev"
                ? "You can't edit desciptions in dev"
                : "Edit"
              : `${titleMap[mode]} to v${nextVersion}`
          }
        >
          <Button
            size="small"
            onClick={() => setOpen(true)}
            type="primary"
            disabled={env === "dev" && mode === "update"}
            style={{
              cursor: env === "dev" ? "not-allowed" : "pointer",
            }}
            icon={
              mode === "increment" || mode === "sync" ? (
                <CaretUpFilled />
              ) : (
                <EditOutlined />
              )
            }
          />
        </Tooltip>
      )}
      {mode === "decrement" && (
        <Tooltip title={`${titleMap[mode]} to v${previousVersion}`}>
          <Button
            size="small"
            onClick={() => setOpen(true)}
            type="primary"
            icon={<CaretDownFilled />}
          />
        </Tooltip>
      )}
    </>
  )
}
