import { Alert, Form, Input, message, Modal, Select } from "antd"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useUpdateProperty } from "../../../apis/notifications"
import { useAuthContext } from "../../../context/AuthContext"
import { NotificationEntityProperty } from "../../../types/notifications"
import { entityType } from "../constant"

type Props = {
  property: NotificationEntityProperty
  isVisible: boolean
  setVisible: Dispatch<SetStateAction<NotificationEntityProperty | undefined>>
}
type Values = {
  description: string
}

export const UpdatePropertyModal = ({
  property,
  isVisible,
  setVisible,
}: Props) => {
  const { token } = useAuthContext()
  const [form] = Form.useForm()
  const { mutateAsync: updateProperty } = useUpdateProperty()
  const [isLoading, setIsLoading] = useState(false)
  const { TextArea } = Input

  const setFields = form.setFieldsValue
  useEffect(() => {
    if (isVisible) {
      setFields({ ...property })
    }
  }, [isVisible, setFields, property])

  const handleFinish = async (values: Values) => {
    if (!token.dev) {
      return message.error("Please log in on Dev")
    }
    if (!token.prod) {
      return message.error("Please log in on Prod")
    }
    setIsLoading(true)
    const payload = { ...property, ...values }
    if (!property.relation) {
      delete payload.relation
    }
    await Promise.allSettled([
      updateProperty({
        payload,
        env: "dev",
        token: token.dev,
      }),
      updateProperty({
        payload,
        env: "prod",
        token: token.prod,
      }),
    ])
    setIsLoading(false)
    setVisible(undefined)
  }
  return (
    <Modal
      title={`Edit Property: ${property.id}`}
      open={isVisible}
      onCancel={() => setVisible(undefined)}
      onOk={() => handleFinish(form.getFieldsValue())}
      okButtonProps={{
        loading: isLoading,
      }}
      cancelButtonProps={{
        loading: isLoading,
      }}
    >
      <Alert
        message="This property will be updated for dev and prod, You need to be logged in both environments"
        type="info"
        style={{ marginBottom: 12 }}
      />
      <Form form={form} layout="vertical">
        <Form.Item
          name="entityType"
          label="Entity Type"
          initialValue={property.entityType}
        >
          <Select disabled>
            {entityType.map((entity) => (
              <Select.Option key={entity.id} value={entity.id}>
                {entity.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="relation"
          label="Relation"
          initialValue={property.relation}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="property"
          label="Property"
          rules={[
            {
              required: true,
              message: "Please input property name",
            },
          ]}
          initialValue={property.property}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          initialValue={property.description}
        >
          <TextArea rows={6} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
