import { Button, Card, Col, Divider, Row, Select } from "antd"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { TopTeamsAndUseLogCount } from "../components/Home/TopTeamsAndUseLogCount"
import DashboardUsageScores from "../components/Home/widget/DashboardUsageScores"
import { useAuthContext } from "../context/AuthContext"
import { environmentType } from "../types/environtment"

export const Home = () => {
  const { handleLogOut, handleSetEnv, env, user } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user[env]) navigate("/login")
  }, [env, navigate, user])
  return (
    <div>
      <Card title="Environment Selector">
        <Row gutter={16} align="middle">
          <Col>
            <Button
              type="primary"
              onClick={() => handleLogOut(() => navigate("/login"))}
              danger
            >
              Log out
            </Button>
          </Col>
          <Col span={5}>
            <div>
              <Select
                defaultValue={env}
                onChange={(value: environmentType) => handleSetEnv(value)}
              >
                <Select.Option value="dev">Development</Select.Option>
                <Select.Option value="prod">Production</Select.Option>
              </Select>
            </div>
          </Col>
        </Row>
      </Card>
      <Divider />
      <TopTeamsAndUseLogCount />
      <Divider />
      <DashboardUsageScores />
    </div>
  )
}
