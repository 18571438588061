import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";

import { useCreateTopic, useUpdateTopic } from "../../apis/topics";
import { Topic, TopicRequestDto } from "../../types/Topic";
type Props = {
  edit?: boolean;
  topic?: Topic;
  details?: boolean;
};

type Values = {
  name: string;
  url: string;
  schema: string;
};

export const NewTopic = ({ edit, topic, details }: Props) => {
  const { env, token } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { mutateAsync: createTopic } = useCreateTopic();
  const { mutateAsync: updateTopic } = useUpdateTopic();
  const [form] = Form.useForm();
  const isEdit = edit && topic;
  useEffect(() => {
    if (topic) {
      form.setFieldsValue({
        name: topic.name,
        url: topic.url,
        schema: topic.schema,
      });
    }
  }, [topic, form]);

  const handleFinish = async (values: Values) => {
    setLoading(true);
    const payload = {
      ...values,
    } as TopicRequestDto;
    edit && topic
      ? await updateTopic({
          env,
          token: token[env],
          payload: { ...payload },
        })
      : await createTopic({ env, token: token[env], payload });
    setLoading(false);
    setOpen(false);
    form.resetFields();
  };

  return (
    <>
      {isEdit ? (
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => setOpen(true)}
        />
      ) : (
        <Button onClick={() => setOpen(true)} type="primary">
          New Topic
        </Button>
      )}

      {open && (
        <Modal
          open={open}
          onCancel={() => setOpen(false)}
          title={isEdit ? `Edit ${topic.name}` : "New Topic"}
          footer={null}
        >
          <Form form={form} onFinish={handleFinish}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please input a name" }]}
              initialValue={topic ? topic.name : undefined}
            >
              <Input disabled={edit} />
            </Form.Item>
            <Form.Item
              name="url"
              label="Url"
              rules={[{ required: true, message: "Please input an url" }]}
              initialValue={topic ? topic.url : undefined}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="schema"
              label="Schema"
              rules={[{ required: true, message: "Please input an schema" }]}
              initialValue={topic ? topic.schema : undefined}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                {edit ? "Update" : "Create Topic"}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};
