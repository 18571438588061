import { motion } from "framer-motion";
import React from "react";
type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const MotionWrapper = ({ children }: Props) => {
  return (
    <motion.div
      key={window.location.href}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
    >
      {children}
    </motion.div>
  );
};
