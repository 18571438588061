import { EditOutlined, TeamOutlined } from "@ant-design/icons"
import { Tooltip, Button } from "antd"
import { VizznUser } from "../../types/user"
import { RowContent } from "../Utils/RowContent"
import { useGetSingleTeam } from "../../apis/teams"
import { useAuthContext } from "../../context/AuthContext"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { ChangeTeamModal } from "./ChangeTeamModal"
import { CopyContent } from "../Utils/CopyContent"

type Props = { user: VizznUser }

export default function UserTeamWidget({ user }: Props) {
  const { env, token } = useAuthContext()
  const team = useGetSingleTeam({ id: user.teamId, env, token: token[env] })
  const navigate = useNavigate()
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <RowContent
      name="Team"
      content={
        <>
          <div style={{ display: "flex", gap: "5px", marginRight: "5px" }}>
            <b>{team.data?.name}</b>
            <CopyContent text={user.teamId} hideText tooltip={`Copy Team ID`} />
          </div>

          <Tooltip title="Team Overview">
            <Button
              size="small"
              type="default"
              style={{ marginRight: "10px" }}
              onClick={() => navigate(`/teams/${user.teamId}`)}
            >
              <TeamOutlined />
            </Button>
          </Tooltip>

          <Button size="small" onClick={() => setModalVisible(true)}>
            <EditOutlined />
          </Button>

          {modalVisible && (
            <ChangeTeamModal
              currentTeam={user.teamId}
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              userId={user.id}
            />
          )}
        </>
      }
    />
  )
}
