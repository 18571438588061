import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Popconfirm, Tooltip, Typography, message } from "antd";
import React, { useState } from "react";
import styles from "../../styles/UniversityVideos.module.css";
import { UniversityVideo } from "../../types/university";
import { UpdateVideoModal } from "./UpdateVideoModal";
import { useRemoveUniversityVideo } from "../../apis/university";
import { useAuthContext } from "../../context/AuthContext";

type Props = {
  video: UniversityVideo;
};

export const VideoCard = ({ video }: Props) => {
  const [editing, setEditing] = useState<boolean>(false);
  const { mutateAsync: removeUniversityVideo } = useRemoveUniversityVideo();
  const { env, token } = useAuthContext();

  return (
    <div key={video.id} className={styles.videoCard}>
      <Card>
        <iframe
          style={{ border: "none" }}
          src={video.path}
          title={video.name}
        />
        <Typography.Title level={4}>{video.name}</Typography.Title>
        <p>Categories: {video.categories.join(", ")}</p>
        <p>Featured Categories: {video.featuredCategories.join(", ")}</p>
        <p>Order: {video.order}</p>
        {video.description && <p>Description: {video.description}</p>}
        <p>Featured: {video.isFeatured ? "Yes" : "No"}</p>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            type="primary"
            onClick={() => setEditing(true)}
            icon={<EditOutlined />}
          >
            Edit
          </Button>

          <Tooltip title="Remove Video">
            <Popconfirm
              title="Are you sure about removing this video?"
              onConfirm={async () => {
                const videoId = video.id;
                await removeUniversityVideo({
                  id: videoId,
                  env,
                  token: token[env],
                });
                message.success("Video Removed");
              }}
              okText={"Yes"}
              cancelText={"No"}
            >
              <Button type="primary" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </div>
      </Card>
      <UpdateVideoModal
        isVisible={editing}
        video={video}
        setVisible={setEditing}
      />
    </div>
  );
};
