import React from "react";
import { useParams } from "react-router-dom";
import { ServiceOverview } from "../components/Services/ServiceOverview";

export const ServicesDetail = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <div>
      <ServiceOverview id={id || ""} />
    </div>
  );
};
