import { Col, Input, Row, Skeleton } from "antd";
import { useGetUniversityVideos } from "../apis/university";
import { useAuthContext } from "../context/AuthContext";
import { GetError } from "../widgets/GetError";
import { VideoCard } from "../components/University/VideoCard";
import { filterBySearchValue } from "../utils/search";
import { useState } from "react";
import { AddVideoModal } from "../components/University/AddVideoModal";

export const VizznUniversityVideos = () => {
  const { token, env } = useAuthContext();
  const [search, setSearch] = useState<string>("");
  const videos = useGetUniversityVideos({ env, token: token[env] });
  if (videos.isLoading) return <Skeleton />;
  if (videos.error || !videos.data)
    return <GetError reFetch={videos.refetch} />;

  const searchFields = ["name", "categories"];

  const filteredData = [...videos.data];
  let filteredByValue = filterBySearchValue({
    searchValue: search,
    searchFields,
    dataArray: filteredData,
  });
  return (
    <>
      <Row gutter={8} style={{ marginBottom: 16 }}>
        <Col flex="auto">
          <Input.Search
            placeholder="input to search videos"
            onChange={(e) => setSearch(e.target.value)}
            style={{ width: "100%" }}
          />
        </Col>
        <Col>
          <AddVideoModal />
        </Col>
      </Row>
      <Row gutter={16}>
        {filteredByValue
          .sort((a, b) => Number(a.order) - Number(b.order))
          .map((video) => (
            <div>
              <VideoCard video={video} />
            </div>
          ))}
      </Row>
    </>
  );
};
