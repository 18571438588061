import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { VizznResponse } from "../../types/Api"
import { EnterpriseDetail } from "../../types/Enterprise"
import { environmentType } from "../../types/environtment"
import { getEnvUrl } from "../../utils/utils"

const getEnterpriseDetail = async (body: {
  env: environmentType
  token: string | null
  id: string
}) => {
  if (!body.token) return
  const { data } = await axios.get<VizznResponse<EnterpriseDetail>>(
    `${getEnvUrl(body.env)}/v1/enterprises/${body.id}`,
    {
      headers: { Authorization: `Bearer ${body.token}` },
    }
  )
  return data.data
}

export const useGetEnterpriseDetail = (body: {
  env: environmentType
  token: string | null
  id: string
}) => {
  return useQuery({
    queryKey: ["enterprise-detail", body.id],
    queryFn: () => getEnterpriseDetail(body),
  })
}
