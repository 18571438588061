import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Tag,
  Tooltip,
} from "antd"
import { useCreateBucket, useUpdateBucket } from "../../../apis/notifications"
import { useAuthContext } from "../../../context/AuthContext"
import {
  NotificationEntityBucket,
  NotificationEntityProperty,
} from "../../../types/notifications"
import { entityType } from "../constant"
import { DeleteOutlined } from "@ant-design/icons"

type Props = {
  bucket: NotificationEntityBucket
  devBucketId: string
  isVisible: boolean
  setVisible: Dispatch<SetStateAction<NotificationEntityBucket | undefined>>
  entityProperties: string[]
  notificationProperties: NotificationEntityProperty[]
}
type Values = {
  name: string
  description: string
  isPublished: boolean
  roles: string[]
}

export const UpdateBucketModal = ({
  bucket,
  isVisible,
  setVisible,
  entityProperties,
  devBucketId,
  notificationProperties,
}: Props) => {
  const { env, token } = useAuthContext()
  const [form] = Form.useForm()
  const { mutateAsync: createBucket } = useCreateBucket()
  const [properties, setProperties] = useState(
    bucket.properties.map((property) => property.id)
  )
  const [propertiesSearch, setPropertiesSearch] = useState("")
  const [selectedPropertiesSearch, setSeletectedPropertiesSearch] = useState("")
  const { TextArea } = Input
  const { mutateAsync: updateBucket } = useUpdateBucket()

  const setFields = form.setFieldsValue

  useEffect(() => {
    if (isVisible) {
      setFields({ ...bucket })
    }
  }, [isVisible, setFields, bucket])

  const handleFinish = async (values: Values) => {
    await updateBucket({
      payload: { ...bucket, ...values, propertyIds: properties },
      env: env,
      token: token[env],
    })

    if (env === "prod") {
      await updateBucket({
        payload: { ...bucket, ...values, propertyIds: properties },
        env: "prod",
        token: token["prod"],
      })
      if (!devBucketId) {
        await createBucket({
          payload: { ...bucket, ...values, entityType: bucket.entityType },
          env: "dev",
          token: token["dev"],
        })

        await updateBucket({
          payload: {
            ...bucket,
            ...values,
            propertyIds: properties,
            id: devBucketId,
          },
          env: "dev",
          token: token["dev"],
        })
      } else {
        await updateBucket({
          payload: {
            ...bucket,
            ...values,
            propertyIds: properties,
            id: devBucketId,
          },
          env: "dev",
          token: token["dev"],
        })
      }
    }

    setVisible(undefined)
    message.success("Bucket updated successfully")
  }

  return (
    <Modal
      title={`Edit Bucket: ${bucket.entityType}/${bucket.name}`}
      width={900}
      open={isVisible}
      onCancel={() => setVisible(undefined)}
      onOk={() => handleFinish(form.getFieldsValue())}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="entityType"
          label="Entity Type"
          initialValue={bucket.entityType}
        >
          <Select disabled>
            {entityType.map((entity) => (
              <Select.Option key={entity.id} value={entity.id}>
                {entity.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row gutter={16}>
          <Col flex="auto">
            <Form.Item
              name="name"
              label="Bucket Name"
              initialValue={bucket.name}
              rules={[{ required: true, message: "Please input bucket name" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="isPublished"
              label="Publish"
              initialValue={bucket.isPublished}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="description"
          label="Description"
          initialValue={bucket.description}
        >
          <TextArea rows={6} />
        </Form.Item>
        {/* <Form.Item
          name="roles"
          label="User Roles"
          initialValue={bucket.roles}
          rules={[
            {
              required: true,
              message:
                "Please select roles of users who can subscribe to this bucket",
            },
          ]}
        >
          <Select mode="multiple">
            {systemRoles.map((role) => (
              <Select.Option value={role.id}>{role.name}</Select.Option>
            ))}
            {jobRoles.map((role) => (
              <Select.Option value={role.id}>{role.name}</Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        <Row gutter={16} style={{ paddingBottom: 20 }}>
          <Col span={12}>
            Selected Properties
            <Input
              placeholder="Search properties"
              onChange={(e) => setSeletectedPropertiesSearch(e.target.value)}
            />
            <div
              style={{
                overflowY: "auto",
                height: "400px",
              }}
            >
              {properties
                .filter((property) =>
                  property.includes(selectedPropertiesSearch)
                )
                .sort((a, b) => a.localeCompare(b))
                .map((property) => (
                  <div key={property}>
                    <Tooltip
                      title={
                        notificationProperties.find(
                          (prop) => prop.id === property
                        )?.description || "No description provided"
                      }
                    >
                      <Tag>{property}</Tag>
                    </Tooltip>
                    <Button
                      type="primary"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        setProperties((prev) => [
                          ...prev.filter(
                            (propertyId) => propertyId !== property
                          ),
                        ])
                      }}
                    />
                  </div>
                ))}
            </div>
          </Col>
          <Col span={12}>
            Available Properties
            <Input
              placeholder="Search properties"
              onChange={(e) => setPropertiesSearch(e.target.value)}
            />
            <div
              style={{
                overflowY: "auto",
                height: "400px",
              }}
            >
              {entityProperties
                .filter(
                  (property) =>
                    !properties.includes(property) &&
                    property.includes(propertiesSearch)
                )
                .sort((a, b) => a.localeCompare(b))
                .map((property) => (
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    key={property}
                    onClick={() => {
                      setProperties((prev) => [...prev, property])
                    }}
                  >
                    <Tooltip
                      title={
                        notificationProperties.find(
                          (prop) => prop.id === property
                        )?.description || "No description provided"
                      }
                    >
                      <Tag>{property}</Tag>
                    </Tooltip>
                  </div>
                ))}
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
