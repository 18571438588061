import { Button, Col, Row } from "antd";

interface ErrorRefetchProps {
  refetch: () => void;
}

export default function ErrorRefetch({ refetch }: ErrorRefetchProps) {
  return (
    <Col>
      <Row>An error has occured fetching the data. Please try again. </Row>
      <Row>
        <Button onClick={refetch}>Refetch Data</Button>
      </Row>
    </Col>
  );
}
