import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { message } from "antd"
import axios from "axios"
import { environmentType } from "../types/environtment"
import {
  NotificationTaxonomy,
  NotificationEntityProperty,
  NotificationEntityPropertyRequest,
  NotificationEntityBucket,
  NotificationEntityBucketRequest,
} from "../types/notifications"
import { getEnvUrl } from "../utils/utils"
import { VizznResponse } from "../types/Api"

const getNotificationTaxonomy = async (body: {
  env: environmentType
  token: string | null
}) => {
  if (!body.token) return
  const { data } = await axios.get(
    `https://voiz.app/v2/notifications/taxonomy?env=${body.env}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) return data.data
}

export const useGetNotificationTaxonomy = (body: {
  env: environmentType
  token: string | null
}) => {
  const response = useQuery<NotificationTaxonomy>(
    ["notification-taxonomy", body.env],
    () => getNotificationTaxonomy(body)
  )

  return response
}

export const updateNotificationTaxonomy = async (body: {
  env: environmentType
  token?: string | null
  payload: NotificationTaxonomy
}) => {
  if (!body.token) return
  const { data } = await axios.put(
    `https://voiz.app/v2/notifications/taxonomy?env=${body.env}`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) {
    message.success(`Notification taxonomy updated`)
    return data.data
  }
}

const getProperties = async (body: {
  env: environmentType
  token: string | null
}) => {
  try {
    const { data } = await axios.get<
      VizznResponse<NotificationEntityProperty[]>
    >(`${getEnvUrl(body.env)}/v1/notifiable/property`, {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    })

    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}
export const useGetProperties = (body: {
  env: environmentType
  token: string | null
}) => {
  const response = useQuery<NotificationEntityProperty[] | undefined>(
    ["notification-entity-property", body.env],
    () => getProperties(body)
  )
  return response
}

const getBuckets = async (body: {
  env: environmentType
  token: string | null
}) => {
  try {
    const { data } = await axios.get<VizznResponse<NotificationEntityBucket[]>>(
      `${getEnvUrl(body.env)}/v1/notifiable/bucket`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )

    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}
export const useGetBuckets = (body: {
  env: environmentType
  token: string | null
}) => {
  const response = useQuery<NotificationEntityBucket[] | undefined>(
    ["notification-entity-bucket", body.env],
    () => getBuckets(body)
  )
  return response
}

const createProperty = async (body: {
  env: environmentType
  token?: string | null
  payload: NotificationEntityPropertyRequest
}) => {
  if (!body.token) return
  const { data } = await axios.post(
    `${getEnvUrl(body.env)}/v1/notifiable/property`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) {
    message.success(`Property "${data.data.id}" created in ${body.env}`)
    return data.data
  }
}
export const useCreateProperty = () => {
  const queryClient = useQueryClient()
  return useMutation(createProperty, {
    onSuccess: async (data, params) => {
      if (!data) return
      const oldData = queryClient.getQueryData<NotificationEntityProperty[]>([
        "notification-entity-property",
        params.env,
      ])
      if (oldData) {
        const records = [...oldData, data]
        queryClient.setQueriesData(
          ["notification-entity-property", params.env],
          () => records
        )
      }
    },
  })
}

const createBucket = async (body: {
  env: environmentType
  token?: string | null
  payload: NotificationEntityBucketRequest
}) => {
  if (!body.token) return
  const { data } = await axios.post(
    `${getEnvUrl(body.env)}/v1/notifiable/bucket`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) {
    message.success(
      `Bucket "${data.data.entityType}/${data.data.name}" created`
    )
    return data.data
  }
}
export const useCreateBucket = () => {
  const queryClient = useQueryClient()
  return useMutation(createBucket, {
    onSuccess: async (data, params) => {
      if (!data) return
      const oldData = queryClient.getQueryData<NotificationEntityBucket[]>([
        "notification-entity-bucket",
        params.env,
      ])
      if (oldData) {
        const records = [...oldData, data]
        queryClient.setQueriesData(
          ["notification-entity-bucket", params.env],
          () => records
        )
      }
    },
  })
}

const updateProperty = async (body: {
  env: environmentType
  token?: string | null
  payload: NotificationEntityPropertyRequest
}) => {
  if (!body.token) return
  const { data } = await axios.put(
    `${getEnvUrl(body.env)}/v1/notifiable/property/${body.payload.id}`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) {
    message.success(`Property "${data.data.id}" updated in ${body.env}`)
    return data.data
  }
}
export const useUpdateProperty = () => {
  const queryClient = useQueryClient()
  return useMutation(updateProperty, {
    onSuccess: async (data, params) => {
      if (!data) return
      const oldData = queryClient.getQueryData<NotificationEntityProperty[]>([
        "notification-entity-property",
        params.env,
      ])
      if (oldData) {
        const index = oldData.findIndex(
          (property) => property.id === params.payload.id
        )
        oldData[index] = data
        queryClient.setQueriesData(
          ["notification-entity-property", params.env],
          () => oldData
        )
      }
    },
  })
}

const updateBucket = async (body: {
  env: environmentType
  token?: string | null
  payload: NotificationEntityBucketRequest & {
    id: string
    propertyIds: string[]
  }
}) => {
  if (!body.token) return
  const { data } = await axios.put(
    `${getEnvUrl(body.env)}/v1/notifiable/bucket/${body.payload.id}`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) {
    return data.data
  }
}
export const useUpdateBucket = () => {
  const queryClient = useQueryClient()
  return useMutation(updateBucket, {
    onSuccess: async (data, params) => {
      if (!data) return
      const oldData = queryClient.getQueryData<NotificationEntityBucket[]>([
        "notification-entity-bucket",
        params.env,
      ])
      if (oldData) {
        const index = oldData.findIndex(
          (bucket) => bucket.id === params.payload.id
        )
        oldData[index] = data
        queryClient.setQueriesData(
          ["notification-entity-bucket", params.env],
          () => oldData
        )
      }
    },
  })
}

const deleteProperty = async (body: {
  env: environmentType
  token?: string | null
  id: string
}) => {
  if (!body.token) return
  const { data } = await axios.delete(
    `${getEnvUrl(body.env)}/v1/notifiable/property/${body.id}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) {
    message.success(`Property "${data.data.id}" deleted from ${body.env}`)
    return data.data
  }
}
export const useDeleteProperty = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteProperty, {
    onSuccess: async (data, params) => {
      if (!data) return
      const oldData = queryClient.getQueryData<NotificationEntityProperty[]>([
        "notification-entity-property",
        params.env,
      ])
      if (oldData) {
        const records = oldData.filter((property) => property.id !== params.id)
        queryClient.setQueriesData(
          ["notification-entity-property", params.env],
          () => records
        )
      }
    },
  })
}

const deleteBucket = async (body: {
  env: environmentType
  token?: string | null
  id: string
}) => {
  if (!body.token) return
  const { data } = await axios.delete(
    `${getEnvUrl(body.env)}/v1/notifiable/bucket/${body.id}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) {
    return data.data
  }
}
export const useDeleteBucket = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteBucket, {
    onSuccess: async (data, params) => {
      if (!data) return
      const oldData = queryClient.getQueryData<NotificationEntityProperty[]>([
        "notification-entity-bucket",
        params.env,
      ])
      if (oldData) {
        const records = oldData.filter((bucket) => bucket.id !== params.id)
        queryClient.setQueriesData(
          ["notification-entity-bucket", params.env],
          () => records
        )
      }
    },
  })
}
