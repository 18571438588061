import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { VizznResponse } from "../../types/Api"
import { environmentType } from "../../types/environtment"
import { getEnvUrl } from "../../utils/utils"

const setEnterpriseTeam = async (body: {
  env: environmentType
  token: string | null
  teamId: string
  enterpriseId: string
  remove?: boolean
}) => {
  if (!body.token) return

  const { data } = await axios.post<VizznResponse<null>>(
    `${getEnvUrl(body.env)}/v1/teams/${body.teamId}/enterprise`,
    {
      teamId: body.teamId,
      enterpriseId: body.enterpriseId,
      remove: body.remove,
    },
    { headers: { Authorization: `Bearer ${body.token}` } }
  )
  return data.data
}

export const useSetEnterpriseTeam = (enterpriseId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: setEnterpriseTeam,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["enterprise-detail", enterpriseId],
      })
    },
  })
}
