import { useEffect, useMemo, useState } from "react"
import { AllUser } from "../../../types/SysAdmin"
import Table, { ColumnsType } from "antd/es/table"
import { Col, Input, Row, Tag } from "antd"
import { useNavigate } from "react-router-dom"
import CopyTextButton from "../../../components/common/CopyTextButton"
import "./SysAdminAllUsersTable.scss"

type Props = {
  allUsers: AllUser[]
}

export default function SysAdminAllUsersTable({ allUsers }: Props) {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [filteredUserAccounts, setFilteredUserAccounts] = useState<AllUser[]>(
    []
  )

  useEffect(() => {
    if (searchTerm === "") {
      return setFilteredUserAccounts(allUsers)
    }

    return setFilteredUserAccounts(
      allUsers.filter(
        (u) =>
          u.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          u.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          u.team.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          u.roleNames.join(" ").toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
  }, [searchTerm, allUsers])

  const columns: ColumnsType<AllUser> = useMemo(
    () => [
      {
        title: "Name",
        key: "name",
        render: (_, e) => e.name,
      },
      {
        title: "ID",
        key: "id",
        render: (_, e) => (
          <Row gutter={8}>
            <Col>{e.id}</Col>
            <Col>
              <CopyTextButton text={e.id} />
            </Col>
          </Row>
        ),
      },
      {
        title: "Team",
        key: "team.id",
        render: (_, e) => e.team.name,
      },
      {
        title: "Roles",
        key: "roleNames",
        render: (_, e) => {
          return e.roleNames.map((r) => <Tag key={r}>{r}</Tag>)
        },
      },
    ],
    []
  )
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
      id="SysAdminAllUsersTable"
    >
      <Input.Search
        style={{ width: "100%" }}
        placeholder="Search"
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
      />
      <Table
        style={{ minWidth: "100%", cursor: "pointer" }}
        columns={columns}
        rowKey="id"
        dataSource={filteredUserAccounts}
        pagination={false}
        rowClassName={(r) =>
          r.activeAccount ? "active-user-row" : "inactive-user-row"
        }
        onRow={(record) => {
          return {
            onClick: () => {
              return navigate(`/users/${record.id}`)
            },
          }
        }}
      />
    </div>
  )
}
