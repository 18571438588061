import { Dispatch, SetStateAction } from "react";
import { Modal, Select, Switch, Button } from "antd";
import { EntityNotificationTaxonomy } from "../../types/notifications";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

type modal = {
  open: boolean;
  type?: "team" | "entity";
  index?: number;
  dev?: Partial<EntityNotificationTaxonomy>;
  prod?: Partial<EntityNotificationTaxonomy>;
};

type Props = {
  modal: modal;
  setModal: Dispatch<SetStateAction<modal>>;
  onOk: () => void;
};

const Label = ({ label }: { label: string }) => (
  <label
    style={{
      fontWeight: "bold",
      fontSize: 16,
      color: "#19b1b4",
      display: "block",
    }}
  >
    {label}
  </label>
);

const IsPublished = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value?: boolean;
  onChange: () => void;
}) => {
  return (
    <>
      <span style={{ marginRight: 7 }}>{label}</span>
      <Switch
        title="isPublished"
        unCheckedChildren="No"
        checkedChildren="Yes"
        defaultChecked={value}
        onChange={onChange}
        style={{ marginRight: 15 }}
      />
    </>
  );
};

const userRoles = [
  "editor",
  "admin",
  "limited",
  "owner",
  "manager",
  "supervisor",
];
const entities = ["site", "driver", "equipment", "truck", "trailer"];

export const TaxonomyModal = ({ modal, setModal, onOk }: Props) => {
  const handle = {
    addBucket: () => {
      setModal((modal) => {
        if (modal.dev?.buckets)
          modal.dev.buckets.push({
            id: "",
            name: "",
            isPublished: false,
            details: "",
            developerNotes: "",
          });
        if (modal.prod?.buckets)
          modal.prod.buckets.push({
            id: "",
            name: "",
            isPublished: false,
            details: "",
            developerNotes: "",
          });
        return { ...modal };
      });
    },
    deleteBucket: (bIndex: number) => {
      setModal((modal) => {
        if (modal.dev?.buckets) modal.dev.buckets.splice(bIndex, 1);
        if (modal.prod?.buckets) modal.prod.buckets.splice(bIndex, 1);
        return { ...modal };
      });
    },
    moveBucket: (bIndex: number, newIndex: number) => {
      setModal((modal) => {
        if (modal.dev?.buckets)
          [modal.dev.buckets[newIndex], modal.dev.buckets[bIndex]] = [
            modal.dev.buckets[bIndex],
            modal.dev.buckets[newIndex],
          ];
        if (modal.prod?.buckets)
          [modal.prod.buckets[newIndex], modal.prod.buckets[bIndex]] = [
            modal.prod.buckets[bIndex],
            modal.prod.buckets[newIndex],
          ];
        return { ...modal };
      });
    },
    changeBucketString: (
      bIndex: number,
      key: "id" | "name" | "details" | "developerNotes",
      value: string
    ) => {
      setModal((modal) => {
        if (modal.dev?.buckets) modal.dev.buckets[bIndex][key] = value;
        if (modal.prod?.buckets) modal.prod.buckets[bIndex][key] = value;
        return { ...modal };
      });
    },
    changeBucketPublished: (
      env: "dev" | "prod",
      bIndex: number,
      value: boolean
    ) => {
      setModal((modal) => {
        if (env === "dev" && modal.dev?.buckets)
          modal.dev.buckets[bIndex].isPublished = value;
        if (env === "prod" && modal.prod?.buckets)
          modal.prod.buckets[bIndex].isPublished = value;
        return { ...modal };
      });
    },
    changeBoolean: (key: "quickview", value: boolean) => {
      setModal((modal) => {
        if (modal.dev) modal.dev[key] = value;
        if (modal.prod) modal.prod[key] = value;
        return { ...modal };
      });
    },
    changePublished: (env: "dev" | "prod", value: boolean) => {
      const tm = modal;
      if (env === "dev" && tm.dev) tm.dev.isPublished = value;
      if (env === "prod" && tm.prod) tm.prod.isPublished = value;

      setModal({ ...tm });
    },
    changeString: (key: "id" | "name", value: string) => {
      setModal((modal) => {
        if (modal.dev) modal.dev[key] = value;
        if (modal.prod) modal.prod[key] = value;
        return { ...modal };
      });
    },
    changeList: (key: "roles" | "subscriptionItem", value: string[]) => {
      setModal((modal) => {
        if (modal.dev) modal.dev[key] = value;
        if (modal.prod) modal.prod[key] = value;
        return { ...modal };
      });
    },
  };

  return (
    <Modal
      width="90vw"
      title={
        modal.index || modal.index === 0
          ? `Edit ${modal.type?.toUpperCase()} > ${modal.dev?.name}`
          : `New ${modal.type?.toUpperCase()} Taxonomy`
      }
      open={modal.open}
      onOk={onOk}
      onCancel={() =>
        setModal((modal) => ({
          ...modal,
          open: false,
        }))
      }
    >
      <div style={{ display: "flex" }}>
        <div style={{ width: "33%", margin: "0 10px" }}>
          <Label label="ID" />
          <input
            style={{ width: "100%" }}
            name="id"
            value={modal.dev?.id}
            onChange={(e) => handle.changeString("id", e.target.value)}
          />
          <Label label="isPublished" />
          <IsPublished
            label="dev"
            value={modal.dev?.isPublished}
            onChange={() =>
              handle.changePublished("dev", !modal.dev?.isPublished)
            }
          />
          <IsPublished
            label="prod"
            value={modal.prod?.isPublished}
            onChange={() =>
              handle.changePublished("prod", !modal.prod?.isPublished)
            }
          />
        </div>
        <div style={{ width: "33%", margin: "0 10px" }}>
          <Label label="Name" />
          <input
            style={{ width: "100%" }}
            name="name"
            value={modal.dev?.name}
            onChange={(e) => handle.changeString("name", e.target.value)}
          />
          {modal.type === "entity" && (
            <>
              <Label label="Quickview" />
              <Switch
                title="QuickView"
                unCheckedChildren="No"
                checkedChildren="Yes"
                defaultChecked={modal.dev?.quickview}
                onChange={() =>
                  handle.changeBoolean("quickview", !modal.dev?.quickview)
                }
              />
            </>
          )}
        </div>
        <div style={{ width: "33%", margin: "0 10px" }}>
          <Label label="User Roles" />
          <Select
            onChange={(val) => handle.changeList("roles", val)}
            mode="multiple"
            value={modal.dev?.roles}
            style={{ width: "100%" }}
          >
            {userRoles.map((role) => (
              <Select.Option key={role} value={role}>
                {role}
              </Select.Option>
            ))}
          </Select>
          {modal.type === "entity" && (
            <>
              <Label label="Subscription Items" />
              <Select
                onChange={(val) => handle.changeList("subscriptionItem", val)}
                mode="multiple"
                value={modal.dev?.subscriptionItem}
                style={{ width: "100%" }}
              >
                {entities.map((entity) => (
                  <Select.Option key={entity} value={entity}>
                    {entity}
                  </Select.Option>
                ))}
              </Select>
            </>
          )}
        </div>
      </div>
      <div style={{ overflow: "hidden" }}>
        <Button style={{ float: "right" }} onClick={() => handle.addBucket()}>
          <PlusOutlined />
        </Button>
        <Label label="Buckets" />
      </div>
      <div style={{ display: "flex" }}>
        {modal.dev?.buckets?.map((bucket, bIndex) => (
          <div
            key={`${bIndex}-${bucket.id}`}
            style={{
              border: "1px solid #ccc",
              padding: 10,
              flexGrow: 1,
              flexBasis: 0,
            }}
          >
            <span style={{ float: "right" }}>
              {bIndex ? (
                <Button onClick={() => handle.moveBucket(bIndex, bIndex - 1)}>
                  <CaretLeftOutlined />
                </Button>
              ) : (
                <></>
              )}
              {bIndex + 1 < (modal.dev?.buckets?.length || 0) ? (
                <Button onClick={() => handle.moveBucket(bIndex, bIndex + 1)}>
                  <CaretRightOutlined />
                </Button>
              ) : (
                <></>
              )}
              <Button onClick={() => handle.deleteBucket(bIndex)}>
                <DeleteOutlined />
              </Button>
            </span>
            <Label label={`Bucket ${bIndex}`} />
            <Label label="Id" />
            <input
              style={{ width: "100%" }}
              name="bucketId"
              value={bucket.id}
              onChange={(e) =>
                handle.changeBucketString(bIndex, "id", e.target.value)
              }
            />
            <Label label="Name" />
            <input
              style={{ width: "100%" }}
              name="bucketName"
              value={bucket.name}
              onChange={(e) =>
                handle.changeBucketString(bIndex, "name", e.target.value)
              }
            />
            <Label label="isPublished" />
            <IsPublished
              label="dev"
              value={bucket.isPublished}
              onChange={() =>
                handle.changeBucketPublished("dev", bIndex, !bucket.isPublished)
              }
            />
            {modal.prod?.buckets && (
              <IsPublished
                label="prod"
                value={modal.prod.buckets[bIndex].isPublished}
                onChange={() =>
                  handle.changeBucketPublished(
                    "prod",
                    bIndex,
                    !(
                      modal.prod &&
                      modal.prod.buckets &&
                      modal.prod.buckets[bIndex].isPublished
                    )
                  )
                }
              />
            )}
            <Label label="Details" />
            <textarea
              style={{ width: "100%", height: 150 }}
              value={bucket.details}
              name="bucketDetails"
              onChange={(e) =>
                handle.changeBucketString(bIndex, "details", e.target.value)
              }
            ></textarea>
            <Label label="Developer Notes" />
            <textarea
              style={{ width: "100%", height: 150 }}
              name="bucketDeveloperNotes"
              onChange={(e) =>
                handle.changeBucketString(
                  bIndex,
                  "developerNotes",
                  e.target.value
                )
              }
              value={bucket.developerNotes}
            ></textarea>
          </div>
        ))}
      </div>
    </Modal>
  );
};
