import { format } from "date-fns"
import React from "react"
import { Link } from "react-router-dom"
import { TeamType } from "../../../types/Team"

type TeamMapCardProps = {
  name: string
  type: string
  createdAt: string
  activeUserCount: number
  id: string
}

export default function TeamMapCard({
  name,
  type,
  createdAt,
  activeUserCount,
  id,
}: TeamMapCardProps) {
  const typeKey = Object.keys(TeamType).find(
    (key) => TeamType[key as keyof typeof TeamType] === type
  )

  return (
    <div className="team-map-card">
      <div>
        Name: <b>{name}</b>
      </div>
      <div>
        Type: <b>{typeKey || type}</b>
      </div>
      <div>
        Account created: <b>{format(new Date(createdAt), "MMM yyyy")}</b>
      </div>
      <div>
        Active user count: <b>{activeUserCount}</b>
      </div>
      <div>
        <Link to={`/teams/${id}`}>[more details]</Link>
      </div>
    </div>
  )
}
