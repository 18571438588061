import { CloudOutlined, NotificationOutlined } from "@ant-design/icons"
import { faMemory, faMobileAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card, Spin, Tag, Tooltip, message } from "antd"
import { useState } from "react"
import { sendTestNotification, useGetUserData } from "../../apis/user"
import { useAuthContext } from "../../context/AuthContext"
import { GetError } from "../../widgets/GetError"
import { UserNotificationModal } from "../UserNotifications/UserNotificationModal"
import { UserCacheModal } from "./UserCacheModal"
import UserEmailWidget from "./widgets/UserEmailWidget/UserEmailWidget"
import UserFocusModeOnly from "./widgets/UserFocusModeOnly/UserFocusModeOnly"
import UserJobRolesWidget from "./widgets/UserJobRolesWidget/UserJobRolesWidget"
import UserLanguagePreference from "./widgets/UserLanguagePreference/UserLanguagePreference"
import UserNameWidget from "./widgets/UserNameWidget/UserNameWidget"
import UserRolesWidget from "./widgets/UserRolesWidget/UserRolesWidget"
import UserTagsWidget from "./widgets/UserTagsWidget/UserTagsWidget"
import UserFileAccessWidget from "./UserFileAccessWidget"
import UserTeamWidget from "./UserTeamWidget"
import UserAuthIDWidget from "./UserAuthIDWidget"
import UserPhoneWidget from "./UserPhoneWidget"
import { AdminIcon } from "../../assets/AdminIcon"
import UserActivityFeedDrawerWidget from "./widgets/UserActivityFeedWidget/UserActivityFeedDrawerWidget"

type Props = {
  id: string
}

export const UserOverview = ({ id }: Props) => {
  const { env, token, user: userCtx, theme } = useAuthContext()
  const user = useGetUserData({ userId: id, env, token: token[env] })

  const [visibleActivity, setVisibleActivity] = useState(false)
  const [viewCache, setViewCache] = useState(false)

  if (user.isLoading) return <Spin />
  if (user.error || !user.data) return <GetError reFetch={user.refetch} />

  return (
    <>
      <Card
        title={
          <div
            style={{
              display: "flex",
              gap: "16px",
              minHeight: "125px",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {user.data.sysadmin ? (
                  <Tooltip title="System Admin">
                    <AdminIcon
                      style={{
                        fill: theme === "light" ? "#000000" : "#FFFFFF",
                      }}
                    />
                  </Tooltip>
                ) : null}
                <UserNameWidget
                  id={user.data.id}
                  firstName={user.data.firstName}
                  lastName={user.data.lastName}
                />
                <Tag
                  style={{
                    color: "white",
                    margin: 0,
                    background: user.data.isOnline ? "green" : "red",
                  }}
                >
                  {user.data.isOnline ? "Online" : "Offline"}
                </Tag>
                <Tag
                  style={{
                    color: "white",
                    margin: 0,
                    background: user.data.activeAccount ? "green" : "red",
                  }}
                >
                  {user.data.activeAccount ? "Active" : "Inactive"}
                </Tag>
              </div>
              <h3 style={{ margin: 0 }}>{user.data.jobTitle}</h3>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
              <Tooltip title="View User Notifications">
                <Button
                  type="primary"
                  icon={<NotificationOutlined />}
                  onClick={() => setVisibleActivity(true)}
                />
              </Tooltip>

              <Tooltip title="View Recent Logs">
                <a
                  href={`https://console.cloud.google.com/logs/query;query=jsonPayload.vizzn.env%3D%22${
                    env === "prod" ? "production" : "development"
                  }%22%0AjsonPayload.vizzn.userId%3D%22${
                    user.data.id
                  }%22;timeRange=PT15M;summaryFields=jsonPayload%252Fname:false:32:beginning;lfeCustomFields=jsonPayload%252FrequestInfo%252Fname?project=vizzn-${
                    env === "prod" ? "prod" : "poc"
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button type="primary" icon={<CloudOutlined />} />
                </a>
              </Tooltip>

              <Tooltip title="Send Test Notification">
                <Button
                  onClick={async () => {
                    const res = await sendTestNotification({
                      env,
                      token: token[env],
                      userId: id,
                    })

                    if (!res)
                      return message.error("Could not sent notification")
                    return message.success("Notification send")
                  }}
                  type="primary"
                  icon={<FontAwesomeIcon icon={faMobileAlt} />}
                />
              </Tooltip>

              <Tooltip title="View User Cache">
                <Button
                  onClick={() => setViewCache(true)}
                  type="primary"
                  icon={<FontAwesomeIcon icon={faMemory} />}
                />
              </Tooltip>

              <UserActivityFeedDrawerWidget id={id} />
            </div>
          </div>
        }
      >
        <UserEmailWidget
          id={user.data.id}
          email={user.data.email}
          verified={user.data.emailVerified}
        />

        <UserPhoneWidget
          id={user.data.id}
          phone={user.data.phone}
          verified={user.data.phoneVerified}
        />

        <UserAuthIDWidget authId={user.data.authId} />

        <UserRolesWidget
          id={user.data.id}
          roles={user.data.roles}
          roleTags={user.data.roleTags}
        />

        <UserJobRolesWidget
          id={user.data.id}
          roleTags={user.data.roleTags}
          roles={user.data.roles}
        />

        <UserFileAccessWidget user={user.data} />

        <UserTeamWidget user={user.data} />

        <UserLanguagePreference
          languagePreference={user.data.languagePreference}
          id={id}
        />
        <UserFocusModeOnly id={id} />
        <UserTagsWidget
          id={user.data.id}
          tags={user.data.tags}
          refetch={user.refetch}
        />
      </Card>

      {visibleActivity && (
        <UserNotificationModal
          id={id}
          visibleActivity={visibleActivity}
          closeDrawer={() => setVisibleActivity(false)}
        />
      )}
      {viewCache && (
        <UserCacheModal
          open={viewCache}
          handleClose={() => setViewCache(false)}
          token={token[env]}
          env={env}
          userId={id}
          userCtxId={userCtx[env]?.uid}
        />
      )}
    </>
  )
}
