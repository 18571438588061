import { NotificationBucket } from "../../types/notifications"

const Env = ({ env, isPublished }: { env: string; isPublished?: boolean }) => {
  return (
    <span
      style={{
        border: "1px solid #fff",
        padding: "0 5px",
        marginLeft: 4,
        background: isPublished ? "#fff" : "transparent",
        color: isPublished ? "#000" : "#fff",
      }}
    >
      {env}
    </span>
  )
}

export const Bucket = ({
  bucket,
}: {
  bucket: {
    dev: NotificationBucket
    prod?: NotificationBucket
  }
}) => {
  return (
    <div
      key={bucket.dev.id}
      style={{
        border: "3px solid",
        width: 300,
        margin: 5,
        padding: 5,
        borderColor: "#ddd",
      }}
    >
      <b
        style={{
          display: "block",
          borderBottom: "1px solid #ddd",
          padding: "0 5px 5px",
        }}
      >
        {bucket.dev.name}
      </b>
      <div style={{ textAlign: "right" }}>
        <Env env="dev" isPublished={bucket.dev.isPublished} />
        <Env env="prod" isPublished={bucket.prod?.isPublished} />
      </div>
      <span
        style={{ display: "block", whiteSpace: "pre-wrap", padding: "5px" }}
      >
        {bucket.dev.details}
      </span>
    </div>
  )
}
