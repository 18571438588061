import { Team } from "../../types/Team"

import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useAuthContext } from "../../context/AuthContext"
import { VizznResponse } from "../../types/Api"
import { environmentType } from "../../types/environtment"
import { getEnvUrl } from "../../utils/utils"

const getTeamsNotArchived = async (body: {
  env: environmentType
  token: string | null
}) => {
  if (!body.token) return
  const { data } = await axios.get<VizznResponse<Team[]>>(
    `${getEnvUrl(body.env)}/v1/teams`,

    {
      params: {
        includeArchived: false,
      },
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) return data.data
}

export const useGetTeamsNotArchived = () => {
  const {env,token} =useAuthContext()


  return useQuery({
    queryKey: ["teams", env],
    queryFn: () => getTeamsNotArchived({ env, token: token[env] }),
  })
}
