import { Skeleton } from "antd"
import { useParams } from "react-router-dom"
import { useGetEnterpriseDetail } from "../../apis/Enterprise"
import ErrorRefetch from "../../components/ErrorRefetch/ErrorRefetch"
import { useAuthContext } from "../../context/AuthContext"
import EnterpriseAdminList from "./EnterpriseAdminList/EnterpriseAdminList"
import EnterpriseChildrenList from "./EnterpriseChildrenList/EnterpriseChildrenList"
import EnterpriseOverview from "./EnterpriseOverview/EnterpriseOverview"

export default function EnterpriseDetailPage() {
  const { id } = useParams()
  const {env,token} =useAuthContext()

  const enterprise = useGetEnterpriseDetail({
    env,
    token: token[env],
    id: id || "",
  })

  if (enterprise.isLoading) return <Skeleton active />
  if (enterprise.isError) return <ErrorRefetch refetch={enterprise.refetch} />

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
      }}
    >
      <EnterpriseOverview
        id={id || ""}
        name={enterprise.data?.name || ""}
        userCount={enterprise.data?.userCount || 0}
        totalUserCount={enterprise.data?.totalUserCount || 0}
      />
      <EnterpriseAdminList
        enterpriseId={id || ""}
        admins={enterprise.data?.admins || []}
      />
      <EnterpriseChildrenList
        enterpriseId={id || ""}
        children={enterprise.data?.children || []}
      />
    </div>
  )
}
