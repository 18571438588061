import { EnterOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Col, Form, message, Modal, Row, Select, Tag } from "antd"
import { useEffect, useState } from "react"
import {
  useCreateLabelInEntity,
  useDeleteLabel,
  useFindTagsByTeamId,
} from "../../../../apis/Label"
import { useGetUserData } from "../../../../apis/user"
import { useAuthContext } from "../../../../context/AuthContext"
import { FindTagsByTeamIdTags } from "../../../../types/MasterPriceItem"
import { RowContent } from "../../../Utils/RowContent"

type Props = {
  id: string
  tags: string[]
  refetch?: () => void
}

type TagsSubmitProps = {
  tag: string
}

export default function UserTagsWidget({ id, tags, refetch }: Props) {
  const { env, token } = useAuthContext()
  const user = useGetUserData({ userId: id, env, token: token[env] })

  const { mutateAsync: addLabel } = useCreateLabelInEntity()
  const { mutateAsync: deleteLabel } = useDeleteLabel()
  const [form] = Form.useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [filteredTags, setFilteredTags] = useState<FindTagsByTeamIdTags[]>([])
  const [creatingLabel, setCreatingLabel] = useState(false)

  const closeModal = () => {
    setSearchValue("")
    setCreatingLabel(false)
    setIsModalOpen(false)
  }

  const allTags = useFindTagsByTeamId(
    env,
    token,
    user.data?.teamId,
    isModalOpen
  )

  const isTagExist = Boolean(
    allTags?.data?.some(
      (tag: any) => tag.name.toLowerCase() === searchValue.toLowerCase()
    )
  )

  const handleSearch = (value: any) => {
    setSearchValue(value)
  }

  const handleDeleteLabel = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    tag: string
  ) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      await deleteLabel({
        env,
        token: token,
        dto: {
          name: tag,
          entityId: id,
          entityType: "user",
        },
      })
      refetch && refetch()
    } catch (error) {
      message.error("Failed to delete tag. Please try again.")
    } finally {
      closeModal()
    }
  }

  const handleFinish = async (data: TagsSubmitProps) => {
    // if (!data.tag) return message.error(t("label.please_select_a_tag_ljx"))

    if (tags?.includes(data.tag)) {
      return
    }

    setCreatingLabel(true)

    await addLabel({
      env,
      token: token,
      dto: {
        name: data.tag,
        entityId: id,
        entityType: "user",
      },
    })
    //keep till folder/file tags emit a socket event
    // if (query) queryClient.refetchQueries(query)
    refetch && refetch()
    closeModal()
  }

  const handlePrepareFormSubmit = () => {
    form.setFieldsValue({ tag: searchValue })
    form.submit()
  }

  useEffect(() => {
    if (allTags.data) {
      const filtered = allTags.data.filter((tag) => !tags?.includes(tag.name))
      setFilteredTags(filtered)
    }

    if (!isModalOpen) return form.resetFields()
  }, [tags, allTags.data, isModalOpen, form])

  return (
    <>
      <RowContent
        name="Tags"
        content={
          <Row align="middle">
            <Col>
              {tags.map((tag, index) => (
                <Tag
                  key={index}
                  closable
                  onClose={(e) => handleDeleteLabel(e, tag)}
                >
                  {tag}
                </Tag>
              ))}
            </Col>
            <Col>
              <Tag
                className="label add-label"
                onClick={(e) => {
                  e.stopPropagation()
                  setIsModalOpen(true)
                }}
              >
                <PlusOutlined
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Tag>
            </Col>
          </Row>
        }
      />
      {isModalOpen && (
        <Modal
          title="Add Tag"
          open={isModalOpen}
          footer={
            <Button
              type="primary"
              onClick={async (e) => {
                e.stopPropagation()
                handleFinish(form.getFieldsValue())
              }}
              disabled={creatingLabel}
            >
              Save
            </Button>
          }
          onCancel={(e) => {
            e.stopPropagation()
            setIsModalOpen(false)
          }}
        >
          <Form
            form={form}
            onFinish={handleFinish}
            onClick={(e) => e.stopPropagation()}
          >
            <Form.Item name="tag">
              <Select
                autoFocus
                showSearch
                value={searchValue}
                onSearch={handleSearch}
                onInputKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    !allTags.data?.some((tag) =>
                      tag.name.toLowerCase().includes(searchValue.toLowerCase())
                    )
                  ) {
                    handlePrepareFormSubmit()
                  }

                  if (e.key === "Enter" && searchValue && !isTagExist) {
                    handlePrepareFormSubmit()
                  }
                }}
                onChange={(value) => setSearchValue(value)}
                notFoundContent={
                  <Button
                    type="primary"
                    onClick={handlePrepareFormSubmit}
                    size="small"
                    className="create-button"
                  >
                    {"Press Enter"} <EnterOutlined />
                    {"Create Tag"} <span> {searchValue}</span>
                  </Button>
                }
                filterOption={(input, option) =>
                  option?.tagname === "New Tag" ||
                  Boolean(
                    option?.value
                      ?.toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  )
                }
              >
                {searchValue && !isTagExist && (
                  <Select.Option
                    value={searchValue}
                    tagname="New Tag"
                    label="New Tag"
                  >
                    <Button
                      type="primary"
                      onClick={handlePrepareFormSubmit}
                      size="small"
                      className="create-button"
                    >
                      {"Press Enter"} <EnterOutlined />
                      {"Create Tag"} <span>{searchValue}</span>
                    </Button>
                  </Select.Option>
                )}
                {filteredTags.map((tag, index) => (
                  <Select.Option key={`${tag.name}-${index}`} value={tag.name}>
                    {tag.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}
