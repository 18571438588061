import { TransformCategoryFunction } from "../../../types/UsageScores"

export const flattenCategoryData: TransformCategoryFunction = (data) => {
  return Object.entries(data).map(([categoryId, entries]) => {
    return {
      id: categoryId,
      category: entries[0].category.name,
      teams: entries
        .map((entry) => ({
          team: entry.team.name,
          score: entry.score,
        }))
        .sort((a, b) => b.score - a.score),
    }
  })
}
