import { useState } from "react"
import { Team, TimeDisplay, TimeDisplayMap } from "../../../types/Team"
import { useAuthContext } from "../../../context/AuthContext"
import { useUpdateTeam } from "../../../apis/teams"
import { Button, Col, Form, message, Row, Select } from "antd"
import { EditOutlined, StopOutlined } from "@ant-design/icons"

type Props = {
  value: TimeDisplay
  data: Team
}

export default function EditableTimeDisplaySelectWidget({
  value,
  data,
}: Props) {
  const [isEditing, setIsEditing] = useState(false)
  const { env, token } = useAuthContext()
  const { mutateAsync: updateTeam } = useUpdateTeam(data.id, env)

  const handleOnSelect = async (value: TimeDisplay) => {
    try {
      await updateTeam({
        data: {
          id: data.id,
          timeDisplay: value,
        },
        env,
        token: token[env],
      })
      return
    } catch (error) {
      return message.error("Failed to update time display")
    } finally {
      setIsEditing(false)
    }
  }

  return isEditing ? (
    <Form style={{ display: "flex", gap: 10, alignItems: "center" }}>
      <Select
        value={value}
        onBlur={() => setIsEditing(false)}
        onChange={(value) => handleOnSelect(value as TimeDisplay)}
        style={{ width: "200px" }}
      >
        {Object.entries(TimeDisplayMap).map(([key, value]) => (
          <Select.Option key={key} value={key as TimeDisplay}>
            {value}
          </Select.Option>
        ))}
      </Select>
      <Button size="small" type="default" onClick={() => setIsEditing(false)}>
        <StopOutlined />
      </Button>
    </Form>
  ) : (
    <Row gutter={8} align={"middle"}>
      <Col>
        <strong>{TimeDisplayMap[value]}</strong>
      </Col>
      <Col>
        <Button size="small" onClick={() => setIsEditing(true)}>
          <EditOutlined />
        </Button>
      </Col>
    </Row>
  )
}
