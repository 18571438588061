import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { VizznResponse } from "../types/AccountApplication"
import { DispatcherUser } from "../types/Dispatcher"
import { environmentType } from "../types/environtment"
import { getEnvUrl } from "../utils/utils"

// const getDispatcherGroupUser = async () => {
//     const { data } = await Axios.get<VizznResponse<DispatcherUser[]>>(`/v1/dispatcher-users`)

//     return data.data
//   }

const getDispatcherGroupUser = async (body: {
  env: environmentType
  token: string | null
  id: string
}) => {
  const { data } = await axios.get<VizznResponse<DispatcherUser[]>>(
    `${getEnvUrl(body.env)}/v1/dispatcher-users`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data.find((user) => user.id === body.id) ?? null
}

export const useGetDispatcherGroupUser = (body: {
  env: environmentType
  token: string | null
  id: string
}) => {
  const response = useQuery<DispatcherUser | null>(
    ["dispatcher-users", body.env],
    () => getDispatcherGroupUser(body)
  )

  return response
}
