import axios from "axios";
import { VizznResponse } from "../types/Api";
import { useQuery } from "@tanstack/react-query";
import { Partnerships } from "../types/Partner";
import { environmentType } from "../types/environtment";
import { getEnvUrl } from "../utils/utils";

export const getPartnerships = async (body: {
  env: environmentType;
  token: string | null;
  id: string;
}) => {
  try {
    const { data } = await axios.get<VizznResponse<Partnerships>>(
      `${getEnvUrl(body.env)}/v2/partnerships?teamId=${body.id}`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    // axios response error intercepted
  }
};

export const useGetPartnerships = (body: {
  env: environmentType;
  token: string | null;
  id: string;
}) => {
  const response = useQuery<Partnerships | undefined>(
    ["partnerships", body.id, body.env],
    () => getPartnerships(body)
  );
  return response;
};
