import { Col, Row } from "antd"
import { useParams } from "react-router-dom"
import { TeamOverview } from "../components/Team/TeamOverview"
import { TeamPartnerships } from "../components/Team/TeamPartnerships"
import { OperatingHoursWidget } from "../components/Team/widgets/OperatingHoursWidget"
import { TeamLogCounts } from "../components/Team/widgets/TeamLogCounts"
import { TeamUnitPreferences } from "../components/Team/widgets/TeamUnitPreferences"
import { TeamUsageMetrics } from "../components/Team/widgets/TeamUsageMetrics"
import TeamUsageScore from "./TeamDetailsPage/TeamUsageScore"
import { UserInTeamList } from "./UserInTeamList"

export const TeamPage = () => {
  const { id } = useParams<{ id: string }>()
  return (
    <div style={{ display: "flex", gap: "20px", flexDirection: "column" }}>
      <TeamOverview id={id || ""} />
      <OperatingHoursWidget id={id || ""} />
      <TeamUnitPreferences id={id || ""} />
      <TeamPartnerships id={id || ""} />
      <UserInTeamList teamId={id || ""} />
      <Row gutter={[8, 16]}>
        <Col lg={24} xl={12} style={{ width: "100%" }}>
          <TeamUsageMetrics id={id || ""} />
        </Col>
        <Col lg={24} xl={12} style={{ width: "100%" }}>
          <TeamLogCounts id={id || ""} />
        </Col>
      </Row>
      <Row gutter={[8, 16]}>
        <TeamUsageScore id={id ?? ""} />
      </Row>
    </div>
  )
}
