import { useState } from "react"
import { RowContent } from "../../../Utils/RowContent"
import { Button, Col, message, Row, Select } from "antd"
import { EditOutlined, StopOutlined } from "@ant-design/icons"
import {
  LanguageCode,
  languageFlagMap,
  VizznSupportedLanguages,
  vizznSupportedLanguagesArray,
} from "../../../../types/Language"
import { useUpdateUserLanguagePreference } from "../../../../apis/user"
import { useAuthContext } from "../../../../context/AuthContext"

type Props = {
  languagePreference: LanguageCode
  id: string
}

export default function UserLanguagePreference({
  languagePreference,
  id,
}: Props) {
  const [isEditing, setIsEditing] = useState(false)
  const { token, env } = useAuthContext()

  const { mutateAsync: updateUserLanguagePreference, isLoading } =
    useUpdateUserLanguagePreference(id, env)

  const onSelect = async (value: string) => {
    try {
      await updateUserLanguagePreference({
        payload: {
          id,
          languagePreference: value as LanguageCode,
        },
        env,
        token: token[env] as string,
      })
      return
    } catch (error) {
      message.error(error as string)
      return
    } finally {
      setIsEditing(false)
      return
    }
  }

  return (
    <RowContent
      name="Language Preference"
      content={
        isEditing ? (
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Select
              defaultValue={languagePreference}
              style={{ width: "200px" }}
              options={vizznSupportedLanguagesArray}
              onSelect={onSelect}
              loading={isLoading}
              disabled={isLoading}
            />
            <Button size="small" onClick={() => setIsEditing(false)}>
              <StopOutlined />
            </Button>
          </div>
        ) : (
          <Row gutter={8}>
            <Col>
              <strong>
                {languageFlagMap[languagePreference]}{" "}
                {VizznSupportedLanguages[languagePreference]}
              </strong>
            </Col>
            <Col>
              <Button size="small" onClick={() => setIsEditing(true)}>
                <EditOutlined />
              </Button>
            </Col>
          </Row>
        )
      }
    />
  )
}
