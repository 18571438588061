import { Typography } from "antd"

type Props = {
  searchTerm: string
}

export default function AddAllPrompt({ searchTerm }: Props) {
  return (
    <div
      style={{
        padding: "5px 10px",
      }}
    >
      Press <Typography.Text keyboard>CTRL/CMD + Enter</Typography.Text> to add
      all use cases with the search term "
      <Typography.Text mark>{searchTerm}</Typography.Text>"
    </div>
  )
}
