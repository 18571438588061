import { Button, Col, Form, Input, Modal, Row, Select } from "antd"
import React, { useMemo } from "react"
import { useUpdateAppVersion } from "../../../apis/DeploymentStatus"
import { useAuthContext } from "../../../context/AuthContext"
import { getNextVersion } from "../../../utils/getNextVersion"
import { isVersionHigher } from "../../../utils/isVersionHigher"

type Props = {
  currentVersion: string
}

type FormValues = {
  version: string
  ref: "master" | "develop"
  commitMessage: string
}

export default function UpdateWepAppVersionModal({ currentVersion }: Props) {
  const [open, setOpen] = React.useState(false)
  const { env, token } = useAuthContext()

  const [form] = Form.useForm<FormValues>()
  const { mutateAsync: updateAppVersion, isLoading } = useUpdateAppVersion(env)

  const handleCancel = () => {
    form.resetFields()
    setOpen(false)
  }

  const handleSubmit = async (values: FormValues) => {
    const { version, ref } = values
    await updateAppVersion({
      env,
      token: token[env],
      payload: {
        version,
        ref,
      },
    })

    setOpen(false)
  }

  const nextVersion = useMemo(
    () => getNextVersion(currentVersion),
    [currentVersion]
  )

  return (
    <div>
      <Button type="primary" onClick={() => setOpen(true)}>
        Update Wep App Version
      </Button>
      <Modal
        title="Update Wep App Version"
        open={open}
        footer={null}
        onCancel={handleCancel}
        confirmLoading={isLoading}
      >
        <Form
          form={form}
          name="control-hooks"
          onFinish={handleSubmit}
          initialValues={{
            version: nextVersion,
            ref: "develop",
          }}
        >
          <Form.Item
            name="version"
            label="Version"
            rules={[
              {
                required: true,
                message: "Updated version must be higher than current version",
                validator: (_, value) => {
                  if (!isVersionHigher(value, currentVersion)) {
                    return Promise.reject(
                      new Error(
                        "Updated version must be higher than current version"
                      )
                    )
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="ref"
            label="Ref"
            rules={[
              {
                required: true,
                message: "Please input ref (master or develop)",
              },
            ]}
          >
            <Select>
              <Select.Option value="master">master</Select.Option>
              <Select.Option value="develop">develop</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Row justify="end" gutter={16} style={{ marginTop: 16 }}>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Submit
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={handleCancel}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
