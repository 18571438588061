import { message } from "antd"
import { initializeApp } from "firebase/app"
import { decodeJwt } from "jose"
import {
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth"
import { Firestore, getFirestore } from "firebase/firestore"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { initializeAuthUserData } from "../apis/user"
import { firebaseConfigDev, firebaseConfigProd } from "../firebase"
import { environmentType } from "../types/environtment"
import {
  AuthenticationState,
  AuthProviderState,
  TokenState,
  UserState,
} from "../types/user"
import { getUserAccountV2 } from "../apis/WorkOs/getUserAccountV2"
import { WorkosLoginResponse } from "../types/WorkOs/WorkosLoginResponse"
import axios from "axios"
import { getEnvUrl } from "../utils/utils"
import { isTokenExpired } from "../utils/isTokenExpired"
import { useLocalStorage } from "../utils/hooks/useLocalStorage"

const appDev = initializeApp(firebaseConfigDev, "development")
const appProd = initializeApp(firebaseConfigProd, "production")

type Props = {
  children: ReactNode
}

type Context = {
  token: TokenState
  env: environmentType
  error: string
  handleLogin: (
    email: string,
    password: string,
    navigate?: () => void
  ) => Promise<void>
  handleLogOut: (navigate: () => void) => Promise<void>
  handleSetEnv: (envName: environmentType) => void
  user: UserState
  setUser: Dispatch<SetStateAction<UserState>>
  redirect: string | null
  setRedirect: Dispatch<SetStateAction<string | null>>
  theme: "dark" | "light"
  setTheme: Dispatch<SetStateAction<"dark" | "light">>
  getDb: (env: environmentType) => Firestore
  isAuthenticated: AuthenticationState
  isFirebaseLogin: AuthProviderState
  setIsFirebaseLogin: Dispatch<SetStateAction<AuthProviderState>>
  handleWorkosLogin: (body: {
    email: string
    password: string
    navigate?: () => void
  }) => Promise<void>
  handleWorkosLogout: (body: { navigate?: () => void }) => Promise<void>
}

const getCurrentEnvironment = (env: environmentType) => {
  switch (env) {
    case "prod":
      return appProd
    default:
      return appDev
  }
}

const getDb = (env: environmentType) => {
  const db = getFirestore(getCurrentEnvironment(env))
  return db
}

const initialThemeState = JSON.parse(localStorage.getItem("theme") as string)

const AuthContext = createContext<Context | null>(null)

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (!context)
    throw new Error(
      "AuthContext must be called from within the AuthContextProvider"
    )
  return context
}

export const AuthContextProvider = ({ children }: Props) => {
  const [isFirebaseLogin, setIsFirebaseLogin] =
    useLocalStorage<AuthProviderState>("isFirebaseLogin", {
      dev: "workos",
      prod: "workos",
    })

  const [user, setUser] = useLocalStorage<UserState>("user", {
    dev: null,
    prod: null,
  })
  const [token, setToken] = useLocalStorage<TokenState>("token", {
    dev: null,
    prod: null,
  })
  const [refreshToken, setRefreshToken] = useLocalStorage<TokenState>(
    "refreshToken",
    {
      dev: null,
      prod: null,
    }
  )
  const [env, setEnv] = useLocalStorage<environmentType>("env", "dev")

  const [loaded, setLoaded] = useState(false)
  const [theme, setTheme] = useState<"dark" | "light">(initialThemeState)
  const [error, setError] = useState("")
  const [redirect, setRedirect] = useState<string | null>(null)
  const [focused, setFocused] = useState(false)
  const auth = getAuth(getCurrentEnvironment(env))

  const isAuthenticated: AuthenticationState = useMemo(
    () => ({
      dev: !!user.dev,
      prod: !!user.prod,
    }),
    [user]
  )

  const handleClearData = useCallback(
    (env: environmentType, where?: string) => {
      setUser((prev) => {
        const oldData = { ...prev }
        oldData[env] = null
        return oldData
      })
      setToken((prev) => {
        const oldData = { ...prev }
        oldData[env] = null
        return oldData
      })
      setRefreshToken((prev) => {
        const oldData = { ...prev }
        oldData[env] = null
        return oldData
      })
    },
    [setRefreshToken, setToken, setUser]
  )

  const AuthCheck = useCallback(
    () =>
      onAuthStateChanged(auth, async (user) => {
        if (!loaded) {
          if (user) {
            const currentToken = await user
              .getIdToken(false)
              .then((token) => token)
            const vizznUser = await initializeAuthUserData({
              userId: user.uid,
              env,
              token: currentToken,
            }).catch((error) => message.error(error.message))

            if (!vizznUser || !vizznUser.roleNames.includes("sysadmin")) {
              setLoaded(true)
              message.error("User must be a sysadmin")
              return
            }
            setUser((prev) => {
              const oldData = { ...prev }
              oldData[env] = user
              return oldData
            })
            setLoaded(true)
            setToken((prev) => {
              const oldData = { ...prev }
              oldData[env] = currentToken
              return oldData
            })
          } else {
            handleClearData(env, "Auth Check")
            setLoaded(true)
          }
        }
      }),
    [auth, env, handleClearData, loaded, setToken, setUser]
  )

  useEffect(() => {
    if (isFirebaseLogin[env] === "firebase" && token[env]) {
      const decodedToken = decodeJwt(token[env]!)
      const expirationTime = decodedToken.exp

      if (expirationTime && isTokenExpired(expirationTime)) {
        AuthCheck()
      }
    }
    // only run once on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const workosAuthCheck = useCallback(async () => {
    const ogToken = token[env]

    if (!ogToken) {
      handleClearData(env, "Workos Auth Check")
      return message.error("Token not found")
    }

    // check if token is expired
    const decodedToken = decodeJwt(ogToken)

    const expirationTime = decodedToken.exp

    if (!expirationTime) return

    if (isTokenExpired(expirationTime)) {
      try {
        const data = await axios.post<WorkosLoginResponse>(
          `${getEnvUrl(env)}/workos/refresh`,
          {
            refreshToken: refreshToken[env],
          }
        )
        setToken((prev) => ({
          ...prev,
          [env]: data.data.jwt,
        }))
        setRefreshToken((prev) => ({
          ...prev,
          [env]: data.data.refreshToken,
        }))
        setUser((prev) => ({
          ...prev,
          [env]: data.data.user,
        }))
      } catch (error) {
        handleClearData(env, "Workos Auth Check")
        message.error("Token expired... Logging out")
      }
    }
  }, [
    token,
    env,
    handleClearData,
    refreshToken,
    setToken,
    setRefreshToken,
    setUser,
  ])

  // Run workosAuthCheck once on app initialization for Workos auth
  useEffect(() => {
    for (const currentEnv in token) {
      if (
        token[currentEnv as environmentType] &&
        isFirebaseLogin[currentEnv as environmentType] === "workos"
      ) {
        const decodedToken = decodeJwt(token[currentEnv as environmentType]!)
        const expirationTime = decodedToken.exp

        if (expirationTime && isTokenExpired(expirationTime)) {
          // Temporarily set env to check the correct environment
          setEnv(currentEnv as environmentType)
          workosAuthCheck()
        }
      }
    }
    // Reset env back to original value if needed
    setEnv(env)

    // only run once on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // This is a global interval checker for tokens
  // Every 5 minutes, It checks if the token is expired and if it is, it refreshes the token
  // This was to save from having to check the token expiry every time an api call is made in the axios interceptor
  useEffect(() => {
    // Initial check when component mounts
    if (!token[env] || isFirebaseLogin[env] === "workos") {
      return
    }

    // Set up interval for periodic checks
    const interval = setInterval(() => {
      AuthCheck()
    }, 1 * 60 * 1000) // 5 minutes in milliseconds

    // Cleanup interval on unmount
    return () => clearInterval(interval)
  }, [AuthCheck, loaded, isFirebaseLogin, env, token])

  // This is a global interval checker for tokens
  // Every 5 minutes, It checks if the token is expired and if it is, it refreshes the token
  // This was to save from having to check the token expiry every time an api call is made in the axios interceptor
  // Workos token refresh
  useEffect(() => {
    if (!token[env] || isFirebaseLogin[env] !== "workos") return

    const checkToken = () => {
      const decodedToken = decodeJwt(token[env]!)
      const expirationTime = decodedToken.exp

      if (expirationTime && isTokenExpired(expirationTime)) {
        workosAuthCheck()
      }
    }

    const interval = setInterval(checkToken, 1 * 60 * 1000) // Check every minute
    return () => clearInterval(interval)
  }, [workosAuthCheck, env, token, isFirebaseLogin])

  const tokenChanged = useCallback(() => {
    const envs: environmentType[] = ["dev", "prod"]
    for (let key of envs) {
      const auth = getAuth(getCurrentEnvironment(key as environmentType))
      onIdTokenChanged(auth, (user) => {
        if (user)
          user
            .getIdToken(false)
            .then((value) => setToken((prev) => ({ ...prev, [key]: value })))
      })
    }
  }, [setToken])

  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(theme))
  }, [theme])

  useEffect(() => {
    if (!focused)
      document.body.addEventListener("mouseenter", () => {
        setFocused(true)
        tokenChanged()
      })
  }, [focused, tokenChanged])

  useEffect(() => {
    if (focused)
      document.body.addEventListener("mouseleave", () => setFocused(false))
  }, [focused])

  // const userStateToJson = JSON.stringify(user)
  // const tokenStateToJson = JSON.stringify(token)

  // useEffect(() => {
  //   localStorage.setItem("user", userStateToJson)
  //   localStorage.setItem("token", tokenStateToJson)
  //   localStorage.setItem("currentEnv", env)
  //   localStorage.setItem("isFirebaseLogin", JSON.stringify(isFirebaseLogin))
  //   localStorage.setItem("refreshToken", JSON.stringify(refreshToken))
  //   localStorage.setItem("theme", JSON.stringify(theme))
  //   localStorage.setItem("env", env)
  // }, [
  //   userStateToJson,
  //   tokenStateToJson,
  //   env,
  //   isFirebaseLogin,
  //   refreshToken,
  //   theme,
  // ])

  const handleSetEnv = (envName: environmentType) => {
    setEnv(envName)
    setLoaded(false)
  }
  const handleLogOut = async (navigate: () => void) => {
    await signOut(auth)
      .then(() => {
        handleClearData(env, "Logout")
        message.success("logged out")
        setLoaded(false)
        navigate()
      })
      .catch((error) => setError(error.message))
  }

  const handleLogin = async (
    email: string,
    password: string,
    navigate?: () => void
  ) => {
    message.loading("login in..", 0.25)
    await signInWithEmailAndPassword(auth, email, password)
      .then(async (user) => {
        const currentToken = await user.user.getIdToken(false)
        const vizznUser = await initializeAuthUserData({
          userId: user.user.uid, // authid
          env,
          token: currentToken,
        }).catch((error) => {
          handleClearData(env, "Login")
          message.error(error.message)
          return null
        })

        if (!vizznUser || !vizznUser.roleNames.includes("sysadmin")) {
          return message.error("User must be a sysadmin")
        }

        message.success("Login successful!", 0.25)
        setUser((prev) => ({ ...prev, [env]: user }))
        setToken((prev) => ({
          ...prev,
          [env]: currentToken,
        }))

        if (navigate) navigate()
      })
      .catch((error) => {
        handleClearData(env, "Login")
        message.error(error.message)
      })
  }

  const workosLogin = async (body: { email: string; password: string }) => {
    const response = await axios.post<WorkosLoginResponse>(
      `${getEnvUrl(env)}/workos/login`,
      body
    )
    return response.data
  }

  const handleWorkosLogin = async (body: {
    email: string
    password: string
    navigate?: () => void
  }) => {
    try {
      const data: WorkosLoginResponse = await workosLogin(body)

      const vizznUser = await getUserAccountV2({
        id: data.user.id,
        token: data.jwt,
        env,
      })

      if (!vizznUser) return
      if (!vizznUser.roleNames.includes("sysadmin")) {
        message.error("User must be a sysadmin")
        return
      }

      const uid = vizznUser.authId
      const vizznUserWithUid = { ...vizznUser, uid }

      setRefreshToken((prev) => ({
        ...prev,
        [env]: data.refreshToken,
      }))

      setToken((prev) => ({
        ...prev,
        [env]: data.jwt,
      }))

      setUser((prev) => ({
        ...prev,
        [env]: vizznUserWithUid,
      }))

      if (body.navigate) body.navigate()
    } catch (error: any) {
      handleClearData(env, "Workos Login")
      message.error(error.error)
    }
  }

  const handleWorkosLogout = async (body: { navigate?: () => void }) => {
    try {
      await axios.post(`${getEnvUrl(env)}/workos/logout`, {
        refreshToken: refreshToken[env],
      })
      handleClearData(env, "Workos Logout 111")
      if (body.navigate) body.navigate()
    } catch (error: any) {
      handleClearData(env, "Workos Logout 222")
      message.error(error.error)
    } finally {
    }
  }

  return (
    <AuthContext.Provider
      value={{
        env,
        error,
        handleLogin,
        handleLogOut,
        handleSetEnv,
        user,
        token,
        redirect,
        setRedirect,
        theme,
        setTheme,
        getDb,
        setUser,
        isAuthenticated,
        isFirebaseLogin,
        setIsFirebaseLogin,
        handleWorkosLogin,
        handleWorkosLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
