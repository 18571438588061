import { useEffect, useMemo, useState } from "react"
import { ColumnsType } from "antd/lib/table"
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Tag,
  Tooltip,
  Table,
  Spin,
  Card,
} from "antd"
import {
  BellFilled,
  BellOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons"
import { SubscribablesList } from "../widgets/SubscribablesList"
import { useAuthContext } from "../../../context/AuthContext"
import { Subscription } from "../../../types/Subscription"
import { useSearchTags } from "../../../utils/useSearchTags"
import {
  useDeleteSubscriptionList,
  useNotificationUserSubscription,
  useSetPrimarySubscription,
  useSubscriptions,
} from "../../../apis/subscriptions"
import { filterBySearchValueAndTag } from "../../Utils/search"

type Props = {
  userId?: string
  isUserDetail: boolean
}

export const SubscriptionList = ({ userId }: Props) => {
  const { user: userState, env, token } = useAuthContext()
  const user = userState[env]!
  const id = userId || user.uid
  const subscriptions = useSubscriptions({
    env,
    token: token[env],
    id,
  })
  const { mutateAsync: deleteUserSubscriptionList } =
    useDeleteSubscriptionList(id)
  const { mutateAsync: setPrimarySubscription } = useSetPrimarySubscription(
    userId ? userId : user.uid
  )
  const { mutateAsync: notificationUserSubscription } =
    useNotificationUserSubscription(id)
  const { tags, addTag, removeTag } = useSearchTags("subscriptions")
  const [searchValue, setSearchValue] = useState("")
  const [filteredData, setFilteredData] = useState<Subscription[]>([])
  const [isSubscriptionsModalOpen, setIsSubscriptionsModalOpen] =
    useState(false)
  const subscriptionsIds = subscriptions.data?.map(
    ({ subscribedId }) => subscribedId
  )
  const columns: ColumnsType<Subscription> = useMemo(
    () => [
      {
        title: "Name",
        align: "left",
        showHeader: false,
        render: (_, e) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>{e.name}</div>
            {e.isPrimary && (
              <Button
                className="primary-button"
                type="primary"
                shape="round"
                size="small"
              >
                Primary
              </Button>
            )}
            <Row
              justify="center"
              className="icon-buttons"
              gutter={4}
              wrap={false}
              style={{ display: "flex", gap: "5px" }}
            >
              <Col>
                <Tooltip
                  title={
                    e.isPrimary
                      ? "Remove primary subscription"
                      : "Make " + e.name + " a primary subscription"
                  }
                >
                  <Button
                    type={"primary"}
                    onClick={async () =>
                      await setPrimarySubscription({
                        isPrimary: !e.isPrimary,
                        userId: userId ? userId : user.uid,
                        id: e.id,
                        token: token[env],
                        env,
                      })
                    }
                  >
                    {e.isPrimary ? <StarFilled /> : <StarOutlined />}
                  </Button>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip
                  title={
                    e.isPushActive
                      ? "Turn off push notifications for " + e.name
                      : "Turn on push notifications for " + e.name
                  }
                >
                  <Button
                    type={"primary"}
                    onClick={() => {
                      e.isPushActive
                        ? notificationUserSubscription({
                            isNotification: false,
                            body: {
                              userId: userId ? userId : user.uid,
                              id: e.id,
                              token: token[env],
                              env,
                            },
                          })
                        : notificationUserSubscription({
                            isNotification: true,
                            body: {
                              userId: userId ? userId : user.uid,
                              id: e.id,
                              token: token[env],
                              env,
                            },
                          })
                    }}
                  >
                    {e.isPushActive ? <BellFilled /> : <BellOutlined />}
                  </Button>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title={"Unsubscribe from " + e.name}>
                  <Button
                    type={"primary"}
                    onClick={() => {
                      subscriptionsIds?.includes(e.id)
                      deleteUserSubscriptionList({
                        userId: userId ? userId : user.uid,
                        id: e.id,
                        token: token[env],
                        env,
                      })
                    }}
                  >
                    <CloseCircleOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </div>
        ),
        key: "subscription-list",
      },
    ],
    [
      setPrimarySubscription,
      userId,
      user.uid,
      token,
      env,
      notificationUserSubscription,
      subscriptionsIds,
      deleteUserSubscriptionList,
    ]
  )

  useEffect(() => {
    if (!subscriptions.data || !subscriptions.dataUpdatedAt) return
    const searchFields = ["name"]

    let filteredSubscriptions = [...subscriptions.data]
    if (tags.length || searchValue)
      filteredSubscriptions = filterBySearchValueAndTag<Subscription>({
        tags,
        searchValue,
        searchFields,
        dataArray: filteredSubscriptions,
      })

    setFilteredData(filteredSubscriptions)
  }, [searchValue, subscriptions.data, tags, subscriptions.dataUpdatedAt])

  if (subscriptions.isLoading || subscriptions.error || !subscriptions.data)
    return <Spin />

  return (
    <Card title="Subscriptions">
      <div className="SubscriptionList">
        <div
          className="list-page"
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <Row gutter={16} wrap={false}>
            <Col flex="auto">
              <Input
                className="list-search-input"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Type to search..."
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    addTag(searchValue)
                    setSearchValue("")
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && !searchValue && tags.length) {
                    removeTag(tags[tags.length - 1])
                  }
                }}
                addonBefore={
                  tags
                    ? tags.map((name) => (
                        <Tag
                          key={`sorting-tag-${name}`}
                          color="grey"
                          closable
                          onClose={() => removeTag(name)}
                        >
                          {name}
                        </Tag>
                      ))
                    : null
                }
                addonAfter={<SearchOutlined />}
              />
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => setIsSubscriptionsModalOpen(true)}
              >
                New Subscription
              </Button>
            </Col>
          </Row>
          <Table
            columns={columns}
            showHeader={false}
            scroll={{ y: "calc(100vh - 300px)" }}
            dataSource={filteredData}
            pagination={false}
            rowKey="id"
          ></Table>
        </div>
        {isSubscriptionsModalOpen && (
          <Modal
            title="Add Subscriptions"
            open={isSubscriptionsModalOpen}
            onCancel={() => setIsSubscriptionsModalOpen(false)}
            onOk={() => setIsSubscriptionsModalOpen(false)}
            footer={null}
            width="80vw"
          >
            <SubscribablesList userId={userId} />
          </Modal>
        )}
      </div>
    </Card>
  )
}
