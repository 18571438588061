import { JsonViewer } from "@textea/json-viewer"
import { Modal, Spin } from "antd"
import React from "react"
import { useGetUserCache } from "../../apis/user"
import { environmentType } from "../../types/environtment"
import { GetError } from "../../widgets/GetError"

type Props = {
  open: boolean
  handleClose: () => void
  userId?: string | undefined
  token: string | null
  env: environmentType
  userCtxId?: string
}

export const UserCacheModal = ({
  handleClose,
  open,
  userId,
  token,
  env,
  userCtxId,
}: Props) => {
  const usercache = useGetUserCache({ userId, token, env, userCtxId })

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={null}
      title={"User Cache"}
    >
      {usercache.isLoading && <Spin />}
      {!!usercache.error && !usercache.data && (
        <GetError reFetch={usercache.refetch} />
      )}
      {!!usercache.data && (
        <div
          style={{
            background: "white",
            padding: 16,
            borderRadius: 4,
          }}
        >
          <JsonViewer value={usercache.data} defaultInspectDepth={2} />
        </div>
      )}
    </Modal>
  )
}
