import { Button, Col, Popconfirm, Row } from "antd"
import { useEffect, useState } from "react"
import {
  useGetUsageCases,
  useGetUsageCategories,
  useUpdateUsageScores,
} from "../../apis/UsageScores"
import { useAuthContext } from "../../context/AuthContext"
import NewUseCaseCategoryModal from "./partials/NewUseCaseCategoryModal"
import UsageCaseCategoryTable from "./UsageCaseCategoryTable"
import "./UsageScores.scss"

export default function UsageScores() {
  const [open, setOpen] = useState(false)
  const { env, token } = useAuthContext()

  useEffect(() => {
    return () => {
      setOpen(false)
    }
  }, [])

  const usageCases = useGetUsageCases({
    env,
    token: token[env],
  })

  const usageCategories = useGetUsageCategories({
    env,
    token: token[env],
  })

  const { mutateAsync: updateUsageScores } = useUpdateUsageScores()

  const handleUpdateUsageScores = async () => {
    await updateUsageScores({ env, token: token[env] })
  }

  if (usageCases.isLoading || usageCategories.isLoading)
    return <div>Loading...</div>

  if (!usageCases.data || !usageCategories.data) return <div>No data</div>

  return (
    <div id="UsageScores">
      <Row gutter={16} justify="space-between">
        <Col>
          <Button type="primary" onClick={() => setOpen(true)}>
            Add Metrics Category
          </Button>
        </Col>
        <Col>
          <Popconfirm
            title="Are you sure you want to update all usage scores?"
            onConfirm={handleUpdateUsageScores}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Update Usage Scores</Button>
          </Popconfirm>
        </Col>
      </Row>
      <div
        style={{
          paddingTop: 20,
          width: "100%",
        }}
      >
        <UsageCaseCategoryTable data={usageCategories?.data} />
      </div>
      {open && (
        <NewUseCaseCategoryModal
          open={open}
          setOpen={setOpen}
          usageCases={usageCases.data ?? []}
        />
      )}
    </div>
  )
}
