import { getYear, getMonth } from "date-fns"

export function getNextVersion(currentVersion: string): string {
  const [currentYearStr, currentMonthStr, currentVersionStr] =
    currentVersion.split(".")

  const currentYear = parseInt(currentYearStr, 10)
  const currentMonth = parseInt(currentMonthStr, 10)
  const currentVersionNumber = parseInt(currentVersionStr, 10)

  const now = new Date()
  const year = parseInt(getYear(now).toString(), 10) % 100
  const month = getMonth(now) + 1 // Month is zero-based, so we add 1 to get the correct month

  let nextVersion: string

  if (year === currentYear && month === currentMonth) {
    const nextVersionNumber = currentVersionNumber + 1
    nextVersion = `${year}.${month}.${nextVersionNumber}`
  } else {
    nextVersion = `${year}.${month}.0` // Start a new version if it's a new month or year
  }

  return nextVersion
}
