import { CheckOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Form, message, Select, Tag } from "antd"
import { useState } from "react"
import { useGetRoles } from "../../../../apis/Roles"
import { usePatchUserData } from "../../../../apis/user"
import { useAuthContext } from "../../../../context/AuthContext"
import { UserRoles, UserRoleTags } from "../../../../types/user"
import { RowContent } from "../../../Utils/RowContent"

type Props = {
  roleTags: UserRoleTags[]
  roles: UserRoles[]
  id: string
}

type FormValues = {
  roleTags: UserRoleTags[]
}

function arraysEqual(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) return false

  arr1.sort()
  arr2.sort()

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }

  return true
}

export default function UserJobRolesWidget({ roleTags, id, roles }: Props) {
  const { token, env } = useAuthContext()
  const [isEditing, setIsEditing] = useState(false)
  const { mutateAsync: updateUserRoles } = usePatchUserData(env)
  const [form] = Form.useForm<FormValues>()

  const userRoles = useGetRoles({
    env,
    token: token[env],
    type: "roles",
    enabled: isEditing,
  })

  const onSubmit = async (values: FormValues) => {
    try {
      if (values.roleTags && !arraysEqual(values.roleTags, roleTags)) {
        await updateUserRoles({
          payload: {
            id,
            roleTags: values.roleTags,
            roles,
          },
          env,
          token: token[env] as string,
        })
        message.success("Job Roles Updated")
      }

      return
    } catch (error) {
      message.error("Error Updating Job Roles")
      return
    } finally {
      setIsEditing(false)
    }
  }

  return (
    <RowContent
      name="Job Roles"
      content={
        isEditing ? (
          <Form
            form={form}
            onFinish={onSubmit}
            style={{ display: "flex", gap: 10, alignItems: "center" }}
          >
            <Form.Item
              name="roleTags"
              initialValue={roleTags}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
              }}
            >
              <Select
                style={{ width: "200px" }}
                loading={userRoles.isLoading}
                mode="multiple"
                placeholder="Select roles"
                options={userRoles?.data
                  ?.filter((r) => r.id !== "driverOnly")
                  .map((role) => ({
                    label: role.name,
                    value: role.id,
                  }))}
              />
            </Form.Item>
            <Button size="small" type="primary" htmlType="submit">
              <CheckOutlined />
            </Button>
          </Form>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {roleTags.map((role, index) => (
              <Tag key={index}>{role}</Tag>
            ))}
            <Button size="small" onClick={() => setIsEditing(true)}>
              <EditOutlined />
            </Button>
          </div>
        )
      }
    />
  )
}
