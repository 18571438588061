import { useQuery } from "@tanstack/react-query"
import { Button, Row, Tag } from "antd"
import { useEffect, useState } from "react"
import { getDispatchDetailsById } from "../../../apis/DispatchExplorer"
import { useAuthContext } from "../../../context/AuthContext"
import "./SelectedNodeCard.scss"
import { theme } from "antd"
const { useToken } = theme

export default function SelectedNodeCard({
  selectedNode,
  selectedNodesList,
  setSelectedNodesList,
  nodePosition,
  setNodeDataList,
  isCurrentNodeSelected,
}: any) {
  const { env, token } = useAuthContext()
  const [fetchData, setFetchData] = useState<any>(false)
  const { token: themeToken } = useToken()

  const dispatchData = useQuery(
    ["dispatch", selectedNode.id],
    async () =>
      await getDispatchDetailsById({
        env,
        token: token[env],
        id: selectedNode.id,
      }),
    {
      enabled: fetchData,
    }
  )

  useEffect(() => {
    if (dispatchData.data) {
      setNodeDataList((prev: any) => [...prev, dispatchData.data])
    }
  }, [dispatchData.data, setNodeDataList])

  return (
    <div
      className="nodeCard"
      style={{
        position: "absolute",
        margin: "2px 0px 2px 0px",
        left: nodePosition?.x,
        top: nodePosition?.y,
      }}
    >
      <Row>
        <div
          className="selected-code-info-card"
          style={{
            backgroundColor: themeToken.colorBgBase,
            color: themeToken.colorText,
          }}
        >
          <span>id: {selectedNode.id}</span>
          <span>team: {selectedNode.team.name}</span>
          <span>
            type: <Tag>{selectedNode.type}</Tag>
          </span>

          <Button
            type="primary"
            danger={isCurrentNodeSelected}
            onClick={() => {
              setFetchData(true)
              if (isCurrentNodeSelected) {
                return setSelectedNodesList(
                  selectedNodesList.filter(
                    (node: any) => node.id !== selectedNode.id
                  )
                )
              }

              return setSelectedNodesList([...selectedNodesList, selectedNode])
            }}
          >
            {isCurrentNodeSelected ? "Close" : "View"} Details
          </Button>
        </div>
      </Row>
    </div>
  )
}
