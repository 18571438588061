import { Card, Spin } from "antd"
import React from "react"

import { EditableTimezoneDropdown } from "../widgets/EditableTimezoneDropdown"
import { GetError } from "../../../widgets/GetError"
import { useGetSingleTeam } from "../../../apis/teams"
import { useAuthContext } from "../../../context/AuthContext"
import { TeamEditableTimePicker } from "./TeamEditableTimePicker"
import { RowContent } from "../../Utils/RowContent"
import EditableTimeDisplaySelectWidget from "./EditableTimeDisplaySelectWidget"

type Props = {
  id: string
}

export const OperatingHoursWidget = ({ id }: Props) => {
  const { env, token } = useAuthContext()
  const teamData = useGetSingleTeam({ id, env, token: token[env] })

  if (teamData.isLoading) <Spin />

  if (teamData.error || !teamData.data)
    return <GetError reFetch={teamData.refetch} />

  return (
    <Card title="Operating Hours">
      <RowContent
        name="Start Time"
        content={
          <TeamEditableTimePicker
            value={teamData.data.hours.start}
            name="start"
            data={teamData.data}
          />
        }
      />
      <RowContent
        name="End Time"
        content={
          <TeamEditableTimePicker
            value={teamData.data.hours.end}
            name="end"
            data={teamData.data}
          />
        }
      />
      <RowContent
        name="Timezone"
        content={
          <EditableTimezoneDropdown
            value={teamData.data.hours.timezone}
            data={teamData.data}
          />
        }
      />
      <RowContent
        name="Time Display"
        content={
          <EditableTimeDisplaySelectWidget
            value={teamData.data.timeDisplay}
            data={teamData.data}
          />
        }
      />
    </Card>
  )
}
