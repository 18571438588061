import { Row, Col, Spin } from "antd"
import { useGetTeamMetrics } from "../../apis/teams"
import { useAuthContext } from "../../context/AuthContext"
import { GetError } from "../../widgets/GetError"
import TeamsGrowthByMonth from "../Home/widget/TeamsGrowthByMonth"
import TeamTypeMetrics from "../Home/widget/TeamTypeMetrics"

export default function TeamTotals() {
  const { env, token } = useAuthContext()

  const teamMetrics = useGetTeamMetrics({
    env,
    token: token[env],
  })

  if (teamMetrics.isLoading) return <Spin />

  if (teamMetrics.error || !teamMetrics.data)
    return <GetError reFetch={teamMetrics.refetch} />

  return (
    <Row gutter={[8, 16]} style={{ marginBottom: "20px" }}>
      <Col md={24} lg={12} xl={12}>
        <TeamTypeMetrics
          teamData={teamMetrics.data.nonArchived}
          title="Total Non-Archived Teams"
        />
      </Col>
      <Col md={24} lg={12} xl={12}>
        <TeamTypeMetrics
          teamData={teamMetrics.data.archived}
          title="Total Archived Teams"
        />
      </Col>
      <Col xs={24} md={24} lg={24} xl={24}>
        <TeamsGrowthByMonth {...teamMetrics.data} />
      </Col>
    </Row>
  )
}
