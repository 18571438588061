import { Button, Modal } from "antd"
import React from "react"
import parse from "html-react-parser"
import "./ViewChangeLogsButton.scss"
import { reduceHtmlBr } from "../../utils/reduceHtmlBr"
import { FeatureFlag } from "../../types/featureFlags"

type Props = {
  flag: any
  flagDescription: FeatureFlag[]
}

export default function ViewChangeLogsButton({ flag, flagDescription }: Props) {
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  return (
    <>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        View
      </Button>
      {isModalVisible && (
        <Modal
          width={"80%"}
          open={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
          destroyOnClose={true}
          okText="Close"
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div id="FlagDescription">
            {flag.description && parse(reduceHtmlBr(flag.description))}
            {!flag.description && <p>No change logs/notes available</p>}
          </div>
        </Modal>
      )}
    </>
  )
}
