import React from "react"
import { Button, message, Popconfirm, Row } from "antd"
import { useUpdateSubscriptionV2 } from "../../../apis/subscriptions"
import { environmentType } from "../../../types/environtment"

type Props = {
  id: string
  isDefault: boolean
  entityName: string
  setIsVisible: (isVisible: boolean) => void
  userId: string
  token: string | null
  env: environmentType
}

export default function ResetNotificationsToDefault({
  isDefault,
  entityName,
  id,
  userId,
  setIsVisible,
  token,
  env,
}: Props) {
  const { mutateAsync: resetToDefault } = useUpdateSubscriptionV2(userId)

  const handleResetToDefault = async () => {
    try {
      await resetToDefault({
        id,
        isDefault: true,
        token,
        env,
      })
      setIsVisible(false)
    } catch (error) {
      message.error(
        "failed to reset to default notification settings please try-again"
      )
    } finally {
      //
    }
  }

  return (
    <Row justify="center">
      {isDefault ? (
        <Button type="primary" disabled>
          Reset to default
        </Button>
      ) : (
        <Popconfirm
          onConfirm={handleResetToDefault}
          title={`Are you sure reset to default ${entityName}`}
        >
          <Button type="primary">Reset to default</Button>
        </Popconfirm>
      )}
    </Row>
  )
}
