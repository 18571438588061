import { Card, Row, Col, Divider } from "antd"
import { RequestMetric } from "../../../types/Team"
import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts"
import CustomToolTip from "./CustomToolTip"

export default function PropertyTeamRequests({
  title,
  requestData,
  sparklineLegend,
}: {
  title?: string
  requestData: RequestMetric
  sparklineLegend: string[]
}) {
  const requests = {
    accepted: {
      value: requestData.accepted,
      sparkline: requestData.acceptedSparkline,
    },
    declined: {
      value: requestData.declined,
      sparkline: requestData.declinedSparkline,
    },
    pending: {
      value: requestData.pending,
      sparkline: requestData.pendingSparkline,
    },
  }

  const sparklineData = requestData.totalSparkline.map((value, index) => ({
    index,
    value,
  }))

  return (
    <Card>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          minHeight: "150px",
        }}
      >
        <b style={{ fontSize: "30px", flex: 1 }}>{title}</b>
        <p
          style={{
            fontSize: "80px",
            margin: 0,
            flex: 1,
            textAlign: "center",
          }}
        >
          {requestData.total}
        </p>
      </div>

      <ResponsiveContainer width="100%" height={75}>
        <LineChart data={sparklineData}>
          <XAxis dataKey="index" hide />
          <YAxis hide />
          <Tooltip content={<CustomToolTip months={sparklineLegend} />} />
          <Line type="monotone" dataKey="value" dot={false} />
        </LineChart>
      </ResponsiveContainer>

      <Row gutter={16} style={{ marginTop: "10px" }}>
        {Object.entries(requests).map(([name, { value, sparkline }], index) => (
          <Col span={24} key={index} style={{ marginBottom: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <p>{name.charAt(0).toUpperCase() + name.slice(1)}</p>
              <div
                style={{
                  display: "flex",
                  gap: "40px",
                  alignItems: "center",
                }}
              >
                <p>{value.toString()}</p>
                <LineChart
                  width={100}
                  height={20}
                  data={sparkline.map((val, idx) => ({
                    index: idx,
                    value: val,
                  }))}
                >
                  <XAxis dataKey="index" hide />
                  <YAxis hide />
                  <Tooltip
                    content={<CustomToolTip months={sparklineLegend} />}
                  />
                  <Line type="monotone" dataKey="value" dot={false} />
                </LineChart>
              </div>
            </div>

            <Divider style={{ margin: "7px 0" }} />
          </Col>
        ))}
      </Row>
    </Card>
  )
}
