import axios from "axios"
import { environmentType } from "../types/environtment"
import { useQuery } from "@tanstack/react-query"
import { getEnvUrl } from "../utils/utils"
import {
  DispatchEvent,
  ActivityFeedData,
  Node,
  ActivityFeed,
} from "../types/DispatchExplorer"

export const getDispatchById = async (body: {
  env: environmentType
  token: string | null
  id: string
}) => {
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/dispatch-admin/dispatch-event-tree?dispatchId=${
      body.id
    }`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useGetDispatchById = (body: {
  env: environmentType
  token: string | null
  id: string
}) => {
  //TODO: fix any type to DispatchExplorer type

  return useQuery<DispatchEvent | undefined>(
    ["dispatchExplorer", body.id, body.env],
    () => getDispatchById(body),
    { enabled: !!body.id }
  )
}

// /v1/dispatches/:dispatchId
export const getDispatchDetailsById = async (body: {
  env: environmentType
  token: string | null
  id: string
}) => {
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/dispatches/${body.id}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useGetDispatchDetailsById = (body: {
  env: environmentType
  token: string | null
  id: string
  enabled: boolean
  onSuccess: (props: any) => void
  onError: (props: any) => void
}) => {
  return useQuery<DispatchEvent | undefined>(
    ["dispatchDetails", body.id, body.env],
    () => getDispatchDetailsById(body),
    { enabled: body.enabled, onSuccess: body.onSuccess, onError: body.onError }
  )
}

export const getActivityFeedData = async (body: {
  env: environmentType
  token: string | null
  dispatchData: DispatchEvent | undefined
}): Promise<ActivityFeed | undefined> => {
  if (!body.dispatchData) {
    return undefined
  }

  const results = await Promise.all(
    body.dispatchData.nodes.map(async (node: Node) => {
      const { data } = await axios.get(
        `${getEnvUrl(body.env)}/v1/activity-feeds?entityId=${
          node.id
        }&feedType=core&page=1&size=100`,
        {
          headers: {
            Authorization: `Bearer ${body.token}`,
          },
        }
      )

      const transformedData = data.data.map((item: ActivityFeedData) => ({
        ...item,
        node,
      }))

      return transformedData
    })
  )

  return results.flat()
}

export const useGetActivityFeedData = (body: {
  env: environmentType
  token: string | null
  dispatchData: DispatchEvent | undefined
}) => {
  const activityFeed = useQuery<ActivityFeed | undefined>(
    [
      "activityFeed",
      body.env,
      body.dispatchData?.nodes?.length,
      body.dispatchData?.links?.length,
    ],
    () => getActivityFeedData(body),
    {
      enabled:
        !!body.dispatchData &&
        (body.dispatchData.nodes?.length > 0 ||
          body.dispatchData.links?.length > 0),
    }
  )

  return activityFeed
}
