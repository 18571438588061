import { environmentType } from "../../types/environtment";
import { FeatureFlagResponse } from "../../types/featureFlags";

export const addEnvToFeatureFlag = (
  feature: FeatureFlagResponse,
  env: environmentType
) => {
  const copy = [...feature.FeatureFlags].map((flag) => {
    const newFlag = { ...flag };
    newFlag.env = env;
    return newFlag;
  });
  return copy;
};
