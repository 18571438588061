import axios from "axios"
import { environmentType } from "../types/environtment"
import { getEnvUrl } from "../utils/utils"
import { useQuery } from "@tanstack/react-query"
import { Entity } from "../types/Entity"

const getEntity = async (
  env: environmentType,
  token: string | null,
  id: string
) => {
  if (!token) return
  const { data } = await axios.get(`${getEnvUrl(env)}/v1/entity/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (data) return data.data
}

export const useGetEntity = (body: {
  env: environmentType
  token: string | null
  searchId: string
}) => {
  const response = useQuery<Entity>(
    ["entities", body.env, body.searchId],
    () => getEntity(body.env, body.token, body.searchId),
    { enabled: !!body.searchId }
  )

  return response
}
