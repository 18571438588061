import { Button, Col, Divider, message, Popconfirm, Row } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import { useAddRemoveSysAdmin, useGetUserAccounts } from "../../apis/SysAdmin"
import CreateNewUserModal from "../../components/Users/widgets/CreateNewUserModal/CreateNewUserModal"
import { useAuthContext } from "../../context/AuthContext"
import SysAdminAllUsersTable from "./widgets/SysAdminAllUsersTable"
import SysAdminOverview from "./widgets/SysAdminOverview"

export default function SysAdminDetailPage() {
  const { id } = useParams()
  const { token, env } = useAuthContext()
  const { mutateAsync: addRemoveSysAdmin, isLoading } = useAddRemoveSysAdmin()
  const navigate = useNavigate()

  const userAccount = useGetUserAccounts<"full">({
    env,
    token: token[env],
    dto: { format: "full", authIds: [id!], sysAdminOnly: true },
  })

  const handleRemoveSysAdminPrivileges = async () => {
    try {
      await addRemoveSysAdmin({
        env,
        token: token[env],
        authId: id!,
        action: "remove",
      })

      message.success("User removed from system admin successfully.")
      navigate("/sysadmin")
      return
    } catch (error) {
      message.error("Failed to remove user as system admin. Please try again.")
      return
    } finally {
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Row justify="end">
        <Col>
          <Row gutter={8}>
            <Col>
              <CreateNewUserModal
                firstName={userAccount.data?.[0]?.firstName || ""}
                lastName={userAccount.data?.[0]?.lastName || ""}
                email={userAccount.data?.[0]?.email || ""}
                refetch={userAccount.refetch}
                id={id!}
                currentTeamIds={
                  userAccount.data?.[0]?.allUsers?.map((u) => u.team.id) || []
                }
                languagePreference={
                  userAccount.data?.[0]?.languagePreference || "en"
                }
              />
            </Col>
            <Col>
              <Popconfirm
                title="Are you sure you want to remove this user as a system admin?"
                onConfirm={handleRemoveSysAdminPrivileges}
              >
                <Button type="primary" danger loading={isLoading}>
                  Remove System Admin
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Col>
      </Row>

      <SysAdminOverview user={userAccount.data?.[0]} />
      <Divider />
      <SysAdminAllUsersTable allUsers={userAccount.data?.[0]?.allUsers || []} />
      <Divider />
    </div>
  )
}
