import { Row, Col, Spin } from "antd"
import PropertyTeamRequests from "./widgets/PropertyTeamRequests"
import TopPropertyRequests from "./widgets/TopPropertyRequests"
import { useGetPartnershipMetrics } from "../../apis/teams"
import { useAuthContext } from "../../context/AuthContext"
import { GetError } from "../../widgets/GetError"

export default function TeamParternshipRequests() {
  const { env, token } = useAuthContext()

  const partnershipMetrics = useGetPartnershipMetrics({
    env,
    token: token[env],
  })

  if (partnershipMetrics.isLoading) return <Spin />

  if (partnershipMetrics.error || !partnershipMetrics.data)
    return <GetError reFetch={partnershipMetrics.refetch} />

  return (
    <Row gutter={[8, 16]} style={{ marginBottom: "20px" }}>
      <Col md={24} lg={12} xl={12}>
        <PropertyTeamRequests
          requestData={partnershipMetrics.data.allRequests}
          sparklineLegend={partnershipMetrics.data.sparklineLegend}
          title="Lifetime Partnership Requests"
        />
      </Col>
      <Col md={24} lg={12} xl={12}>
        <PropertyTeamRequests
          requestData={partnershipMetrics.data.requestsToNonCustomers}
          sparklineLegend={partnershipMetrics.data.sparklineLegend}
          title="Requests to Non-Customers"
        />
      </Col>
      <Col xs={24} md={24} lg={24} xl={24}>
        <TopPropertyRequests
          requestData={partnershipMetrics.data.requestsPerCustomer}
        />
      </Col>
    </Row>
  )
}
