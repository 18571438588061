import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { environmentType } from "../types/environtment";
import {
  AddExceptionDTO,
  FeatureFlagDTO,
  FeatureFlagResponse,
  UpdateFeaturFlagDTO,
} from "../types/featureFlags";
import { getEnvUrl } from "../utils/utils";

export const getFeatureFlags = async (body: {
  env: environmentType;
  token: string | null;
}) => {
  if (body.token) {
    const { data } = await axios.get(
      `${getEnvUrl(body.env)}/v1/feature-flags`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    );
    if (data) return data.data;
  }
};

export const useGetFeatureFlags = (body: {
  env: environmentType;
  token: string | null;
  enabled: boolean;
}) => {
  const response = useQuery<FeatureFlagResponse>(
    ["feature-flags", body.env],
    () => getFeatureFlags(body),
    {
      enabled: body.enabled,
    }
  );

  return response;
};

const createFeatureFlags = async (body: {
  env: environmentType;
  token: string | null;
  payload: FeatureFlagDTO;
}) => {
  if (body.token) {
    const { data } = await axios.post(
      `${getEnvUrl(body.env)}/v1/feature-flags`,
      body.payload,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    );
    if (data) return data.data;
  }
};

export const useCreateFeatureFlag = () => {
  const queryClient = useQueryClient();
  return useMutation(createFeatureFlags, {
    onSuccess: (data, params) => {
      if (!data) return;
      const oldData = queryClient.getQueryData<FeatureFlagResponse>([
        "feature-flags",
        params.env,
      ]);
      if (oldData) {
        oldData.FeatureFlags.push(data);
        queryClient.setQueryData(["feature-flags", params.env], () => oldData);
      }
    },
  });
};

const addException = async (body: {
  env: environmentType;
  token: string | null;
  payload: AddExceptionDTO;
}) => {
  if (body.token) {
    const { data } = await axios.post(
      `${getEnvUrl(body.env)}/v1/feature-flags-exceptions`,
      body.payload,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    );
    if (data) return data.status as number;
  }
};

export const useAddException = () => {
  const queryClient = useQueryClient();
  return useMutation(addException, {
    onSuccess: (data, params) => {
      if (!data) return;
      queryClient.refetchQueries<FeatureFlagResponse>([
        "feature-flags",
        params.env,
      ]);
    },
  });
};
const deleteException = async (body: {
  env: environmentType;
  token: string | null;
  payload: AddExceptionDTO;
}) => {
  if (body.token) {
    const { data } = await axios.delete(
      `${getEnvUrl(body.env)}/v1/feature-flags-exceptions`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
        data: body.payload,
      }
    );
    if (data) return data.status as number;
  }
};

export const useDeleteException = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteException, {
    onSuccess: (data, params) => {
      if (!data) return;
      queryClient.refetchQueries<FeatureFlagResponse>([
        "feature-flags",
        params.env,
      ]);
    },
  });
};

const updateFeatureFlag = async (body: {
  env: environmentType;
  token: string | null;
  payload: UpdateFeaturFlagDTO;
}) => {
  if (body.token) {
    const { data } = await axios.patch(
      `${getEnvUrl(body.env)}/v1/feature-flags`,
      body.payload,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    );
    if (data) return data.data;
  }
};

export const useUpdateFeatureFlag = () => {
  const queryClient = useQueryClient();
  return useMutation(updateFeatureFlag, {
    onSuccess: (data, params) => {
      if (!data) return;
      queryClient.invalidateQueries<FeatureFlagResponse>([
        "feature-flags",
        params.env,
      ]);
    },
  });
};

const deleteFeatureFlag = async (body: {
  env: environmentType;
  token: string | null;
  payload: UpdateFeaturFlagDTO;
}) => {
  if (body.token) {
    const { data } = await axios.delete(
      `${getEnvUrl(body.env)}/v1/feature-flags`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
        data: body.payload,
      }
    );
    if (data) return data.status as number;
  }
};

export const useDeleteFeatureFlag = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteFeatureFlag, {
    onSuccess: (data, params) => {
      if (!data) return;
      queryClient.refetchQueries<FeatureFlagResponse>([
        "feature-flags",
        params.env,
      ]);
    },
  });
};
