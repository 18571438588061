import { Form, Select } from "antd"
import { useEffect, useState } from "react"
import { useTeams } from "../../../../apis/teams"
import { useAuthContext } from "../../../../context/AuthContext"
import { Team } from "../../../../types/Team"

type Props = {
  currentTeamIds?: string[]
}

export default function SelectTeamSelect({ currentTeamIds }: Props) {
  const { env, token } = useAuthContext()
  const teams = useTeams({ env, token: token[env] })
  const [teamList, setTeamList] = useState<Team[]>([])

  useEffect(() => {
    if (currentTeamIds && teams.data) {
      const filteredTeams = teams.data.filter(
        (team) => !currentTeamIds.includes(team.id)
      )
      setTeamList(filteredTeams)
    }
  }, [currentTeamIds, teams.data])

  return (
    <Form.Item label="Team" name="teamId" rules={[{ required: true }]}>
      <Select
        showSearch
        filterOption={(input, option) =>
          Boolean(option?.label.toLowerCase().includes(input.toLowerCase()))
        }
        options={teamList.map((team) => ({
          label: team?.name,
          value: team?.id,
        }))}
        disabled={teams.isLoading}
        loading={teams.isLoading}
      />
    </Form.Item>
  )
}
