import { useAuthContext } from "../../context/AuthContext"
import { useGetTeamsAndUsersLogCount } from "../../apis/teams"
import { Col, Row, Spin } from "antd"
import { GetError } from "../../widgets/GetError"
import { TopTeamsLogCount } from "./widget/TopTeamsLogCount"
import { TopUseCaseLogCount } from "./widget/TopUseCaseLogCount"

export const TopTeamsAndUseLogCount = () => {
  const { env, token } = useAuthContext()
  const topTeamsAndUsers = useGetTeamsAndUsersLogCount({
    env,
    token: token[env],
  })

  if (topTeamsAndUsers.isLoading || !token[env]) return <Spin />
  if (topTeamsAndUsers.error || !topTeamsAndUsers.data)
    return <GetError reFetch={topTeamsAndUsers.refetch} />

  return (
    <Row gutter={[8, 16]}>
      <Col lg={24} xl={12}>
        <TopTeamsLogCount logCount={topTeamsAndUsers.data.TeamLogCount || []} />
      </Col>
      <Col lg={24} xl={12}>
        <TopUseCaseLogCount
          logCount={topTeamsAndUsers.data.UseCaseLogCount || []}
        />
      </Col>
    </Row>
  )
}
