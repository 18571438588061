import { TimePicker } from "antd";
import { startOfDay } from "date-fns";
type Props = {
  setSchedule: (value: number) => void;
};

export const TeamTimePicker = ({ setSchedule }: Props) => {
  const midNight = startOfDay(new Date());

  return (
    <TimePicker
      showNow={false}
      minuteStep={30}
      className="overview-field-content"
      format="HH:mm"
      onChange={(e) => {
        if (!e) {
          setSchedule(0);
        }
        if (e) {
          const newValue = e?.diff(midNight, "minutes");
          setSchedule(newValue);
        }
      }}
    />
  );
};
