import { Button, Col, Input, Row, Skeleton, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useMemo, useState } from "react"
import { useGetCompanyApplications } from "../../apis/SelfApplications"
import { useAuthContext } from "../../context/AuthContext"
import { CompanyApplication, orgTypeMap } from "../../types/AccountApplication"
import {
  LanguageCode,
  languageFlagMap,
  VizznSupportedLanguages,
} from "../../types/Language"
import ErrorRefetch from "../ErrorRefetch/ErrorRefetch"
import { format } from "date-fns"
import { useNavigate } from "react-router-dom"

export default function CompanyApplications() {
  const { token, env } = useAuthContext()
  const [searchText, setSearchText] = useState("")
  const companies = useGetCompanyApplications(env, token[env])
  const navigate = useNavigate()

  const columns: ColumnsType<CompanyApplication> = useMemo(
    () => [
      {
        title: (
          <Row justify="center">
            <Col>Orgnization Name</Col>
          </Row>
        ),
        dataIndex: "orgName",
        key: "orgName",
        render: (_, row) => (
          <Row justify="center">
            <span>{row.orgName || "-"}</span>
          </Row>
        ),
      },
      {
        title: (
          <Row justify="center">
            <Col>Owner Name</Col>
          </Row>
        ),
        dataIndex: "ownerName",
        key: "ownerName",
        render: (_, row) => (
          <Row justify="center">
            <span>{row.ownerName || "-"}</span>
          </Row>
        ),
      },
      {
        title: (
          <Row justify="center">
            <Col>Owner Email</Col>
          </Row>
        ),
        dataIndex: "ownerEmail",
        key: "ownerEmail",
        render: (_, row) => (
          <Row justify="center">
            <span>{`${row.ownerEmail || "-"}`}</span>
          </Row>
        ),
      },
      {
        title: (
          <Row justify="center">
            <Col>Organization Address</Col>
          </Row>
        ),
        key: "orgAddress",
        render: (_, record) => {
          return (
            <Row justify="center">
              <Col>
                <a
                  href={
                    record?.orgAddress
                      ? `https://www.google.com/maps/search/?api=1&query=${record?.orgAddress}`
                      : "#"
                  }
                  {...(record?.orgAddress
                    ? { target: "_blank", rel: "noreferrer" }
                    : {})}
                >
                  {record?.orgAddress || "-"}
                </a>
              </Col>
            </Row>
          )
        },
      },
      {
        title: (
          <Row justify="center">
            <Col>Organization Type</Col>
          </Row>
        ),
        dataIndex: "orgType",
        key: "orgType",
        render: (_, record) => {
          return (
            <Row justify="center">
              <Col>{orgTypeMap[record.orgType]}</Col>
            </Row>
          )
        },
      },
      {
        title: (
          <Row justify="center">
            <Col>Employee Count</Col>
          </Row>
        ),
        dataIndex: "itemEmployeeCount",
        key: "itemEmployeeCount",
        render: (_, record) => {
          return (
            <Row justify="center">
              <Col>{record.itemEmployeeCount}</Col>
            </Row>
          )
        },
      },
      {
        title: (
          <Row justify="center">
            <Col>Partner Count</Col>
          </Row>
        ),
        dataIndex: "itemPartnerCount",
        key: "itemPartnerCount",
        render: (_, record) => {
          return (
            <Row justify="center">
              <Col>{record.itemPartnerCount}</Col>
            </Row>
          )
        },
      },
      {
        title: (
          <Row justify="center">
            <Col>Truck Count</Col>
          </Row>
        ),
        dataIndex: "itemTruckCount",
        key: "itemTruckCount",
        render: (_, record) => {
          return (
            <Row justify="center">
              <Col>{record.itemTruckCount}</Col>
            </Row>
          )
        },
      },
      {
        title: (
          <Row justify="center">
            <Col>Preferred Language</Col>
          </Row>
        ),
        dataIndex: "orgPrefLang",
        key: "orgPrefLang",
        render: (_, record) => {
          return (
            <Row justify="center">
              <Col>
                {
                  VizznSupportedLanguages[
                    (record.orgPrefLang as LanguageCode) ?? "en"
                  ]
                }{" "}
                {languageFlagMap[(record.orgPrefLang as LanguageCode) ?? "en"]}
              </Col>
            </Row>
          )
        },
      },
      {
        title: (
          <Row justify="center">
            <Col>Status</Col>
          </Row>
        ),
        dataIndex: "status",
        key: "status",
        render: (_, record) => {
          return (
            <Row justify="center">
              <Col>
                <Tag color={`#${record?.status?.bgColor}`}>
                  <span
                    style={{
                      color: `#${record?.status?.textColor}`,
                    }}
                  >
                    {record.status.name}
                  </span>
                </Tag>
              </Col>
            </Row>
          )
        },
      },
      {
        title: (
          <Row justify="center">
            <Col>Created At</Col>
          </Row>
        ),
        dataIndex: "createdAt",
        key: "createdAt",
        render: (_, record) => (
          <Row justify="center">
            <span>{format(new Date(record.createdAt), "MMM-dd-yyyy")}</span>
          </Row>
        ),
      },
    ],
    []
  )

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleRowClick = (record: CompanyApplication) => {
    navigate(`/company-applications/${record.id}`)
  }

  if (companies.isLoading) return <Skeleton active />
  if (!companies.data || companies.isError)
    return <ErrorRefetch refetch={companies.refetch} />

  return (
    <Col span={24}>
      <Row style={{ width: "100%", paddingBottom: 20 }} justify="space-between">
        <Col span={22}>
          <Input.Search
            placeholder="Search table data"
            onSearch={handleSearch}
            enterButton
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>
        <Col span={2}>
          <Row justify="end">
            <Button onClick={() => handleSearch("")}>Clear</Button>
          </Row>
        </Col>
      </Row>

      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={companies?.data?.filter(
              (record: CompanyApplication) =>
                record.orgName?.toLocaleLowerCase().includes(searchText) ||
                record.ownerName?.toLocaleLowerCase().includes(searchText)
            )}
            pagination={false}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
              style: { cursor: "pointer" },
            })}
          />
        </Col>
      </Row>
    </Col>
  )
}
