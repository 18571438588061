import { Button, Col, Form, Input, Row, Tag } from "antd"
import { useState } from "react"
import { useAuthContext } from "../../context/AuthContext"
import { useUpdateTeam } from "../../apis/teams"
import { EditOutlined, StopOutlined } from "@ant-design/icons"
import { RowContent } from "../Utils/RowContent"

type Props = {
  emails: string
  teamId: string
}

export const EditableEmails = ({ emails, teamId }: Props) => {
  const { env, token } = useAuthContext()
  const [isEditing, setIsEditing] = useState(false)
  const [emailValue, setEmailValue] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { mutateAsync: updateTeam } = useUpdateTeam(teamId, env)

  const emailTags = emails ? emails.split(",") : []

  const validateEmail = (email: string) => {
    const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
    return re.test(String(email).toLowerCase())
  }

  const removeEmail = async (email: string) => {
    setIsSubmitting(true)
    const newData = emailTags.filter((e) => e !== email)
    await updateTeam({
      data: {
        id: teamId,
        emailParts: newData.length ? newData.join(",") : null,
      },
      env,
      token: token[env],
    })
    setIsSubmitting(false)
  }

  return (
    <RowContent
      name="Email"
      content={
        isEditing ? (
          <Form style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Input
              className="list-search-input"
              disabled={isSubmitting}
              type="email"
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
              placeholder="Add email"
              style={{
                maxHeight: "32px",
                width: "200px",
              }}
              onKeyPress={async (e) => {
                if (e.key === "Enter") {
                  if (validateEmail(emailValue)) {
                    setIsSubmitting(true)
                    const newData = [
                      ...emailTags.filter(
                        (existingEmail) => existingEmail !== emailValue
                      ),
                      emailValue,
                    ]
                    await updateTeam({
                      data: { id: teamId, emailParts: newData.join(",") },
                      env,
                      token: token[env],
                    })
                    setEmailValue("")
                    setIsSubmitting(false)
                  } else return new Error()
                }
              }}
            />
            <Button
              size="small"
              type="default"
              onClick={() => setIsEditing(false)}
            >
              <StopOutlined />
            </Button>
            <div
              style={{
                display: "flex",
                maxWidth: "100%",
                flexWrap: "wrap",
                gap: 8,
              }}
            >
              {emailTags.map((tag) => (
                <Tag
                  key={tag}
                  className="list-tag-filter"
                  color="grey"
                  closable
                  onClose={() => {
                    removeEmail(tag)
                  }}
                >
                  {tag}
                </Tag>
              ))}
            </div>
          </Form>
        ) : (
          <Row gutter={8} align={"middle"}>
            {emailTags.length > 0 ? (
              <Col
                style={{
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  maxWidth: "100%",
                }}
              >
                <b>
                  {emailTags.map((tag, index) => (
                    <span key={index}>{tag}; </span>
                  ))}
                </b>
              </Col>
            ) : null}

            <Col>
              <Button size="small" onClick={() => setIsEditing(true)}>
                <EditOutlined />
              </Button>
            </Col>
          </Row>
        )
      }
    ></RowContent>
  )
}
