import { Card, Row, Table } from "antd"
import { useMemo } from "react"
import { useAuthContext } from "../../context/AuthContext"
import { useGetUserDevices } from "../../apis/user"
import { GetError } from "../../widgets/GetError"
import { Device } from "../../types/Device"
import { ColumnsType } from "antd/lib/table"
import { formatToDateHourAndSeconds } from "../Utils/TimeUtils"
import { CopyContent } from "../Utils/CopyContent"

type Props = {
  id: string
}

export const UserDevices = ({ id }: Props) => {
  const { env, token } = useAuthContext()
  const devices = useGetUserDevices({
    env,
    token: token[env],
    userId: id,
  })

  const columns: ColumnsType<Device> = useMemo(
    () => [
      {
        title: "Model",
        align: "left",
        width: 150,
        render: (_, e) => e.model,
        sorter: (a, b) =>
          a.model.toLocaleLowerCase() < b.model.toLocaleLowerCase() ? -1 : 1,
        key: "model",
      },
      {
        title: "Type",

        width: 150,
        render: (_, e) => e.deviceType,
        sorter: (a, b) =>
          a.deviceType.toLocaleLowerCase() < b.deviceType.toLocaleLowerCase()
            ? -1
            : 1,
        key: "deviceType",
      },
      {
        title: "OS",

        width: 150,
        render: (_, e) => e.os,
        sorter: (a, b) => (a.os < b.os ? -1 : 1),
        key: "os",
      },
      {
        title: "OS Version",

        width: 150,
        render: (_, e) => e.osVersion,
        sorter: (a, b) => (a.osVersion < b.osVersion ? -1 : 1),
        key: "os",
      },
      {
        title: "App Version",

        width: 150,
        render: (_, e) => e.appVersion,
        sorter: (a, b) => (a.appVersion < b.appVersion ? -1 : 1),
        key: "appVersion",
      },
      {
        title: "Token",
        ellipsis: true,
        width: 150,
        render: (_, e) => <CopyContent left text={e.token} />,
        sorter: (a, b) => (a.token < b.token ? -1 : 1),
        key: "token",
      },
      {
        title: "Location Permission",

        width: 150,
        render: (_, e) => (
          <Row justify="center">
            <span>{camelCaseToWords(String(e.authorizationStatus))}</span>
          </Row>
        ),
        key: "os",
      },
      {
        title: "Manufacturer",

        width: 150,
        render: (_, e) => e.manufacturer,
        sorter: (a, b) =>
          a.manufacturer.toLocaleLowerCase() <
          b.manufacturer.toLocaleLowerCase()
            ? -1
            : 1,
        key: "os",
      },
      {
        title: "Created At",

        width: 150,
        render: (_, e) => formatToDateHourAndSeconds(e.createdAt),
        sorter: (a, b) => (a.createdAt < b.createdAt ? -1 : 1),
        key: "createdAt",
      },
    ],
    []
  )

  if (devices.error || !devices.data)
    return <GetError reFetch={devices.refetch} />
  return (
    <Card title="User Devices">
      <Table
        scroll={{ y: "60vh" }}
        dataSource={devices.data.slice(0, 10)}
        columns={columns}
        pagination={false}
        rowKey="id"
        loading={devices.isLoading}
      />
    </Card>
  )
}

const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1")
  return result.charAt(0).toUpperCase() + result.slice(1)
}
