import { CloseOutlined, CopyOutlined } from "@ant-design/icons"
import { UseQueryResult } from "@tanstack/react-query"
import {
  Button,
  Card,
  Col,
  Input,
  message,
  Popconfirm,
  Row,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd"
import { flatten, unionBy } from "lodash"
import { useMemo, useState } from "react"
import {
  useCreateFeatureFlag,
  useDeleteException,
  useDeleteFeatureFlag,
  useGetFeatureFlags,
} from "../apis/featureFlags"
import { AddTeamOrUserModal } from "../components/FeatureFlags/AddTeamOrUserModal"
import { NewFeatureFlagModal } from "../components/FeatureFlags/NewFeatureFlagModal"
import { UpdateFeatureFlagModal } from "../components/FeatureFlags/UpdateFeatureFlagModal"
import { addEnvToFeatureFlag } from "../components/FeatureFlags/utils"
import ViewChangeLogsButton from "../components/FeatureFlags/ViewChangeLogsButton"
import { LoginWidget } from "../components/login/LoginWidget"
import { CopyContent } from "../components/Utils/CopyContent"
import { useAuthContext } from "../context/AuthContext"
import { environmentType } from "../types/environtment"
import {
  DeleteFeatureFlagDTO,
  FeatureFlagResponse,
  ReducedFlag,
} from "../types/featureFlags"
import "./FeatureFlag.scss"

export const FeatureFlags = () => {
  const { token, user, env } = useAuthContext()
  const { mutateAsync: createFeatureFlag } = useCreateFeatureFlag()
  const { mutateAsync: deleteException } = useDeleteException()
  const { mutateAsync: deleteFeatureFlag } = useDeleteFeatureFlag()
  const [searchText, setSearchText] = useState("")
  const [filterMismatchedVersions, setFilterMismatchedVersions] =
    useState(false)

  const handleDelete = (
    id: string,
    type: "team" | "user",
    version: number,
    name: string,
    description: string
  ) => {
    deleteException({
      env,
      token: token[env],
      payload: {
        ids: [id],
        type,
        name,
        activeVersion: version,
        description,
      },
    })
  }
  const devFlags = useGetFeatureFlags({
    env: "dev",
    token: token["dev"],
    enabled: !!user["dev"],
  })

  const prodFlags = useGetFeatureFlags({
    env: "prod",
    token: token["prod"],
    enabled: !!user["prod"],
  })

  const devData: FeatureFlagResponse = useMemo(
    () =>
      devFlags.data
        ? { ...devFlags.data }
        : {
            Environment: "dev",
            FeatureFlagCount: 0,
            FeatureFlags: [],
          },
    [devFlags.data]
  )

  const prodData: FeatureFlagResponse = useMemo(
    () =>
      prodFlags.data
        ? { ...prodFlags.data }
        : {
            Environment: "prod",
            FeatureFlagCount: 0,
            FeatureFlags: [],
          },
    [prodFlags.data]
  )

  const devProdData = useMemo(
    () => [
      ...addEnvToFeatureFlag(devData, "dev"),
      ...addEnvToFeatureFlag(prodData, "prod"),
    ],
    [devData, prodData]
  )

  const mergedData = useMemo(
    () =>
      devProdData.reduce((prev, current) => {
        const index = prev.findIndex((flag) => flag.name === current.name)
        if (index === -1 && current.env) {
          const obj: ReducedFlag = {
            name: current.name,
            description:
              devProdData.find(
                (flag) => flag.env === "prod" && flag.name === current.name
              )?.description || current.description,
            // description: current.description,
            activeVersion: { [current.env]: current.activeVersion },
            [current.env]: [...current.versions],
          }

          prev.push(obj)
        }
        if (index >= 0 && current.env) {
          prev[index][current.env] = [...current.versions]
          prev[index].activeVersion = {
            ...prev[index].activeVersion,
            [current.env]: current.activeVersion,
          }
        }
        return prev
      }, [] as ReducedFlag[]),
    [devProdData]
  )

  const mismastchedVersions = useMemo(
    () =>
      mergedData.filter(
        (flag) =>
          flag.dev &&
          flag.prod &&
          flag.activeVersion.dev !== flag.activeVersion.prod
      ),
    [mergedData]
  )

  const handleFeatureFlagDelete = async (flag: any) => {
    message.success(flag.name)
    const deleteFlagDto: DeleteFeatureFlagDTO = {
      name: flag.name,
      type: "app",
    }
    await deleteFeatureFlag({ env, token: token[env], payload: deleteFlagDto })
  }

  const handleQueryState = (
    query: UseQueryResult<FeatureFlagResponse, unknown>,
    env: environmentType
  ) => {
    return !query.isLoading && !query.error && user[env]
  }

  const handleSearch = (value: string) => {
    setSearchText(value.toLowerCase())
  }

  const dev = env === "dev"
  const prod = env === "prod"

  return (
    <>
      <Row
        style={{ width: "100%", paddingBottom: 20 }}
        justify="space-between"
        align="middle"
      >
        <Col span={20}>
          <Input.Search
            placeholder="Search table data"
            onSearch={handleSearch}
            enterButton
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>

        <Col span={4}>
          <Row justify="end" align={"middle"} gutter={8}>
            <Col>
              <Switch
                checked={filterMismatchedVersions}
                onChange={setFilterMismatchedVersions}
              />
            </Col>
            <Col>
              <span>Filter versions</span>
            </Col>
            <Col>
              <Button onClick={() => handleSearch("")}>Clear</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={8} wrap={false}>
        {(!!user["dev"] || !!user["prod"]) && (
          <Col flex="auto">
            <Card>
              <Table
                rowKey="id"
                dataSource={
                  filterMismatchedVersions
                    ? mismastchedVersions.filter((record) =>
                        record.name.toLocaleLowerCase().includes(searchText)
                      )
                    : mergedData.filter((record) =>
                        record.name.toLocaleLowerCase().includes(searchText)
                      )
                }
                pagination={false}
                scroll={{ x: true }}
              >
                <Table.Column
                  fixed="left"
                  title="Feature Flag"
                  dataIndex="name"
                  key="name"
                  render={(_, e) => (
                    <>
                      <CopyContent text={e.name} />
                      <UpdateFeatureFlagModal
                        flag={e}
                        env={env}
                        mode="update"
                      />
                    </>
                  )}
                  defaultSortOrder="ascend"
                  sorter={(a: ReducedFlag, b: ReducedFlag) => {
                    if (!a.name) return 1
                    if (!b.name) return -1
                    return a.name.toLocaleLowerCase() <
                      b.name.toLocaleLowerCase()
                      ? -1
                      : 1
                  }}
                />
                {handleQueryState(devFlags, "dev") && (
                  <Table.ColumnGroup
                    title={
                      <>
                        {`Dev (${devData.FeatureFlagCount}) `}
                        {dev && <NewFeatureFlagModal envName="dev" />}
                      </>
                    }
                    align="left"
                  >
                    <Table.Column
                      className={dev ? "activeEnvironment" : ""}
                      title="Active Version"
                      align="center"
                      dataIndex="activeVersion"
                      key="activeVersion"
                      render={(_, e: ReducedFlag) => {
                        return (
                          <>
                            <span style={{ marginRight: 4 }}>
                              {e.activeVersion.dev}
                            </span>
                            {dev && (
                              <UpdateFeatureFlagModal
                                flag={e}
                                env="dev"
                                mode="increment"
                              />
                            )}
                          </>
                        )
                      }}
                    />

                    <Table.Column
                      className={dev ? "activeEnvironment" : ""}
                      title="Team Exceptions"
                      align="center"
                      render={(_, e: ReducedFlag) => {
                        if (e.dev && typeof e.activeVersion.dev === "number") {
                          const teamsArray = e.dev.map((version) => {
                            if (e.activeVersion.dev! < version.version)
                              return version.teamExceptions.map((team) => ({
                                ...team,
                                version: version.version,
                              }))
                            return []
                          })

                          const teams = unionBy(flatten(teamsArray))
                          return (
                            <>
                              {teams.map((team) => (
                                <Popconfirm
                                  title="Are you sure about removing this Team?"
                                  disabled={!dev}
                                  onConfirm={() =>
                                    handleDelete(
                                      team.id,
                                      "team",
                                      team.version,
                                      e.name,
                                      e.description ?? ""
                                    )
                                  }
                                  key={team.id + team.version}
                                >
                                  <Tag>
                                    <span style={{ marginRight: 2 }}>
                                      {`${team.name} [${team.version}]`}
                                    </span>
                                    {dev && (
                                      <CloseOutlined className="pointer" />
                                    )}
                                  </Tag>
                                </Popconfirm>
                              ))}
                              {dev && (
                                <AddTeamOrUserModal
                                  type="team"
                                  env={env}
                                  flag={e}
                                  activeVersion={e.activeVersion.dev}
                                  versions={e.dev.map((v) => v.version)}
                                />
                              )}
                            </>
                          )
                        }
                      }}
                    />
                    <Table.Column
                      className={dev ? "activeEnvironment" : ""}
                      title="User Exceptions"
                      align="center"
                      render={(_, e: ReducedFlag) => {
                        if (e.dev && typeof e.activeVersion.dev === "number") {
                          const usersArrays = e.dev.map((version) => {
                            if (e.activeVersion.dev! < version.version)
                              return version.userExceptions.map((user) => ({
                                ...user,
                                version: version.version,
                              }))
                            return []
                          })

                          const users = unionBy(flatten(usersArrays))

                          return (
                            <>
                              {users.map((user) => (
                                <Popconfirm
                                  title="Are you sure about removing this User?"
                                  disabled={!dev}
                                  onConfirm={() =>
                                    handleDelete(
                                      user.id,
                                      "user",
                                      user.version,
                                      e.name,
                                      e.description ?? ""
                                    )
                                  }
                                  key={user.id + user.version}
                                >
                                  <Tag>
                                    <span style={{ marginRight: 2 }}>
                                      {`${user.name} [${user.version}]`}
                                    </span>
                                    {dev && (
                                      <CloseOutlined className="pointer" />
                                    )}
                                  </Tag>
                                </Popconfirm>
                              ))}
                              {dev && (
                                <AddTeamOrUserModal
                                  type="user"
                                  env={env}
                                  flag={e}
                                  activeVersion={e.activeVersion.dev}
                                  versions={e.dev.map((v) => v.version)}
                                />
                              )}
                            </>
                          )
                        }
                      }}
                    />
                  </Table.ColumnGroup>
                )}

                {handleQueryState(prodFlags, "prod") && (
                  <Table.ColumnGroup
                    title={<>{`Prod (${prodData.FeatureFlagCount}) `}</>}
                    align="left"
                  >
                    <Table.Column
                      className={prod ? "activeEnvironment" : ""}
                      title="Active Version"
                      align="center"
                      dataIndex="activeVersion"
                      key="activeVersion"
                      render={(_, e: ReducedFlag) => {
                        return (
                          <>
                            {!!e.prod ? (
                              <>
                                <span
                                  style={
                                    e.activeVersion.prod !== e.activeVersion.dev
                                      ? {
                                          background: "#FF7D7D",
                                          padding: "2px 2px",
                                          borderRadius: 2,
                                          marginRight: 4,
                                        }
                                      : undefined
                                  }
                                >
                                  {e.activeVersion.prod}
                                </span>
                                {prod &&
                                  e.activeVersion.prod !==
                                    e.activeVersion.dev && (
                                    <UpdateFeatureFlagModal
                                      flag={e}
                                      env="prod"
                                      mode="sync"
                                    />
                                  )}
                                {prod &&
                                  e.activeVersion.prod !== 0 &&
                                  e.activeVersion.prod ===
                                    e.activeVersion.dev && (
                                    <span className="small-pad">
                                      <UpdateFeatureFlagModal
                                        flag={e}
                                        env="prod"
                                        mode="decrement"
                                      />
                                    </span>
                                  )}
                              </>
                            ) : (
                              <Popconfirm
                                disabled={!prod}
                                title={
                                  <>
                                    <p>
                                      Are you sure about duplicating this flag?
                                    </p>
                                    <p>
                                      This could make hidden features visible in
                                      this environment
                                    </p>
                                  </>
                                }
                                onConfirm={async () => {
                                  await createFeatureFlag({
                                    env: "prod",
                                    token: token.prod,
                                    payload: {
                                      name: e.name,
                                      description: e.description,
                                      activeVersion: e.activeVersion
                                        .dev as number,
                                      type: "app",
                                      versions: e.dev!.map((v) => v.version),
                                    },
                                  })
                                }}
                              >
                                <Tooltip
                                  title={
                                    env === "dev"
                                      ? "This action is only available in the prod environment"
                                      : "Copy Flag"
                                  }
                                >
                                  <Button
                                    type="primary"
                                    icon={<CopyOutlined />}
                                    disabled={env === "dev"}
                                  >
                                    Copy flag
                                  </Button>
                                </Tooltip>
                              </Popconfirm>
                            )}
                          </>
                        )
                      }}
                    />
                    <Table.Column
                      className={prod ? "activeEnvironment" : ""}
                      title="Team Exceptions"
                      align="center"
                      render={(_, e: ReducedFlag) => {
                        if (
                          e.prod &&
                          typeof e.activeVersion.prod === "number"
                        ) {
                          const teamsArray = e.prod.map((version) => {
                            if (e.activeVersion.prod! < version.version)
                              return version.teamExceptions.map((team) => ({
                                ...team,
                                version: version.version,
                              }))
                            return []
                          })

                          const teams = unionBy(flatten(teamsArray))
                          return (
                            <>
                              {teams.map((team) => (
                                <Popconfirm
                                  disabled={!prod}
                                  title="Are you sure about removing this Team?"
                                  onConfirm={() =>
                                    handleDelete(
                                      team.id,
                                      "team",
                                      team.version,
                                      e.name,
                                      e.description ?? ""
                                    )
                                  }
                                  key={team.id + team.id}
                                >
                                  <Tag key={team.id}>
                                    <span style={{ marginRight: 2 }}>
                                      {`${team.name} [${team.version}]`}
                                    </span>
                                    {prod && <CloseOutlined />}
                                  </Tag>
                                </Popconfirm>
                              ))}
                              {prod && (
                                <AddTeamOrUserModal
                                  type="team"
                                  env={env}
                                  flag={e}
                                  activeVersion={e.activeVersion.prod}
                                  versions={e.prod.map((v) => v.version)}
                                />
                              )}
                            </>
                          )
                        }
                      }}
                    />
                    <Table.Column
                      className={prod ? "activeEnvironment" : ""}
                      title="User Exceptions"
                      align="center"
                      render={(_, e: ReducedFlag) => {
                        if (
                          e.prod &&
                          typeof e.activeVersion.prod === "number"
                        ) {
                          const usersArrays = e.prod.map((version) => {
                            if (e.activeVersion.prod! < version.version)
                              return version.userExceptions.map((user) => ({
                                ...user,
                                version: version.version,
                              }))
                            return []
                          })

                          const users = unionBy(flatten(usersArrays))
                          return (
                            <>
                              {users.map((user) => (
                                <Popconfirm
                                  disabled={!prod}
                                  title="Are you sure about removing this User?"
                                  onConfirm={() =>
                                    handleDelete(
                                      user.id,
                                      "user",
                                      user.version,
                                      e.name,
                                      e.description ?? ""
                                    )
                                  }
                                  key={user.id + user.version}
                                >
                                  <Tag>
                                    <span style={{ marginRight: 2 }}>
                                      {user.name}
                                    </span>
                                    {prod && <CloseOutlined />}
                                  </Tag>
                                </Popconfirm>
                              ))}
                              {prod && (
                                <AddTeamOrUserModal
                                  type="user"
                                  env={env}
                                  flag={e}
                                  activeVersion={e.activeVersion.prod}
                                  versions={e.prod.map((v) => v.version)}
                                />
                              )}
                            </>
                          )
                        }
                      }}
                    />
                  </Table.ColumnGroup>
                )}
                <Table.Column
                  title="Change Logs"
                  dataIndex="description"
                  key="changeLogs"
                  render={(c, e: { [key: string]: any }) => (
                    <Row justify="center">
                      <ViewChangeLogsButton
                        flag={e}
                        flagDescription={devData.FeatureFlags}
                      />
                    </Row>
                  )}
                />
                <Table.Column
                  fixed="left"
                  title="Delete"
                  dataIndex="id"
                  key="delete"
                  render={(c, e: { [key: string]: any }) => (
                    <Row justify="center">
                      <Popconfirm
                        title="Delete the task"
                        description={
                          <span>
                            Are you sure to delete the{" "}
                            <b>
                              <u>{e?.name}</u>
                            </b>{" "}
                            flag?
                          </span>
                        }
                        onConfirm={() => handleFeatureFlagDelete(e)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="primary" danger>
                          Delete
                        </Button>
                      </Popconfirm>
                    </Row>
                  )}
                />
              </Table>
            </Card>
          </Col>
        )}

        {!handleQueryState(devFlags, "dev") && (
          <LoginWidget environment="dev" query={devFlags} />
        )}

        {!handleQueryState(prodFlags, "prod") && (
          <LoginWidget environment="prod" query={prodFlags} />
        )}
      </Row>
    </>
  )
}
