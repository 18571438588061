import { EditOutlined, StopOutlined } from "@ant-design/icons"
import { Button, Col, Form, message, Select, Tooltip } from "antd"
import { useState } from "react"
import { useUpdateTeamPreferences } from "../../../apis/teams"
import { useAuthContext } from "../../../context/AuthContext"
import {
  Preferences,
  TeamUnitKeys,
  TeamUnitSelectValuesMap,
  unitsMap,
} from "../../../types/Team"

type Props = {
  data: Preferences
  name: TeamUnitKeys
  id: string
}

export const EditableDimensionWidget = ({ data, name, id }: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const { env, token } = useAuthContext()

  const { mutateAsync: updateTeamPreferences, isLoading } =
    useUpdateTeamPreferences(id, env)

  const toggleIsEditing = () => {
    setIsEditing(!isEditing)
  }

  const handleUpdateTeamPreference = async (value: string) => {
    try {
      await updateTeamPreferences({
        data: { id, [name]: value },
        env,
        token: token[env],
      })
    } catch (error) {
      message.error(error as string)
      return
    } finally {
      toggleIsEditing()
    }
    return
  }

  return isEditing ? (
    <>
      <Col flex="auto">
        <Form style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <Select
            autoFocus
            value={data[name]}
            style={{ width: "200px" }}
            loading={isLoading}
            disabled={isLoading}
            onChange={handleUpdateTeamPreference}
          >
            {Object.entries(TeamUnitSelectValuesMap[name]).map(
              ([key, value]) => (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              )
            )}
          </Select>
          <Button size="small" type="default" onClick={() => toggleIsEditing()}>
            <StopOutlined />
          </Button>
        </Form>
      </Col>
    </>
  ) : (
    <>
      <Col>
        <b>{unitsMap[data[name]]}</b>
      </Col>
      <Col>
        <Tooltip title="Edit">
          <Button size="small" onClick={() => toggleIsEditing()}>
            <EditOutlined />
          </Button>
        </Tooltip>
      </Col>
    </>
  )
}
