import { QuestionCircleFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'

type Props = {
  message: string | React.ReactNode
}
export const TooltipInfo = ({ message }: Props) => {
  return (
    <Tooltip color="#fff" title={<p style={{ width: 200, color: '#414141' }}>{message}</p>}>
      <QuestionCircleFilled style={{ fontSize: '18px', marginLeft: '8px', cursor: 'pointer', color: '#595959' }} />
    </Tooltip>
  )
}
