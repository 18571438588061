import { Button, Form, FormProps, message, Modal, Row, Select } from "antd"
import React, { useEffect, useState } from "react"
import {
  useAddRemoveSysAdmin,
  useGetUserAccounts,
} from "../../../apis/SysAdmin"
import { useAuthContext } from "../../../context/AuthContext"
import { GetError } from "../../../widgets/GetError"
import { SysAdminDataSmall } from "../../../types/SysAdmin"

type FormValues = {
  authId: string
}

type Props = {
  refetch: () => void
}

export default function AddSysAdminModal({ refetch }: Props) {
  const [open, setOpen] = React.useState(false)
  const { token, env } = useAuthContext()
  const { mutateAsync: addRemoveSysAdmin } = useAddRemoveSysAdmin()
  const [page, setPage] = useState(1)
  const [userList, setUserList] = useState<SysAdminDataSmall[]>([])

  const handlePopupScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = event.target as HTMLDivElement
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 10) {
      setPage((prev) => prev + 1)
    }
  }

  const userAccounts = useGetUserAccounts<"small">({
    env,
    token: token[env],
    dto: { format: "small", sysAdminOnly: false, page, size: 25 }, // liam is in 15
    enabled: open,
  })

  const onSubmit: FormProps<FormValues>["onFinish"] = async ({
    authId,
  }: FormValues) => {
    try {
      await addRemoveSysAdmin({
        env,
        token: token[env],
        authId,
        action: "add",
      })

      message.success("User added as system admin successfully.")
    } catch (error) {
      message.error("Failed to add user as system admin. Please try again.")
      return
    } finally {
      setOpen(false)
      refetch()
      setPage(1)
      setUserList([])
    }
  }

  useEffect(() => {
    if (userAccounts.isSuccess && userAccounts.data) {
      setUserList((prev) => [...prev, ...userAccounts.data])
    }
  }, [userAccounts.isSuccess, userAccounts.data])

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={() => setOpen(true)} type="primary">
          Add System Admin
        </Button>
      </div>
      <Modal footer={null} open={open} onCancel={() => setOpen(false)}>
        {userAccounts.isError && <GetError reFetch={userAccounts.refetch} />}
        <Form onFinish={onSubmit}>
          <Form.Item<FormValues>
            name="authId"
            rules={[{ required: true, message: "Please select a use" }]}
          >
            <Select
              showSearch
              placeholder="Select a user to add as a system admin"
              style={{ width: "80%", margin: "1rem" }}
              options={userList?.map((user) => ({
                value: user.id,
                label: user.name,
              }))}
              onPopupScroll={handlePopupScroll}
              filterOption={(input, option) => {
                return Boolean(
                  option?.label.toLowerCase().includes(input.toLowerCase())
                )
              }}
            />
          </Form.Item>
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
