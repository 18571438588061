import { Tabs } from "antd"
import TeamParternshipRequests from "../components/Team/TeamParternshipRequests"
import TeamTotals from "../components/Team/TeamTotals"

export default function TeamMetrics() {
  return (
    <Tabs>
      <Tabs.TabPane tab="General" key="general-metrics">
        <TeamTotals />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Partnership Requests" key="partnership-metrics">
        <TeamParternshipRequests />
      </Tabs.TabPane>
    </Tabs>
  )
}
