import { Button, Form, Input, InputNumber, message, Modal, Select } from "antd"
import { SelectProps } from "antd/lib"
import React, { useState } from "react"
import {
  useEditUsageCategory,
  useGetUsageCases,
} from "../../../apis/UsageScores"
import { useAuthContext } from "../../../context/AuthContext"
import {
  UpdateCategoryWeight,
  UpdateUsageCategoryRequestDto,
} from "../../../types/UsageScores"
import { environmentType } from "../../../types/environtment"
import { GroupedData } from "../UsageCaseCategoryTable"
import "../UsageScores.scss"

type Props = {
  category: GroupedData
}

const UsageCategoryEditButton = React.memo<Props>(({ category }) => {
  const [open, setOpen] = useState(false)
  const [form] = Form.useForm()
  const [selectedUseCases, setSelectedUseCases] = useState<
    UpdateCategoryWeight[]
  >(
    category.children.map((w) => ({
      id: w.id,
      useCaseId: w.useCaseId,
      name: w.name,
      weight: w.weight,
    }))
  )

  const [categoryName, setCategoryName] = useState(category.name as string)
  const { mutateAsync: editUsageCategory, isLoading } = useEditUsageCategory()
  const { env, token } = useAuthContext()
  const usageCases = useGetUsageCases({
    env,
    token: token[env],
  })

  if (usageCases.isLoading) return <div>Loading...</div>
  if (!usageCases.data) return <div>No data</div>

  const onSelectChange: SelectProps<string[]>["onChange"] = (
    value: string[]
  ) => {
    const prevUseCaseIds = selectedUseCases.map((u) => u.useCaseId)

    // deleting a use case
    const deletedUseCase = prevUseCaseIds.filter((p) => !value.includes(p))
    if (deletedUseCase.length > 0) {
      setSelectedUseCases((prev) =>
        prev.filter((p) => !deletedUseCase.includes(p.useCaseId))
      )
      return
    }

    // adding a use case
    const addedUseCase = value.filter((v) => !prevUseCaseIds.includes(v))
    if (addedUseCase.length > 0) {
      setSelectedUseCases((prev) => [
        ...prev,
        ...addedUseCase.map((a) => ({
          id: "",
          useCaseId: a,
          weight: 1,
          name: usageCases.data?.find((u) => u.id === a)?.name ?? "",
        })),
      ])
      return
    }
    return
  }

  const handleWeightChange = (e: number | null, useCase: string) => {
    if (e === null) return

    setSelectedUseCases((prev) =>
      prev.map((p) => (p.useCaseId === useCase ? { ...p, weight: e } : p))
    )
    return
  }

  const onOk = async () => {
    const name = form.getFieldValue("name")

    if (!name) return message.error("Category name is required")
    if (selectedUseCases.length === 0)
      return message.error("Use cases are required")

    const categoryReqDto: UpdateUsageCategoryRequestDto = {
      id: category.id,
      name: category.name,
      weights: selectedUseCases,
    }

    const newBody: {
      env: environmentType
      token: string | null
      dto: UpdateUsageCategoryRequestDto
      id: string
    } = {
      env,
      token: token[env],
      dto: categoryReqDto,
      id: category.id,
    }

    try {
      await editUsageCategory(newBody)
    } catch (error) {
      message.error("Error editing category")
    } finally {
      form.resetFields()
      setOpen(false)
      setSelectedUseCases([])
      setCategoryName("")

      return
    }
  }

  const deleteUseCase = (useCase: string) => {
    setSelectedUseCases((prev) => {
      const filtered = prev.filter((p) => p.useCaseId !== useCase)
      form.setFieldValue(
        "useCases",
        filtered.map((f) => f.useCaseId)
      )
      return filtered
    })

    return
  }

  const initModal = () => {
    form.setFieldsValue({
      name: category.name,
      useCases: category.children.map((u) => u.useCaseId),
    })
    if (selectedUseCases.length === 0) {
      setSelectedUseCases(
        category.children.map((w) => ({
          id: w.id,
          useCaseId: w.useCaseId,
          name: w.name,
          weight: w.weight,
        }))
      )
    }
    setOpen(true)
    return
  }

  return (
    <>
      <Button type="primary" onClick={initModal}>
        Edit
      </Button>
      {open && (
        <Modal
          open={open}
          title="Edit Category"
          onCancel={() => setOpen(false)}
          width={"70%"}
          onOk={onOk}
          confirmLoading={isLoading}
        >
          <Form
            form={form}
            initialValues={{
              name: category.name,
              useCases: category.children.map((u) => u.useCaseId),
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                paddingTop: "2rem",
                paddingBottom: "2rem",
              }}
            >
              <Form.Item label="Category Name" name="name">
                <Input
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Select Use Cases" name="useCases">
                <Select
                  mode="multiple"
                  placeholder="Select use cases"
                  style={{ width: "100%" }}
                  onChange={onSelectChange}
                  options={usageCases.data?.map((u) => ({
                    label: u.name,
                    value: u.id,
                  }))}
                  optionRender={(u) => u.label}
                  filterOption={(text, option) => {
                    const textValue = (option?.label as string) || ""
                    return textValue.toLowerCase().includes(text.toLowerCase())
                  }}
                />
              </Form.Item>
            </div>
            <table className="usageTable">
              <thead>
                <tr>
                  <th>Use Case</th>
                  <th>Weight</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {selectedUseCases.map((u) => (
                  <tr key={u.id + u.useCaseId}>
                    <td>{u.name}</td>
                    <td className="center-td">
                      <InputNumber
                        min={1}
                        max={100}
                        defaultValue={u.weight}
                        onChange={(e) => handleWeightChange(e, u.useCaseId)}
                      />
                    </td>
                    <td className="center-td-button">
                      <Button
                        size="small"
                        type="primary"
                        danger
                        onClick={() => deleteUseCase(u.useCaseId)}
                      >
                        x
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Form>
        </Modal>
      )}
    </>
  )
})

export default UsageCategoryEditButton
