import axios from "axios"
import { VizznUser } from "../../types/user"
import { VizznResponse } from "../../types/Api"
import { getEnvUrl } from "../../utils/utils"
import { environmentType } from "../../types/environtment"

export const getUserAccountV2 = async (body: {
  id: string
  token: string | null
  env: environmentType
}) => {
  try {
    // TODO: Get type
    const { data } = await axios.get<VizznResponse<VizznUser>>(
      `${getEnvUrl(body.env)}/v2/user-accounts/${body.id}`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    return data.data
  } catch (error: any) {
    //   message.error(error.message)
  }
}
