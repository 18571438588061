import { useState } from "react"
import Map, { Marker } from "react-map-gl"
import { TeamCoordinates } from "../../../types/Team"

type Props = {
  coordinate: TeamCoordinates
}

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`

const SIZE = 20

export default function TeamLocationMap({ coordinate }: Props) {
  const [viewport, setViewport] = useState({
    latitude: coordinate?.lat,
    longitude: coordinate?.lng,
    zoom: 7, // Initial zoom level
  })

  if (!coordinate?.lat || !coordinate?.lng)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <strong>No coordinates found</strong>
      </div>
    )

  return (
    <Map
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
      style={{ width: "100%", height: "100%" }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      {...viewport}
      onMove={(evt) => setViewport(evt.viewState)}
    >
      <Marker longitude={coordinate?.lng} latitude={coordinate?.lat}>
        <svg
          height={SIZE}
          viewBox="0 0 24 24"
          style={{
            cursor: "pointer",
            fill: `red`,
          }}
        >
          <path d={ICON} />
        </svg>
      </Marker>
    </Map>
  )
}
