import { useState, useEffect } from "react";

export const useWindowDimensions = () => {
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [dimensions, setDimensions] = useState(getCurrentDimension());

  useEffect(() => {
    const updateDimension = () => {
      setDimensions(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [dimensions]);

  return dimensions;
};
