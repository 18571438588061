import { Input, Spin, Typography } from "antd"
import "mapbox-gl/dist/mapbox-gl.css"
import { ChangeEvent, useState } from "react"
import { useTeams } from "../../apis/teams"
import { CALGARY_LAT_LNG } from "../../constants/CalgaryLatLng"
import { useAuthContext } from "../../context/AuthContext"
import useViewportSize from "../../hooks/useViewportSize"
import { GetError } from "../../widgets/GetError"
import "./TeamMaps.scss"
import TeamMapsV2 from "./TeamMapsV2"

export default function TeamMaps() {
  const { token, env } = useAuthContext()
  const teams = useTeams({ env, token: token[env] })
  const [searchQuery, setSearchQuery] = useState("")
  const [viewport, setViewport] = useState({
    ...CALGARY_LAT_LNG,
    zoom: 4, // Initial zoom level
  })

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const filteredTeams = teams.data
    ?.filter((team) =>
      team.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name))

  const { height } = useViewportSize()

  if (teams.isLoading) return <Spin />
  if (teams.error || !teams.data) return <GetError reFetch={teams.refetch} />

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
      }}
    >
      <TeamMapsV2
        data={teams.data}
        viewport={viewport}
        setViewport={setViewport}
      />

      <div
        style={{
          width: 350,
          padding: 10,
        }}
      >
        {/* List of teams. Filter teams. On click, focus on location */}
        <Input.Search
          placeholder="Search for a team"
          onChange={handleSearch}
          allowClear
        />
        <div style={{ overflowY: "auto", height: height - 170 }}>
          {filteredTeams
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((team) => (
              <div
                key={team.id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  padding: 5,
                  fontSize: team.coordinate?.lat ? 16 : 14,
                  ...(team.coordinate?.lat && { cursor: "pointer" }),
                  fontWeight: team.coordinate?.lat ? "bold" : "normal",
                  overflowY: "auto",
                }}
                onClick={() => {
                  if (!team.coordinate?.lat) return

                  setViewport({
                    // ...viewport,
                    latitude: team.coordinate?.lat || 0,
                    longitude: team.coordinate?.lng || 0,
                    zoom: 14,
                  })
                }}
              >
                <Typography.Text>{team.name}</Typography.Text>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
