import { Button } from "antd";
import React from "react";
import { getAppUrl } from "../../../utils/utils";
import { useAuthContext } from "../../../context/AuthContext";
import { LinkOutlined } from "@ant-design/icons";
type Props = {
  type: string;
  id: string;
};
export const EntityLink = ({ type, id }: Props) => {
  const { env } = useAuthContext();
  const entityType = {
    equipment: {
      path: "/equipment",
    },
    contact: {
      path: "/contacts",
    },
    site: {
      path: "/sites",
    },
    "dispatch-request": { path: "/dispatch-requests" },
    dispatch: { path: "/dispatches" },
    trailer: { path: "/trailers" },
    truck: {
      path: "/trucks",
    },
    "truck-configuration": { path: "/truck-configurations" },
    "trailer-combination": { path: "/trailer-combinations" },
    "external-company": {
      path: "/partners",
    },
    "dispatch-resource": { path: "/dispatch-resources/" },
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `${getAppUrl(env)}/${
        entityType[type as keyof typeof entityType].path
      }/${id}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  return (
    <Button icon={<LinkOutlined />} type="primary" onClick={openInNewTab}>
      View in Vizzn App
    </Button>
  );
};
