import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  SelectProps,
} from "antd"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useCreateUsageCategory } from "../../../apis/UsageScores"
import { useAuthContext } from "../../../context/AuthContext"
import {
  CreateUsageCategoryRequestDto,
  UsageCase,
  UsageCategoryWeightRequestDto,
} from "../../../types/UsageScores"
import "../UsageScores.scss"
import AddAllPrompt from "./AddAllPrompt"

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  usageCases: UsageCase[]
}

export default function NewUseCaseCategoryModal({
  open,
  setOpen,
  usageCases,
}: Props) {
  const [selectedUseCases, setSelectedUseCases] = useState<
    UsageCategoryWeightRequestDto[]
  >([])
  const [searchTerm, setSearchTerm] = useState<string>("")
  const { env, token } = useAuthContext()
  const { mutateAsync: createUsageCategory, isLoading } =
    useCreateUsageCategory()
  const [categoryName, setCategoryName] = useState<string>("")

  const isSearchTermThreeOrMoreCharacters = useMemo(
    () => searchTerm.length >= 3,
    [searchTerm]
  )

  const handleWeightChange = (e: number | null, useCaseId: string) => {
    if (e === null) return

    setSelectedUseCases((prev) =>
      prev.map((p) => (p.useCaseId === useCaseId ? { ...p, weight: e } : p))
    )
    return
  }

  const resetFields = useCallback(() => {
    setOpen(false)
    setSelectedUseCases([])
    setCategoryName("")
  }, [setOpen, setSelectedUseCases])

  const onOk = async () => {
    if (!categoryName) return message.error("Category name is required")
    if (selectedUseCases.length === 0)
      return message.error("Use cases are required")
    const categoryReqDto: CreateUsageCategoryRequestDto = {
      name: categoryName,
      weights: selectedUseCases,
    }

    const newBody = {
      env,
      token: token[env],
      dto: categoryReqDto,
    }

    try {
      await createUsageCategory(newBody)
    } catch (error) {
      message.error("Error creating category")
    } finally {
      resetFields()
      return
    }
  }

  const handleSearch = (value: string) => {
    setSearchTerm(value || "")
  }

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (
      isSearchTermThreeOrMoreCharacters &&
      event.key === "Enter" &&
      (event.metaKey || event.ctrlKey)
    ) {
      event.preventDefault()

      const filteredUseCases = usageCases.filter((u) =>
        u.name.toLowerCase().includes(searchTerm.toLowerCase())
      )

      setSelectedUseCases((prev) => [
        ...prev,
        ...filteredUseCases.map((u) => ({
          useCaseId: u.id,
          weight: 1,
          name: u.name,
        })),
      ])
      setSearchTerm("")
      return
    }

    if (event.key === "Enter") {
      event.preventDefault()
      const newUseCase = usageCases.find(
        (u) => u.name.toLowerCase() === searchTerm.toLowerCase()
      )
      if (newUseCase) {
        setSelectedUseCases((prev) => [
          ...prev,
          {
            useCaseId: newUseCase.id,
            weight: 1,
            name: newUseCase.name,
          },
        ])
      }
    }
  }

  const deleteUseCase = (useCase: string) =>
    setSelectedUseCases((prev) => prev.filter((p) => p.useCaseId !== useCase))

  const onSelectChange: SelectProps<string>["onSelect"] = (value: string) => {
    const newUseCase = usageCases.find((u) => u.id === value)
    if (!newUseCase) return

    setSelectedUseCases((prev) => [
      ...prev,
      { useCaseId: newUseCase.id, weight: 1, name: newUseCase.name },
    ])
  }

  useEffect(() => {
    return () => {
      // setOpen(false);
      setSelectedUseCases([])
      setSearchTerm("")
    }
  }, [setOpen])

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      width={"70%"}
      onOk={onOk}
      confirmLoading={isLoading}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          paddingTop: "2rem",
          paddingBottom: "2rem",
        }}
      >
        <Form.Item label="Category Name">
          <Input
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </Form.Item>

        <Select
          mode="multiple"
          allowClear
          onClear={() => setSelectedUseCases([])}
          style={{ width: "100%" }}
          placeholder="Please select use cases"
          value={selectedUseCases.map((s) => s.useCaseId)}
          onDeselect={(value) => deleteUseCase(value)}
          onSelect={onSelectChange}
          filterOption={(text, option) => {
            const textValue = (option?.children as unknown as string) || ""
            setSearchTerm(text)
            return textValue.toLowerCase().includes(text.toLowerCase())
          }}
          onSearch={handleSearch}
          onInputKeyDown={handleKeyDown}
          dropdownRender={(menu) => (
            <>
              {isSearchTermThreeOrMoreCharacters && (
                <AddAllPrompt searchTerm={searchTerm} />
              )}
              {menu}
            </>
          )}
        >
          {usageCases?.map((u) => (
            <Select.Option key={u.id} value={u.id}>
              {u.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <table className="usageTable">
        <thead>
          <tr>
            <th>Use Case</th>
            <th>Weight</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {selectedUseCases.map((u) => (
            <tr key={u.useCaseId}>
              <td>{u.name}</td>
              <td className="center-td">
                <InputNumber
                  min={1}
                  max={100}
                  defaultValue={u.weight}
                  onChange={(e) => handleWeightChange(e, u.useCaseId)}
                />
              </td>
              <td className="center-td-button">
                <Button
                  size="small"
                  type="primary"
                  danger
                  onClick={() => deleteUseCase(u.useCaseId)}
                >
                  x
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  )
}
