import React, { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../../../context/AuthContext";
import { environmentType } from "../../../types/environtment";
import { Form, Select } from "antd";
import { debouncedTeamFetch } from "../../../apis/teams";
import { TeamsResponse } from "../../../types/Team";
type Props = {
  env: environmentType;
  onChange?: (id: string) => void;
};

export const SearchTeamDropdown = ({ env }: Props) => {
  const { token } = useAuthContext();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState<TeamsResponse>();
  const fetchTeams = useCallback(async () => {
    if (!searchTerm) return;
    setLoading(true);
    const data = await debouncedTeamFetch({
      env,
      token: token[env],
      searchTerm,
    });
    if (data) setTeams(data);
    setLoading(false);
  }, [env, searchTerm, token]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchTeams();
    }, 400);
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <Form.Item label="Team" name="users">
      <Select
        placeholder="Type to search for an team name"
        loading={loading}
        onSearch={(value) => setSearchTerm(value)}
        showSearch
        allowClear
        filterOption={(input, option) =>
          option?.searchValue?.toLowerCase()?.includes(input.toLowerCase())
        }
      >
        {teams?.records
          .sort((a, b) =>
            a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1
          )
          .map(({ id, name }) => (
            <Select.Option key={id} value={id} searchValue={name}>
              {name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};
