import { Switch } from "antd"
import { useUpdateUserFocusModeOnly } from "../../../../apis/user"
import { useAuthContext } from "../../../../context/AuthContext"
import { RowContent } from "../../../Utils/RowContent"

type Props = { id: string }

export default function UserFocusModeOnly({ id }: Props) {
  const { token, env } = useAuthContext()
  const { mutateAsync: updateUserFocusModeOnly, isLoading } =
    useUpdateUserFocusModeOnly(id, env)

  const handleFocusModeChange = async (e: boolean) => {
    try {
      await updateUserFocusModeOnly({
        payload: {
          id,
          focusModeOnly: e,
        },
        env,
        token: token[env] as string,
      })
      return
    } catch (error) {
      console.log(error)
      return
    } finally {
      return
    }
  }

  return (
    <RowContent
      name="Focus Mode Only"
      content={
        <Switch
          checkedChildren="YES"
          unCheckedChildren="NO"
          onChange={handleFocusModeChange}
          loading={isLoading}
          disabled={isLoading}
        />
      }
    />
  )
}
