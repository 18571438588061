import { CheckOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Form, Input, message, Typography } from "antd"
import { useState } from "react"
import { usePatchUserData } from "../../../../apis/user"
import { useAuthContext } from "../../../../context/AuthContext"

type Props = {
  id: string
  firstName: string
  lastName: string
}

type FormValues = {
  firstName: string
  lastName: string
}

export default function UserNameWidget({ id, firstName, lastName }: Props) {
  const { token, env } = useAuthContext()
  const { mutateAsync: updateUserName } = usePatchUserData(env)
  const [isEditing, setIsEditing] = useState(false)
  const [form] = Form.useForm<FormValues>()

  const onSubmit = async (values: FormValues) => {
    try {
      if (firstName === values.firstName && lastName === values.lastName) return
      await updateUserName({
        payload: {
          id,
          firstName: values.firstName,
          lastName: values.lastName,
        },
        env,
        token: token[env] as string,
      })
      return
    } catch (error) {
      message.error("Error updating user name. Please try again.")
      return
    } finally {
      setIsEditing(false)
    }
  }

  if (!isEditing) {
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          minHeight: "50px",
        }}
      >
        <Typography.Title style={{ margin: "0" }}>
          {firstName} {lastName}
        </Typography.Title>
        <Button size="small" onClick={() => setIsEditing(true)}>
          <EditOutlined />
        </Button>
      </div>
    )
  }

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        minHeight: "50px",
        flexWrap: "wrap",
      }}
    >
      <Form.Item<FormValues>
        name="firstName"
        initialValue={firstName}
        style={{ margin: 0 }}
      >
        <Input style={{ width: "200px", flex: 1 }} />
      </Form.Item>
      <Form.Item<FormValues>
        name="lastName"
        initialValue={lastName}
        style={{ margin: 0 }}
      >
        <Input style={{ width: "200px", flex: 1 }} />
      </Form.Item>
      <Button size="small" type="primary" htmlType="submit">
        <CheckOutlined />
      </Button>
    </Form>
  )
}
