import { Button, Col, Row, Table } from "antd"
import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { UsageCategory } from "../../types/UsageScores"
import UsageCategoryDeleteButton from "./partials/UsageCategoryDeleteButton"
import UsageCategoryEditButton from "./partials/UsageCategoryEditButton"

type Props = {
  data: UsageCategory[]
}

export type GroupedData = {
  key: string
  id: string
  name: string
  children: GroupedDataChild[]
}

type GroupedDataChild = {
  name: string
  weight: number
  id: string
  useCaseId: string
}

const UsageCaseCategoryTable = React.memo<Props>(({ data }) => {
  const groupedData: GroupedData[] = data.map((item: UsageCategory) => {
    return {
      key: item.id,
      id: item.id,
      name: item.name,
      children:
        item.weights?.map((w) => ({
          id: w.id,
          useCaseId: w.useCase.id,
          name: w.useCase.name,
          weight: w.weight,
        })) ?? [],
    }
  })

  const columns = useMemo(
    () => [
      {
        title: () => (
          <Row align="middle" justify="start">
            Category/Use Case Name
          </Row>
        ),
        dataIndex: "name",
        key: "name",
      },

      {
        title: () => (
          <Row align="middle" justify="center">
            Weight
          </Row>
        ),
        dataIndex: "weight",
        key: "weight",
        render: (text: string) => (
          <Row align="middle" justify="center">
            {text}
          </Row>
        ),
      },
      {
        title: () => (
          <Row align="middle" justify="center">
            Actions
          </Row>
        ),
        key: "actions",
        width: "300px",
        render: (_: any, record: GroupedData) => {
          if (record.children) {
            return (
              <Row align="middle" justify="center" gutter={8}>
                <Col>
                  <Link to={`/usage-category/${record.id}/${record.name}`}>
                    <Button>View Detail</Button>
                  </Link>
                </Col>
                {record.children.length > 0 && (
                  <>
                    <Col>
                      <UsageCategoryEditButton category={record} />
                    </Col>
                    <Col>
                      <UsageCategoryDeleteButton category={record} />
                    </Col>
                  </>
                )}
              </Row>
            )
          }

          return null
        },
      },
    ],
    []
  )

  return <Table columns={columns} dataSource={groupedData} />
})

export default UsageCaseCategoryTable
