import { TooltipProps } from "recharts"
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent"
import convertStringToTitleCase from "../utils/convertStringToTitleCase"

export const CustomRechartsTooltip = (e: TooltipProps<ValueType, NameType>) => {
  const { label, payload, active } = e
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
          color: "black",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
          }}
        >{`${label}`}</p>
        <p>{`${convertStringToTitleCase(payload[0].name?.toString() ?? "")}: ${
          payload[0].value
        }`}</p>
      </div>
    )
  }

  return null
}
