import { UsergroupAddOutlined } from "@ant-design/icons"
import { Button, Form, Input, Select, Modal, message, Alert } from "antd"
import { useState } from "react"
import { useCreateProperty } from "../../../apis/notifications"
import { useAuthContext } from "../../../context/AuthContext"
import { NotifiableEntityType } from "../../../types/notifications"
import { entityType } from "../constant"

export const NewPropertyModal = () => {
  const [open, setOpen] = useState(false)
  const [propertyStatement, setPropertyStatement] = useState(<></>)
  const [form] = Form.useForm()
  const { mutateAsync: createProperty } = useCreateProperty()
  const { token } = useAuthContext()
  const [creating, setCreating] = useState(false)
  const { TextArea } = Input

  const handleFormUpdate = () => {
    const values: Values = form.getFieldsValue()

    setPropertyStatement(
      values.property ? (
        <>
          <b>{values.property.toLowerCase()}</b> is a property of{" "}
          <b>{values.relation?.toLowerCase() || values.entityType}</b>{" "}
          {values.relation && (
            <>
              and is to be notified to user who is subscribed to a{" "}
              <b>{values.entityType}</b>
            </>
          )}
        </>
      ) : (
        <>Please enter property name</>
      )
    )
  }

  type Values = {
    entityType: NotifiableEntityType
    relation?: string
    property: string
    description?: string
  }

  const handleCreateProperty = async () => {
    const values: Values = form.getFieldsValue()

    const entityType = values.entityType
    const id: string[] = [entityType]

    const relation = values.relation?.trim()
    if (relation) id.push(relation)

    const property = values.property.trim()
    id.push(property)

    const payload = {
      id: id.join("."),
      entityType,
      relation,
      property,
      description: values.description?.trim(),
    }
    const hasError = await form.validateFields()
    if (Array.isArray(hasError)) return

    if (!token.dev) {
      return message.error("Please log in on Dev")
    }
    if (!token.prod) {
      return message.error("Please log in on Prod")
    }

    setCreating(true)
    await Promise.allSettled([
      createProperty({ env: "dev", token: token.dev, payload }),
      createProperty({ env: "prod", token: token.prod, payload }),
    ])
    handleCloseModal()
  }

  const handleCloseModal = () => {
    form.resetFields()
    handleFormUpdate()
    setCreating(false)
    setOpen(false)
  }
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        icon={<UsergroupAddOutlined />}
        type="primary"
      >
        Create New Property
      </Button>
      {open && (
        <Modal
          title="New Property"
          open={open}
          onCancel={handleCloseModal}
          onOk={() => handleCreateProperty()}
          okButtonProps={{ loading: creating }}
        >
          <Alert
            message={
              <>
                This property will be created for dev and prod, You need to be
                logged in both environments
                <br />
                <b>Relation</b> and <b>Property</b> are to use <b>snake_case</b>
                , uppercase characters are not allowed.
              </>
            }
            type="info"
            style={{ marginBottom: 12 }}
          />
          <Alert
            message={propertyStatement}
            type="warning"
            style={{ marginBottom: 12 }}
          />
          <Form form={form} layout="vertical" onChange={handleFormUpdate}>
            <Form.Item
              name="entityType"
              label="Entity Type"
              rules={[
                {
                  required: true,
                  message: "Please select entity type",
                },
              ]}
            >
              <Select onChange={handleFormUpdate}>
                {entityType.map((entity) => (
                  <Select.Option value={entity.id}>{entity.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="relation" label="Relation">
              <Input style={{ textTransform: "lowercase" }} />
            </Form.Item>
            <Form.Item
              name="property"
              label="Property"
              rules={[
                {
                  required: true,
                  message: "Please input property name",
                },
              ]}
            >
              <Input style={{ textTransform: "lowercase" }} />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <TextArea rows={6} />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}
